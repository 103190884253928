import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as convert from 'xml-js';

const TOKEN_KEY = 'auth-token';
const REFRESH_KEY = 'auth-refresh';
const USER_KEY = 'auth-user';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export class Alert{
title: string;
link: string;
pubDate: string;
}

@Injectable({
  providedIn: 'root'
})
export class AdminService  {

  private API_ENDPOINT: string;

  constructor(private http: HttpClient) {
    this.API_ENDPOINT = `${environment.apiURL}admin/`;
  }

  public getAllUsers():Observable<any>
  {
    return this.http.get<any>(this.API_ENDPOINT + 'getAllUsers',httpOptions);
  }

  public adminPasswordReset(email: string)
  {
    return this.http.post<any>( this.API_ENDPOINT + `adminPasswordReset?email=${email}`, {
    },httpOptions).pipe(
      catchError(this.handelError), tap(resData =>
        {

        })
    )
  }

  handelError(errorRes)
  {
   let errorMessage = 'An unknown error occurred!'

   if(!errorRes.error||!errorRes.error.result)
   {
     return throwError(() => { return new Error(errorMessage)});
   }

   switch (errorRes.error.result) {
     case 'INVALID_CREDENTIALS':
       errorMessage = "Invalid Login Credentials";
       break;
     case 'EMAIL_EXISTS':
       errorMessage = "An Account Already Exists With That Email";
       break;

     default:
       break;
   }

   return throwError(() => { return new Error(errorMessage)});

  }
}


