import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { FeedbackDTO } from './models/feedbackDTO';

@Component({
  selector: 'app-send-feedback-dialog',
  templateUrl: './send-feedback-dialog.component.html',
  styleUrls: ['./send-feedback-dialog.component.css']
})
export class SendFeedbackDialogComponent implements OnInit {
  feedbackForm: UntypedFormGroup;

  constructor(private dialogRef:MatDialogRef<SendFeedbackDialogComponent>) { }

  ngOnInit(): void {
    this.feedbackForm = new UntypedFormGroup({
      'type': new UntypedFormControl(null, CustomValidators.required),
      'feedback': new UntypedFormControl(null, CustomValidators.required),
      'firstName': new UntypedFormControl(null, [CustomValidators.required]),
      'lastName' :new UntypedFormControl(null, [CustomValidators.required]),
      'email': new UntypedFormControl(null, [CustomValidators.required, CustomValidators.email]),
    }
      );

  }

  onSubmit(): void {


    let feedback = new FeedbackDTO();

    Object.assign(feedback, this.feedbackForm.value);

    this.dialogRef.close(feedback);
  }


}
