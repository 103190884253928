import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PromiseExtended } from 'dexie';
import { environment } from 'src/environments/environment';
import { NpvReport } from '../models/NPV Calculator/npv-report-model';
import { DexieDatabaseService } from './dexieIndexDB.service';

interface calcValues {
  key: string,
  value: number
}

@Injectable({
  providedIn: 'root'
})
export class NpvCalculatorDataStorageService {

  calculationData: {
    costs: calcValues[]
    benefits: calcValues[]
  } = {
      costs: [],
      benefits: []
    }


  constructor(private dexieService:DexieDatabaseService, private http: HttpClient) {
  }

  get(id:string): PromiseExtended<NpvReport>
  {
    return this.dexieService.npvReports.where("paddockID").equalsIgnoreCase(id).or("propertyID").equalsIgnoreCase(id).first();
  }

  add(report: NpvReport)
  {
    return this.dexieService.npvReports.put(report);
  }

  submit(report: NpvReport)
  {
    return this.http.post(environment.apiURL + `NpvReport/${report.paddockID == null
      ? 'property'
      : 'paddock'}/add`,report)
  }

  update(report: NpvReport)
  {
    return this.dexieService.npvReports.update(report.npvReportID, report);
  }

  updateCosts(data: calcValues) {

    let valueUpdated: boolean = false;

    // if value exists updated the value
    this.calculationData.costs.length > 0 && this.calculationData.costs.forEach(cost => {
      if (cost.key === data.key) {
        cost.value = data.value;

        valueUpdated = true;
        return valueUpdated
      }
    })

    if (!valueUpdated) {
      // else add the value
      this.calculationData.costs.push(data)
    } else {
      return valueUpdated
    }


  }

  updateBenefits(data: calcValues) {

    let valueUpdated: boolean = false;

    // if value exists updated the value
    this.calculationData.benefits.length > 0 && this.calculationData.benefits.forEach(benefit => {
      if (benefit.key === data.key) {
        benefit.value = data.value;

        valueUpdated = true;
        return valueUpdated
      }
    })

    if (!valueUpdated) {
      // else add the value
      this.calculationData.benefits.push(data)
    } else {
      return valueUpdated
    }
  }

  // Sum of all costs
  returnTotalCosts() {
    let total: number = 0;

    this.calculationData.costs.forEach(cost => {
      if (cost.value) {
        total += cost.value
      }
    })

    return total;
  }

  // Sum of all benefits
  returnTotalBenefits() {
    let total: number = 0;

    this.calculationData.benefits.forEach(cost => {
      if (cost.value) {
        total += cost.value
      }
    })

    return total;
  }

  // Return a sum of a specific cost
  getSpecificBenefit(filterValue: string) {
    let value = 0;
    this.calculationData.benefits && this.calculationData.benefits.forEach(benefit => {
      if (benefit.key === filterValue) {
        value = benefit.value;
        return;
      }
    });

    return value;
  }

  // Return a sum of a specific benefit
  getSpecificCost(filterValue: string) {
    let value = 0;
    this.calculationData.costs && this.calculationData.costs.forEach(cost => {
      if (cost.key === filterValue) {
        value = cost.value;
        return;
      }
    });
    return value;
    setTimeout(() => {
    }, 100)
  }

}
