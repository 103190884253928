<div class="basemaps-selector" (click)="openGallery()" >
  <img id="active_basemap_tile" src="assets/images/basemaps/{{getLayerImageName(selectedBaseMap)}}.png" class="active-basemap-tile" />
</div>

<!-- Basemap Gallery -->
<div #gallery class="basemaps-gallery" [@galleryState]="isOpen"
(@galleryState.start)="$event.element.style.display = 'grid'"
(@galleryState.done)="$event.element.style.display = ($event.toState ? 'grid' : 'none');"
(blur)="lostFocus()"
tabindex="0"
>

  <!-- Gallery Title -->
  <div class="basemap-gallery-title mb-2"
  >
    Basemaps Gallery
  </div>

  <!-- Gallery Basemap Thumbnails -->
  <div class="basemaps-gallery-container">
      <div *ngFor="let layer of layers" class="text-center basemaps-gallery-tile-container">
        <img src="assets/images/basemaps/{{getLayerImageName(layer)}}.png" id="bingmaps-basemap" (click)="setBaseMapVisible(layer)" class="basemaps-gallery-tile" />
        <p class="basemap-name">{{layer.get('title')}}</p>
      </div>
  </div>
</div>
