import { Component, OnInit, Input, SimpleChange, Output, EventEmitter } from '@angular/core';
import { NpvCalculatorDataStorageService } from 'src/app/services/npv-calculator-data-storage.service';

@Component({
  selector: 'app-arisings-revenue',
  templateUrl: './arisings-revenue.component.html',
  styleUrls: ['./arisings-revenue.component.css']
})
export class ArisingsRevenueComponent implements OnInit {

  @Input() impactOnHarvest: number = 0;
  @Input() totalActualLogsHarvested: number = 0;
  @Input() harvestThatAreArisings: number = 0;
  @Input() arisingsPrice: number = 0;
  @Input() discountRate: number = 0;
  @Input() harvestLifespan: number | string = 0;
  @Output() calculationsValueEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private npvCalcService: NpvCalculatorDataStorageService) { }

  ngOnInit(): void {
  }

  ngOnChanges(change: SimpleChange) {

    // Passing values to the parent component from 
    if (change) {
      const calculationData = {
        "Arisings Revenue": {
          "Impact on Harvest Rates from FPP (% reduction)": this.impactOnHarvest,
          "Total Actual Volume of Logs Harvested (tonne)": this.totalActualLogsHarvested,
          "% of Harvest that are Arisings": this.harvestThatAreArisings,
          "Total Actual Volume of Sawlogs Sold (tonne)": this.calculateTotalActualLogsHarvested(),
          "Arisings Price ($/tonne)": this.arisingsPrice,
          "Monetary Value": this.calculateMonetaryValue(),
          "Discount Rate": this.discountRate,
          "Harvest Lifespan": this.harvestLifespan,
          "PV(B)": this.calculatePVb().toFixed(2),
        }
      }
      this.calculationsValueEmitter.emit(calculationData)
    }

  }

  calculateTotalActualLogsHarvested() {
    return this.totalActualLogsHarvested * (this.harvestThatAreArisings / 100)
  }


  calculateMonetaryValue() {
    return this.calculateTotalActualLogsHarvested() * this.arisingsPrice
  }

  calculatePVb() {


    if (this.harvestLifespan === "Indefinite") {

      const pVB = this.calculateMonetaryValue() / (this.discountRate / 100)
      this.npvCalcService.updateBenefits({ key: 'arisings-revenue', value: pVB })
      return pVB

    }

    else {

      const pVB = this.calculateMonetaryValue() * ((1 - (1 + (this.discountRate / 100)) ** -this.harvestLifespan) / (this.discountRate / 100))
      this.npvCalcService.updateBenefits({ key: 'arisings-revenue', value: pVB })
      return pVB

    }

  }

}
