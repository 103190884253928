import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round'
})
export class RoundingPipe implements PipeTransform {

  transform(value: number,decimal: number,  ...args: unknown[]): number {
    return parseFloat(value.toFixed(decimal));
  }

}
