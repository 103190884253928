import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatDividerModule } from '@angular/material/divider';

import { MatIconModule } from '@angular/material/icon';

import { MatSidenavModule } from '@angular/material/sidenav';

import { MatToolbarModule } from '@angular/material/toolbar';

import { RouterModule } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { MapRoleNamesPipe } from './pipes/map-role-names.pipe';
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog/confirm-delete-dialog.component';


import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OnlineStatusModule } from 'ngx-online-status';
import { PasswordResetDialogComponent } from './components/password-reset-dialog/password-reset-dialog.component';
import { PasswordComplexityComponent } from './components/password-complexity/password-complexity.component';
import { AboutDialogComponent } from './components/about-dialog/about-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list'
import {MatSliderModule} from '@angular/material/slider'
import {MatChipsModule} from '@angular/material/chips'
@NgModule({
  declarations: [BreadcrumbsComponent, FormatDatePipe, MapRoleNamesPipe, ConfirmDeleteDialogComponent, PasswordResetDialogComponent, PasswordComplexityComponent, AboutDialogComponent],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatButtonModule,
    MatDividerModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatSelectModule,
    FormsModule,
    RouterModule,
    MatCheckboxModule,
    HttpClientModule,
MatDialogModule,
MatSlideToggleModule,
MatSliderModule,
MatExpansionModule,
MatRadioModule,
MatTabsModule,
MatSnackBarModule,
MatMenuModule,
MatChipsModule,
OnlineStatusModule,
MatProgressBarModule,
  ],
  exports:[
    BreadcrumbsComponent,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatButtonModule,
    MatDividerModule,
    MatPaginatorModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatCheckboxModule,
    HttpClientModule,
    FormatDatePipe,
    MapRoleNamesPipe,
    ConfirmDeleteDialogComponent,
    MatDialogModule,
    MatSlideToggleModule,
MatSliderModule,
MatExpansionModule,
MatRadioModule,
MatTabsModule,
MatSnackBarModule,
MatMenuModule,
MatChipsModule,
OnlineStatusModule,
MatProgressBarModule,
PasswordResetDialogComponent,
PasswordComplexityComponent,
AboutDialogComponent
  ]
})
export class SharedModule { }
