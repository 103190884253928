import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.css']
})
export class ActivateComponent implements OnInit {
  token: string;
  activated: boolean = false;
  error: boolean = false;

  constructor(private _route:ActivatedRoute, private _account:AccountService) { }

  ngOnInit(): void {

    this._route.queryParamMap.subscribe(params => {
      this.token = params.get('token');
    });

    if(this.token)
    {
      this.submit();
    }
    else
    {
      this.activated = false;
      this.error = true;
    }
  }

  private submit()
  {
    this._account.confirm(this.token).subscribe(
      {
        next: (data) => {
          this.activated = true;
        },
        error: (error) => {
          this.activated = false;
          this.error = true;
        }
      }
    );
  }

}
