import { Component, Input, OnInit } from '@angular/core';
import {MatSlideToggleChange } from '@angular/material/slide-toggle';
import {  MatSliderDragEvent } from '@angular/material/slider';
import { Layer } from 'ol/layer';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';

@Component({
  selector: 'app-layer-group',
  templateUrl: './layer-group.component.html',
  styleUrls: ['./layer-group.component.css']
})
export class LayerGroupComponent implements OnInit {

  @Input() layerGroup: LayerGroup;
  @Input() type: 'Base' | 'Normal' | 'Singular';

  layerGroups = [];
  layers = [];

  constructor() { }

  ngOnInit(): void {

   this.layerGroup.getLayers().forEach((layer)=>
   {
    if(layer instanceof LayerGroup)
    {
      this.layerGroups.push(layer);
    }
    if(layer instanceof VectorLayer)
    {
      this.layers.push(layer);
    }
   });
  }

  getLayerGroupOpacityLabel() : string
  {
    return (this.layerGroup.getOpacity() * 100) + '%';
  }

  getLayerGroupOpacity(): Number {
    return (this.layerGroup.getOpacity() * 100);
  }

  setLayerGroupOpacity(event: MatSliderDragEvent) : void
  {
    this.layerGroup.setOpacity(event.value / 100);
  }

  getLayerGroupVisibility(): boolean
  {
    return this.layerGroup.getVisible();
  }

  setLayerGroupVisibility(event: MatSlideToggleChange) : void
  {
    this.layerGroup.setVisible(event.checked);
  }

  formatLabel(value: number) {
    return value + '%';
  }

}
