import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import { PluggableMap } from 'ol';
import { Control } from 'ol/control';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit,AfterViewInit {

  @Input() map: PluggableMap;

  control: Control;

  constructor(private elRef:ElementRef) { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {

  }


}
