import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { NpvCalculatorDataStorageService } from 'src/app/services/npv-calculator-data-storage.service';

@Component({
  selector: 'app-total',
  templateUrl: './total.component.html',
  styleUrls: ['./total.component.css']
})
export class TotalComponent implements OnInit {

  // benefitsTotal: number = 0;
  // costsTotal: number = 0;
  // totalValue: number = 0;

  constructor(private npvCalcService: NpvCalculatorDataStorageService) { }

  ngOnInit(): void {
  }

  ngOnChanges(C: SimpleChanges) {
  }

  returnBenefits() {
    if (this.npvCalcService.returnTotalBenefits()) {
      return this.npvCalcService.returnTotalBenefits()
    } else {
      return 0
    }
  }


  returnCosts() {
    if (this.npvCalcService.returnTotalCosts()) {
      return this.npvCalcService.returnTotalCosts()
    } else {
      return 0
    }
  }

  returnTotalValue() {
    // this.totalValue = this.npvCalcService.returnTotalBenefits() - this.npvCalcService.returnTotalCosts()
    return this.npvCalcService.returnTotalBenefits() - this.npvCalcService.returnTotalCosts();
  }

}
