
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { string } from 'mathjs';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  @Input() users;
  @Output() resetPassword = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  requestPasswordReset(email: string) {
    this.resetPassword.emit(email);
  }

  convertToLocalDate(utcDate) {

    if(utcDate == '')
    return;

    let localDate = new Date(utcDate);
    return localDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }
}
