import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinal'
})
export class OrdinalPipe implements PipeTransform {

  transform(value: number,  ...args: unknown[]): string {
    let s = ["th", "st", "nd", "rd"],
    v = value % 100;
    return value + (s[(v - 20) % 10] || s[v] || s[0]);
  }

}
