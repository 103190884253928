<section class="account_confirmed" *ngIf="activated && !error; else showError">
<i class="fa fa-5x fa-check-circle"></i>
<h1>Account Confirmed!</h1>
<p>Your account has been confirmed, Log in to use the system!</p>
<button [routerLink]="['/login']" class="button button--brand">Login</button>
</section>

<ng-template #showError>
  <section class= "error">
    <i class="fa fa-5x fa-times"></i>
    <p>There was an issue confirming your account, please contact the adminstrator on &#64;EMAIL</p>
  </section>
</ng-template>
