import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-compensation',
  templateUrl: './compensation.component.html',
  styleUrls: ['./compensation.component.css']
})
export class CompensationComponent implements OnInit {

  @Input() formGroupName: string;
  form: FormGroup

  @Output() valueChange = new EventEmitter();

  constructor(private rootForm: FormGroupDirective) { }

  ngOnInit(): void {
    this.form = this.rootForm.control.get(this.formGroupName) as FormGroup
  }

}
