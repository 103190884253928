<div class="uk-column uk-padding-small@m uk-padding-remove@m" [formGroup]="form">

    <div class="uk-column-1-3@m uk-flex-row medium-flex">

        <div class="uk-column uk-flex-column uk-flex-1 uk-flex-bottom uk-margin-bottom">
            <label _ngcontent-geb-c215="" class="fw-label uk-flex uk-flex-between overflow-hidden vertically-center-them">% Increase in Cost due to Mobility
                <button type="button"
                    class="icon-button btn btn-outline-secondary me-2 uk-button uk-button-link uk-padding-small uk-padding-remove-vertical"
                    placement="top" uk-tooltip="title:% Increase in Foresty Policy Prescription Cost due to Mobility; pos: left; animation:uk-animation-slide-left;">
                    <i class="fa fa-info"></i>
                </button>
            </label>

            <input class="uk-input" formControlName="mobilityImpact" type="number" [value]="mobileImpact">
        </div>

        <div class="uk-column uk-flex-column uk-flex-1 uk-flex-bottom uk-margin-bottom">
            <label _ngcontent-geb-c215="" class="fw-label uk-flex uk-flex-between overflow-hidden vertically-center-them">% Increase in Cost due to Threatened Status
                <button type="button"
                    class="icon-button btn btn-outline-secondary me-2 uk-button uk-button-link uk-padding-small uk-padding-remove-vertical"
                    placement="top" uk-tooltip="title:% Increase in Forest Policy Prescription Cost due to Threatened Status; pos: left; animation:uk-animation-slide-left;">
                    <i class="fa fa-info"></i>
                </button>
            </label>

            <input class="uk-input" formControlName="statusImpact" type="number" [value]="statusImpact">
        </div>

        <div class="uk-column uk-flex-column uk-flex-1 uk-flex-bottom uk-margin-bottom">
            <label _ngcontent-geb-c215="" class="fw-label uk-flex uk-flex-between overflow-hidden vertically-center-them">% Increase in Cost due to Uncertainity
                <button type="button"
                    class="icon-button btn btn-outline-secondary me-2 uk-button uk-button-link uk-padding-small uk-padding-remove-vertical"
                    placement="top" uk-tooltip="title:% Increase in Forest Policy Prescription Cost due to Uncertainity; pos: left; animation:uk-animation-slide-left;">
                    <i class="fa fa-info"></i>
                </button>
            </label>

            <input class="uk-input" formControlName="uncertainityImpact" type="number" [value]="uncertainityImpact">
        </div>
    </div>
</div>
