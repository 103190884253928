import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  error: string = null;
  forgotPasswordForm: UntypedFormGroup;
  submitted : boolean = false

  constructor(private accountService: AccountService) { }

  ngOnInit(): void {
    this.forgotPasswordForm = new UntypedFormGroup({
      'email': new UntypedFormControl(null, [CustomValidators.required, CustomValidators.email])
    });
  }

  onSubmit()
  {
    this.submitted = true;

    this.accountService.requestReset(this.forgotPasswordForm.get('email').value).subscribe(
      {
        next: (data) => {
          console.log(data)
        },
        error: (error) => {
          console.log(error.message);
        }
      }
    );
  }

}
