import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-time-inputs',
  templateUrl: './time-inputs.component.html',
  styleUrls: ['./time-inputs.component.css']
})
export class TimeInputsComponent implements OnInit {

  @Input() discountRate: number;
  @Input() formGroupName: string;
  formGroup: FormGroup;


  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.formGroup = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  minValuChangeHandler(eventData: any) {
    const eventTarget = eventData.target as HTMLInputElement;
    const eventValue: number = Number(eventTarget.value);

    if (eventValue < 1) {
      this.formGroup.get("harvestLifespan").setValue(1)
      console.log(this.formGroup.get("harvestLifespan").setValue(1))
    }
  }
}
