import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  token: string;
  newPasswordForm: UntypedFormGroup;
  submitted: boolean = false;

  constructor( private _route:ActivatedRoute, private _account:AccountService) { }

  ngOnInit(): void {

    this._route.queryParamMap.subscribe(params => {
      this.token = params.get('token');
    });

  this.newPasswordForm = new UntypedFormGroup({
    'password': new UntypedFormControl(null, [CustomValidators.required, CustomValidators.minLength(8), CustomValidators.passwordHasSpecial, CustomValidators.passwordHasUpper]),
    'rptPassword': new UntypedFormControl(null,CustomValidators.required)
  },{validators: [CustomValidators.passwordsMatch]});

  }

  onSubmit()
  {
    this.submitted = true;

    this._account.reset({token: this.token, password: this.newPasswordForm.get('password').value}).subscribe(
      {
        next: (data) => {
          console.log(data)
        },
        error: (error) => {
          console.log(error.message);
        }
      }
    );
  }

  showErrors()
  {
    console.log(this.newPasswordForm.errors)
  }

}
