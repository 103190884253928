<table class="uk-table uk-table-hover uk-table-divider uk-table uk-table-justify">
    <tbody>
        <tr>
            <td>Impact on Harvest Rates from FPP (% reduction)</td>
            <td>{{impactOnHarvest | number}}%</td>
        </tr>

        <tr>
            <td>Total Actual Volume of Logs Harvested (tonne)</td>
            <!-- <td>{{(1 - impactOnHarvest) * totalActualLogsHarvested | number}}</td> -->
            <td>{{totalActualLogsHarvested | number}}</td>
        </tr>

        <tr>
            <td>% of Harvest that are Peeler Logs</td>
            <td>{{harvestThatArePeelerlogs| number}}%</td>
        </tr>

        <tr>
            <td>Total Actual Volume of Sawlogs Sold (tonne)</td>
            <td>{{calculateTotalActualLogsHarvested()| number}}</td>
        </tr>

        <tr>
            <td>Peeler Log Price ($/tonne)</td>
            <td>{{peelerPrice | currency}}</td>
        </tr>

        <tr>
            <td>Monetary Value</td>
            <td>{{calculateMonetaryValue()| currency}}</td>
        </tr>

        <tr>
            <td>Discount Rate</td>
            <td>{{discountRate| number}}%</td>
        </tr>

        <tr>
            <td>Harvest Lifespan</td>
            <td>{{harvestLifespan | number}}</td>
        </tr>

        <tr>
            <td>PV(B)</td>
            <td>{{calculatePVb() | currency}}</td>
        </tr>

    </tbody>
</table>