import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  users: any;

  constructor(public adminService: AdminService) { }

   ngOnInit(): void {

    this.adminService.getAllUsers().subscribe(users =>{
      this.users = users;

    console.log(this.users);
    });


  }

  passwordReset(email: string)
  {
    this.adminService.adminPasswordReset(email).subscribe(response => {
      console.log(response);
    })
  }

}
