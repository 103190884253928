<h2 mat-dialog-title>Enterprise Suitability Overlay</h2>
<mat-dialog-content class="mat-typography overflow-clip">
<section #map class="overlay-map_map">
  <div class="overlay-map_legend">
    .<article *ngFor="let color of colorIndex"  [ngStyle]="{backgroundColor: color.color}">{{color.name | spaceBeforeCap}}</article>
  </div>
</section>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      close
    </button>
  </footer>
</mat-dialog-actions>
