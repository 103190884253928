<div
popperHelp
helpOrder="13"
helpContent="This will show the active property, you can also change the active property from this tool."
helpPlacement="right"
class="grid grid--gap"
>
  <app-property-select></app-property-select>

  <button class="button button--danger grid grid--auto-column grid--gap i-center" *ngIf="needsDownloading$ | async" (click)="downloadLayersClick()">Download Reference Layers <i class="fa fa-download"></i></button>
</div>

