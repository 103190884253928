<h2 mat-dialog-title>Update Property</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="editPropertyForm">
    <div class="grid">

      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input formControlName="name" matInput placeholder="">
      </mat-form-field>

      <mat-form-field disabled appearance="outline">
        <mat-label>Total Area (ha)</mat-label>
        <input type="number" readonly="true" matInput [value]=" property.area" >
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Notes</mat-label>
          <textarea formControlName="notes" cols="30"
          rows="10" matInput></textarea>
        </mat-form-field>
    </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black" [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      type="button"
      class="button button--danger"
      (click)="openDeleteConfirmationDialog()"
    >
      Delete
    </button>
    <button
      class="button button--blue"
      (click)="submit()"
      [disabled]="!editPropertyForm.valid"
    >
      Update
    </button>
  </footer>
</mat-dialog-actions>
