import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, OnChanges, SimpleChanges } from '@angular/core';
import { NpvCalculatorDataStorageService } from 'src/app/services/npv-calculator-data-storage.service';

@Component({
  selector: 'app-fencing-cost',
  templateUrl: './fencing-cost.component.html',
  styleUrls: ['./fencing-cost.component.css']
})
export class FencingCostComponent implements OnInit, OnChanges {

  @Input() fencingLength: number = 0;
  @Input() fencing: number;
  @Input() monetaryValue: string;
  @Output() calculationsValueEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private npvCalcService: NpvCalculatorDataStorageService) { }

  ngOnInit(): void {
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    
    // Passing values to the parent component from 
    if (changes) {
      const calculationData = {
        "Fencing Cost": {
          "Forest Size (ha)": this.fencing,
          "Land Purchase Price ($/ha)": this.fencing,
          "Monetary Value": this.calculateMonetaryValue(),
          "PV(C)": this.calculateMonetaryValue().toFixed(2),
        }
      }
      this.calculationsValueEmitter.emit(calculationData)
    }

  }

  calculateMonetaryValue() {
    if (this.monetaryValue === "Yes") {

      const monVal = this.fencingLength * this.fencing
      this.npvCalcService.updateCosts({ key: 'fencing-costs', value: monVal })
      return monVal;

    } else {

      this.npvCalcService.updateCosts({ key: 'fencing-costs', value: 0 })
      return 0

    }
  }


}
