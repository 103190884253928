import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import LayerGroup from 'ol/layer/Group';

@Component({
  selector: 'app-base-group',
  templateUrl: './base-group.component.html',
  styleUrls: ['./base-group.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseGroupComponent implements OnInit {
  @Input() layerGroup: LayerGroup;

  layers: Array<any> = [];

  selectedBaseMap: any;

  constructor() {}

  ngOnInit(): void {
    this.layerGroup.on('change', (event) => {
      // console.log(event);
      this.setupBaseMaps();
    });

    this.setupBaseMaps();
  }

  setupBaseMaps() {
    this.layers = [];

    let layersArray = this.layerGroup.getLayersArray();
    layersArray.forEach((layer) => {
      this.layers.push(layer);
    });

    if(this.selectedBaseMap == null)
    this.selectedBaseMap = this.getVisibleBaseMap();
  }

  setBaseMapVisible() {
    let previousBaseMap = this.getVisibleBaseMap();

    previousBaseMap.setVisible(false);

    this.selectedBaseMap.setVisible(true);
  }

  getVisibleBaseMap() {
    let layersArray = this.layerGroup.getLayersArray();

    return this.layers.find((layer) => {
      return layer.getVisible() == true;
    });
  }

  getLayerGroupVisibility() {
    return this.layerGroup.getVisible();
  }
}
