import { JsonpClientBackend } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as convert from 'xml-js';

const TOKEN_KEY = 'auth-token';
const REFRESH_KEY = 'auth-refresh';
const USER_KEY = 'auth-user';

export class Alert{
title: string;
link: string;
pubDate: string;
}

@Injectable({
  providedIn: 'root'
})
export class AlertsService  {

  bomAlerts = new BehaviorSubject<Array<Alert>>(null);


  constructor() {

    this.getAlerts();

  }

  public async getAlerts()
  {
   let response = await fetch('http://www.bom.gov.au/fwo/IDZ00065.warnings_land_tas.xml');

   let data = await response.text();

  let a = convert.xml2json(data, {compact: false, spaces: 4});

    let alerts = JSON.parse(a);

    let _alerts = alerts.elements[0].elements[0].elements.filter((a) => {
      return a.name == 'item';
    });


    let alertsArray = [];

    _alerts.forEach(alert => {
      let _alert = new Alert();
      _alert.title = alert.elements[0].elements[0].text;
      _alert.link = alert.elements[1].elements[0].text;
      _alert.pubDate = alert.elements[2].elements[0].text;
      alertsArray.push(_alert);
    });

    this.bomAlerts.next(alertsArray);

  }
}


