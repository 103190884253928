import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, SimpleChanges, OnDestroy } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-threatened-species-in-the-area',
  templateUrl: './threatened-species-in-the-area.component.html',
  styleUrls: ['./threatened-species-in-the-area.component.css']
})
export class ThreatenedSpeciesInTheAreaComponent implements OnInit,OnDestroy {

  mobility: string[] = ["", "Highly Mobile", "Somewhat Mobile", "Somewhat Fixed", "Fixed"];
  status: string[] = ["", "Not Endangered", "Critically Endangered", "Endangered", "Threatened"];
  uncertainty: string[] = ["", "No Uncertainty", "Medium Uncertainty", "High Uncertainty"];

  @Input() potentialVolumnOfLogsHarvested: number = 0;
  @Input() formGroupName: string;
  @Input() landSize: number;

  @Input() decreseInMobility: number = 0;
  @Input() decreaseInUncertainity: number = 0;

  @Input() impactOnForestyPolicy: number[];
  @Input() impactOnHarvestRatePercentages: number[];
  form: FormGroup;

  schanges: Subject<SimpleChange> = new Subject<SimpleChange>();

  constructor(private rootFromGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.form = this.rootFromGroup.control.get(this.formGroupName) as FormGroup;
    const totalActualVolumeAvail = this.potentialVolumnOfLogsHarvested * (1 - ((this.decreaseInUncertainity + this.decreseInMobility) / 100))
    this.form["controls"]["totalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount"].setValue(totalActualVolumeAvail.toFixed(2))

    this.schanges.subscribe(sc => {

      if (sc["potentialVolumnOfLogsHarvested"]) {
        const potentialVolumeOfLogs = sc["potentialVolumnOfLogsHarvested"]["currentValue"]
        this.form["controls"]["totalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount"].setValue(potentialVolumeOfLogs.toFixed(2))
      }

      if (sc["decreaseInUncertainity"] || sc["decreseInMobility"]) {
        const potentialValue = this.form["controls"]["totalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount"].getRawValue() * (1 - ((this.decreaseInUncertainity / 100) + (this.decreseInMobility / 100)))
        this.form["controls"]["totalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount"].setValue(potentialValue.toFixed(2))
      }

      if (sc["impactOnHarvestRatePercentages"]) {
        let sum = 0;
        this.impactOnForestyPolicy.forEach(percentage => {
          sum += percentage
        })
      }

      if (this.form) {
        this.setTotalPotentialVolumeOfLogsHarvested()
      }
    })
  }

  ngOnChanges(sc: SimpleChange) {
    this.schanges.next(sc);

    if (sc["potentialVolumnOfLogsHarvested"]) {
      const potentialVolumeOfLogs = sc["potentialVolumnOfLogsHarvested"]["currentValue"]
      if (potentialVolumeOfLogs) {
        this.form["controls"]["TotalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount"].setValue(potentialVolumeOfLogs.toFixed(2))
      }
    }

    if (sc["decreaseInUncertainity"] || sc["decreseInMobility"]) {
      const potentialValue = this.form["controls"]["TotalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount"].getRawValue() * (1 - ((this.decreaseInUncertainity / 100) + (this.decreseInMobility / 100)))
      if (potentialValue) {
        this.form["controls"]["TotalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount"].setValue(potentialValue.toFixed(2))
      }
    }

    if (sc["impactOnHarvestRatePercentages"]) {
      let sum = 0;
      this.impactOnForestyPolicy.forEach(percentage => {
        sum += percentage
      })
    }
  }

  ngOnDestroy(): void {
      this.schanges.complete();
  }

  radioChangeEvent(e) {
    const target = e.target as HTMLInputElement;
  }

  calculateActualVolumnOfLogsHarvested(percentages: number[]) {

    if (this.impactOnHarvestRatePercentages.length <= 0) {
      return 0;
    }

    let percentageSum: number = 0;

    percentages.forEach(percentage => {
      percentageSum += (percentage / 100)
    })

    const newPotentialTotal = this.potentialVolumnOfLogsHarvested * (1 - percentageSum);
    this.rootFromGroup.control.controls["characteristicsOfThreatenedSpeciesInTheArea"].get("totalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount").setValue(newPotentialTotal.toFixed(2));

    return newPotentialTotal;
  }


  setTotalPotentialVolumeOfLogsHarvested() {
    if (this.potentialVolumnOfLogsHarvested) {
      const totalActualVolume = this.potentialVolumnOfLogsHarvested * (1 - ((this.decreaseInUncertainity / 100) + (this.decreseInMobility / 100)))
      this.form.controls['TotalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount'].setValue(totalActualVolume.toFixed(2))
    }
  }


}
