import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PropertyDexie } from 'src/app/models/property.model';
import { CustomValidators } from 'src/app/_helpers/customValidators';

export interface CreatePropertyResult {
  createProperty:boolean,
  property:PropertyDexie
}

@Component({
  selector: 'app-create-property',
  templateUrl: './create-property.component.html',
  styleUrls: ['./create-property.component.css']
})
export class CreatePropertyComponent implements OnInit {

  data: CreatePropertyResult = {
    property:null,
    createProperty: false
  }

  createPropertyForm: UntypedFormGroup;


  constructor(public dialogRef: MatDialogRef<CreatePropertyComponent>,@Inject(MAT_DIALOG_DATA) public property: PropertyDexie) { }

ngOnInit(): void {
  this.dialogRef.disableClose = true;

  this.createPropertyForm = new UntypedFormGroup(
    {
    'name': new UntypedFormControl(null, CustomValidators.required),
    'notes': new UntypedFormControl(null)
    },
  );

}
    submit(){
      Object.assign(this.property, this.createPropertyForm.value);

      this.dialogRef.close(this.property);
    }

}
