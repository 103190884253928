import { Component, OnInit, Input, AfterViewInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';
import { TreeProtectionService } from '../tree-protection.service';
import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';


interface IObjectKeys {
  [key: string]: string | number | null | IObjectKeys;
}

interface summaryNumberOfRollsInterface {
  standarddWire: number,
  barbWire: number,
  wallabyProofMesh: number,
  star: number,
  steelWood: number
}


@Component({
  selector: 'app-tree-protection',
  templateUrl: './tree-protection.component.html',
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, maxHeight: 0 }),
            animate('0.75s ease-in',
              style({ opacity: 1, maxHeight: 1000 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1, maxHeight: 1000 }),
            animate('0.25s ease-out',
              style({ maxHeight: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ],
  styleUrls: ['./tree-protection.component.css']
})

export class TreeProtectionComponent implements OnInit, OnChanges {

  @Output() parentEventTransmitter = new EventEmitter();
  @Input() fenceSize: any;

  fencingMaterialTableInformation: IObjectKeys = {
    "Cattle-proof": {
      "Fencing Requirements": "Cattle-proof",
      "Timing": "Cattle need to be kept out of trees until they reach about Xm in height",
      "Other Considerations": null,
      "Standard Wire Rows Required": 0,
      "Barb Wire Rows Required": 5,
      "Standard Wire Roll length - metres": 200,
      "Barb Wire Roll Length - metres": 500,
      "Wallaby Proof Mesh Roll Length - metres": 0,
    },
    "Sheep-proof": {
      "Fencing Requirements": "Sheep-proof",
      "Timing": "Sheep need to be kept out of trees until they reach about Xm in height",
      "Other Considerations": null,
      "Standard Wire Rows Required": 4,
      "Barb Wire Rows Required": 1,
      "Standard Wire Roll length - metres": 200,
      "Barb Wire Roll Length - metres": 500,
      "Wallaby Proof Mesh Roll Length - metres": 0,
    },
    "Wallaby-proof": {
      "Fencing Requirements": "Wallaby-proof",
      "Timing": "Wallabies need to be kept out of trees until they reach about Xm in height, approximately 2-4 years of age depending on how well the trees are growing. ",
      "Other Considerations": "Wallabies can also be controlled via shooting. This needs to commence months prior to planting, then continue throughout and after planting for approximately X months",
      "Standard Wire Rows Required": 0,
      "Barb Wire Rows Required": 1,
      "Standard Wire Roll length - metres": 200,
      "Barb Wire Roll Length - metres": 500,
      "Wallaby Proof Mesh Roll Length - metres": 30,
    },
  }

  materialTreeProtectionForm: FormGroup = new FormGroup({
    lengthOfFenceRequired: new FormControl({ value: null, disabled: true }, [Validators.required]),
    postSpacing: new FormControl(3, [Validators.minLength(0)]),
    ratioStarPostToSteel: new FormControl(3, [Validators.minLength(0)]),
    standardWireRows: new FormControl(0, [Validators.minLength(0)]),
    barbWireRows: new FormControl(0, [Validators.minLength(0)]),
  });

  fencingType: string[] = [];
  selectedFencingType?: string | null;
  selectedFencingTypeDetails?: any;

  summaryNumberOfRollsRequired: summaryNumberOfRollsInterface = {
    standarddWire: 0,
    barbWire: 10,
    wallabyProofMesh: 100,
    star: 0,
    steelWood: 0
  };

  constructor(public treeProtectionService: TreeProtectionService) {

  }

  ngOnInit(): void {

    // Generating keys for the fencing type dropdown list.
    this.fencingType = Object.keys(this.fencingMaterialTableInformation);

  }

  ngOnChanges(changes: SimpleChanges): void {

     this.materialTreeProtectionForm.controls["lengthOfFenceRequired"].setValue(this.fenceSize)
  }

  changeToFencingTypeHandler(evt: Event) {
    this.selectedFencingType = null;

    const elem = evt.target as HTMLInputElement;
    const val = elem.value;

    if (!val) {
      this.selectedFencingType = null;
      this.materialTreeProtectionForm.controls["lengthOfFenceRequired"].disable();
    } else {
      this.materialTreeProtectionForm.controls["lengthOfFenceRequired"].enable();
    }

    // Timeout has been set to trigger fade in animation on the tree-protection component.

    setTimeout(() => {
      this.selectedFencingType = val;
      this.selectedFencingTypeDetails = this.fencingMaterialTableInformation[this.selectedFencingType];

      this.populateFormDataHandler(this.fencingMaterialTableInformation[this.selectedFencingType]);
    }, 200)
  }

  populateFormDataHandler(tableObjectData: any) {
    this.materialTreeProtectionForm.controls["standardWireRows"].setValue(tableObjectData["Standard Wire Rows Required"]);
    this.materialTreeProtectionForm.controls["barbWireRows"].setValue(tableObjectData["Barb Wire Rows Required"]);

    // Passing data down to the summary table.
    this.populateSummaryTableData(tableObjectData);
  }

  // Populating summary table data handler
  populateSummaryTableData(tableObjectData: any) {

    const isWallabyProof: boolean = tableObjectData["Fencing Requirements"].toLowerCase().includes("wallaby"); // C13

    const lengthOfTheFence = this.materialTreeProtectionForm.get("lengthOfFenceRequired")?.value; // C14

    const ratioStarPostsToSteelPine = Number(this.materialTreeProtectionForm.get("ratioStarPostToSteel")?.value); // C18
    const standardWireRowRequired = this.materialTreeProtectionForm.get("standardWireRows")?.value; // C19
    const barbWireRequired = this.materialTreeProtectionForm.get("barbWireRows")?.value; // C20

    const barbWireRollLength = tableObjectData["Barb Wire Roll Length - metres"]; // C29
    const standardWireRollLength = tableObjectData["Standard Wire Roll length - metres"]; // B29
    const wallabyProofMeshLength = tableObjectData["Wallaby Proof Mesh Roll Length - metres"]; // D29


    const starValue: number = ((lengthOfTheFence / (ratioStarPostsToSteelPine + 1)) * ratioStarPostsToSteelPine) / (ratioStarPostsToSteelPine + 1);
    const steelWoodValue: number = ((lengthOfTheFence / (ratioStarPostsToSteelPine + 1)) * 1) / (ratioStarPostsToSteelPine + 1);


    this.summaryNumberOfRollsRequired = {
      standarddWire: (lengthOfTheFence * standardWireRowRequired) / standardWireRollLength,
      barbWire: (lengthOfTheFence * barbWireRequired) / barbWireRollLength,
      wallabyProofMesh: isWallabyProof ? lengthOfTheFence / wallabyProofMeshLength : 0,
      star: Math.ceil(starValue),
      steelWood: Math.ceil(steelWoodValue)
    }

  }

  // Manual trigger on keydown on specific fields
  inputChangePopulateFormDataHandler() {
    if (this.selectedFencingType && this.selectedFencingType?.trim().length > 0) {
      this.populateSummaryTableData(this.fencingMaterialTableInformation[this.selectedFencingType]);
    }
  }

  passValuesToParent() {
    this.parentEventTransmitter.emit({
      stage: 2,
      values: {
        "selectedFencingType": this.selectedFencingType,

        "fenceSize": this.fenceSize,

        "lengthOfFenceRequired": this.materialTreeProtectionForm.controls["lengthOfFenceRequired"].value,
        "postSpacing": this.materialTreeProtectionForm.controls["postSpacing"].value,
        "ratioStarPostToSteel": this.materialTreeProtectionForm.controls["ratioStarPostToSteel"].value,
        "standardWireRows": this.materialTreeProtectionForm.controls["standardWireRows"].value,
        "barbWireRows": this.materialTreeProtectionForm.controls["barbWireRows"].value,

        "standarddWire": this.summaryNumberOfRollsRequired.standarddWire,
        "barbWire": this.summaryNumberOfRollsRequired.barbWire,
        "wallabyProofMesh": this.summaryNumberOfRollsRequired.wallabyProofMesh,
        "star": this.summaryNumberOfRollsRequired.star,
        "steelWood": this.summaryNumberOfRollsRequired.steelWood,
      }
    })
  }

}
