<div class="card card--black">
  <div class="manage-pad">
    <div class="card-title">
      <h3>MANAGE PADDOCKS</h3>
    </div>
    <div class="card-icon">
    </div>
    <button class="button button--map-tool button--black" [routerLink]="['/map','paddock']">MAP TOOL</button>
    <button class="button button--report-tool button--black" [routerLink]="['/map','report']">REPORT TOOL</button>
  </div>
</div>
