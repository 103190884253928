import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forest-description-disclaimer-overlay',
  templateUrl: './forest-description-disclaimer-overlay.component.html',
  styleUrls: ['./forest-description-disclaimer-overlay.component.css']
})
export class ForestDescriptionDisclaimerOverlayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
