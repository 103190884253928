<div class="dialog-header">
  <h2>Welcome to the Farm and Forest Mapper</h2>
</div>
<mat-dialog-content class="mat-typography">
  <p>
    Farm and Forest Mapper is a set of mapping and decision support tools and calculators, designed to assist you with making informed decisions on your property, with emphasis on assisting you with establishing or managing forests to leverage the wide range of benefits that trees on farms provide. The first release of Farm and Forest Mapper is limited to just four tools, with another four tools due for release before the end of 2023, but we’re keen for your feedback or ideas of tools or calculators you might find helpful into the future.
  </p>

  <p>
    To work, Farm and Forest Mapper requires your property boundary to be defined in the
    map, from which it can extract and analyse map based models and information.
    So, the very first thing you need to do is search in the map for the
    property you’re interested in and select the titles comprising it, which
    Farm and Forest Mapper will save as property boundary so you can use it again and again.
    From there you’re free to apply any of the Farm and Forest Mapper tools or calculators
    to analyse the whole property, but you can also optionally map out and
    analyse paddocks or fence lines that are within the property if you need to
    fine tune analysis to a specific part of the property.
  </p>
  <p>
    Private Forest Tasmania and the Forest Practices Authority hope you enjoy
    using these tools, and can gain valuable insights to support your farming
    and forest enterprises!
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <footer>

    <section class="logos">
      <img class="footer--logo" src="assets/images/PFT-Logo-Dark.png" alt="">
      <img class="footer--logo" src="assets/images/fpa_logo.png" alt="">
      <img class="footer--logo" src="assets/images/ESK-Logo-Dark.png" alt="">
    </section>

<section class="actions">
  <section class="grid grid--auto-row">
    <label
    ><input
      type="checkbox"
      [(ngModel)]="welcomeDialogResult.hideOnStart"
    />Don't show this again?</label
  >
  <label
    ><input
      type="checkbox"
      [(ngModel)]="welcomeDialogResult.startHelp"
    />Start help?</label
  >
  </section>
<button class="button button--blue" (click)="closeDialog()" cdkFocusInitial>
  Close
</button>

</section>

  </footer>
</mat-dialog-actions>
