export var other_map_defs = [{
  "active": true,
  "attribution_text": "\u00a9 Department of Finance, Services & Innovation 2018",
  "attribution_url": "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Cadastre/MapServer/?f=pjson",
  "display_name": "Cadastre",
  "epsg": 3857,
  "legend_url": "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Cadastre/MapServer/legend?f=pjson",
  "legend_layer_ids": new Set([4]),
  "maxResolution": 40,
  "id": 1,
  "name": "ARC_NSW_SIX_Cadastre",
  "ol_type": "ArcGISREST",
  "params": {
    "TRANSPARENT":"true",
    "OPACITY":1,
    "LAYERS":"show:4",
    "f":"image",
    "FORMAT":"png"
  },
  "order": 1,
  "url": "https://services.thelist.tas.gov.au/arcgis/rest/services/Public/CadastreAndAdministrative/MapServer/38",
  "visible": false
}];


interface list_map_interface {
  name,
  url,
  id
}

export var LIST_MAP_DEFS: Array<list_map_interface> = [{
  name: 'Hydrographic Areas',
  url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Public/OpenDataWFS/MapServer/',
  id: '22'
},
{
  name: 'Hydrographic Lines',
  url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Public/OpenDataWFS/MapServer/',
  id: '23'
}
,
{
  name: 'Transport Segments',
  url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Public/OpenDataWFS/MapServer/',
  id: '42'
},

{
  name: 'Contours 10m',
  url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Public/OpenDataWFS/MapServer/',
  id: '16'
},
{
  name: 'Private Reserves',
  url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Public/OpenDataWFS/MapServer/',
  id: '37'
}
// {
//   name: 'Public Land Classification',
//   url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Public/OpenDataWFS/MapServer/',
//   id: '38'
// }
,
{
  name: 'Private Timber Reserves',
  url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Public/CadastreAndAdministrative/MapServer/',
  id: '11'
}




]


export var VERSATILITY_NAMES = [
  {
    name:'barley',
    label:'Barley',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Barley_factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_barley.pdf#page=3'
  },
  ,{
    name:'bluebnhb',
    label:'Blueberries (Northern Highbush)',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Blueberry-factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_blueberriesNHB.pdf#page=3'
  },
  ,{
    name:'bluebshb',
    label:'Blueberries (Southern Highbush)',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Blueberry-factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_blueberriesSHB.pdf#page=3'
  },
  ,{
    name:'carrots',
    label:'Carrots',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Carrot_factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_carrots.pdf#page=3'
  },
  ,{
    name:'carrotseed',
    label:'Carrot Seed',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Carrot-seed_factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_carrot_seed.pdf#page=3'
  },
  ,{
    name:'cherries',
    label:'Cherries',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Cherry_factsheet.pdf.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_cherries.pdf#page=3'
  },
  ,{
    name:'eglobulus',
    label:'Eucalyptus (Globulus)',
    detailsLink:'https://www.treealliance.com.au/__data/assets/pdf_file/0013/260401/FF_Information_Series_-_EUCALYPTS_-_No._1_-_Overview.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_plantationtreespecies.pdf#page=2'
  },
  ,{
    name:'enitens',
    label:'Eucalyptus (Nitens)',
    detailsLink:'https://www.treealliance.com.au/__data/assets/pdf_file/0013/260401/FF_Information_Series_-_EUCALYPTS_-_No._1_-_Overview.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_plantationtreespecies.pdf#page=2'
  },
  ,{
    name:'hazelnuts',
    label:'Hazelnuts',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Hazelnut_factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_hazelnuts.pdf#page=3'
  },
  ,{
    name:'hemp',
    label:'Industrial Hemp',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Indiustrial-Hemp_factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_hemp.pdf#page=3'
  },
  ,{
    name:'linseed',
    label:'Linseed',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Linseed_factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_linseed.pdf#page=3'
  },
  ,{
    name:'lucerne',
    label:'Lucerne',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Lucerne_factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_lucerne.pdf#page=3'
  },
  ,{
    name:'olives',
    label:'Olives',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Olive_factsheet.pdf.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_olives.pdf#page=3'
  },
  ,{
    name:'onions',
    label:'Onions',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Onion_factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_onions.pdf#page=3'
  },
  ,{
    name:'pradiata',
    label:'Radiata Pine',
    detailsLink:'https://www.treealliance.com.au/__data/assets/pdf_file/0018/260406/FF_Information_Series_-_RADIATA_-_No._1_-_Why_Grow_Radiata_Pine.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_plantationtreespecies.pdf#page=2'
  },
  ,{
    name:'poppies',
    label:'Poppies',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Poppy_factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_poppies.pdf#page=3'
  },
  ,{
    name:'potatoes',
    label:'Potatoes',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Potato_factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_potatoes.pdf#page=3'
  },
  ,{
    name:'pyrethrum',
    label:'Pyrethrum',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Pyrethrum_factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_pyrethrum.pdf#page=3'
  },
  ,{
    name:'raspberries',
    label:'Raspberries',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Raspberry-factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_raspberries.pdf#page=3'
  },
  ,{
    name:'ryegrass',
    label:'Ryegrass',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Ryegrass_factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_ryegrass.pdf#page=3'
  },
  ,{
    name:'spwinegrapes',
    label:'Grapes (Sparkling Wine)',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Barley_factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_sparklingwg.pdf#page=1'
  },
  ,{
    name:'strawberry',
    label:'Strawberry',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Strawberry-factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_strawberries.pdf#page=3'
  }
  ,{
    name:'tbwinegrapes',
    label:'Grapes (Normal (Table) Wine)',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Barley_factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_sparklingwg.pdf#page=1'
  }
  ,{
    name:'wheat',
    label:'Wheat',
    detailsLink:'https://nre.tas.gov.au/Documents/WfW-Wheat_factsheet.pdf',
    rulesLink:'https://nrmdatalibrary.dpipwe.tas.gov.au/FactSheets/WfW/ListMapUserNotes/Map_user_notes_wheat.pdf#page=3'
  }
];
