import { AfterViewInit, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { AboutDialogComponent } from '../../shared/components/about-dialog/about-dialog.component';


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit,AfterViewInit,  OnDestroy {

  isAuthenticated = false;
  userFullName = "";
  collapsed = true;
  public user:User = null;
  private userSub: Subscription


  constructor(private _authService: AuthService, private _router: Router, private dialog:MatDialog) {

  }

  ngOnInit(): void {

   this.userSub = this._authService.user.subscribe(user =>
    {
      this.isAuthenticated = !user ? false : true;

      this.user = user;


      this.userFullName = !user ? "" : user.userFullName.trim().length > 0 ? user.userFullName : "Unknown User";

    });
  }

  ngAfterViewInit(): void
  {

  }



  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }

  openAbout()
  {
    this.dialog.open(AboutDialogComponent,{width:'30%'}).afterClosed().subscribe(result => {});
  }




  logout()
  {
    this._router.navigate(["login"]);
    this._authService.logout();

  }





}
