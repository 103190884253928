/*

Layer builder helper

D Livingston Eskmapping & GIS

Dep: Mod ol-layerswitcher

*/

import TileLayer from 'ol/layer/Tile';
import WebGLTileLayer from 'ol/layer/WebGLTile';

import XYZ from 'ol/source/XYZ';
import WMTS, {optionsFromCapabilities} from 'ol/source/WMTS';

import WMTSTileGrid from 'ol/tilegrid/WMTS';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';

import { Attribution } from 'ol/control';
import { Collection } from 'ol';
import LayerGroup from 'ol/layer/Group';
// import { Layer } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
// import { Options as TileGridOptions}  from 'ol/tilegrid/TileGrid';

import { Options as WMTSTileGridOptions}  from 'ol/tilegrid/WMTS';
import BaseVectorLayer from 'ol/layer/BaseVector';
import BaseImageLayer from 'ol/layer/BaseImage';
import { Image as ImageLayer} from 'ol/layer';

import {ImageArcGISRest, TileArcGISRest, ImageWMS} from 'ol/source';
import BingMaps from 'ol/source/BingMaps';


import {BaseLayerOptions, GroupLayerOptions} from '../_helpers/ol-layerswitcher_esk';

import $ from "jquery";


const parserWMTS = new WMTSCapabilities();

const default_epsg = 3857;
const default_min_zoom = 0;
const default_max_zoom = 18;



interface LayerBuilderLayerOptions{
    format?: any;
    layer?: string;
    opacity?: number;
    tilegrid?: WMTSTileGridOptions;
}

//TODO Clean definition type
interface LayerBuilderDefinition{
    active?: boolean;
    attribution_text?: string;
    attribution_url?: string;
    bing_key?: string;
    bing_imagry_set?: string;
    capabilities?: string;
    copyrightText?: string;
    display_name?: string;
    epsg?: number;
    // extent_wgs84?: number[];
    extent_wgs84_ulx?: number;
    extent_wgs84_uly?: number;
    extent_wgs84_lrx?: number;
    extent_wgs84_ury?: number;
    id?: number;
    // layer?: string; //WMS layer
    legend_url?: string;
    matrix_set?: string;
    max_zoom?: number;
    min_zoom?: number;
    maxResolution?: number;
    minResolution?: number;
    name?: string;
    ol_type?: string;
    options?: string;//|LayerBuilderLayerOptions;//JSON sting???
    order?: number;
    params?:object;
    type?: string;
    url?: RequestInfo;
    visible?: boolean;
}


declare class LayerBuilderLayer extends Object{
    DisplayName : string;
    layer: BaseLayer;
}
// type EntitiesAndMigrationsOpts = Pick<ConnectionOptions, "entities" | "migrations">;

var legendCache = [];

export async function populateLayerGroup(definitions_array:LayerBuilderDefinition[]|any[], out_layer_group:LayerGroup, callback:Function){
    var layers = out_layer_group.getLayers();

    for(let i=0; i<definitions_array.length; i++){
        let definition = definitions_array[i];
        if(definition.active){
            if(definition.ol_type == "XYZ"){
                buildXYZLayer(definition).then(function(l:LayerBuilderLayer){
                    addLayerToGroup(l.layer, layers, callback);
                });
            }
            else if(definition.ol_type == 'WMTS'){
                buildWMTSLayer(definition,function(l:LayerBuilderLayer){
                    addLayerToGroup(l.layer, layers, callback);
                });
            }
            else if (definition.ol_type == 'Bing'){
                buildBingLayer(definition).then(function(l:LayerBuilderLayer){
                    addLayerToGroup(l.layer, layers, callback);
                });
            }
            else if (definition.ol_type == 'WMS'){
                buildWMSLayer(definition).then(function(l:LayerBuilderLayer){
                    addLayerToGroup(l.layer, layers, callback);
                });
            }
            else if (definition.ol_type == 'ArcGISREST'){
                buildArcGISREST(definition).then(function(l:LayerBuilderLayer){
                    addLayerToGroup(l.layer, layers, callback);
                });
            }
        }
    }
};


function addLayerToGroup(layer:BaseLayer, layerGroup:BaseLayer[]|Collection<BaseLayer>, callback:Function){
    try{
        layerGroup.push(layer);
        callback();
    }
    catch(e){
        console.log(e)
    }
}


async function getAndSetCopyright(attribution_url:RequestInfo, layer:BaseVectorLayer<any, any>|BaseImageLayer<any,any>){
    fetch(attribution_url).then(function(response) {
        // console.log(response.status);
        return response.text();
    }).then(r=>{
        const cr = JSON.parse(r);
        if(cr.copyrightText){
            const attrib = new Attribution();
            attrib.setProperties({html:cr.copyrightText})
            layer.getSource().setAttributions(cr.attribution);
        }
    });
}


async function buildWMTSLayerFromCapabilities(definition:LayerBuilderDefinition, options:LayerBuilderLayerOptions, callback:Function){
    fetch(definition.capabilities).then(function(response) {
        return response.text();
    }).then(function(xml){
        if(xml){
            let result = parserWMTS.read(xml);
            let wmtsOptions = optionsFromCapabilities(result, {
                format: (options.format?options.format:'image/png'),
                layer: options.layer,
                matrixSet: 'EPSG:'+(definition.epsg?definition.epsg: default_epsg),
            });
            let tileLayer = new TileLayer({
                source: new WMTS(wmtsOptions)
            });

            tileLayer.setProperties({
                enableOpacitySliders : (options.opacity?true:false),
                title: definition.display_name,
                type: (definition.type?definition.type:'base')
            });

            buildGroupLayer(tileLayer, definition, callback);
        }
        else{
            throw('Bad Capabilities Statment');
        }
    }).catch(err => {
        console.error('fetch failed', err);
    });
}

async function buildWMTSLayerFromDefinitioninition(definition:LayerBuilderDefinition, layerOptions:LayerBuilderLayerOptions, callback:Function){
    let layer = new TileLayer({
        title: definition.display_name,
        type: (definition.type?definition.type:'base'),
        minZoom: (definition.min_zoom?definition.min_zoom:default_min_zoom),
        maxZoom: (definition.min_zoom?definition.min_zoom:default_max_zoom),
        enableOpacitySliders : (layerOptions.opacity?true:false),
        source: new WMTS({
            attributions: (definition.attribution_text?definition.attribution_text:''),
            format: (layerOptions.format?layerOptions.format:'image/png'),
            layer: (layerOptions.layer?layerOptions.layer:''),
            matrixSet: (definition.matrix_set?definition.matrix_set:''),
            projection : 'EPSG:'+(definition.epsg?definition.epsg:default_epsg),
            style: null,
            tileGrid: new WMTSTileGrid(layerOptions.tilegrid),
            url: <string>definition.url,
        }),
    } as BaseLayerOptions);

    if(layer){
        buildGroupLayer(layer, definition, callback);
    }
}


function buildGroupLayer(layer:BaseVectorLayer<any, any>|BaseImageLayer<any, any>, definition:LayerBuilderDefinition, callback:Function){
    if(typeof definition.visible !== 'undefined'){
        layer.setVisible(definition.visible);
    }
    if(definition.attribution_url){
        getAndSetCopyright(definition.url, layer)
    }
    callback({
        DisplayName : definition.display_name,
        layer: layer,
    })
}


export async function buildWMTSLayer(definition:LayerBuilderDefinition, callback:Function){
    const layerOptions: LayerBuilderLayerOptions = JSON.parse(definition.options);

    if(definition.capabilities && layerOptions && layerOptions.layer){
        return buildWMTSLayerFromCapabilities(definition, layerOptions, callback);
    }
    else{
        return buildWMTSLayerFromDefinitioninition(definition, layerOptions, callback);
    }
};


async function buildXYZLayer(definition: LayerBuilderDefinition){
        try{
            // let layer = new WebGLTileLayer({
            let layer = new TileLayer({
                title: definition.display_name,
                type: 'base',
                className: 'BaseLayers',
                source: new XYZ({
                    url: <string>definition.url,
                    projection : 'EPSG:'+(definition.epsg?definition.epsg:default_epsg),
                    attributions: (definition.attribution_text?definition.attribution_text:''),
                    minZoom: (definition.min_zoom?definition.min_zoom:default_min_zoom),
                    maxZoom: (definition.min_zoom?definition.min_zoom:default_max_zoom),
                    crossOrigin: null
                }),
                visible:definition.visible
            } as BaseLayerOptions);


            if(layer){
                if(definition.attribution_url) getAndSetCopyright(definition.attribution_url, layer);

                return {
                    DisplayName : definition.display_name,
                    layer: layer
                };
            }
        }
        catch(e){
            console.log(e);
        }

        return null;
}


async function buildBingLayer(def: LayerBuilderDefinition){
    try{
        var layer = new TileLayer({
            title: def.display_name,
            type: 'base',
            className: 'BaseLayers',
            visible: def.visible,
            preload: Infinity,
            source: new BingMaps({
                key: def.bing_key,
                imagerySet: def.bing_imagry_set,
            } ),
            updateWhileAnimating: true,
 updateWhileInteracting: true,
        } as BaseLayerOptions);

        if(layer){
            if(def.attribution_url) getAndSetCopyright(def.attribution_url, layer);
            return {
                DisplayName : def.display_name,
                layer: layer
            };
        }
    }
    catch(e){
        console.log(e);
    }

    return null;
}


async function buildWMSLayer(def: LayerBuilderDefinition){
	try{
		var o = JSON.parse(def.options);

		var layer = new ImageLayer({
			title: def.display_name,
			extent: (o.extent?o.extent:(def.extent_wgs84_lrx && def.extent_wgs84_ulx && def.extent_wgs84_uly && def.extent_wgs84_ury?[def.extent_wgs84_lrx, def.extent_wgs84_ulx, def.extent_wgs84_uly, def.extent_wgs84_ury]:[])),
      className: 'BaseLayers',
			// type: 'base',
			source: new ImageWMS({
				url: <string>def.url,
				// layer: (o.layer?o.layer:''), //should be in params?
				// format: (o.format?o.format:'image/png'), //should be in params?
				projection : 'EPSG:'+(def.epsg?def.epsg:3857),
				attributions: (def.attribution_text?def.attribution_text:''),
				params: o.params?o.params:{},
				ratio: (o.ratio?o.ratio:1),
				serverType: (o.serverType?o.serverType:'geoserver'),
				// crossOrigin : "anonymous"

			}),
            updateWhileAnimating: true,
 updateWhileInteracting: true,
			zIndex:1
		} as BaseLayerOptions);


		if(layer){
			if(def.attribution_url){
				//getCopyright(def, layer)
			}

			return {
                DisplayName : def.display_name,
                layer: layer
            };
		}
	}
	catch(e){
		console.log(e);
	}

	return null;
}

async function buildArcGISREST(def: LayerBuilderDefinition){
    try{
		let layer = new TileLayer({
			title: def.display_name,
      className: 'BaseLayers',
			// extent: (o.extent?o.extent:(def.extent?def.extent:[])),
			// type: 'base',
			source: new TileArcGISRest({
				url: <string>def.url,
                params: def.params?def.params:{},
				projection : 'EPSG:'+(def.epsg?def.epsg:3857),
				attributions: (def.attribution_text?def.attribution_text:''),
				// ratio: 1,
                // hidpi:true
			}),
            minResolution: (def.minResolution?def.minResolution:0),
            maxResolution: (def.maxResolution?def.maxResolution:Number.MAX_SAFE_INTEGER),
            visible: def.visible?def.visible:false,
			zIndex:1,
            updateWhileAnimating: true,
            updateWhileInteracting: true,
            enableOpacitySliders: true,
            opacityLabel: 'Layer Opacity',
		} as BaseLayerOptions);

        if(def.legend_url){
            layer.set('extraDivConfig',{"function":arcGISRESTLegend, "def":def});
        }

		if(layer){
			if(def.attribution_url){
				// getCopyright(def, layer)
			}

			return {
                DisplayName : def.display_name,
                layer: layer
            };
		}
	}
	catch(e){
		console.log(e);
	}

	return null;
}

async function arcGISRESTLegend(config, container, lyrId,lyr){
    let html = '';
    if(legendCache[lyrId]){
        html = legendCache[lyrId];
    }
    else{
        if(config.def && config.def.legend_url){
            try{
                const r = await fetch(config.def.legend_url);
                const j = await r.json();

                if(!j.layers) return;

                let limit_set = false;
                if(config.def.legend_layer_ids && config.def.legend_layer_ids.size > 0){
                    limit_set = config.def.legend_layer_ids
                }

                html = '<div id="'+lyrId+'_legend_block" class="legend_block">';

                j.layers.forEach(l=>{
                    try{
                        if(limit_set === false){
                            html += '<div class="legend_layer_name"> <input type="checkbox" id="legend'+l.layerId+'" name="'+l.layerName+'" value="'+l.layerId+'"><label for="'+l.layerName+'">'+l.layerName+'</label></div>';
                            l.legend.forEach(record=>{
                                html += '<div class="legend_row">';
                                    html += '<div class="legend_row_icon" style="background: url(data:'+record.contentType+';base64,'+record.imageData+'); width:'+record.width+'; height:'+record.height+';"></div>';
                                    html += '<div class="legend_row_heading">'+record.label+'</div>';
                                html += '</div>';
                            });
                        }
                    }
                    catch(e){
                        console.log(e);
                    }
                });

                html += '</div>';
            }catch(e){}
        }

       // legendCache[lyrId] = html;
    }

    $(container).append(html);

    otherLayersCheckbox(config.def,lyr);

}

/**
 * Check currently active sub-layers from the Administitive Boundaries layer
 * Add on change to the checkboxes to send ArcGis rest query to update layers
 * @param {*} other_map_defs definition at run time for the Other_map_layers
 * @param {*} lyr referece to the Open-layer layer.
 * @returns {null}
 */
function otherLayersCheckbox(other_map_defs,lyr)
  {

      if(other_map_defs.display_name == "Administrative Boundaries")
      {
        var array = other_map_defs.params.LAYERS.replace("show:","").split(",");

        var legendIds = other_map_defs.legend_layer_ids;

        legendIds.forEach( id => {
            if(array.includes(id.toString()))
            {
                $( "#legend"+id ).prop( "checked", true );
            }

            $( "#legend"+id ).on("change",function(e) {
                let value = e.target.value;

                console.log(lyr);

                let source = lyr.getSource();

                let params = source.params_.LAYERS;
                let showArray = params.replace("show:","").split(",");



                if($(this).prop("checked") == true){

                    if(!showArray.includes(value))
                    {
                        showArray.push(value);
                    }

                }
                else if($(this).prop("checked") == false){

                    if(showArray.includes(value))
                    {
                        if(showArray.length == 1)
                        {
                            $(this).prop( "checked", true );
                            return;
                        }
                        else
                        {
                            const index = showArray.indexOf(value);
                            if (index > -1) {
                                showArray.splice(index, 1);
                            }
                        }

                    }
                }

                let showString = "";

                if(showArray.length >= 1 && showArray[0] != "")
                {
                    lyr.setVisible(true);
                    showString = "show:";

                    showArray.forEach(s => {
                        showString += s + ',';
                    });

                    showString = showString.replace(/,\s*$/, "");

                        lyr.getSource().updateParams({
                            'LAYERS': showString,
                        });

                }
                else{
                    lyr.setVisible(false);
                }

                lyr.changed();

              });
        });
      }
  }
