import { Pipe, PipeTransform } from '@angular/core';
import { Role } from 'src/app/admin/roles/roles.service';


@Pipe({
  name: 'mapRoleNames'
})
export class MapRoleNamesPipe implements PipeTransform {

  transform(value: Role[], ...args: unknown[]): unknown {
    return value.map(role => role.name).join(', ');
  }

}
