import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit,OnDestroy {

  private userSub: Subscription
  public isAuthenticated: boolean;

  constructor(public router: Router, private auth: AuthService,private onlineStatusService:OnlineStatusService

    ) {

      console.log(environment.nonce);
      this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
        // Retrieve Online status Type
        console.log(status)
      });
  }

  async ngOnInit() {
     this.auth.autoLogin();

    this.userSub = this.auth.user.subscribe(user =>
    {
      this.isAuthenticated = !user ? false : true;

    });


  }

  ngOnDestroy(): void {
      this.userSub.unsubscribe();
  }



}
