import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import { section } from 'src/app/interfaces/JsPDF.extended.interface';
@Injectable({
  providedIn: 'root'
})

export class PrintPdfFilesService {
  doc: jsPDF;
  constructor() {
    this.doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
    });
  }

  disclaimerContent: Array<section> = [
    {
      title: "Important information about these TOPEX Scores",
      paragraphs: [
        "TOPEX readings are an assessment of exposure for a location and are provided in this tool as one of many inputs used in assessing the wind-throw hazard class for a site. A TOPEX score is based on the fact that the degree of exposure is influenced by the topographic features surrounding the site.",
        "The TOPEX scores tabulated above were modelled from a statewide 3D terrain model, a TOPEX score being generated at each and every intersection of a 50m x 50m grid laid out across Tasmania. At each location on the 50m grid, the 3D terrain model was distance limited to a 5km radius and the actual score was calculated as the sum of angles of inclination to the modelled horizon at the eight compass points from that modelled position in the landscape. The tabulated values above are a summary of the amount of area classified under each TOPEX score that make up your target area.",
        "The values provided are a guide only and you can undertake this same analysis on your property on ground to improve the exposure estimates. For more information on wind-throw risk in forests refer here."
      ]

    },
    {
      title: "Disclaimers",
      paragraphs: [
        "This report was generated by the Topex Tool on behalf of the Farm & Forest Mapper app, built by Esk Mapping & GIS on behalf of Private Forests Tasmania and the Forest Practices Authority.",
        "Some of the information within this report has been prepared by Private Forests Tasmania (PFT).",
        "Every reasonable endeavour has been used to ensure that the material was accurate at the time of publication. No representation or warranty is made by PFT for the accuracy, completeness, or relevance of such information to the user’s purpose.\n\nYou agree and accept that any use of the information is entirely at your own risk. PFT, the Crown, its officers, employees and agents do not accept any liability howsoever arising (including, without limitation, negligence) for any loss in connection with or arising out of any use of or reliance upon the information. Anyone considering any investment project should seek independent professional advice on the particular matter before making a decision.",
        "For further information please contact:",
        "Private Forests Tasmania\nPO Box 180, Kings Meadows Tas 7249\nTelephone: (03) 6777 2720\nEmail: admin@pft.tas.gov.au"
      ]
    }
  ];

  generateNewPDF(targetName: string, area: number, htmlElement: string, filename?: string) {
    // Clearing out the previously held information by redefining the structure.

    this.doc = new jsPDF("p", "pt", "a4");
    this.doc.setFontSize(6);

    /* Backup config
    this.doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      compress: true,
      format: "a4"
    });  */

    const date = new Date();
    // const weekday = date.getDay();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    // const hour = date.getHours();
    // const milli = date.getMilliseconds().toFixed(2);

    const fileName = filename ? filename : "Farm Woodlot tool";
    let width = this.doc.internal.pageSize.getWidth() - 20;
    let height = this.doc.internal.pageSize.getHeight() - 30;

    // Body content of the pdf
    const pdfModalElement: HTMLElement = document.getElementById(htmlElement)
    
    this.doc.html(pdfModalElement.innerHTML.trim(), {
      callback: (pdf) => {

        let totalPages = pdf.internal.pages.length - 1
        for (let i = 1; i <= totalPages; i++) {

          pdf.setPage(i);

          pdf.setTextColor(0, 0, 0);
          pdf.setFillColor(109, 203, 153);
          pdf.rect(0, 0, (width + 20), 100, "F");

          // Image and header
          var logo = new Image();
          logo.src = 'assets/images/ESK-Logo-Dark.png'; // https://www.treealliance.com.au/__data/assets/file/0023/2876/logo.svg
          pdf.addImage(logo, "png", 12, 10, 178, 75);

          this.doc.setFontSize(25)
          this.doc.text(fileName, width, 30, { align: 'right', }); // line-55

          this.doc.setFontSize(13)
          this.doc.text(`Location: ${targetName}
Area analysed: ${area.toFixed(1)}ha
Date reported: ${day}/${month}/${year}
          `, width, 52, { align: 'right', });


          // Footer
          this.doc.setLineWidth(3)
          this.doc.setDrawColor(110, 204, 153);
          this.doc.line(10, (height - 64), width, (height - 64));

          this.doc.setFontSize(14)
          let disclaimer = `This report was generated by the ${fileName} of the Farm & Forest Mapper app, built by Esk Mapping & GIS on behalf of Private Forests Tasmania and the Forest Practices Authority.`
          let lines = this.doc.splitTextToSize(disclaimer, (width - 24));

          this.doc.text(lines, 12, height - 43);


          // Copyright       
          pdf.setFillColor(50, 54, 57);
          pdf.rect(0, height - 3, (width + 20), height - 10, "F");

          pdf.setTextColor(255, 255, 255);
          this.doc.setFontSize(12)
          this.doc.text(`Farm & Forest Mapper`, 12, height + 18.5, { align: 'left', });
          this.doc.text(`Copyright © Esk Mapping & GIS ${year}`, (width / 2) + 20, height + 18.5, { align: 'center', });
          this.doc.text(`Page ${i} of ${totalPages}`, width, height + 18.5, { align: 'right', });
        }

        pdf.save(`${fileName}.pdf`); // pdf.output('dataurlnewwindow');

      },
      autoPaging: "slice",
      margin: [112, 5, 115, 5],
      html2canvas: {
        scale: 1,
        width: width,
        windowWidth: width
      },
      x: 10,
      y: 32,
      width: width, //target width in the PDF document
      windowWidth: width //window width in CSS pixels
    });

  }

  /**
   * Description: Helps generating PDF
   * Use: Pass in the file name and data and a PDF will be generated for you.
   * Author: it wasn't you
   **/

}
