import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-impact-harvest-rate',
  templateUrl: './impact-harvest-rate.component.html',
  styleUrls: ['./impact-harvest-rate.component.css']
})
export class ImpactHarvestRateComponent implements OnInit, OnChanges {

  @Input() mobility: string;
  @Input() certainity: string;

  @Input() formGroupName: string;
  form: FormGroup;

  @Output() valueChange = new EventEmitter();

  constructor(private rootFromGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.form = this.rootFromGroup.control.get(this.formGroupName) as FormGroup
  }

  ngOnChanges(C: SimpleChanges): void {

    if (C?.['certainity']) {
      setTimeout(() => {
        this.form.controls['uncertainity'].setValue(this.calculateCertainity());
        return;
      }, 0);
    }

    if (C?.['mobility']) {
      setTimeout(() => {
        this.form.controls['mobility'].setValue(this.calculateMobility());
        return;
      }, 0);
    }

    this.valueChange.emit(true)
  }

  calculateMobility() {

    if (this.mobility === "Highly Mobile") {
      return 16.66;
    }
    else if (this.mobility === "Somewhat Mobile") {
      return 8.33;
    }
    else {
      return 0;
    }
  }

  calculateCertainity() {

    if (this.certainity === "High Uncertainty") {
      return 16.66;
    }
    else if (this.certainity === "Medium Uncertainty") {
      return 8.33
    }
    else {
      return 0
    }
  }

}
