import { section } from "src/app/interfaces/JsPDF.extended.interface";
import { PaddockDexie } from "src/app/models/paddock.model";
import { PropertyDexie } from "src/app/models/property.model";

export const DssReportSections: Array<section> =
[
    {
        title: "Definitions of Suitability Classes for the Enterprise Suitability Maps",
        paragraphs: [
          "1.0 Well suited\n\nLand having no significant soil or climatic limitations to sustained production where risk of significant crop loss due to adverse conditions are unlikely.",
          "2.0 Suitable\n\nLand having only minor soil or climatic limitations that will not significantly reduce productivity where any risk of crop loss is possible, but inherently low.",
          "3.0 Moderately suitable\n\nLand having soil or climatic limitations that is likely to impact on sustained productivity where risk of significant crop loss is possible in some years.",
          "4.0 Unsuitable\n\nLand having soil and climatic limitations that are too severe for sustained production and will so reduce benefits, or increase required inputs, that this expenditure may not justify. Risk of crop loss is high.",
        ]
      },
    {
        title: "Important information about these Crop Suitability classes",
        paragraphs:
        [
            "The suitability classes listed above for this area of land were derived from the Enterprise Suitability Maps as prepared by the Department of Natural Resources and Environment Tasmania (NRET). Some of the information presented in this table has been aggregated to reduce complexity in the table but the full state-wide versions of the maps are available on LIST Map here.",
            "These suitability classes are based on computer modelling of the potential suitability to a given area and, as such, there are inherent uncertainties in the results. Some of the sites may have minor soil impediments with regard to pH, drainage and/or stone abundance that ought to be managed if land is to meet the full growing potential of the suitability class listed.",
            "These suitability classes listed are based upon the assumption that water for crop irrigation is available and therefore is not a limiting factor. Similarly, these suitability classes do not factor in accessibility to markets or any required setup cost. You should independently assess the financial feasibility of any of these crops for your specific situation prior to starting any such enterprise."
        ]
    }
      ,
    {
        title: "Disclaimers",
        paragraphs:
        [
            'This report was generated by the Forest Description Tool from within the Farm & Forest Mapper app, built by Esk Spatial on behalf of Private Forests Tasmania and the Forest Practices Authority.',
            'Some of the information within this report has been prepared by Private Forests Tasmania (PFT).',
            'Every reasonable endeavour has been used to ensure that the material was accurate at the time of publication. No representation or warranty is made by PFT for the accuracy, completeness, or relevance of such information to the user’s purpose.\n\nYou agree and accept that any use of the information is entirely at your own risk. PFT, the Crown, its officers, employees and agents do not accept any liability howsoever arising (including, without limitation, negligence) for any loss in connection with or arising out of any use of or reliance upon the information. Anyone considering any investment project should seek independent professional advice on the particular matter before making a decision.',
            'For further information please contact:',
            'Private Forests Tasmania\nPO Box 180, Kings Meadows Tas 7249\nTelephone: (03) 6777 2720\nEmail: admin@pft.tas.gov.au'
        ]
    }
]


export function getDssOverViewSection(target: PropertyDexie | PaddockDexie, totalAreaNotCovered:Number) : section
{
    let section:section = {title:"",paragraphs:[]}
    section.title = "Overview";
    section.paragraphs =
    [
        `Report Target: ${target.name}`,
        `Type: ${target instanceof PropertyDexie ? 'Property' : 'Paddock'} `,
        `Area Analyzed: ${target.area.toFixed(1)}(ha)`,
        `Area Not Analyzed: ${totalAreaNotCovered.toFixed(1)}(ha)`
    ]

      return section;
}

