import { Component, Input, OnInit, Output, EventEmitter, SimpleChange, OnChanges, SimpleChanges } from '@angular/core';
import { NpvCalculatorDataStorageService } from 'src/app/services/npv-calculator-data-storage.service';


@Component({
  selector: 'app-transport-costs',
  templateUrl: './transport-costs.component.html',
  styleUrls: ['./transport-costs.component.css']
})
export class TransportCostsComponent implements OnInit, OnChanges {

  @Input() distanceFromBuyer: number;
  @Input() totalActualVolumeLogsHarvestedPerYear: number;
  @Input() totalPotentialLogsHarvestedPerYear: number;
  @Input() discountRate: number;
  @Input() transportationCost: number;
  @Input() harvestLifespan: number | string;
  @Output() calculationsValueEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private npvCalcService: NpvCalculatorDataStorageService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

    // Passing values to the parent component from 
    if (changes) {
      const calculationData = {
        "Transport Cost": {
          "Distance from Buyer (km)": this.distanceFromBuyer,
          "Total Actual Volume of Logs Harvested (tonne)": this.totalActualVolumeLogsHarvestedPerYear,
          "Transport Cost ($/tonne)": this.transportationCost,
          "Monetary Value": this.calculateMonetaryValue(),
          "Discount Rate": this.discountRate,
          "Harvest Lifespan": this.harvestLifespan,
          "PV(C)": this.calculatePVC().toFixed(2),
        }
      }
      this.calculationsValueEmitter.emit(calculationData)
    }

  }

  calculateActualVolumnOfLogsHarvested() {
    return this.totalPotentialLogsHarvestedPerYear * (1 - this.totalActualVolumeLogsHarvestedPerYear);
  }

  calculateMonetaryValue() {
    return this.totalActualVolumeLogsHarvestedPerYear * this.transportationCost
  }

  // $C37*((1-(1+$C38)^-$C39)/$C38))
  calculatePVC() {

    if (this.harvestLifespan === "Indefinate") {

      const cPVC = this.calculateMonetaryValue() * this.discountRate
      this.npvCalcService.updateCosts({ key: 'transportation-costs', value: cPVC })
      return cPVC;

    } else {

      const cPVC = this.calculateMonetaryValue() * ((1 - (1 + (this.discountRate / 100)) ** -this.harvestLifespan) / (this.discountRate / 100))
      this.npvCalcService.updateCosts({ key: 'transportation-costs', value: cPVC })
      return cPVC;

    }
  }
}
