<div class="container-fluid p-0">
  <h4>Introduction</h4>
  <div class="row">
    <div class="col-12">
      <p>
        Preparation of the ground for any crop (including trees) is essential
        for a successful crop and to protect the long-term sustainability of the
        soil. Good preparation will:
      </p>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <span><b>1.</b></span> Minimise soil disturbance;
        </li>
        <li class="list-group-item">
          <span><b>2.</b></span> Result in good seedling survival;
        </li>
        <li class="list-group-item">
          <span><b>3.</b></span> Allow the crop to grow as uniformly as
          possible;
        </li>
        <li class="list-group-item">
          <span><b>4.</b></span> Increase the effectiveness of weed control
          operations and minimise the use of chemicals;
        </li>
        <li class="list-group-item">
          <span><b>5.</b></span> Mitigate against frost damage, waterlogging or
          drought;
        </li>
        <li class="list-group-item">
          <span><b>6.</b></span> Reduce risk of wind damage later in the
          rotation;
        </li>
        <li class="list-group-item">
          <span><b>7.</b></span> Reduce and cater for fire risk; and
        </li>
        <li class="list-group-item">
          <span><b>8.</b></span> Provide good access for thinning and pruning.
        </li>
      </ul>
      <p class="mt-3">
        In addition to ground preparation, completely removing difficult to
        control or highly competitive weeds prior to tree planting, allows the
        planted trees to grow as quickly as possible so they can occupy the site
      </p>
      <p>
        Weeds are a problem because they can compete with trees for water,
        nutrients and light. By their nature, most weed species are aggressive
        at occupying a site. If additional nutrients are applied using
        fertilizers, or water is applied by irrigation, weeds will generally
        benefit from these much more than the trees. Weeds can also contribute
        to increased frost damage, browsing susceptibility, fungal diseases and
        insect attack in young tree seedlings.
      </p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <h5>More information</h5>
      <ul class="list-group list-group-flush">
        <li class="list-group-item border border-0">
          <a
            href="https://pft.tas.gov.au/volumes/documents/Farming-Guides/Information_Series_-_No._6_-_Site_Preparation_-_Version_3_-_August_2020.pdf"
            target="_blank"
            >Site Preparation Guidelines</a
          >
        </li>
        <li class="list-group-item border border-0">
          <a
            href="https://pft.tas.gov.au/volumes/documents/Farming-Guides/Information_Series_-_No._7_-_Weed_Control_-_Version_3_-_August_2020.pdf"
            target="_blank"
            >Weed Control Guidelines</a
          >
        </li>
        <li class="list-group-item border border-0">
          <a
            href="https://pft.tas.gov.au/farming-guides"
            target="_blank"
            >Tree Farming Guide
          </a>
        </li>
      </ul>
    </div>
  </div>

  <!-- <div class="row">
        <div class="col mt-3">
            <button (click)="previewPDFHandler()" class="btn btn-primary">Preview PDF</button>
          </div>
    </div> -->
</div>
