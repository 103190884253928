<button
class="button button--brand button--full_width"
[ngClass]="{ 'select-active': active === true }"
(click)="toggleSelectProperty()"
popperHelp
helpOrder="3"
helpContent="Once you have navigated to your property you can toggle the property selection layer using this button, after which you can select the boundaries that make uo your property."
helpPlacement="right"
helpTitle="Help - Create Property"
[helpPreShowFunction]="helpPreShowFunction"
helpPreShowFunctionParams="createProperty"
appTooltip="Select Property Boundaries"
tooltipPosition="left"
>
Select parcels to make up the property boundary
</button>
