

<div class="card card--black">
    <div class="tools">
      <div class="card-title">
        <h3>FARM TOOLS & CALCULATORS</h3>
      </div>
          <div class="card-icon">
        </div>
      <button class="button button--crop-tool button--black" [routerLink]="['/map','report-dss']">CROP/SPECIES DSS TOOL</button>
      <button  class="button button--forest-tool  button--black" [routerLink]="['/map','report-forest']">FOREST DESCRIPTION TOOL</button>
      <button  class="button button--woodlot-tool  button--black" [routerLink]="['/map','report-woodlot']">FARM WOODLOT TOOL</button>
      <button  class="button button--topex-tool  button--black" [routerLink]="['/map','report-topex']">TOPEX SCORE TOOL</button>
      <button  appRole="['Administrator']" [routerLink]="['/map','report-npv']" class="button button--npv-tool  button--black">FOREST MANAGEMENT SCENARIO BASELINE COSTS & BENEFITS</button>
    </div>

</div>
