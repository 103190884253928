
import {intersect} from 'mathjs';
import { Feature, PluggableMap } from 'ol';
import { MultiPoint } from 'ol/geom';
import LineString from 'ol/geom/LineString';
import RenderFeature from 'ol/render/Feature';
import { Circle, Fill, RegularShape, Stroke, Style, Text } from 'ol/style';

export var styleMap: Map<string, Style | Style[]> = new Map();

var contoursStyle = new Style({
  stroke: new Stroke({
    color: [135, 135, 135, 1],
    width: 2,
  }),
});

var transportSegmentStyle = new Style({
  stroke: new Stroke({
    color: [234, 103, 10, 1],
    width: 3,
    lineDash: [10, 10],
  }),
});

var propertyEditStyle = [ new Style({
  image: new Circle({
    radius: 5,
      fill: new Fill({
          color: 'rgb(0, 0, 255)'
      }),
    stroke: new Stroke({color: 'blue', width: 2})
  }),
  geometry: function(feature : Feature<any>) {
      var coordinates = feature.getGeometry().getCoordinates()[0];
      return new MultiPoint(coordinates);
  }
}), new Style({
  stroke: new Stroke({
    color: [0, 112, 255, 1],
    width: 2,
  })
})];

var propertyStyle = new Style({
  stroke: new Stroke({
    color: [0, 112, 255, 1],
    width: 2,
  })
});

var hydroAreaStyle = new Style({
  stroke: new Stroke({
    color: 'rgb(0,224,202)',
    width: 3,
  }),
  fill: new Fill({
    color: 'rgb(169,255,247)',
  }),
});

export var firePermitStyleIcon = new RegularShape({
  fill:  new Fill(
    {
      color: 'rgba(0,255,0,1)',
    }
  ),
  stroke: new Stroke({
    color: 'rgb(0,0,0)',
    width: 3,
  }),
  points: 3,
  radius: 10,
  rotation: 0,
  angle: 0,
});

var firePermitsStyle = new Style({
  image: firePermitStyleIcon
  });

  export var fireAlertStyleIcon = new RegularShape({
    fill:  new Fill(
      {
        color: 'rgba(255,10,0,1)',
      }
    ),
    stroke: new Stroke({
      color: 'rgb(0,0,0)',
      width: 3,
    }),
    points: 3,
    radius: 10,
    rotation: 0,
    angle: 0,
  });

  var fireAlertStyle = new Style({
    image: fireAlertStyleIcon
    });


styleMap.set('Hydrographic Areas', hydroAreaStyle);
styleMap.set('Hydrographic Lines', new Style());
styleMap.set('Transport Segments', transportSegmentStyle);
styleMap.set('Contours 10m', contoursStyle);
styleMap.set('Private Reserves', new Style());
styleMap.set('Public Land Classification', new Style());
styleMap.set('Private Timber Reserves', new Style());
styleMap.set('Property', propertyStyle);
styleMap.set('editProperty',propertyEditStyle);
styleMap.set('Fire Permits', firePermitsStyle);
styleMap.set('Fire Alerts', fireAlertStyle);

let styleCache: Map<string, Style | Style[]> = new Map();

let LandUseColors = {
  Cropping: [120, 162, 204, 0.5], // rgba(120, 162, 204, 0.5),
  Grazing: [136, 174, 208, 0.5],// rgba(136, 174, 208, 0.5),
  Horticulture: [164, 195, 210, 0.5],// rgba(164, 195, 210, 0.5),
  Native_Forest: [133, 224, 133, 0.5],// rgba(133, 224, 133, 0.5),
  Native_Vegetation: [160, 231, 160, 0.5],// rgba(160, 231, 160, 0.5),
  Plantation: [173, 235, 173, 0.5],// rgba(173, 235, 173, 0.5),
  Other: [255, 235, 175, 0.5],// rgba(255, 235, 175, 0.5),
};

export var topexColors = {
  severelyExposed: [197, 70, 74,0.75], //rgba(197,70,74, 0.75)
  veryExposed: [209, 161, 110, 0.75],
  moderatelyExposed: [230, 223, 88, 0.75],
  moderatelySheltered: [	48, 170, 201, 0.75],
  verySheltered: [106, 106, 213, 0.75]
}

// export var ForestClassColors = {
//   "ER2/1,2": [255, 0, 0, 0.5], // red
//   "ER2/3": [0, 255, 0, 0.5], // green
//   "E3b": [0, 0, 255, 0.5], // blue
//   "E3c": [255, 255, 0, 0.5], // yellow
//   "ER3,4/3": [255, 0, 255, 0.5], // purple
//   "E3d": [0, 255, 255, 0.5], // cyan
//   "ER1/4": [255, 128, 0, 0.5], // orange
//   "E4ab": [128, 255, 0, 0.5], // light green
//   "ER1/3": [0, 128, 255, 0.5], // light blue
//   "E4c": [128, 0, 255, 0.5], // light purple
//   "<=E4(d)": [0, 255,128, 0.5], // light cyan
//   "ER2/4": [255, 0, 128, 0.5], // pink
// };

export var ForestClassColors = {
  "Other Non-Commercial": [255, 0, 0, 0.5], // red
  "High Quality Regrowth Eucalypt": [0, 255, 0, 0.5], // green
  "Low Quality Myrtle":[255, 0, 255, 0.5], // blue
  "Commercial Secondary Species (No Euc but containing Tea-tree)": [255, 255, 0, 0.5], // yellow
  "High Quality Mature Eucalypt": [0, 0, 255, 0.5], // purple
  "Medium Quality Regrowth Eucalypt":[0, 255, 0, 0.5], // cyan
  "High Quality Myrtle": [255, 0, 255, 0.5], // orange
  "Regrowth Myrtle: Below Commercial":[255, 0, 0, 0.5], // light green
  "Medium Quality Eucalypt Regeneration": [0, 255,128, 0.5], // light blue
  "Low Quality Mature Eucalypt: Non-Commercial": [255, 0, 0, 0.5], // light purple
  "Low Quality Mature Eucalypt": [0, 0, 255, 0.5], // light cyan
  "Low Quality Regrowth Eucalypt": [0, 255, 0, 0.5],
  "Low Quality Eucalypt Regeneration": [0, 255,128, 0.5], // pink
  "High Quality Eucalypt Regeneration": [0, 255,128, 0.5], // red
  "Commercial Secondary Species (No Euc or Wattle)": [255, 255, 0, 0.5], // green
  "Commercial Secondary Species (No Euc but containing Wattle)": [255, 255, 0, 0.5], // blue
  "Medium Quality Mature Eucalypt": [0, 0, 255, 0.5], // yellow
  "Commercial Secondary Species (No Euc but containing Blackwood)":[255, 255, 0, 0.5] , // purple
};

export var ForestClassesLegend = {
  "Mature Eucalypt": [0, 0, 255, 0.5],
  "Regrowth Eucalypt": [0, 255, 0, 0.5],
  "Eucalypt Regeneration" : [0, 255,128, 0.5],
  "Myrtle": [255, 0, 255, 0.5],
  "Commercial Secondary Species": [255, 255, 0, 0.5],
  "Non-Commercial" : [255, 0, 0, 0.5]
}

let opacity = 0.75;

export var versatilityGradientColors = {
  WellSuited: [0,38,115, opacity], //rgba(0,38,115,0.75)
  Suitable: [0,115,76, opacity], //rgba(0,115,76,0.5)
  ModeratelySuitable: [255,255,0, opacity], //rgba(255,255,0,0.5)
  Unsuitable: [255,170,0, opacity], //rgba(255, 36, 0,0.5)
};


export function getPaddockStyle(
  feature: Feature<any> | RenderFeature,
  resolution,
  filter
) {
  let featProps = feature.getProperties();
  let styles: Array<Style> = [];
  let opacity = 1;
  let color;
  let stroke = [0, 0, 0, 1];
  let landUse = featProps.landUseType;

  if (!styleCache.has(landUse)) {
    switch (landUse) {
      case 'Cropping':
        color = LandUseColors.Cropping;
        break;
      case 'Grazing':
        color = LandUseColors.Grazing;
        break;
      case 'Horticulture':
        color = LandUseColors.Horticulture;
        break;
      case 'Native Forest':
        color = LandUseColors.Native_Forest;
        break;
      case 'Native Vegetation':
        color = LandUseColors.Native_Vegetation;
        break;
      case 'Plantation':
        color = LandUseColors.Plantation;
        break;
      case 'Other':
        color = LandUseColors.Other;
        break;
    }

    let style = new Style({
      fill: new Fill({
        color: color,
      }),
      stroke: new Stroke({
        width: 0.5,
        color: stroke,
      }),
    });

    styleCache.set(landUse, style);
  }

  let cachedStyle = styleCache.get(landUse);

  if(cachedStyle instanceof Array)
  {
    styles.push(...cachedStyle);
  }
  else
  {
    styles.push(cachedStyle)
  }

  let labelStyle = new Style({
    text: new Text({
      text: featProps.name,
      font: '400 18px "Arial"',
      fill: new Fill({
        color: [255, 255, 255, 1.0],
      }),
      // rotation : rotation,
      textBaseline: 'bottom',
      stroke: new Stroke({ color: [0, 0, 0, 1.0], width: 2 }),
    }),

    zIndex: 100,
  });

  styles.push(labelStyle);

  return styles;
}


export function getTopexStyle(
  feature: Feature<any> | RenderFeature,
  resolution,
  filter
) {
  let featProps = feature.getProperties();
  let styles: Array<Style> = [];
  let opacity = 1;
  let color;
  let stroke = [0, 0, 0, 1];
  let gridCode = featProps.Gridcode;

  if (!styleCache.has(gridCode)) {
    switch (gridCode) {
      case 10:
        color = topexColors.severelyExposed;
        break;
      case 7:
        color = topexColors.veryExposed;
        break;
      case 3:
        color =topexColors.moderatelyExposed;
        break;
      case 1:
        color = topexColors.moderatelySheltered;
        break;
      case 0:
        color = topexColors.verySheltered;
        break;
    }

    let style = new Style({
      fill: new Fill({
        color: color,
      }),
      stroke: new Stroke({
        width: 0.5,
        color: stroke,
      }),
    });

    styleCache.set(gridCode, style);
  }

  let cachedStyle = styleCache.get(gridCode);

  if(cachedStyle instanceof Array)
  {
    styles.push(...cachedStyle);
  }
  else
  {
    styles.push(cachedStyle)
  }


  return styles;
}


export function getFcCodeStyle(
  feature: Feature<any> | RenderFeature,
  resolution,
  filter
) {
  let featProps = feature.getProperties();
  let styles: Array<Style> = [];
  let color;
  let stroke = [0, 0, 0, 1];
  let fc_desc = featProps.fc_desc;

  if(!styleCache.has(fc_desc))
  {

    color = ForestClassColors[fc_desc];

        let style = new Style({
          fill: new Fill({
          color: color,
          }),
          stroke: new Stroke({
          width: 0.5,
          color: stroke,
          }),
          });

          styles.push(style);

          styleCache.set(fc_desc, style)
  }

  let cachedStyle = styleCache.get(fc_desc);

  if(cachedStyle instanceof Array)
  {
    styles.push(...cachedStyle);
  }
  else
  {
    styles.push(cachedStyle)
  }

  let labelStyle = new Style({
    text: new Text({
      text: featProps.fc_code,
      font: '400 18px "Arial"',
      fill: new Fill({
        color: [255, 255, 255, 1.0],
      }),
      // rotation : rotation,
      textBaseline: 'bottom',
      stroke: new Stroke({ color: [0, 0, 0, 1.0], width: 2 }),
    }),

    zIndex: 100,
  });

  styles.push(labelStyle);


      return styles;
      }


export function getFenceStyle( feature: Feature<any> | RenderFeature,
  resolution,
  filter, map: PluggableMap)
  {
    let featProps = feature.getProperties();
    let styles: Array<Style> = [];
    let opacity = 1;
    let color;
    let stroke = [0, 0, 0, 1];
    let existing = featProps.existing;
    let wallabyProof = featProps.wallabyProof;
    let styleKey = existing == true ? 'Existing' : 'Planned';

    styleKey = wallabyProof == true ? styleKey + '_WB' : styleKey;



    if (!styleCache.has(styleKey))
    {

      let style = new Style({
        stroke: new Stroke({
          width: 4,
        }),
      });

      if(existing == true)
      {
        style.getStroke().setColor([58, 219, 4,1]);
      }
      else
      {
        style.getStroke().setColor([245, 208, 88,1]);
        style.getStroke().setLineDash([10,10]);
      }

      if(wallabyProof == true)
      {
        if(existing)
        {
          style.getStroke().setColor([58, 219, 4,1]);
        }
        else
        {
          style.getStroke().setColor([245, 191, 15,1]);
        }

        style.getStroke().setWidth(8);
      }

      styleCache.set(styleKey,style);
    }

    let cachedStyle = styleCache.get(styleKey);

    if(cachedStyle instanceof Array)
    {
      styles.push(...cachedStyle);
    }
    else
    {
      styles.push(cachedStyle)
    }


    let labelStyle = new Style({
      text: new Text({
        text: featProps.FEAT_NAME,
        font: '400 18px "Arial"',
        fill: new Fill({
          color: [255, 255, 255, 1.0],
        }),
        // rotation : rotation,
        textBaseline: 'bottom',
        stroke: new Stroke({ color: [0, 0, 0, 1.0], width: 2 }),
      }),

      zIndex: 100,
    });



    styles.push(labelStyle);

    return styles;

  }

function doubleLines(feature: Feature<any> | RenderFeature, resolution, width, stroke,map: PluggableMap): Style[]
{
  let styles = [];

  let canvas = document.createElement('canvas');

var ctx = canvas.getContext("2d");


  for (var line = 0; line < 2; line++) {
    var dist = width * resolution * (line - (2)/2);
    var geom = feature.getGeometry();
    var coords = [];
    var counter = 0;
    geom.forEachSegment(function(from, to) {

      console.log(from);
      console.log(to);

      let pixelFrom = map.getPixelFromCoordinate(from);

      let pixelTo = map.getPixelFromCoordinate(to);

      console.log('pixles',pixelFrom);


      var xMid = (from[0] + to[0]) / 2;
      var yMid = (from[1] + to[1]) / 2;

      console.log("X mid point",xMid);
      console.log("Y mid point", yMid);




        var angle = Math.atan2(to[1] - from[1], to[0] - from[0]);
        console.log(angle);
        // var newFrom = [
        //     Math.sin(angle) * dist + from[0],
        //     -Math.cos(angle) * dist + from[1]
        // ];
        // var newTo = [
        //     Math.sin(angle) * dist + to[0],
        //     -Math.cos(angle) * dist + to[1]
        // ];
        // coords.push(newFrom);
        // coords.push(newTo);
        // if (coords.length > 2) {
        //     var intersection = intersect(coords[counter], coords[counter+1], coords[counter+2], coords[counter+3]);
        //     coords[counter+1] = (intersection) ? intersection : coords[counter+1];
        //     coords[counter+2] = (intersection) ? intersection : coords[counter+2];
        //     counter += 2;
        // }

        var grd = ctx.createLinearGradient(pixelFrom[0],pixelFrom[1],pixelTo[0],pixelTo[1]);
        grd.addColorStop(0,"red");
        grd.addColorStop(0.5,'rgba(255,255,255,0');
        grd.addColorStop(1,"red");

            styles.push(
                new Style({
                    stroke: new Stroke({
                      width:4,
                      color:grd
                    })
                })
            );
    });

}

return styles;
}



export function getVersatilityStyle(
  feature: Feature<any> | RenderFeature,
  resolution,
  filter
) {
  let featProps = feature.getProperties();
  let styles: Array<Style> = [];
  let opacity = 1;
  let color = [240, 101, 149, 0.2]; //rgba(240,101,149,0.2)
  let stroke = [0, 0, 0, 1];
  let styleKey = '';

  if (!featProps[filter]) {
    return new Style({
      fill: new Fill({
        color: color,
      }),
      stroke: new Stroke({
        width: 0.5,
        color: stroke,
      }),
    });
  }

  let versatilityIndex = parseFloat(featProps[filter]);

  if (versatilityIndex >= 4) {
    color = versatilityGradientColors.Unsuitable;
    styleKey = 'Unsuitable';
  } else if (versatilityIndex >= 3) {
    color = versatilityGradientColors.ModeratelySuitable;
    styleKey = 'ModeratelySuitable';
  }else if (versatilityIndex >= 2) {
    color = versatilityGradientColors.Suitable;
    styleKey = 'Suitable';
  } else if (versatilityIndex >= 1) {
    color = versatilityGradientColors.WellSuited;
    styleKey = 'WellSuited';
  }

  if (!styleCache.has(styleKey)) {
    let style = new Style({
      fill: new Fill({
        color: color,
      }),
    });

    styleCache.set(styleKey, style);
  }

  let cachedStyle = styleCache.get(styleKey);

  if(cachedStyle instanceof Array)
  {
    styles.push(...cachedStyle);
  }
  else
  {
    styles.push(cachedStyle)
  }

  return styles;
}

export function getPropertiesStyle(feature, resolution, filter)
{

  let styles: Array<Style> = [];

 let strokeStyle = new Style({
    stroke: new Stroke({
      color: [0, 112, 255, 1],
      width: 2,
    })
  });

  styles.push(strokeStyle);

  let labelStyle = new Style({
    text: new Text({
      text: feature.getProperties().name,
      font: '400 18px "Arial"',
      fill: new Fill({
        color: [255, 255, 255, 1.0],
      }),
      // rotation : rotation,
      textBaseline: 'bottom',
      stroke: new Stroke({ color: [0, 0, 0, 1.0], width: 2 }),
    }),

    zIndex: 100,
  });

  styles.push(labelStyle);

  return styles;

}

export function getCadasterStyle(
  feature: Feature<any> | RenderFeature,
  resolution,
  filter
) {
  let featProps = feature.getProperties();
  let styles: Array<Style> = [];
  let opacity = 1;
  let color = [240, 101, 149, 0.05];
  let stroke = [0, 0, 0, 1];
  let styleKey = 'CADASTER_STYLE';

  if (!styleCache.has(styleKey)) {
    let style = new Style({
      fill: new Fill({
        color: color,
      }),
      stroke: new Stroke({
        width: 0.5,
        color: stroke,
      }),
    });

    styleCache.set(styleKey, style);
  }


  let cachedStyle = styleCache.get(styleKey);

  if(cachedStyle instanceof Array)
  {
    styles.push(...cachedStyle);
  }
  else
  {
    styles.push(cachedStyle)
  }


  let labelStyle = new Style({
    text: new Text({
      text: featProps.PID + '',
      font: '400 18px "Arial"',
      fill: new Fill({
        color: [255, 255, 255, 1.0],
      }),
      // rotation : rotation,
      textBaseline: 'middle',
      stroke: new Stroke({ color: [0, 0, 0, 1.0], width: 2 }),
    }),

    zIndex: 100,
  });

  styles.push(labelStyle);

  return styles;
}
