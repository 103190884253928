import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

interface IObjectKeys {
  [key: string]: string | number | null | IObjectKeys;
}

interface summaryNumberOfRollsInterface {
  standarddWire: number,
  barbWire: number,
  wallabyProofMesh: number,
  star: number,
  steelWood: number
}

@Injectable({
  providedIn: 'root'
})
export class TreeProtectionService {

  fencingMaterialTableInformation: IObjectKeys = {
    "Cattle-proof": {
      "Fencing Requirements": "Cattle-proof",
      "Timing": "Cattle need to be kept out of trees until they reach about 2m in height",
      "Other Considerations": null,
      "Standard Wire Rows Required": 0,
      "Barb Wire Rows Required": 5,
      "Standard Wire Roll length - metres": 200,
      "Barb Wire Roll Length - metres": 500,
      "Wallaby Proof Mesh Roll Length - metres": 0,
    },
    "Sheep-proof": {
      "Fencing Requirements": "Sheep-proof",
      "Timing": "Sheep need to be kept out of trees until they reach about 2m in height",
      "Other Considerations": null,
      "Standard Wire Rows Required": 4,
      "Barb Wire Rows Required": 1,
      "Standard Wire Roll length - metres": 200,
      "Barb Wire Roll Length - metres": 500,
      "Wallaby Proof Mesh Roll Length - metres": 0,
    },
    "Wallaby-proof": {
      "Fencing Requirements": "Wallaby-proof",
      "Timing": "Wallabies need to be kept out of trees until they reach about 2m in height, approximately 2-4 years of age depending on how well the trees are growing. ",
      "Other Considerations": "Wallabies can also be controlled via shooting. This needs to commence months prior to planting, then continue throughout and after planting for approximately X months",
      "Standard Wire Rows Required": 0,
      "Barb Wire Rows Required": 1,
      "Standard Wire Roll length - metres": 200,
      "Barb Wire Roll Length - metres": 500,
      "Wallaby Proof Mesh Roll Length - metres": 30,
    },
  }

  materialTreeProtectionForm: FormGroup = new FormGroup({
    lengthOfFenceRequired: new FormControl({ value: null, disabled: true }, [Validators.required]),
    postSpacing: new FormControl(3, [Validators.minLength(0)]),
    ratioStarPostToSteel: new FormControl(3, [Validators.minLength(0)]),
    standardWireRows: new FormControl(0, [Validators.minLength(0)]),
    barbWireRows: new FormControl(0, [Validators.minLength(0)]),
  });

  fencingType: string[] = [];
  selectedFencingType?: string | null;
  selectedFencingTypeDetails?: any;

  summaryNumberOfRollsRequired: summaryNumberOfRollsInterface = {
    standarddWire: 0,
    barbWire: 10,
    wallabyProofMesh: 100,
    star: 0,
    steelWood: 0
  };

  constructor() {

    // Generating keys for the fencing type dropdown list.
    this.fencingType = Object.keys(this.fencingMaterialTableInformation);

    // This value is coming from the property or paddock
    this.materialTreeProtectionForm.controls["lengthOfFenceRequired"].setValue(1500)
  }

  changeToFencingTypeHandler(evt: Event) {
    this.selectedFencingType = null;

    const elem = evt.target as HTMLInputElement;
    const val = elem.value;

    if (!val) {
      this.selectedFencingType = null;
      this.materialTreeProtectionForm.controls["lengthOfFenceRequired"].disable();
    } else {
      this.materialTreeProtectionForm.controls["lengthOfFenceRequired"].enable();
    }

    // Timeout has been set to trigger fade in animation on the tree-protection component.

    setTimeout(() => {
      this.selectedFencingType = val;
      this.selectedFencingTypeDetails = this.fencingMaterialTableInformation[this.selectedFencingType];

      this.populateFormDataHandler(this.fencingMaterialTableInformation[this.selectedFencingType]);
    }, 200)
  }

  populateFormDataHandler(tableObjectData: any) {
    this.materialTreeProtectionForm.controls["standardWireRows"].setValue(tableObjectData["Standard Wire Rows Required"]);
    this.materialTreeProtectionForm.controls["barbWireRows"].setValue(tableObjectData["Barb Wire Rows Required"]);

    // Passing data down to the summary table.
    this.populateSummaryTableData(tableObjectData);
  }

  // Populating summary table data handler
  populateSummaryTableData(tableObjectData: any) {

    const isWallabyProof: boolean = tableObjectData["Fencing Requirements"].toLowerCase().includes("wallaby"); // C13

    const lengthOfTheFence = this.materialTreeProtectionForm.get("lengthOfFenceRequired")?.value; // C14

    const ratioStarPostsToSteelPine = Number(this.materialTreeProtectionForm.get("ratioStarPostToSteel")?.value); // C18
    const standardWireRowRequired = this.materialTreeProtectionForm.get("standardWireRows")?.value; // C19
    const barbWireRequired = this.materialTreeProtectionForm.get("barbWireRows")?.value; // C20

    const barbWireRollLength = tableObjectData["Barb Wire Roll Length - metres"]; // C29
    const standardWireRollLength = tableObjectData["Standard Wire Roll length - metres"]; // B29
    const wallabyProofMeshLength = tableObjectData["Wallaby Proof Mesh Roll Length - metres"]; // D29


    const starValue: number = ((lengthOfTheFence / (ratioStarPostsToSteelPine + 1)) * ratioStarPostsToSteelPine) / (ratioStarPostsToSteelPine + 1);
    const steelWoodValue: number = ((lengthOfTheFence / (ratioStarPostsToSteelPine + 1)) * 1) / (ratioStarPostsToSteelPine + 1);


    this.summaryNumberOfRollsRequired = {
      standarddWire: (lengthOfTheFence * standardWireRowRequired) / standardWireRollLength,
      barbWire: (lengthOfTheFence * barbWireRequired) / barbWireRollLength,
      wallabyProofMesh: isWallabyProof ? lengthOfTheFence / wallabyProofMeshLength : 0,
      star: Math.ceil(starValue),
      steelWood: Math.ceil(steelWoodValue)
    }

  }

  // Manual trigger on keydown on specific fields
  inputChangePopulateFormDataHandler() {
    if (this.selectedFencingType && this.selectedFencingType?.trim().length > 0) {
      this.populateSummaryTableData(this.fencingMaterialTableInformation[this.selectedFencingType]);
    }
  }

}
