<div class="full-screen-container">
  <section class="change-password">

    <form
    [formGroup]="newPasswordForm"
    (ngSubmit)="onSubmit()"
    class="change-password_form"
    *ngIf="!submitted; else showSuccess"
    >
    <header><h2>Change Your Password</h2></header>
      <fieldset>

        <div class="form-group">
          <label>New Password:
            <input
             type="password"
             formControlName="password"
             name="password"
             >
          </label>
          <span class="form-error" *ngIf="!newPasswordForm.get('password').valid && newPasswordForm.get('password').touched ">

            <span *ngIf="newPasswordForm.get('password').errors['required']">This field is required!</span>

            <span *ngIf="!newPasswordForm.get('password').errors['required']">
              Password must contain:
              <ul>
                <li *ngIf="newPasswordForm.get('password').errors['noUpper']">One or more uppercase characters.</li>
                <li *ngIf="newPasswordForm.get('password').errors['noSpecial']">One or more special characters !$&. </li>
                <li *ngIf="newPasswordForm.get('password').errors['minlength']">8 or more characters.</li>
              </ul>
            </span>

          </span>
        </div>
        <div class="form-group">
          <label>Confirm Password:
            <input
              type="password"
              formControlName="rptPassword"
              name="rptPassword"
            >
          </label>
          <span class="form-error" *ngIf="!newPasswordForm.valid">
            <span *ngIf="newPasswordForm.errors['notMatched']">
              Passwords don't match!
             </span>
          </span>
        </div>

      </fieldset>
      <button [disabled]="!newPasswordForm.valid" type="submit" class="button button--brand">Change Password</button>
      </form>

      <ng-template #showSuccess>
        <section class="success">
          <i class="fa fa-5x fa-check-circle"></i>
          <p>Your password has been reset successfully. please login to continue</p>
          <button [routerLink]="['/login']" class="button button--brand">Login</button>
        </section>
      </ng-template>

  </section>


</div>

