<div class="container-fluid p-0">
  <div id="ptr-guideline-component">
    <h4>Introduction</h4>
    <div class="row">
      <div class="col-12">
        <p>
          In Tasmania private timber reserves are an integral part of the
          State's commitment to encouraging and fostering the sustainable
          management of private forests. Forestry, as a major statewide
          industry, is covered by a statewide planning system in the form of the
          Forest Practices Act 1985 and Forest Practices Code. Private timber
          reserves create a secure environment for investment in private
          forestry within the State and help to deliver commitments for the
          sustainable management of private forests.
        </p>

        <p class="mt-2">
          A private timber reserve is an area of private land set aside for
          forestry purposes and registered on the title. It is land or forest
          used to establish forests, or grow or harvest timber in accordance
          with the Forest Practices Code.
          <br />
          A private timber reserve is a parcel of private land or forest of at
          least five hectares in area set aside to grow or harvest forests. It
          may be an area of:
        </p>

        <ul class="list-group list-group-flush line-before">
          <li class="list-group-item">native forest; or</li>
          <li class="list-group-item">a tree plantation; or</li>
          <li class="list-group-item">
            an area of land intended to be planted in the near future.
          </li>
        </ul>

        <p class="mt-3">
          On land declared a private timber reserve local government approval is
          not required when undertaking forestry operations. Under the Land Use
          Planning and Approvals Act 1993 section 20 (7), nothing in any
          planning scheme, or interim order, affects the management of land
          declared as a private timber reserve under the Forest Practices Act
          1985
        </p>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <h5>More information</h5>
        <ul class="list-group list-group-flush">
          <li class="list-group-item border border-0 px-0">
            <a
              href="https://pft.tas.gov.au/private-timber-reserves-ptr"
              target="_blank"
              >Private Timber Reserves</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- <div class="row">
        <div class="col mt-3">
            <button (click)="previewPDFHandler()" class="btn btn-primary">Preview PDF</button>
        </div>
    </div> -->
</div>
