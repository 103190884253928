import { Component, OnInit, Input, SimpleChange, OnChanges, Output, EventEmitter } from '@angular/core';
import { NpvCalculatorDataStorageService } from 'src/app/services/npv-calculator-data-storage.service';

@Component({
  selector: 'app-peeler-log-revenue',
  templateUrl: './peeler-log-revenue.component.html',
  styleUrls: ['./peeler-log-revenue.component.css']
})
export class PeelerLogRevenueComponent implements OnInit {

  @Input() impactOnHarvest: number = 0;
  @Input() totalActualLogsHarvested: number = 0;
  @Input() harvestThatArePeelerlogs: number = 0;
  @Input() peelerPrice: number = 0;
  @Input() discountRate: number = 0;
  @Input() harvestLifespan: number | string = 0;
  @Output() calculationsValueEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private npvCalcService: NpvCalculatorDataStorageService) { }

  ngOnInit(): void {
  }

  ngOnChanges(change: SimpleChange) {

    // Passing values to the parent component from 
    if (change) {
      const calculationData = {
        "Peeler Log Revenue": {
          "Impact on Harvest Rates from FPP (% reduction)": this.impactOnHarvest,
          "Total Actual Volume of Logs Harvested (tonne)": this.totalActualLogsHarvested,
          "% of Harvest that are Peeler logs": this.harvestThatArePeelerlogs,
          "Total Actual Volume of Sawlogs Sold (tonne)": this.calculateTotalActualLogsHarvested(),
          "Peeler log Price ($/tonne)": this.peelerPrice,
          "Monetary Value": this.calculateMonetaryValue(),
          "Discount Rate": this.discountRate,
          "Harvest Lifespan": this.harvestLifespan,
          "PV(B)": this.calculatePVb().toFixed(2),
        }
      }
      this.calculationsValueEmitter.emit(calculationData)
    }
  }

  calculateTotalActualLogsHarvested() {
    return this.totalActualLogsHarvested * (this.harvestThatArePeelerlogs / 100)
  }


  calculateMonetaryValue() {
    return this.calculateTotalActualLogsHarvested() * this.peelerPrice
  }

  calculatePVb() {

    // =IF($C14="Indefinite",$C12/$C13,$C12*((1-(1+$C13)^-$C14)/$C13))
    if (this.harvestLifespan === "Indefinite") {

      const pVB = this.calculateMonetaryValue() / (this.discountRate / 100)
      this.npvCalcService.updateBenefits({ key: 'peeler-log-revenue', value: pVB })
      return pVB

    }

    else {

      const pVB = this.calculateMonetaryValue() * ((1 - (1 + (this.discountRate / 100)) ** -this.harvestLifespan) / (this.discountRate / 100))
      this.npvCalcService.updateBenefits({ key: 'peeler-log-revenue', value: pVB })
      return pVB

    }

    return 0
  }

}