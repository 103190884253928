<mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header >
        <mat-panel-title>
          <mat-slide-toggle
          (click)="$event.stopPropagation();"
          class="example-margin"
          [checked]="getLayerGroupVisibility()"
          (change)="setLayerGroupVisibility($event)"
          >
      </mat-slide-toggle>
        </mat-panel-title>
        <mat-panel-description>
          {{layer.get('title')}}
        </mat-panel-description>
      </mat-expansion-panel-header>


      <span>Opacity:{{getLayerGroupOpacityLabel()}}</span>
      <mat-slider
      thumbLabel
      [displayWith]="formatLabel"
      tickInterval="10"
      step="10"
      min="0"
      max="100"
      aria-label="units">
      <input matSliderThumb  (input)="setLayerGroupOpacity($event)" [value]="getLayerGroupOpacity()" #slider>
    </mat-slider>


      <mat-form-field *ngIf="paddockSelect" appearance="fill">
        <mat-label>Select Paddocks</mat-label>
        <mat-select [formControl]="paddocksForm" multiple (openedChange)="onPaddockSelected($event)" >
          <mat-option *ngFor="let paddock of paddocks" [value]="paddock">{{paddock.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <button *ngIf="$needsDownloading | async" (click)="downloadLayers.emit(true)" class="button button--blue button-margin">
        Download Layers
      </button>

      <article *ngFor="let color of colorIndex" class="suitabilityMatrix">
        <div [ngStyle]="{backgroundColor: color.color}" class="color-box"></div>
        <span class="capitalized-text">{{color.name | spaceBeforeCap}}</span>
      </article>
    </mat-expansion-panel>
  </mat-accordion>
