<div class="user-details card card--black">
  <div class="card-title">
    <h3>USER DETAILS</h3>
  </div>
  <div class="card-icon">
  </div>
  <Ul class="user-detials__list">
    <li><a class="user-detials__link user-detials__link--orange" (click)="open()">Change Password</a></li>
    <li><a class="user-detials__link user-detials__link--orange" (click)="getFeedback()" type="button" >Send Feedback</a></li>
  </Ul>
</div>

