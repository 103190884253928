import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() title: string;
  @Input() position?: string;
  @Input() size?: string;

  private element: HTMLElement;

  constructor( private modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement;
   }

  ngOnInit(): void {
    this.modalService.register(this.id,this.element);
    // console.log(this.position)
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
  }

}
