<div class="uk-column-1-2@m uk-flex-row medium-flex uk-margin-bottom uk-margin-remove" [formGroup]="formGroup">
    <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
        <label class="uk-flex-1">Planned Fence Length (m) <input class="uk-input" formControlName="plannedFenceLength"
                type="number">
        </label>
    </div>
    <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
        <label class="uk-flex-1">Existing Fence Length (m) <input class="uk-input" formControlName="existingFenceLength"
                type="number">
        </label>
    </div>
    <!-- <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
        <label class="uk-flex-1">Land size (ha) <input class="uk-input" formControlName="ForestSize" type="number"
                [value]="forestSize">
        </label>
    </div> -->
    <!-- <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
        <label class="uk-flex-1">Type of Forestry Operation <select formControlName="TypeOfForestryOperation"
                class="uk-select">
                <option *ngFor="let typeOfOperations of typeOfForestryOperationValues" value="{{typeOfOperations}}">
                    {{typeOfOperations}} </option>
            </select>
        </label>
    </div> -->
</div>
