<h2 mat-dialog-title>Estimated Standing Volume Disclaimer</h2>
<mat-dialog-content class="mat-typography">
    <p>The actual volume of timber that can be extracted from your area of native forest will typically be less than the estimated total standing volume figure shown. The actual volume available for harvest could be anywhere from 75% to 25% of that standing volume figure estimated by the tool, and in some cases none of your forest might be available.</p>
    <p>The sections below provide a brief overview as to reasons why the actual volume available for harvest might be considerably lower than the total standing volume that has been estimated and it is recommended you seek professional advice should you wish to harvest your forest.</p>
    <h3>Forest Practices Code</h3>
    <p>The Forest Practices Code provides the guidelines under which all forest practices can be undertaken in Tasmania including forest harvesting (including firewood), forest regeneration, forest clearing, tree fern harvesting, and the construction of roads and quarries for such purposes. The Forest Practices Code and associated planning tools are used to identify areas of the forest, or even individual trees, that may need to be retained during harvesting operations to maintain flora, fauna, soil, water and other environmental values present in the local landscape. The Forest Practices Code is one component of the forest practices system which under the Forest Practices Act 1985 supports sustainable management of Crown and private forests with due care for the environment and taking into account social, economic and environmental outcomes. The ‘Forestry Management Baseline Costs and Benefits Tool’ available in this app provides an indication of the costs of compliance, accounting for management under the forest practices system.</p>
    <h3>Threatened Native Vegetation Communities</h3>
    <p>Some forests have been identified as requiring additional protection within the Forest Practices Code management requirements. The extent of these Threatened Native Vegetation Communities (TNVC) can be displayed as a layer in the map in this app. If your planned harvest areas include TNVC please contact the Forest Practices Authority for more information.</p>
    <h3>Selective Harvesting</h3>
    <p>The majority of native forest on private land is harvested with selective harvesting techniques to ensure successful forest regeneration post-harvest. This might mean that only 75% to 50% of the trees available for harvest under the Forest Practices Code can actually be extracted, the other 25-50% left standing to assist with regenerating the forest after harvesting has been completed.</p>
    <h3>Recent Harvesting Activity</h3>
    <p>Any forest regeneration after recent harvesting might take up to 80 years to achieve a size that would be available for harvest again.</p>
    <h3>Local Planning Scheme</h3>
    <p>Unless the forest is within a Private Timber Reserve, some local planning schemes may prevent you from harvesting native forest.</p>
    <h3>Accessibility</h3>
    <p>Steep slopes, inadequate access across a river and other limitations to access to the forest with harvesting machinery might reduce the area of forest available for harvesting.</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      close
    </button>
  </footer>
</mat-dialog-actions>
