<div class="uk-column uk-margin-top uk-padding-small@m uk-padding-remove@m" [formGroup]=" form">
    <div class="uk-column-1-3@m uk-margin-bottom">
        <div class="uk-column uk-flex-column uk-flex-1 uk-flex-bottom uk-margin-bottom">
            <label _ngcontent-geb-c215="" class="fw-label uk-flex uk-flex-between overflow-hidden vertically-center-them">% Decrease in Volume due to Mobility
                <button type="button"
                    class="icon-button btn btn-outline-secondary me-2 uk-button uk-button-link uk-padding-small uk-padding-remove-vertical"
                    placement="top" uk-tooltip="title:% Decrease in Log Volume Available for Harvest due to Mobility; pos: left; animation:uk-animation-slide-left;">
                    <i class="fa fa-info"></i>
                </button>
            </label>
            <!-- <input class="uk-input" formControlName="Mobility" type="number" [value]="calculateMobility()"> -->
            <input class="uk-input" formControlName="mobility" type="number">
        </div>

        <div class="uk-column uk-flex-column uk-flex-1 uk-flex-bottom uk-margin-bottom uk-invisible@s classHiddenOnMobile uk-height-1-1 uk-card uk-card-body">
            <p class="uk-invisible classHiddenOnMobile">ref container</p>
        </div>

        <div class="uk-column uk-flex-column uk-flex-1 uk-flex-bottom uk-margin-bottom">
            <label _ngcontent-geb-c215="" class="fw-label uk-flex uk-flex-between overflow-hidden vertically-center-them">% Decrease in Volume due to Uncertainity
                <button type="button"
                    class="icon-button btn btn-outline-secondary me-2 uk-button uk-button-link uk-padding-small uk-padding-remove-vertical"
                    placement="top" uk-tooltip="title:% Decrease in Log Volume Available for Harvest due to Uncertainity; pos: left; animation:uk-animation-slide-left;">
                    <i class="fa fa-info"></i>
                </button>
            </label>
            <!-- <input class="uk-input" formControlName="Uncertainity" type="number" [value]="calculateCertainity()"> -->
            <input class="uk-input" formControlName="uncertainity" type="number">
        </div>
    </div>
</div>
