import { Injectable } from '@angular/core';
import Dexie, { liveQuery, Table } from 'dexie';
import { BehaviorSubject, catchError, from, Observable, switchMap, throwError } from 'rxjs';
import { FenceDexie } from '../models/fence.model';
import { PaddockDexie } from '../models/paddock.model';
import { PropertyDexie } from '../models/property.model';
import {
  ClippedLayer_Paddock,
  ClippedPropertyLayer
} from '../models/clippedLayers.model';
import { LandUseType } from '../models/landUseType.model';
import { EnterpriseType } from '../models/enterpriseType.model';
import {
  cropTypeDefinition,
  landuseDefinition
} from '../_helpers/bootstrapDbDefinitions';
import { NpvReport } from '../models/NPV Calculator/npv-report-model';
import { Enterprise } from '../models/enterprise.model';
import { HttpClient } from '@angular/common/http';
import { EnterpriseReport } from '../models/enterpriseReport.model';
import { TopexReport } from '../models/topexReport.model';
import {
  Action,
  DatabaseSyncAction,
  EntityType
} from '../models/databaseSync.model';
import { environment } from 'src/environments/environment';
import { ActivationStart } from '@angular/router';
import { AuthService } from './auth.service';
import { User } from '../models/user.model';
import _ from 'lodash';
import { DeletedEntity } from '../interfaces/deletedEntity.interface';
import { ForestDescriptionReport } from '../models/Forest Description/forestReport.model';

@Injectable({
  providedIn: 'root'
})
export class DexieDatabaseService extends Dexie {
  databaseReady = new BehaviorSubject(false);
  user: User;

  landUseTypes!: Table<LandUseType, number>;
  enterpriseTypes!: Table<EnterpriseType, number>;
  propertyClippedLayers!: Table<ClippedPropertyLayer, string>;
  paddockClippedLayers!: Table<ClippedLayer_Paddock, number>;
  properties!: Table<PropertyDexie, string>;
  paddocks!: Table<PaddockDexie, string>;
  fences!: Table<FenceDexie, string>;
  npvReports!: Table<NpvReport, string>;
  enterprises!: Table<Enterprise, number>;
  enterpriseReports!: Table<EnterpriseReport, string>;
  topexReports!: Table<TopexReport, string>;
  databaseSyncActions!: Table<DatabaseSyncAction, number>;
  forestDescriptionReports!: Table<ForestDescriptionReport, string>;

  constructor(private http: HttpClient, private authService: AuthService) {
    super('FarmAndForestMapper-Latest');
  authService.user.subscribe(user => {
    this.user = user;
  });

    this.version(17).stores({
      propertyClippedLayers: '&clippedLayerID,propertyID',
      paddockClippedLayers: '++id,paddockID',
      properties: '&propertyID,userID',
      paddocks: '&paddockID,propertyID',
      fences: '&fenceID,propertyID,paddocks',
      npvReports: '&npvReportID,propertyID,paddockID',
      enterpriseReports: '&enterpriseReportID,propertyID,paddockID',
      topexReports: '&topexReportID,propertyID,paddockID',
      forestDescriptionReports: '&forestDescriptionReportID,propertyID,paddockID',
      enterpriseTypes: '++enterpriseTypeID',
      landUseTypes: '++landUseTypeID',
      enterprises: '++id',
      databaseSyncActions: '++id,entityID'
    });

    this.on('populate', () => this.populate());

    this.on('ready', async () => {
      await this.removeDeletedEntities();
      await this.performSyncActions();
      this.getDataFromDatabase();
    });

    this.properties.mapToClass(PropertyDexie);
    this.paddocks.mapToClass(PaddockDexie);
    this.fences.mapToClass(FenceDexie);
    this.npvReports.mapToClass(NpvReport);
    this.landUseTypes.mapToClass(LandUseType);
    this.enterpriseTypes.mapToClass(EnterpriseType);
    this.enterprises.mapToClass(Enterprise);
    this.enterpriseReports.mapToClass(EnterpriseReport);
    this.topexReports.mapToClass(TopexReport);
    this.forestDescriptionReports.mapToClass(ForestDescriptionReport);
    this.databaseSyncActions.mapToClass(DatabaseSyncAction);
    this.databaseReady.next(true);
  }


/* Removing deleted entities from the database. */
  async removeDeletedEntities()
  {
    this.http.get<Array<DeletedEntity>>(environment.apiURL + 'Deleted/getAll').subscribe(async deletedEntities => {


      deletedEntities.sort((a, b) => {
      if (
        a.entityType == EntityType.Property &&
        (b.entityType == EntityType.Paddock ||
          b.entityType == EntityType.Fence ||
          b.entityType == EntityType.NpvReport)
      ) {
        return -1;
      } else if (
        a.entityType == EntityType.Paddock &&
        (b.entityType == EntityType.Fence ||
          b.entityType == EntityType.NpvReport)
      ) {
        return -1;
      } else {
        return 0;
      }
    });

      for (let index = 0; index < deletedEntities.length; index++) {
        const deletedEntity = deletedEntities[index];


        switch(deletedEntity.entityType){
          case EntityType.Property:
          await this.removeProperty(deletedEntity.entityID)
          break;

          case EntityType.Paddock:
            await this.removePaddock(deletedEntity.entityID)
          break;

          case EntityType.Fence:
            await this.removeFence(deletedEntity.entityID)
          break;

        }
      }
      return true;
    })
  };


  async removeProperty(id: string)
  {
    await this.properties.delete(id);

    let paddocks = await this.paddocks.where({propertyID: id});

    paddocks.eachPrimaryKey(async key =>
     {
      await this.paddockClippedLayers.where({paddockID: key}).delete()
      await this.enterpriseReports.where({paddockID:key}).delete();
      await this.topexReports.where({paddockID:key}).delete();
      await this.forestDescriptionReports.where({paddockID:id}).delete();
     }
     );

     await paddocks.delete();

     await this.fences.where({propertyID: id}).delete();
     await this.enterpriseReports.where({propertyID:id}).delete();
     await this.topexReports.where({propertyID:id}).delete();
     await this.propertyClippedLayers.where({propertyID:id}).delete();
     await this.propertyClippedLayers.where({propertyID:id}).delete();
     await this.forestDescriptionReports.where({propertyID:id}).delete();

  }

  async removePaddock(id: string)
  {
    await this.paddocks.delete(id);

    await this.paddockClippedLayers.where({paddockID: id}).delete()
    await this.enterpriseReports.where({paddockID:id}).delete();
    await this.topexReports.where({paddockID:id}).delete();
    await this.forestDescriptionReports.where({paddockID:id}).delete();
  }

  async removeFence(id: string)
  {
    await this.fences.where({fenceID: id}).delete();
  }

/**
 * It gets all the actions from the database, sorts them, then loops through them and performs the
 * action.
 *
 * The actions are sorted because the order in which they are performed is important.
 *
 * The actions are performed by calling the sync function.
 *
 * The sync function is called with the entity that is being synced, the action that is being
 * performed, and the url that the action is being performed on.
 *
 * The sync function is shown below:
 */
  async performSyncActions() {
    let actions = await this.databaseSyncActions.toArray();

    actions  = this.sortActions(actions)

    for (let index = 0; index < actions.length; index++) {
      const action = actions[index];

      switch (action.entityType) {
        case EntityType.Property:
          let property = await this.properties.get(action.entityID);
          await this.sync(property, action, 'Property');
          break;
        case EntityType.Paddock:
          let paddock = await this.paddocks.get(action.entityID);
          await this.sync(paddock, action, 'Paddock');
          break;
        case EntityType.Fence:
          let fence = await this.fences.get(action.entityID);
          await this.sync(fence, action, 'Fence');
          break;
        case EntityType.EnterpriseReport:
          let enterpriseReport = await this.enterpriseReports.get(
            action.entityID
          );

          if(enterpriseReport == null)
          break;

          await this.sync(
            enterpriseReport,
            action,
            enterpriseReport.paddockID == null
              ? 'EnterpriseReport/property'
              : 'EnterpriseReport/paddock'
          );
          break;
        case EntityType.TopexReport:
          let topexReport = await this.topexReports.get(action.entityID);

          if(topexReport == null)
          break;

          await this.sync(
            topexReport,
            action,
            topexReport.paddockID == null
              ? 'TopexReport/property'
              : 'TopexReport/paddock'
          );

          break;

          case EntityType.ForestDescriptionReport:
            let forestDescriptionReport = await this.forestDescriptionReports.get(action.entityID);

            if(forestDescriptionReport == null)
            break;

            await this.sync(
              forestDescriptionReport,
              action,
              forestDescriptionReport.paddockID == null
                ? 'ForestDescriptionReport/property'
                : 'ForestDescriptionReport/paddock'
            );

            break;
      }
    }

    return true;
  }

  private sortActions(_actions)
  {

    let actions: Array<DatabaseSyncAction> = _actions;

    if (actions.length >= 2) {
      actions = actions.sort((a, b) => {
        if (
          a.entityType == EntityType.Property &&
          (b.entityType == EntityType.Paddock ||
            b.entityType == EntityType.Fence ||
            b.entityType == EntityType.NpvReport)
        ) {
          return -1;
        } else if (
          a.entityType == EntityType.Paddock &&
          (b.entityType == EntityType.Fence ||
            b.entityType == EntityType.NpvReport)
        ) {
          return -1;
        } else {
          return 0;
        }
      });

      actions = actions.sort((a, b) => {
        if(a.action == Action.Insert && b.action == Action.Update)
        {
          return -1;
        }
        else if(a.action == Action.Update && b.action == Action.Remove)
        {
          return -1;
        }
        else {
          return 0;
        }
      })
    }

    return actions;
  }

  private async sync(entity, syncAction: DatabaseSyncAction, endpoint: string) {
    switch (syncAction.action) {
      case Action.Insert: {
        this.http
          .post(environment.apiURL + endpoint + '/add', entity)
          .subscribe(async (Response) => {
            await this.databaseSyncActions.delete(syncAction.id);
          });
        break;
      }
      case Action.Update: {
        this.http
          .post(environment.apiURL + endpoint + '/update', entity)
          .subscribe(async (Response) => {
           await this.databaseSyncActions.delete(syncAction.id);
          });
        break;
      }
      case Action.Remove: {
        this.http
          .post(environment.apiURL + endpoint + '/delete', syncAction.entityID)
          .subscribe((Response) => {

          });
        break;
      }
    }
    await this.databaseSyncActions.delete(syncAction.id);
  }

  async populate() {
    await this.landUseTypes.bulkPut(landuseDefinition);
    await this.enterpriseTypes.bulkPut(cropTypeDefinition);

  }


/**
 * It gets all the properties from the remote database, then it gets all the properties from the local
 * database, then it compares the two and adds any properties that are missing from the local database,
 * then it gets all the properties from the local database again, then it gets all the enterprise
 * reports for each property, then it gets all the topex reports for each property, then it gets all
 * the paddocks for each property, then it gets all the paddocks from the local database, then it
 * compares the two and adds any paddocks that are missing from the local database, then it gets all
 * the paddocks from the local database again, then it gets all the topex reports for each paddock,
 * then it gets all the enterprise reports for each paddock, then it gets all the fences for each
 * property, then it gets all the fences from the local database, then it compares the two and adds any
 * fences that are missing from the local database.
 *
 * I
 */
  getDataFromDatabase()
  {

    this.http.get<Array<PropertyDexie>>(environment.apiURL  + 'Property/getAll').subscribe(async (remoteProperties) => {

      let localProperties = await this.properties.where({
        userID:this.user.Id
           }).toArray();

      let entitiesToAdd: Array<PropertyDexie> = [];

      remoteProperties.forEach(_remoteProperty => {

        let localProperty = localProperties.find(_localProperty => _localProperty.propertyID == _remoteProperty.propertyID);

        if(!localProperty)
        {
          entitiesToAdd.push(_remoteProperty);
        }
        else if(Date.parse(localProperty?.modifiedOn) < Date.parse(_remoteProperty.modifiedOn))
        {
          entitiesToAdd.push(_remoteProperty);
        }

      });

      await this.properties.bulkPut(entitiesToAdd);

      localProperties = await this.properties.where({
        userID:this.user.Id
           }).toArray();

      localProperties.forEach(localProperty => {

        this.getPropertyEnterpriseReport(localProperty.propertyID).subscribe(async report => {
          await this.enterpriseReports.put(report);
        });


        this.getPropertyEnterpriseReport(localProperty.propertyID).subscribe(async report => {
          await this.enterpriseReports.put(report);
        });

        this.getNpvPropertyReport(localProperty.propertyID).subscribe(async report => {
          await this.npvReports.put(report);
        });

        this.getPropertyTopexReport(localProperty.propertyID).subscribe(async report => {
          await this.topexReports.put(report);
        })


        this.getPropertyForestReport(localProperty.propertyID).subscribe(async report => {
          await this.forestDescriptionReports.put(report);
        })

        this.getPaddocks(localProperty.propertyID).subscribe(async remotePaddocks => {

          let localPaddocks = await this.paddocks.where({
            propertyID:localProperty.propertyID
               }).toArray();

          let entitiesToAdd: Array<PaddockDexie> = [];

          remotePaddocks.forEach(_remotePaddock => {

            let localPaddock = localPaddocks.find(_localPaddock => _localPaddock.propertyID == _remotePaddock.propertyID);

            if(!localPaddock)
            {
              entitiesToAdd.push(_remotePaddock);
            }
            else if(Date.parse(localPaddock?.modifiedOn) < Date.parse(_remotePaddock.modifiedOn))
            {
              entitiesToAdd.push(_remotePaddock);
            }

          });

          await this.paddocks.bulkPut(entitiesToAdd);

          localPaddocks = await this.paddocks.where({
            propertyID:localProperty.propertyID
               }).toArray();

            localPaddocks.forEach(localPaddock => {
              this.getPaddockTopexReport(localPaddock.paddockID).subscribe(report => this.topexReports.put(report));
              this.getPaddockEnterpriseReport(localPaddock.paddockID).subscribe(report => this.enterpriseReports.put(report))
              this.getNpvPaddockReport(localPaddock.paddockID).subscribe(async report => {
                await this.npvReports.put(report);
              });

              this.getPaddockForestReport(localPaddock.paddockID).subscribe(async report => {
                await this.forestDescriptionReports.put(report);
              })
            })
        });

        this.getFences(localProperty.propertyID).subscribe(async remoteFences => {


          let localFences = await this.fences.where({
            propertyID:localProperty.propertyID
               }).toArray();

          let entitiesToAdd: Array<FenceDexie> = [];

          remoteFences.forEach(_remoteFence => {

            let localFence = localFences.find(_localFence => _localFence.propertyID == _remoteFence.propertyID);

            if(!localFence)
            {
              entitiesToAdd.push(_remoteFence);
            }
            else if(Date.parse(localFence?.modifiedOn) < Date.parse(_remoteFence.modifiedOn))
            {
              entitiesToAdd.push(_remoteFence);
            }

          });

          await this.fences.bulkPut(entitiesToAdd);

        })
      })

    })

  }


   getPropertyEnterpriseReport(propertyID)
   {
    return this.http.get<EnterpriseReport>(environment.apiURL  + 'EnterpriseReport/property/get' + `?propertyID=${propertyID}`).pipe(
      catchError(this.handelError)
    );
   }

   getPropertyForestReport(propertyID)
   {
    return this.http.get<ForestDescriptionReport>(environment.apiURL  + 'ForestDescriptionReport/property/get' + `?propertyID=${propertyID}`).pipe(
      catchError(this.handelError)
    );
   }


   getPaddockForestReport(paddockID)
   {
    return this.http.get<ForestDescriptionReport>(environment.apiURL  + 'ForestDescriptionReport/paddock/get' + `?paddockID=${paddockID}`).pipe(
      catchError(this.handelError)
    );
   }


   getPaddockEnterpriseReport(paddockID)
   {
    return this.http.get<EnterpriseReport>(environment.apiURL  + 'EnterpriseReport/paddock/get' + `?paddockID=${paddockID}`).pipe(
      catchError(this.handelError)
    );
   }

   getNpvPropertyReport(propertyID)
   {
    return this.http.get<NpvReport>(environment.apiURL  + 'NpvReport/property/get' + `?propertyID=${propertyID}`).pipe(
      catchError(this.handelError)
    );
   }

   getNpvPaddockReport(paddockID)
   {
    return this.http.get<NpvReport>(environment.apiURL  + 'NpvReport/paddock/get' + `?paddockID=${paddockID}`).pipe(
      catchError(this.handelError)
    );
   }

   getPropertyTopexReport(propertyID)
   {
    return this.http.get<TopexReport>(environment.apiURL  + 'TopexReport/property/get' + `?propertyID=${propertyID}`).pipe(
      catchError(this.handelError)
    );
   }

   getPaddockTopexReport(paddockID)
   {
    return this.http.get<TopexReport>(environment.apiURL  + 'TopexReport/paddock/get' + `?paddockID=${paddockID}`).pipe(
    );
   }

   getPaddocks(propertyID)
   {
    return this.http.get<Array<PaddockDexie>>(environment.apiURL  + 'Paddock/getAll' + `?propertyID=${propertyID}`).pipe(
      catchError(this.handelError)
    );
   }

   getFences(propertyID)
   {
    return this.http.get<Array<FenceDexie>>(environment.apiURL  + 'Fence/getAll' + `?propertyID=${propertyID}`).pipe(
catchError(this.handelError)
    );
   }


   handelError(errorRes)
   {
    let errorMessage = 'An unknown error occurred!'

    if(!errorRes.error||!errorRes.error.result)
    {
      return throwError(() => { return new Error(errorMessage)});
    }

    switch (errorRes.error.result) {
      case 'NO_REPORT_FOUND':
        errorMessage = "There was no report found for the entity.";
        break;
      case 'EMAIL_EXISTS':
        errorMessage = "An Account Already Exists With That Email";
        break;

      case 'EMAIL_NOT_CONFIRMED':
        errorMessage = "This user has not yet been activated. Please check your email to find the activation email before logging in.";
        break;

        case 'ACCOUNT_DISABLED':
          errorMessage = "Your account has been disabled, please contact a PFT admin";
          break;

      default:
        break;
    }

    return throwError(() => { return new Error(errorMessage)});

   }

}
