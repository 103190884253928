/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { IDssReport } from 'src/app/interfaces/dssReport.Interface';
import { addFooter, addHeader, addParagraph, addSubHeader, checkSectionOffPage, jsPDFCustom } from 'src/app/interfaces/JsPDF.extended.interface';
import { ITopexReportData } from 'src/app/interfaces/topexReport.interface';
import { PaddockDexie } from 'src/app/models/paddock.model';
import { PropertyDexie } from 'src/app/models/property.model';
import { mapService } from 'src/app/services/map.service';
import { TopexMapOverlayComponent } from '../topex-report/topex-map-overlay/topex-map-overlay.component';
import { TopexReportComponent } from '../topex-report/topex-report.component';
import { DssMapOverlayComponent } from './dss-map-overlay/dss-map-overlay.component';
import { DssReportSections, getDssOverViewSection } from './dss-report-pdf-data';



@Component({
  selector: 'app-dss-report',
  templateUrl: './dss-report.component.html',
  styleUrls: ['./dss-report.component.css']
})
export class DssReportComponent implements OnInit {
  displayedColumns: string[] = ['rank', 'image', 'crop', 'wellSuited','suitable','moderatelySuitable','unsuitable','showOnMap'];


  constructor(public dialogRef: MatDialogRef<TopexReportComponent>,@Inject(MAT_DIALOG_DATA) public dssReports:
  Array<IDssReport>, private mapService:mapService, private dialog: MatDialog) { }

  ngOnInit(): void {
  }


  fixUrl(url: string): string {
    return url.replace(/https=\/\//g, 'https://');
  }

  async showCrop(crop, target)
  {

    await this.mapService.showCrop(crop.name,target);

    let enterpriseLayer = target instanceof PropertyDexie ? this.mapService.enterpriseSuitibilityGroupProperty : this.mapService.enterpriseSuitibilityGroupPaddock;

    if(target instanceof PaddockDexie)
    {
      this.mapService.loadSelectedPaddocks([target]);
    }

    enterpriseLayer.setVisible(true);

    const dialogRef = this.dialog.open(DssMapOverlayComponent,{hasBackdrop:true,data:{map: this.mapService.map, layers: [...this.mapService.propertyLayerGroup.getLayersArray(),enterpriseLayer]}, width:'40%'});

    dialogRef.afterClosed().subscribe((result) => {

     this.mapService.loadSelectedPaddocks([]);

     enterpriseLayer.setVisible(false);
    })
  }



  export(report: IDssReport)
  {
    let target = report.target;
    let data = report.enterpriseReport.data;

    const pdf = new jsPDF() as jsPDFCustom;
    const margin = 10;
    const headerHeight = 30;
    const pageWidth = pdf.internal.pageSize.width;
    const textWidth = pageWidth - 2 * margin;

    let title = 'Crop Decision Report';


    addHeader(title, pdf, margin, headerHeight);
    addFooter(pdf, margin);

    const paragraphNewPageCallback = () => {
            pdf.addPage();
            addHeader(title, pdf, margin, headerHeight);
            addFooter(pdf, margin)
            return headerHeight + margin;
          };



    const contentTopMargin = 50;
    const contentBottomMargin = pdf.internal.pageSize.height - 30;





    let y = headerHeight + margin;

    let overviewSection = getDssOverViewSection(report.target,report.totalAreaNotCovered);

    y += addSubHeader(overviewSection.title, pdf, margin, y);
    y += margin;

    overviewSection.paragraphs.forEach(paragraph => {
      y = addParagraph(pdf, paragraph, margin, y, textWidth, margin, paragraphNewPageCallback);
      y += 5; // Space between paragraphs
    });

    y+= margin





    let headerData = addSubHeader("Data", pdf, margin, y);

    let destructuredData = [];

    destructuredData = data.map((obj) => {
      return [this.getNumberWithOrdinal(obj.rank),
        obj.crop.label,
        obj.wellSuited.toFixed(1),
        obj.suitable.toFixed(1),
        obj.moderatelySuitable.toFixed(1),
        obj.unsuitable.toFixed(1),
      ]
    });


    pdf.autoTable( {
      head: [['Rank', 'Crop', 'Well Suited (ha)','Suitable (ha)', 'Moderately Suitable (ha)', 'Unsuitable (ha)']],
      body: destructuredData,
      startY:y + headerData + margin,
      didDrawPage: (data) => {
        addHeader(title, pdf, margin, headerHeight);
        addFooter(pdf, margin)
      },
      margin:{top:contentTopMargin}
    },)

    pdf.addPage();
    addHeader(title, pdf, margin, headerHeight);
    addFooter(pdf, margin)
    y = headerHeight + margin;


    DssReportSections.forEach(section => {

      let offPage = checkSectionOffPage(pdf, margin,section, y);

      if(offPage)
      {
        pdf.addPage();
        addHeader(title, pdf, margin, headerHeight);
        addFooter(pdf, margin)
        y = headerHeight + margin;
      }


      y += addSubHeader(section.title, pdf, margin, y);
      y += margin;

    section.paragraphs.forEach(paragraph => {
      y = addParagraph(pdf, paragraph, margin, y, textWidth, margin, paragraphNewPageCallback);
      y += 5; // Space between paragraphs
    });
      y+= margin
  });



    pdf.save(`dss-report-${target.name.replace(" ", '-')}-${new Date().toUTCString()}.pdf`);
  }

  private getNumberWithOrdinal(n) {
    var s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

}
