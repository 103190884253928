import { Injectable } from "@angular/core";
import GeoJSON from 'ol/format/GeoJSON';
import { Feature } from "ol";
import { environment } from "src/environments/environment";
import { CalculateArea } from "../_helpers/transformations";

@Injectable({
  providedIn: 'root'
})
export class SpatialService {


  private API_ENDPOINT: string;
  private format = new GeoJSON();

  constructor() {
    this.API_ENDPOINT =    this.API_ENDPOINT = `${environment.apiURL}Spatial/`;
   }

   async getSuitabilityLayer(feature: Feature<any>) : Promise<Array<Feature<any>>>
   {
    let geom = this.format.writeGeometry(feature.getGeometry());

    let response = await fetch(this.API_ENDPOINT + 'getSuitabilityLayer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ feature: geom }),
    });


    let features = await response.json();

    let featureArray = [];


    features.forEach((element, index) => {
      let feature = this.format.readFeature(element);
      featureArray.push(feature)
    });

    return featureArray;
   }

   async getTopexLayer(feature: Feature<any>) : Promise<Array<Feature<any>>>
   {
    let geom = this.format.writeGeometry(feature.getGeometry());

    let response = await fetch(this.API_ENDPOINT + 'getTopexLayer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ feature: geom }),
    });


    let features = await response.json();

    let featureArray = [];


    features.forEach((element, index) => {
      let feature = this.format.readFeature(element);
      featureArray.push(feature)
    });

    return featureArray;
   }


   async getForestClassesLayer(feature: Feature<any>) : Promise<Array<Feature<any>>>
   {
    let geom = this.format.writeGeometry(feature.getGeometry());

    let response = await fetch(this.API_ENDPOINT + 'getForestClassesLayer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ feature: geom }),
    });


    let features = await response.json();

    let featureArray = [];


    features.forEach((element, index) => {
      let feature = this.format.readFeature(element);
      featureArray.push(feature)
    });

    return featureArray;
   }

  }
