/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { addFooter, addHeader, addParagraph, addSubHeader, jsPDFCustom } from 'src/app/interfaces/JsPDF.extended.interface';
import { ITopexReportData } from 'src/app/interfaces/topexReport.interface';
import { PaddockDexie } from 'src/app/models/paddock.model';
import { PropertyDexie } from 'src/app/models/property.model';
import { TopexReport } from 'src/app/models/topexReport.model';
import { mapService } from 'src/app/services/map.service';
import { TopexMapOverlayComponent } from './topex-map-overlay/topex-map-overlay.component';
import { getTopexOverViewSection, TopexPdfSections } from './topex-report-pdf-data';

@Component({
  selector: 'app-topex-report',
  templateUrl: './topex-report.component.html',
  styleUrls: ['./topex-report.component.css']
})
export class TopexReportComponent implements OnInit {

  displayedColumns: string[] = ['topexScore', 'exposureDescription', 'area', 'percentage'];
  dataSource;
  @Output() loadPaddock = new EventEmitter<PaddockDexie>();




  constructor(public dialogRef: MatDialogRef<TopexReportComponent>,@Inject(MAT_DIALOG_DATA) public topexReports:
  Array<ITopexReportData>, private mapService:mapService, private dialog: MatDialog) { }

  ngOnInit(): void {

  }

  viewOnMap(target: PropertyDexie | PaddockDexie)
  {

    let topexLayer = target instanceof PropertyDexie ? this.mapService.topexLayerProperty : this.mapService.topexLayerPaddock;

    if(target instanceof PaddockDexie)
    {
      this.mapService.loadSelectedPaddocks([target]);
    }

    topexLayer.setVisible(true);

    const dialogRef = this.dialog.open(TopexMapOverlayComponent,{hasBackdrop:true,data:{map: this.mapService.map, layers: [...this.mapService.propertyLayerGroup.getLayersArray(),topexLayer]}, width:'40%'});

    dialogRef.afterClosed().subscribe((result) => {

     this.mapService.loadSelectedPaddocks([]);

     topexLayer.setVisible(false);
    })
  }

  async export(report: ITopexReportData)
  {
    const pdf = new jsPDF() as jsPDFCustom;
    const margin = 10;
    const headerHeight = 30;
    const pageWidth = pdf.internal.pageSize.width;
    const textWidth = pageWidth - 2 * margin;


    addHeader('Topex Report', pdf, margin, headerHeight);
    addFooter(pdf, margin);

    const paragraphNewPageCallback = () => {
            pdf.addPage();
            addHeader('Topex Report', pdf, margin, headerHeight);
            addFooter(pdf, margin)
            return headerHeight + margin;
          };


    let y = headerHeight + margin;

    let overviewSection = getTopexOverViewSection(report.target);

    y += addSubHeader(overviewSection.title, pdf, margin, y);
    y += margin;

    overviewSection.paragraphs.forEach(paragraph => {
      y = addParagraph(pdf, paragraph, margin, y, textWidth, margin, paragraphNewPageCallback);
      y += 5; // Space between paragraphs
    });

    y += margin;

    let headerData = addSubHeader("Data", pdf, margin, y);

    let destructuredData = [];

    destructuredData = report.data.map((obj: any) => {
      return [obj.topexScore ,
        obj.exposureDescription,
        obj.area.toFixed(1),
        obj.percentage.toFixed(1)
      ]
    });

    pdf.autoTable({
      head: [['Topex Score', 'Exposure Description', 'Area (ha)', '% of Reporting Area']],
      body: destructuredData,
      startY:y + headerData + margin,
    },)

    pdf.addPage();

    addFooter(pdf, margin);
    addHeader('Topex Report', pdf, margin,headerHeight);


    y = headerHeight + margin;


     TopexPdfSections.forEach(section => {
        y += addSubHeader(section.title, pdf, margin, y);
        y += margin;

      section.paragraphs.forEach(paragraph => {
        y = addParagraph(pdf, paragraph, margin, y, textWidth, margin, paragraphNewPageCallback);
        y += 5; // Space between paragraphs
      });
      y += margin;
    });

    pdf.save(`topex-report-${report.target.name.replace(" ", '-')}-${new Date().toUTCString()}.pdf`);
  }


}
