import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroupDirective, FormGroup, Form } from '@angular/forms';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-plantation-cost',
  templateUrl: './plantation-cost.component.html',
  styleUrls: ['./plantation-cost.component.css']
})
export class PlantationCostComponent implements OnInit {

  // @Input() distanceFromBuyer;
  @Input() formGroupName: string;
  @Input() parentForm: FormGroup
  @Input() typeOfForestOperationValue: string;

  @Input() plannedFenceLength: string;
  @Input() existingFenceLength: string;

  form: FormGroup
  fencingDropdownUpdated: boolean = false;

  tempFencing: number;
  tempFencingMaintenance: number;

  defaultValuePloughing: number = 0;
  defaultValuePlantationAndPlantingVosts: number = 0;
  defaultValueWeedingHerbicideCosts: number = 0;


  defaultPlannedFenceLengthCost: number = 0;
  defaultExistingFenceLengthcost: number = 0;


  constructor(private rootForm: FormGroupDirective,
    private modalService: ModalService) { }

  ngOnInit(): void {
    this.form = this.rootForm.control.get(this.formGroupName) as FormGroup;

    const ploughingInput = this.form.controls["ploughing"];
    const plantationInput = this.form.controls["plantationAndPlantingVosts"];
    const weedingInput = this.form.controls["weedingHerbicideCosts"];

    this.defaultValuePloughing = ploughingInput.getRawValue()
    this.defaultValuePlantationAndPlantingVosts = plantationInput.getRawValue()
    this.defaultValueWeedingHerbicideCosts = weedingInput.getRawValue()

    this.tempFencing = this.form.get("fencing").getRawValue();
    this.tempFencingMaintenance = this.form.get("fencingMaintenance").getRawValue();

    this.defaultPlannedFenceLengthCost = this.rootForm.control.controls["propertySpecifications"]["controls"]["plannedFenceLength"].getRawValue();
    this.defaultExistingFenceLengthcost = this.rootForm.control.controls["propertySpecifications"]["controls"]["existingFenceLength"].getRawValue();
  }

  ngOnChanges(ch: SimpleChanges): void {

    if (ch) {
      // console.log("ch", ch)

      if (ch["plannedFenceLength"] && ch["plannedFenceLength"].currentValue != 0) {
        this.defaultPlannedFenceLengthCost = ch["plannedFenceLength"].currentValue
      }

      if (ch["existingFenceLength"] && ch["existingFenceLength"].currentValue != 0) {
        this.defaultExistingFenceLengthcost = ch["existingFenceLength"].currentValue
      }

      if (ch["typeOfForestOperationValue"]?.currentValue) {
        const ploughingInput = this.form.controls["ploughing"];
        const plantationInput = this.form.controls["plantationAndPlantingVosts"];
        const weedingInput = this.form.controls["weedingHerbicideCosts"];

        const harvestingHaulageCostsForThinning = this.form.controls["harvestingAndHaulageCostsForThinning"];
        const harvestingHaulageCostsForClearfell = this.form.controls["harvestingAndHaulageCostsForClearfell"];

        let tempInputArray = [ploughingInput, plantationInput, weedingInput];

        /* Showing and hiding the harvesting values based on user input*/
        const foresttOperationValue = ch["typeOfForestOperationValue"].currentValue

        if (foresttOperationValue === "Native forest - Clearfelling" || foresttOperationValue === "Plantation - Clearfelling") {
          // harvestingHaulageCostsForThinning.setValue({ value: 0, disabled: true });
          harvestingHaulageCostsForThinning.disable();

          harvestingHaulageCostsForClearfell.enable()
        }

        if (foresttOperationValue === "Native forest - Thinning" || foresttOperationValue === "Plantation - Thinning") {
          // harvestingHaulageCostsForClearfell.setValue({ value: 0 });
          harvestingHaulageCostsForClearfell.disable();

          harvestingHaulageCostsForThinning.enable();
        }

        if (foresttOperationValue && foresttOperationValue.toLowerCase().includes("native forest")) {

          for (const element of tempInputArray) {
            // element.setValue({ value: 0 });
            element.setValue(0);
            element.disable();
          }

        } else {

          // setting the default values
          ploughingInput.setValue(this.defaultValuePloughing)
          plantationInput.setValue(this.defaultValuePlantationAndPlantingVosts)
          weedingInput.setValue(this.defaultValueWeedingHerbicideCosts)

          for (const element of tempInputArray) {
            element.enable();
          }

        }

      }

    }

  }

  calculateTransportationCost(e: Event) {
    const ev = e.target as HTMLInputElement;
    let eventValue: any = ev.value;
    const newTransportationCost: any = 2 * (eventValue ** (1 / 2));

    this.form.get("transportCode").setValue(newTransportationCost.toFixed(2))

  }

  fencingRequiredHandler(e) {

    const fencingMaintenance = this.form.get("fencingMaintenance")
    const fencing = this.form.get("fencing")
    const dropdownValue = e.target.value;

    const plannedFenceLength = this.rootForm.control.controls["propertySpecifications"]["controls"]["plannedFenceLength"];
    const existingFenceLength = this.rootForm.control.controls["propertySpecifications"]["controls"]["existingFenceLength"];


    if (!this.fencingDropdownUpdated) {

      this.fencingDropdownUpdated = true;

      if (dropdownValue.toLowerCase() === "no") {
        this.modalHandler(false)

        plannedFenceLength.disable()
        existingFenceLength.disable()

        plannedFenceLength.setValue(0)
        existingFenceLength.setValue(0)

      } else {
        this.modalHandler(true)

      }

    } else {

      if (dropdownValue.toLowerCase() === "no") {

        this.modalHandler(false)
        this.modalService.open('confirmReset')

        plannedFenceLength.disable()
        existingFenceLength.disable()

        plannedFenceLength.setValue(0)
        existingFenceLength.setValue(0)


      } else {

        this.modalHandler(true)
        fencingMaintenance.setValue(this.tempFencingMaintenance)
        fencing.setValue(this.tempFencing)

        plannedFenceLength.setValue(this.defaultPlannedFenceLengthCost)
        existingFenceLength.setValue(this.defaultExistingFenceLengthcost)

        plannedFenceLength.enable()
        existingFenceLength.enable()

      }

    }

  }

  modalHandler(resRej: boolean) {

    const fencingRequired = this.form.get("isFencingRequired")
    const fencingMaintenance = this.form.get("fencingMaintenance")
    const fencing = this.form.get("fencing")

    if (resRej) {

      fencingMaintenance.enable();
      fencing.enable();

      // temp solution
      fencingMaintenance.setValue(this.tempFencingMaintenance)
      fencing.setValue(this.tempFencing)

    } else {

      fencingMaintenance.disable();
      fencing.disable();

      fencingMaintenance.setValue(0)
      fencing.setValue(0)
      fencingRequired.setValue("No")

    }
  }

  modalRejectionHandler(confirmation: boolean) {

    const fencingRequired = this.form.get("isFencingRequired")
    const fencingMaintenance = this.form.get("fencingMaintenance")
    const fencing = this.form.get("fencing")

    if (confirmation) {
      fencingMaintenance.disable();
      fencing.disable();

      fencingMaintenance.setValue("")
      fencing.setValue("")
    } else {
      fencingRequired.setValue("Yes")
      fencingMaintenance.setValue(this.tempFencingMaintenance)
      fencing.setValue(this.tempFencing)
    }
  }

  tempFencingStorageHandler(e: Event) {
    const element = e.target as HTMLElement;
    const formControlName = element.getAttribute("formcontrolname");
    this.tempFencing = this.form.get(formControlName).value;
  }

  tempFencingMaintenanceStorageHandler(e: Event) {
    const element = e.target as HTMLElement;
    const formControlName = element.getAttribute("formcontrolname");
    this.tempFencingMaintenance = this.form.get(formControlName).value;
  }

}
