import { Injectable } from '@angular/core';
import { feature } from '@turf/turf';
import { AuthService } from '../auth.service';
import { Connection, Repository } from 'typeorm';
import { DexieDatabaseService } from '../dexieIndexDB.service';
import { ClippedLayer_Paddock, ClippedPropertyLayer } from 'src/app/models/clippedLayers.model';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { __awaiter } from 'tslib';

@Injectable({
  providedIn: 'root',
})
export class ClippedLayerService {


  constructor(
    private dexieDb: DexieDatabaseService,
    private http:HttpClient
  ) {


    };


  async addClippedLayers_Property(clippedDataSet : Array<ClippedPropertyLayer> | ClippedPropertyLayer)
  {
    if(clippedDataSet instanceof Array)
    {
      await this.dexieDb.propertyClippedLayers.bulkAdd(clippedDataSet)
      //this.AddToDatabaseTest(clippedDataSet).subscribe();
    }
    else
    {
      await this.dexieDb.propertyClippedLayers.add(clippedDataSet)
    }

  }


async addClippedLayers_Paddock(clippedDataSet : Array<ClippedLayer_Paddock> | ClippedLayer_Paddock)
  {
    if(clippedDataSet instanceof Array)
    {
      await this.dexieDb.paddockClippedLayers.bulkAdd(clippedDataSet)
    }
    else
    {
      await this.dexieDb.paddockClippedLayers.add(clippedDataSet)
    }

  }

   getClippedLayers_Property(propertyID) {

    return from(this.dexieDb.propertyClippedLayers
    .where({
      propertyID: propertyID,
    })
    .toArray());
  }


 async  getClippedLayers_Property_Async(propertyID) {

    return await this.dexieDb.propertyClippedLayers
    .where({
      propertyID: propertyID,
    })
    .toArray()
  }

  async getClippedLayers_Paddock(paddockId) {

    return await this.dexieDb.paddockClippedLayers
    .where({
      paddockID: paddockId,
    })
    .toArray();
  }

  async deleteClippedLayers_Paddock(paddockId)
  {

    let clippedLayerIds = await this.dexieDb.paddockClippedLayers
    .where
    ({
      paddockID: paddockId,
    })
    .primaryKeys();

    await this.dexieDb.paddockClippedLayers.bulkDelete(clippedLayerIds);

  }


  async deleteClippedLayers_Property(propertyId)
  {

    let clippedLayerIds = await this.dexieDb.propertyClippedLayers
    .where
    ({
      propertyID: propertyId,
    })
    .primaryKeys();

    await this.dexieDb.propertyClippedLayers.bulkDelete(clippedLayerIds);

  }

  AddToDatabaseTest(models: Array<ClippedPropertyLayer>)
  {
   return this.http.post("https://localhost:7292/api/" + 'ClippedLayers/property/bulkadd', models).pipe(

   );
  }



}
