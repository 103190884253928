<div class="full-screen-container">
  <div>
    <section class="login">
      <h2 class="center">Farm and Forest Mapper</h2>
      <form [formGroup]="loginForm"  (ngSubmit)="onSubmit()" class="login-form center">
        <div class="login-form_header"><label class="center">Not a member?<a href="" routerLink="/register"> Register Now</a></label></div>
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput placeholder="example@example.com.au">
          <mat-error *ngIf="loginForm.get('email').invalid && (loginForm.get('email').dirty || loginForm.get('email').touched)">
            <div *ngIf="loginForm.get('email').errors?.['required']">
              Email is required.
            </div>
            <div *ngIf="loginForm.get('email').errors?.['email']">
              Please enter a valid email address.
            </div>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" type="password">
          <mat-error *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched)">
            <div *ngIf="loginForm.get('password').errors?.['required']">
              Password is required.
            </div>
          </mat-error>
        </mat-form-field>

      <button type="submit" [disabled]="!loginForm.valid" class="button button--brand">Login</button>

          <a class="center" [routerLink]="['/account/forgotPassword']">Forgot your password</a>
      </form>


      <div class="hero">
        <img  src="assets/images/tree_mapper_hero.jpg" width="100%"
        height="auto" alt="">
      </div>
    </section>

  </div>
</div>
