export class User {
  constructor(public Id:number, public email:string, public userFullName : string, private _token: string, private _refreshToken: string, public showWelcome: boolean = true, private _isAdmin = false){

  }

  get token()
  {
    return this._token;
  }

  get refreshToken()
  {
    return this._refreshToken;
  }

  setIsAdmin(isAdmin)
  {
    this._isAdmin = isAdmin;
  }

  get isAdmin()
  {
    return this._isAdmin;
  }
}
