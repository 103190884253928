import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalculatorNavigationService {

  validStages: {} = {
    1: {
      title: "Property / Paddock Description",
      status: false
    },
  
    2: {
      title: "Benefits",
      status: false
    },
    3: {
      title: "Costs",
      status: false
    },
    4: {
      title: "Threatened Species",
      status: false
    },
    5: {
      title: "Forestry Policy Prescriptions",
      status: false
    },
  }

  constructor() { }

  setStageStatus(stage: string | number, status?: boolean) {
    const stageStatus = status ? status : false;

    if (status) {
      this.validStages[stage].status = status
    } else {
      this.validStages[stage].status = stageStatus
    }
  }

  getStageStatus(stage: string | number) {
    return this.validStages[stage].status
  }

  getAllStageStatus() {
    return this.validStages
  }

  allStagesValid() {
    let valid = true;

    for (let property in this.validStages) {
      if (this.validStages[property].status === false) {
        valid = false;
        break;
      }
    }

    return valid;

  }

}
