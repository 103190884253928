import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription,switchMap } from 'rxjs';
import { Repository } from 'typeorm';
import { EventData } from '../_helpers/event.class';
import { TreeMapperCustomEvents } from '../_helpers/enums';
import { AuthService } from './auth.service';
import { EventBusService } from './EventBus.service';
import { PropertyService } from './property.service';
import { PropertyDexie } from '../models/property.model';
import { FenceDexie } from '../models/fence.model';
import { DexieDatabaseService } from './dexieIndexDB.service';
import { liveQuery } from 'dexie';
import { HttpClient } from '@angular/common/http';
import { DatabaseSyncAction, EntityType, Action } from '../models/databaseSync.model';
import { environment } from 'src/environments/environment';
import { OfflineService } from './offline.service';
import { ThisReceiver } from '@angular/compiler';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class FenceService {

  private activeProperty: PropertyDexie;

  private userSub: Subscription;

  private databaseSub: Subscription;

  private propertySub: Subscription;

  private isOnline: boolean = true;

  private api = environment.apiURL + 'Fence/';

  fences = this.propertyService.activeProperty.pipe(
    switchMap((value) => {
      return value == null ? ([]) : liveQuery(() => this.dexieService.fences.where({propertyID: value.propertyID}).toArray())
    })
  )


  constructor(private auth: AuthService, private dexieService:DexieDatabaseService, private eventBusService: EventBusService, private propertyService: PropertyService, private http: HttpClient, private connectionService: OfflineService, private toastService: ToastService) {

    this.userSub = auth.user.subscribe(user => {
    });

    connectionService.isOnline.subscribe(status => this.isOnline = status);

   }

   async save(fence: FenceDexie)
   {
    await this.dexieService.fences.add(fence);

    if(this.isOnline)
    {
      this.http.post(this.api + 'add', fence).subscribe(resp =>  this.toastService.showSuccess('Fence created and synced'));
    }
    else
    {
      this.dexieService.databaseSyncActions.add(new DatabaseSyncAction(EntityType.Fence, fence.fenceID, Action.Insert));
      this.toastService.showSuccess('Fence added locally')
    }

   }

   async remove(fence: FenceDexie)
   {
     await this.dexieService.fences.delete(fence.fenceID);

     if(this.isOnline)
     {
       this.http.post(this.api + `delete?fenceID=${fence.fenceID}`, '').subscribe(resp =>  this.toastService.showSuccess('Fence deleted and synced'));
     }
     else
     {
       this.dexieService.databaseSyncActions.add(new DatabaseSyncAction(EntityType.Fence, fence.fenceID, Action.Remove));
       this.toastService.showSuccess('Fence deleted locally')
     }

   }

   async update(fence: FenceDexie)
   {

      await this.dexieService.fences.update(fence.fenceID, fence);

      if(this.isOnline)
      {
        this.http.post(this.api + 'update', fence).subscribe(resp =>  this.toastService.showSuccess('Fence updated and synced'));
      }
      else
      {
        this.dexieService.databaseSyncActions.add(new DatabaseSyncAction(EntityType.Fence, fence.fenceID, Action.Update));
        this.toastService.showSuccess('Fence updated locally')
      }
   }

}
