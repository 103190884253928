<div class="uk-section uk-padding-small header-background">
    <div class=" uk-margin-remove uk-flex uk-flex-between uk-flex-middle">
        <a routerLink="">
            <img src="../../../assets/logo.svg" alt="Logo" width="200" />
        </a>
        <div class="column">
            <!-- <button class="uk-button uk-button-danger uk-margin-right">Go Back</button> -->
            <button class="uk-button uk-button-danger">
                <a routerLink="/cost-breakdown">
                    Cost breakdown
                </a>
            </button>
        </div>
    </div>
</div>