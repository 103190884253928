import { Injectable } from "@angular/core";
import UIkit from "uikit";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  modalMap: Map<string,HTMLElement> = new Map();

  constructor() {

  }

  register(id: string, element: HTMLElement)
  {
    if(this.modalMap.has(id))
    {
      return;
    }

    this.modalMap.set(id,element);

  }

  remove(id: string)
  {
    if(!this.modalMap.has(id))
    {
      return;
    }
    this.close(id);
    this.modalMap.delete(id);
  }

  open(id: string)
  {
    let modal = this.modalMap.get(id);

    if(!modal)
    {
      return;
    }
    UIkit.modal(modal).show();
  }
  close(id: string)
  {
    let modal = this.modalMap.get(id);

    if(!modal)
    {
      return;
    }
    UIkit.modal(modal).hide();
  }

}
