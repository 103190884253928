import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, OnChanges, SimpleChanges } from '@angular/core';
import { NpvCalculatorDataStorageService } from 'src/app/services/npv-calculator-data-storage.service';

@Component({
  selector: 'app-fencing-maintenance',
  templateUrl: './fencing-maintenance.component.html',
  styleUrls: ['./fencing-maintenance.component.css']
})
export class FencingMaintenanceComponent implements OnInit, OnChanges {

  @Input() plannedFencing: number = 0;
  @Input() existingFencing: number = 0;
  @Input() fencing: number;
  @Input() monetaryValue: string;
  @Input() discountRate: number;
  @Input() harvestLifespan: number | string;
  updatedMonetaryValue: number;

  @Input() totalFenceConstructionCost: number;
  @Input() totalFenceMaintenanceCost: number;

  @Output() calculationsValueEmitter: EventEmitter<any> = new EventEmitter();


  constructor(private npvCalcService: NpvCalculatorDataStorageService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    // Passing values to the parent component from 
    if (changes) {
      const calculationData = {
        "Fencing Maintenance Cost": {
          "Planned fenching length (m)": this.plannedFencing,
          "Existing fenching length (m)": this.existingFencing,
          "Fencing maintenance cost ($/m)": this.fencing,
          "Monetary Value": this.calculateMonetaryValue(),
          "Discount Rate": this.discountRate,
          "Harvest Lifespan": this.harvestLifespan,
          "PV(C)": this.calculatePVC().toFixed(2),
        }
      }
      this.calculationsValueEmitter.emit(calculationData)
    }

  }

  calculateMonetaryValue() {
    if (this.monetaryValue === "Yes") {
      this.updatedMonetaryValue = (this.plannedFencing + this.existingFencing) * this.fencing;
      return this.updatedMonetaryValue
    } else {
      this.updatedMonetaryValue = 0;
      return 0
    }
  }

  calculatePVC() {
    if (this.harvestLifespan === "Indefinite") {

      const cPVC = this.updatedMonetaryValue / this.discountRate
      this.npvCalcService.updateCosts({ key: 'fencing-maintenance', value: cPVC })
      return cPVC;

    } else {

      const cPVC = this.updatedMonetaryValue * ((1 - (1 + (this.discountRate / 100)) ** -this.harvestLifespan) / (this.discountRate / 100))
      this.npvCalcService.updateCosts({ key: 'fencing-maintenance', value: cPVC })
      return cPVC;

    }
  }


}
