import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';


@Directive({
  selector: '[appRole]'
})
export class RoleDirective implements OnInit {
  @Input() appRole: string[];

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private authService: AuthService
  ) {}


  ngOnInit(): void {

    if(!this.appRole || this.appRole.length === 0)
      return;

    const userRole = this.authService.hasRoles(this.appRole);

    if(!userRole) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');
    }
  }
}
