 <div class="uk-column uk-hidden uk-padding-small@m uk-padding-remove@m" [formGroup]="form">
    <!--<div class="uk-column-1-2@m uk-flex-row medium-flex"> -->
        <!-- <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1">
                Will you seek compensation?
                <select class="uk-select" formControlName="willYouSeekCompensation">
                    <option></option>
                    <option>Yes</option>
                    <option>No</option>
                </select>
            </label>
        </div> -->

        <!-- <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1">
                Legal Fees to Access Compensation (One-Off)
                <input class="uk-input" formControlName="legalFeesToAccessCompensation" type="number" placeholder="">
            </label>
        </div> -->

        <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1">
                Social Contribution %
                <input class="uk-input" formControlName="socialContribution" type="number" placeholder="">
            </label>
        </div>
    <!-- </div>-->
</div> 