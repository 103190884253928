<h2 mat-dialog-title>Send Feedback</h2>
<mat-dialog-content class="mat-typography">

  <form
    [formGroup]="feedbackForm"
    (ngSubmit)="onSubmit()"
    class="grid"
  >
  <section class="grid grid--row grid--gap">


    <mat-label>Feedback Type</mat-label>
<mat-radio-group class="grid grid--auto-column gap-xs" formControlName="type">
    <mat-radio-button value="comments">Comments</mat-radio-button>
    <mat-radio-button value="suggestions">Suggestions</mat-radio-button>
    <mat-radio-button value="questions">Questions</mat-radio-button>
    <mat-error *ngIf="feedbackForm.get('firstName').errors?.['type']">This field is required!</mat-error>
  </mat-radio-group>


<mat-form-field appearance="fill">
  <mat-label>Feedback</mat-label>
  <textarea  formControlName="feedback" matInput></textarea>
</mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>First Name</mat-label>
      <input autocomplete="given-name" formControlName="firstName" matInput>
      <mat-error *ngIf="feedbackForm.get('firstName').errors?.['required']">This field is required!</mat-error>
    </mat-form-field>


    <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input autocomplete="given-name" formControlName="lastName" matInput>
        <mat-error *ngIf="feedbackForm.get('lastName').errors?.['required']">This field is required!</mat-error>
      </mat-form-field>


      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input autocomplete="given-name" formControlName="email" matInput>
        <mat-error *ngIf="feedbackForm.get('email').errors?.['required']">This field is required!</mat-error>
      </mat-form-field>


  </section>

  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer class="grid grid--gap grid--auto-column">
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      type="button"
      class="button button--blue"
[disabled]="!feedbackForm.valid"
      (click)="onSubmit()"
      type="submit"
    >
      Submit
    </button>
  </footer>
</mat-dialog-actions>
