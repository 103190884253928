<!--  eslint-disable -->
<div #mapElementRef id="map" class="map"></div>

<!-- <div id="ol-toolbar" class="esk-toolbar"></div> -->
<div id="ol-geocode" class="esk-geocode-search"></div>

<div id="ol-alert" class="map-status-message">
  <span [(textContent)]="alertMessage"></span>
</div>

<div
   class="connectivity-icon"
  *ngIf="connectionService.isOnline | async as connectionStatus"
>
  <span
    [ngStyle]="{ color: connectionStatus ? 'green' : 'red' }"
    class="fa fa-wifi"
  ></span>
</div>

<app-active-property
  @slideFadeInOut
  *ngIf="(mapService.createPropertyToolActive | async) === false"
  (downloadLayers)="mapService.downloadClippedLayers(paddocks)"
></app-active-property>

<app-base-float [layerGroup]="mapService.basemapGroup"></app-base-float>

<div
  id="esk-toolbar"
  class="esk-toolbar"
  popperHelp
  helpOrder="10"
  helpStage="1"
  helpContent="The toolbar contains spatial tools for improved map interaction. Use these tools to zoom, measure, draw, and analyze map data. Click on an icon to begin and follow any additional instructions."
  helpPlacement="right"
  helpTitle="Help - Welcome"
  [helpPostShowFunction]="openSidebarPane"
  helpPostShowFunctionParams="createProperty"
>
  <app-zoom
    [map]="mapService.map"
    popperHelp
    helpOrder="1"
    helpStage="2"
    helpContent="This tool is zoom in and out of the map"
    helpPlacement="right"
    helpTitle="Help - Map Tools"
  ></app-zoom>
  <app-gps
    [map]="mapService.map"
    popperHelp
    helpOrder="2"
    helpStage="2"
    helpContent="This tool show your gps location on the map"
    helpPlacement="right"
    helpTitle="Help - Map Tools"
  ></app-gps>
  <app-measure
    [map]="mapService.map"
    popperHelp
    helpOrder="3"
    helpStage="2"
    helpContent="This tool is used to measure areas and lengths on the map."
    helpPlacement="right"
    helpTitle="Help - Map Tools"
  ></app-measure>
  <app-graticle
    [map]="mapService.map"
    popperHelp
    helpOrder="4"
    helpStage="2"
    helpContent="This tool is used to show a graticule on the map."
    helpPlacement="right"
    helpTitle="Help - Map Tools"
  ></app-graticle>
  <app-select
    [map]="mapService.map"
    [layers]="getSelectLayers()"
    popperHelp
    helpOrder="5"
    helpStage="2"
    helpContent="This tool is used to select features on the map"
    helpPlacement="right"
    helpTitle="Help - Map Tools"
    (FeatureSelected)="onFeatureSelected($event)"
  ></app-select>
  <app-digitize-paddock
    [map]="mapService.map"
    [snapLayers]="[mapService.propertyLayer, mapService.paddockLayer]"
    (paddockDigitized)="createPaddock($event)"
    (active)="paddockToolClicked($event)"
    popperHelp
    helpOrder="6"
    helpStage="2"
    helpContent="This tool is used to digitize a paddock on your property."
    helpPlacement="right"
    helpTitle="Help - Map Tools"
  ></app-digitize-paddock>
  <app-digitize-fence
    [map]="mapService.map"
    [snapLayers]="[mapService.propertyLayer, mapService.paddockLayer]"
    (fenceCreated)="fenceCreated($event)"
    (active)="fenceToolClicked($event)"
    popperHelp
    helpOrder="7"
    helpStage="2"
    helpContent="This tool is used to digitize a fence on your property."
    helpPlacement="right"
    helpTitle="Help - Map Tools"
  ></app-digitize-fence>
</div>

<div
  id="processingWindow"
  [ngClass]="{ processing: processing }"
  class="loading style-2"
>
  <p>{{ processingText }}...<br />{{ processingLayerText }}...</p>
  <div class="loading-wheel"></div>
</div>

<div #addAttributeOverlayHere></div>

<div
  #sidebarElementRef
  id="sidebar_div"
  class="sidebar collapsed sidebar-right"
>
  <!-- Nav tabs -->
  <div class="sidebar-tabs">
    <ul id="sidebar_tabs_main" role="tablist">
      <li>
        <a
          href="#home"
          popperHelp
          helpOrder="1"
          helpStage="1"
          helpContent="This tool is used to manage the layers shown on the map."
          helpPlacement="right"
          helpTitle="Help - Welcome"
          [helpPreShowFunction]="openSidebarPane"
          helpPreShowFunctionParams="home"
          appTooltip="Manage Layers"
          tooltipPosition="left"
          role="tab"
          class="sidebar_tooltip"
        >
          <i class="fa fa-layer-group"></i
        ></a>
      </li>

      <li>
        <a
          href="#createProperty"
          popperHelp
          helpOrder="2"
          helpStage="1"
          helpContent="This tool is used to create a property in the system."
          helpPlacement="right"
          helpTitle="Help - Welcome"
          [helpPreShowFunction]="openSidebarPane"
          helpPreShowFunctionParams="createProperty"
          appTooltip="Create Property"
          tooltipPosition="left"
          role="tab"
          class="sidebar_tooltip"
        >
          <i class="fa fa-plus"></i
        ></a>
      </li>

      <li>
        <a
          href="#editProperty"
          popperHelp
          helpOrder="3"
          helpStage="1"
          helpContent="This tool is used to manage properties"
          helpPlacement="right"
          helpTitle="Help - Welcome"
          [helpPreShowFunction]="openSidebarPane"
          helpPreShowFunctionParams="editProperty"
          appTooltip="Manage Properties"
          tooltipPosition="left"
          role="tab"
          class="sidebar_tooltip"
        >
          <i class="fa fa-pen"></i
        ></a>
      </li>

      <li>
        <a
          href="#paddocks"
          [class.disabled]="activeProperty == null"
          type="button"
          role="tab"
          class="sidebar_tooltip"
          popperHelp
          helpOrder="4"
          helpStage="1"
          helpContent="This tool is used to create and manage paddocks, you will need to have an active property to access this section."
          helpPlacement="right"
          helpTitle="Help - Welcome"
          [helpPreShowFunction]="openSidebarPane"
          helpPreShowFunctionParams="paddocks"
          appTooltip="Paddocks"
          tooltipPosition="left"
        >
          <i class="fa fa-vector-square"></i
        ></a>
      </li>

      <li>
        <a
          href="#fences"
          [class.disabled]="activeProperty == null"
          type="button"
          role="tab"
          class="sidebar_tooltip"
          popperHelp
          helpOrder="5"
          helpStage="1"
          helpContent="This tool is used to create and manage fences, you will need to have an active property to access this section."
          helpPlacement="right"
          helpTitle="Help - Welcome"
          [helpPreShowFunction]="openSidebarPane"
          helpPreShowFunctionParams="fences"
          appTooltip="Manage Fences"
          tooltipPosition="left"
        >
          <i class="fa fa-wave-square"></i
        ></a>
      </li>

      <li>
        <a
          href="#reporting"
          [class.disabled]="activeProperty == null"
          type="button"
          role="tab"
          class="sidebar_tooltip"
          popperHelp
          helpOrder="6"
          helpStage="1"
          helpContent="This is the reporting section, use this to send a property/paddock to another tool or show a generated report."
          helpPlacement="right"
          helpTitle="Help - Welcome"
          [helpPreShowFunction]="openSidebarPane"
          helpPreShowFunctionParams="reporting"
          appTooltip="Reporting"
          tooltipPosition="left"
        >
          <i class="fa fa-chart-bar " [class.glow-pulse]="activeProperty !== null"></i
        ></a>
      </li>
    </ul>

    <ul role="tablist">
      <li>
        <a
          href="#help"
          id="helpButton"
          role="tab"
          class="sidebar_tooltip"
          popperHelp
          helpOrder="7"
          helpStage="1"
          helpContent="This section is where you can start the help popups for different tools."
          helpPlacement="right"
          helpTitle="Help - Welcome"
          [helpPreShowFunction]="openSidebarPane"
          helpPreShowFunctionParams="help"
          appTooltip="Help"
          tooltipPosition="left"
        >
          <i class="fa fa-question-circle"></i>
          </a>

      </li>

      <li>
        <a
          (click)="openAbout()"
          role="tab"
          class="sidebar_tooltip"
          appTooltip="About"
          tooltipPosition="left"
        >
          <i class="fa fa-info-circle"></i>
        </a>
      </li>

      <li>
        <a
          href=""
          routerLink="/dashboard"
          role="tab"
          popperHelp
          helpOrder="9"
          helpStage="1"
          helpContent="Clicking this will take you to the dashboard. "
          helpPlacement="right"
          helpTitle="Help - Welcome"
          appTooltip="Dashboard"
          tooltipPosition="left"
          ><i class="fa fa-home"></i
        ></a>
      </li>
    </ul>
  </div>

  <!-- Tab panes -->
  <div class="sidebar-content">
    <div class="sidebar-pane" id="home">
      <h1 class="sidebar-header">
        Layers
        <span class="sidebar-close"><i class="fa fa-caret-right"></i></span>
      </h1>
      <div class="sidebar-main">
        <app-analysis-group
          (downloadLayers)="mapService.downloadClippedLayers(paddocks)"
          [layerGroup]="mapService.enterpriseSuitibilityGroupProperty"
        ></app-analysis-group>
        <app-analysis-group
          (downloadLayers)="mapService.downloadClippedLayers(paddocks)"
          [paddockSelect]="true"
          [paddocks]="paddockService.paddocks | async"
          (showPaddocks)="mapService.loadSelectedPaddocks($event)"
          [layerGroup]="mapService.enterpriseSuitibilityGroupPaddock"
        ></app-analysis-group>
        <app-topex-layer
          (downloadLayers)="mapService.downloadClippedLayers(paddocks)"
          [layer]="mapService.topexLayerProperty"
        ></app-topex-layer>
        <app-topex-layer
          (downloadLayers)="mapService.downloadClippedLayers(paddocks)"
          [layer]="mapService.topexLayerPaddock"
          [paddockSelect]="true"
          [paddocks]="paddockService.paddocks | async"
          (showPaddocks)="mapService.loadSelectedPaddocks($event)"
        ></app-topex-layer>

        <app-forest-class-layer
          (downloadLayers)="mapService.downloadClippedLayers(paddocks)"
          [layer]="mapService.forestClassesLayerProperty"
        ></app-forest-class-layer>
        <app-forest-class-layer
          (downloadLayers)="mapService.downloadClippedLayers(paddocks)"
          [layer]="mapService.forestClassesLayerPaddock"
          [paddockSelect]="true"
          [paddocks]="paddockService.paddocks | async"
          (showPaddocks)="mapService.loadSelectedPaddocks($event)"
        ></app-forest-class-layer>

        <app-layer-group
          [layerGroup]="mapService.propertyLayerGroup"
        ></app-layer-group>

        <app-layer-group
          [layerGroup]="mapService.clippedLayerGroup"
        ></app-layer-group>

        <app-layer-group
          [layerGroup]="mapService.alertsGroup"
        ></app-layer-group>

        <app-base-group [layerGroup]="mapService.basemapGroup"></app-base-group>

        <div id="layers" class="layer-switcher layer-switcher-sidebar"></div>
      </div>
    </div>

    <div class="sidebar-pane" id="createProperty">
      <h1 class="sidebar-header">
        Create Property<span class="sidebar-close"
          ><i class="fa fa-caret-right"></i
        ></span>
      </h1>
      <div class="sidebar-main">
        <span
          >Step 1: Zoom into the general location of your property in the map by either using the search tool below or by directly interacting with the map</span
        >
        <app-geocode
          [map]="mapService.map"
          popperHelp
          helpOrder="1"
          helpStage="3"
          helpContent="Use the search bar to find your property or zoom your property manually in the map."
          helpPlacement="right"
          helpTitle="Help - Create Property"
          [helpPreShowFunction]="openSidebarPane"
          helpPreShowFunctionParams="createProperty"
        ></app-geocode>
        <span
          >Step 2. Zoom into the general location of your property in the map
          until the land title parcel boundaries appear. After they appear,
          press the green button below then click in the map to select each
          parcel that makes up the property boundary</span
        >

        <span></span>

        <div
          *ngIf="zoomLevel < cadasterRequiredZoomLevel"
          class="alert-warning"
        >
          <p>
            Please zoom closer into the map to make the land title parcel
            boundaries appear
          </p>
        </div>

        <app-select-property
          [map]="mapService.map"
          [layers]="[mapService.cadasterLayer]"
          multiSelectAttribute="PID"
          [helpPreShowFunction]="openSidebarPane"
          (PropertySelectChanged)="populateCreatePropertyDefaults($event)"
          popperHelp
          helpOrder="2"
          helpStage="3"
          helpContent="When this is active (highlighted in red) you can select your property boundary on the map"
          helpPlacement="right"
          helpTitle="Help - Create Property"
          [helpPreShowFunction]="openSidebarPane"
          helpPreShowFunctionParams="createProperty"
        ></app-select-property>
        <span
          >Step 3. Once you’ve finished selecting land parcels in the map, click
          the green button below to create the property boundary from the
          <span class="text-primary"> {{ selectedBoundariesCount }} </span>
          selected land parcels</span
        >
        <button
          class="button button--brand button--full_width"
          [disabled]="!featureSelected"
          (click)="saveProperty()"
          popperHelp
          helpOrder="3"
          helpStage="3"
          helpContent="Once you have selected all the boundaries that mark your property click this button to being the creation process."
          helpPlacement="right"
          helpTitle="Help - Create Property"
          [helpPreShowFunction]="openSidebarPane"
          helpPreShowFunctionParams="createProperty"
        >
          Create Property
        </button>
      </div>
    </div>

    <div class="sidebar-pane" id="editProperty">
      <h1 class="sidebar-header">
        Manage Properties<span class="sidebar-close"
          ><i class="fa fa-caret-right"></i
        ></span>
      </h1>

      <div
        class="sidebar-main"
        popperHelp
        helpOrder="4"
        helpStage="3"
        helpContent="A list of properties created by you will appear here. you will be able to edit/delete or set them as active."
        helpPlacement="right"
        helpTitle="Help - Manage Properties"
        [helpPreShowFunction]="openSidebarPane"
        helpPreShowFunctionParams="editProperty"
      >
        <div *ngFor="let property of properties" class="list-item">
          <span appTooltip="{{ property.name }}" tooltipPosition="left" class="list-item__name">Name: {{ property.name  | truncateText: 10 }}</span>
          <span appTooltip="{{ property.area }}(ha)" tooltipPosition="left" class="list-item__area">Area (ha): {{ property.area | round: 1  }}</span>
          <button
            class="list-item__zoom button button--blue"
            (click)="mapService.setActiveProperty(property)"
          >
            Set Active
          </button>
          <button
            class="list-item__edit button button--blue"
            (click)="editProperty(property)"
          >
            Edit
          </button>
        </div>
      </div>
    </div>

    <div
      class="sidebar-pane"
      id="paddocks"
      popperHelp
      helpOrder="1"
      helpStage="4"
      helpContent="A list of paddocks created will appear here. you will be able to edit/delete them."
      helpPlacement="right"
      helpTitle="Help - Manage Paddocks"
      [helpPreShowFunction]="openSidebarPane"
      helpPreShowFunctionParams="paddocks"
    >
      <h1 class="sidebar-header">
        Paddocks<span class="sidebar-close"
          ><i class="fa fa-caret-right"></i
        ></span>
      </h1>

      <div class="sidebar-main">
        <button
          class="button button--brand button--full_width"
          [ngClass]="{
            'select-active': digitizePaddockComponent?.isActive === true
          }"
          [disabled]="activeProperty == null"
          (click)="digitizePaddockComponent.toggleDigitizePaddock()"
          popperHelp
          helpOrder="2"
          helpStage="4"
          helpContent="click this to start digitizing paddocks on your property."
          helpPlacement="right"
          helpTitle="Help - Manage Paddocks"
          [helpPreShowFunction]="openSidebarPane"
          helpPreShowFunctionParams="paddocks"
        >
          Create Paddock
        </button>
        <div
          *ngFor="let paddock of paddockService.paddocks | async"
          class="list-item"
        >
          <span
            appTooltip="{{ paddock.name }}"
            tooltipPosition="left"
            class="list-item__name"
            >Name: {{ paddock.name | truncateText: 10 }}</span
          >
          <span  appTooltip="{{ paddock.area }}(ha)"
          tooltipPosition="left" class="list-item__area"
            >Total Area (ha): {{ paddock.area | round: 1 }}</span
          >
          <button
            class="list-item__zoom button button--blue"
            (click)="ZoomToPaddock(paddock)"
          >
            Zoom To
          </button>
          <button
            class="list-item__edit button button--blue"
            (click)="editPaddock(paddock)"
          >
            Edit
          </button>
        </div>
      </div>
    </div>

    <div
      class="sidebar-pane"
      id="fences"
      popperHelp
      helpOrder="1"
      helpStage="5"
      helpContent="A list of fences created will appear here. you will be able to edit/delete them."
      helpPlacement="right"
      helpTitle="Help - Manage Fences"
      [helpPreShowFunction]="openSidebarPane"
      helpPreShowFunctionParams="fences"
    >
      <h1 class="sidebar-header">
        Fences<span class="sidebar-close"
          ><i class="fa fa-caret-right"></i
        ></span>
      </h1>
      <div class="sidebar-main">
        <button
          class="button button--brand button--full_width"
          [ngClass]="{
            'select-active': digitizeFenceComponent?.isActive === true
          }"
          [disabled]="activeProperty == null"
          (click)="digitizeFenceComponent.toggleDigitizeFence()"
          popperHelp
          helpOrder="2"
          helpStage="5"
          helpContent="Click this button to start digitizing fences on your property."
          helpPlacement="right"
          helpTitle="Help - Manage Fences"
          [helpPreShowFunction]="openSidebarPane"
          helpPreShowFunctionParams="fences"
        >
          Create Fence
        </button>
        <div
          *ngFor="let fence of fenceService.fences | async"
          class="list-item"
        >
          <span   appTooltip="{{ fence.name }}"
          tooltipPosition="left" class="list-item__name">Name: {{ fence.name | truncateText: 10 }}</span>
          <span  appTooltip="{{ fence.length }}(m)"
          tooltipPosition="left" class="list-item__area">Length: {{ fence.length | truncateText: 10 }}</span>
          <button
            class="list-item__zoom button button--blue"
            (click)="map.zoomToFence(fence)"
          >
            Zoom To
          </button>
          <button
            class="list-item__edit button button--blue"
            (click)="editFence(fence)"
          >
            Edit
          </button>
        </div>
      </div>
    </div>

    <div class="sidebar-pane" id="reporting">
      <h1 class="sidebar-header">
        Reporting<span class="sidebar-close"
          ><i class="fa fa-caret-right"></i
        ></span>
      </h1>

      <div class="sidebar-main">
        <label for="reportingToolType"
          >Tool
          <select
            [(ngModel)]="reportingToolType"
            name="reportingToolType"
            class="uk-select"
            id="reportingToolType"
            required
          >
            <option value="1">Crop/Species Decision Support Tool</option>

            <option appRole="['Administrator']" value="2">
              Forestry Management Baseline Costs and Benefits Tool
            </option>
            <option value="3">Topex Score Support Tool</option>
            <option value="4">Farm Woodlot Tool</option>

            <option value="5">Forest Description Tool</option>
          </select></label
        >

        <ng-container
          class="reporting-target_container"
          *ngIf="reportingToolType == 1 && activeProperty !== null"
        >
          <app-report-card
            [property]="activeProperty"
            [paddocks]="paddocks"
            [multi]="true"
            (runReport)="showCropReport($event)"
          >
            <p>
              This Tool will summarize and rank suitability by area for key crop
              or tree species for your Property or chosen Paddocks. Suitability
              is derived from the Enterprise Suitability Maps which were
              compiled by the Department of Natural Resources and Environment
              Tasmania (NRET). The maps apply a set of crop rules against the
              modelled climatic, soil and landscape conditions to show which
              areas have the best or worst conditions for particular crops or
              enterprises.
              <br />
              <br />
              More information about the Enterprise Suitability Maps, and the
              specific rules applied to each crop or tree species, can be found
              <a
                target="_blank"
                href="https://nre.tas.gov.au/agriculture/investing-in-irrigation/enterprise-suitability-toolkit/enterprise-suitability-maps"
                >here</a
              >
            </p>
          </app-report-card>
        </ng-container>
        <ng-container
          class="reporting-target_container"
          *ngIf="reportingToolType == 2 && activeProperty !== null"
          appRole="['Administrator']"
        >
          <app-report-card
            [property]="activeProperty"
            [paddocks]="paddocks"
            [multi]="false"
            (runReport)="showNPVCalculator($event)"
          >
            <p>
              This calculator provides a summary of key baseline costs and
              benefits for standing native forest and plantation forestry
              management enterprises, against which sensitivity analysis can be
              undertaken to provide insight into the main drivers of forest
              value at harvest. The calculator was developed by the Forest
              Practices Authority.
              <br />
              <br />
              Any input values set as default by the calculator, and any
              calculated output dollar values are indicative only, and should
              not be relied upon. You should consult professional advice should
              you need an accurate valuation of your forest.
              <br />
              <br />
              More information about the Forestry Management Baseline Costs And
              Benefits Tool can be found
              <a target="_blank" href="https://www.fpa.tas.gov.au/">here</a>
            </p>
          </app-report-card>
        </ng-container>
        <ng-container
          class="reporting-target_container"
          *ngIf="reportingToolType == 3 && activeProperty !== null"
        >
          <app-report-card
            [property]="activeProperty"
            [paddocks]="paddocks"
            [multi]="true"
            (runReport)="showTopexReport($event)"
          >
            <p>
              The Topex Score Support Tool was designed to generate inputs for
              Private Forests Tasmania's 'Wind Risk Calculator' available for
              download
              <a
                target="_blank"
                href="https://treealliance.com.au/wind-risk-calculator"
                >here</a
              >. Within Tasmania the forest wind risk must not be
              underestimated. The establishment and management of forests,
              plantation and native, can be severely affected by wind if it is
              not assessed as part of the strategic forest planning process,
              failure to assess this could result in commercial failure. For
              more information about the risk of wind damaging forests in
              Tasmania, please refer to this link
              <a
                target="_blank"
                href="https://pft.tas.gov.au/volumes/documents/Wind-Risk/Forest-and-wind-risk-in-Tasmania.pdf"
                >here</a
              >.
            </p>
          </app-report-card>
        </ng-container>
        <ng-container
          class="reporting-target_container"
          *ngIf="reportingToolType == 4 && activeProperty !== null"
        >
          <app-report-card
            [property]="activeProperty"
            [paddocks]="paddocks"
            [multi]="false"
            (runReport)="showWoodlotTool($event)"
          >
            <p>
              The Farm Woodlot Tool provides a set of simple calculators and
              reference material to help you understand the main requirements to
              achieve a successful tree enterprise from establishment through
              management to harvest and marketing
              <br />
              <br />
              Like any agricultural enterprise it is wise to prepare a simple
              management plan considering establishment costs, market location,
              product requirements, wood prices and plantation management
              options. The Farm Woodlot Tool provides a set of guidelines and
              calculators to assist with successful establishment of trees on
              farm land, including indication of likely yields and markets.
              <br />
              <br />
              If you need more background information on why and how you might
              grow trees on your property, or a basic overview of the likely
              timing and planning required for tree establishment, please click
              <a
                target="_blank"
                href="https://pft.tas.gov.au/6-steps-to-growing"
                >here</a
              >. If in doubt, seek professional advice from Private Forests
              Tasmania.
            </p>
          </app-report-card>
        </ng-container>

        <ng-container
          class="reporting-target_container"
          *ngIf="reportingToolType == 5 && activeProperty !== null"
        >
          <app-report-card
            [property]="activeProperty"
            [paddocks]="paddocks"
            [multi]="true"
            (runReport)="showForestReport($event)"
          >
            <p>
              The Forest Description Tool will summarize the area of native
              forest for your Property or chosen Paddocks, and provide an
              indicative estimate of the amount of timber products that might be
              standing in that forest, some of which might be available for
              harvest under an approved Forest Practices Plan.
              <br />
              <br />
              The native forest mapping and classification underpinning this
              tool is managed by Private Forests Tasmania, and is sourced from a
              mix of aerial and satellite imagery of various ages. Some of the
              mapping reaches back to 1995, but as part of PFT's annual forest
              review program, every effort has been made to update the mapping
              where changes have been identified. We would welcome feedback via
              this app where you note significant differences in the mapping for
              your Property.
            </p>
          </app-report-card>
        </ng-container>
      </div>
    </div>

    <div class="sidebar-pane" id="ol-display-props">
      <h1 class="sidebar-header">
        Attributes<span class="sidebar-close"
          ><i class="fa fa-caret-right"></i
        ></span>
      </h1>
    </div>

    <div class="sidebar-pane" id="help">
      <h1 class="sidebar-header">
        Help<span class="sidebar-close"><i class="fa fa-caret-right"></i></span>
      </h1>
      <div class="sidebar-main">
        <mat-form-field appearance="fill">
          <mat-label>Select a help stage</mat-label>
          <br />
          <mat-select [(value)]="helpStage">
            <mat-option value="1"> Welcome </mat-option>
            <mat-option value="2"> Map Tools </mat-option>
            <mat-option value="3"> Create Property </mat-option>

            <mat-option value="4"> Create Paddock </mat-option>
            <mat-option value="5"> Create Fences </mat-option>
          </mat-select>
        </mat-form-field>
        <button (click)="showHelpGuide()" class="button button--blue">
          Show Help Guide
        </button>
      </div>
    </div>
  </div>
</div>
