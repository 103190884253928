import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, OnChanges, SimpleChanges } from '@angular/core';
import { NpvCalculatorDataStorageService } from 'src/app/services/npv-calculator-data-storage.service';

@Component({
  selector: 'app-foregone-land-sale-revenue',
  templateUrl: './foregone-land-sale-revenue.component.html',
  styleUrls: ['./foregone-land-sale-revenue.component.css']
})
export class ForegoneLandSaleRevenueComponent implements OnInit, OnChanges {

  @Input() forestSize: number;
  @Input() purchasePrisePerHectare: number;
  @Output() calculationsValueEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private npvCalcService: NpvCalculatorDataStorageService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    // Passing values to the parent component from 
    if (changes) {
      const calculationData = {
        "Foregone Land Sale Revenue": {
          "Forest Size (ha)": this.forestSize,
          "Land Purchase Price ($/ha)": this.purchasePrisePerHectare,
          "Monetary Value": this.returnMonetoryValue(),
          "PV(C)": this.returnMonetoryValue().toFixed(2),
        }
      }
      this.calculationsValueEmitter.emit(calculationData)
    }

  }

  returnMonetoryValue() {
    const monetaryVal = this.forestSize * this.purchasePrisePerHectare
    this.npvCalcService.updateCosts({ key: 'foregone-land-sale-revenue', value: monetaryVal })
    return monetaryVal;
  }

  calculatePVC() {
    /*
      LegalFees + (MonetaryValue * ((1 - ((1 + DiscountRate)**-HarvestLifeSpan)) / DiscountRate))
      */
  }


}
