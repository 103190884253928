<div class="full-screen-container">
  <section class="registration">

    <form
      [formGroup]="registerForm"
      (ngSubmit)="onSubmit()"
      class="registration_form"
      *ngIf="!submitted; else showResult"
    >
    <header><h2>Register User</h2></header>
    <fieldset class="grid">
      <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input formControlName="firstName" matInput placeholder="Luke">
        <mat-error *ngIf="registerForm.get('firstName').invalid && (registerForm.get('firstName').dirty || registerForm.get('firstName').touched)">
          <div *ngIf="registerForm.get('firstName').errors?.['required']">
           This is required.
          </div>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Surname</mat-label>
        <input formControlName="surname" matInput placeholder="bennett">
        <mat-error *ngIf="registerForm.get('surname').invalid && (registerForm.get('surname').dirty || registerForm.get('surname').touched)">
          <div *ngIf="registerForm.get('surname').errors?.['required']">
            This is required.
          </div>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput placeholder="example@example.com.au">
        <mat-error *ngIf="registerForm.get('email').invalid && (registerForm.get('email').dirty || registerForm.get('email').touched)">
          <div *ngIf="registerForm.get('email').errors?.['required']">
            Email is required.
          </div>
          <div *ngIf="registerForm.get('email').errors?.['email']">
            Please enter a valid email address.
          </div>
          <div *ngIf="registerForm.get('email').errors?.['emailExists']">
            An account with that email already Exists!
          </div>
        </mat-error>
      </mat-form-field>
    </fieldset>

    <fieldset formGroupName="passwordData" class="grid">
      <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" type="password">
          <mat-error *ngIf="registerForm.get('passwordData.password').invalid && (registerForm.get('passwordData.password').dirty || registerForm.get('passwordData.password').touched)">
            <div *ngIf="!registerForm.get('passwordData.password').errors['required']">
              Password is required.
            </div>
            <span *ngIf="!registerForm.get('passwordData.password').errors['required']">
              Password must contain:
              <ul>
                <li *ngIf="registerForm.get('passwordData.password').errors['noUpper']">One or more uppercase characters.</li>
                <li *ngIf="registerForm.get('passwordData.password').errors['noSpecial']">One or more special characters !$&. </li>
                <li *ngIf="registerForm.get('passwordData.password').errors['minlength']">8 or more characters.</li>
              </ul>
            </span>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Repeat password</mat-label>
          <input matInput formControlName="rptPassword" type="password">
          <mat-error *ngIf="registerForm.get('passwordData.rptPassword').invalid && (registerForm.get('passwordData.rptPassword').dirty || registerForm.get('passwordData.rptPassword').touched)">
            <div *ngIf="!registerForm.get('passwordData').valid && registerForm.get('passwordData.password').touched  && registerForm.get('passwordData.rptPassword').touched">
              Your passwords don't match
            </div>
          </mat-error>
        </mat-form-field>


    </fieldset>
          <button [disabled]="!registerForm.valid" type="submit" class="button button--brand">
            Register
          </button>
    </form>

    <ng-template #showResult>
      <div *ngIf="!error" class="success">
        <i class="fa fa-5x fa-check-circle"></i>
        <p>An email has been sent with a link to activate your account!</p>
        <p>Please check your spam/junk folders, If you are unable to find the email, please contact PFT Admin at admin&#64;pft.tas.gov.au</p>
      </div>
      <div *ngIf="error" class="error">
        <i class="fa fa-5x fa-times"></i>
        <p>{{errorMessage}}</p>
      </div>
    </ng-template>

</section>

</div>
