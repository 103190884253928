import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BreadcrumbsService } from './breadcrumbs.service';


export interface Breadcrumb {
  label: string;
  url?: string;
}



@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  breadcrumbs$: Observable<Breadcrumb[]>;

  constructor(private breadcrumbsService: BreadcrumbsService) {

    this.breadcrumbs$ = this.breadcrumbsService.getBreadcrumbs();

  }
/* A lifecycle hook that is called after the constructor. */

  ngOnInit(): void {
  }

}
