
import { v4 as uuidv4 } from 'uuid';


export class ClippedPropertyLayer {

  clippedLayerID?: string;
  layerName: string;
  wkt: string;
  propertyID: string


  constructor() {
    this.clippedLayerID = uuidv4();
  }


}


export class ClippedLayer_Paddock {

  id?: number;
  layerName: string;
  geom: string;
  paddockID: string

}


