import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, map, Observable, Subject, switchMap, tap, throwError } from "rxjs";
import { User } from "../models/user.model";

import { environment } from 'src/environments/environment';
import { TokenStorageService } from "./token-storage.service";
import { trueDependencies } from "mathjs";
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from "@angular/router";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
interface AuthResponseDataUser{
  refreshToken: string,
  email: string,
  userFullName: string
  userID: number
}

interface AuthResponseData {
  accessToken: string,
  user: AuthResponseDataUser
}

interface UserDetails{
email: string,
firstName: string,
surname: string,
password: string,
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = new BehaviorSubject<User>(null);

  jwtHelper: JwtHelperService;
  username = "";

  authApi: string;

  constructor(private http: HttpClient, private tokenService: TokenStorageService, private router: Router) {

    this.authApi = environment.apiURL;

    this.jwtHelper = new JwtHelperService();
   }


  login(credentials): Observable<AuthResponseData> {

    return this.http.post<AuthResponseData>(this.authApi + 'login', {
      email: credentials.email,
      password: credentials.password
    }, httpOptions)
    .pipe(
      catchError(this.handelError), tap(resData =>
        {

          const newUser = new User(resData.user.userID, resData.user.email, resData.user.userFullName,resData.accessToken, resData.user.refreshToken);


          let decodedToken = this.jwtHelper.decodeToken(resData.accessToken);

          if(decodedToken.role?.includes("Administrator"))
          newUser.setIsAdmin(true)


          this.user.next(newUser);
        })
    )
  }

  resetPassword(password): Observable<any> {
    return this.http.post(this.authApi + 'password/reset', {
      password: password
    }, httpOptions).pipe(
      catchError(this.handelError)
    );
  }

  register(userDetails:UserDetails ): Observable<any> {
    return this.http.post(this.authApi + 'register', {
      email: userDetails.email,
      password: userDetails.password,
      firstName: userDetails.firstName,
      lastName: userDetails.surname
    }, httpOptions).pipe(
      catchError(this.handelError)
    );
  }

  logout(){
   this.tokenService.removeToken();
   this.tokenService.removeUser();

   this.user.next(null);

  }

  refreshToken(token: string,refreshToken: string)
  {

    return this.http.post<any>(this.authApi + `token/refresh`, {
        accessToken: token,
        refreshToken:refreshToken
    }, httpOptions).pipe(
      catchError(error => {
        this.handelError(error);
        this.logout(); // Call the logout function from your authentication service
        return throwError(() => new Error('Refresh Token Expired'));
      }),
    );
  }

  async autoLogin()  {

    const loadedToken = this.tokenService.getToken();
    const loadedUserData = this.tokenService.getUser();

      if(!loadedToken || !loadedUserData)
      {
        return;
      }


      const userData = JSON.parse(loadedUserData);

      if(this.tokenExpired(loadedToken))
      {
        this.refreshToken(loadedToken,userData.refreshToken).subscribe(results => {
          userData.refreshToken = results.refreshToken;
          const loadedUser = new User(userData.userID, userData.email,userData.userFullName,results.accessToken,results.refreshToken,true);
          this.tokenService.saveToken(results.accessToken);
          this.tokenService.saveUser(loadedUser);
          loadedUser.setIsAdmin(this.isAdmin(loadedToken));
          this.user.next(loadedUser);
        });
      }
      else{


      const loadedUser = new User(userData.userID, userData.email,userData.userFullName,loadedToken,userData.refreshToken,true);


      loadedUser.setIsAdmin(this.isAdmin(loadedToken));
      this.user.next(loadedUser);


      }

  }

  hasRoles(roles: string[]): boolean {

    const token = this.tokenService.getToken();
    const decodedToken = this.jwtHelper.decodeToken(token);

    const userRoles = decodedToken.role instanceof Array ? decodedToken.role : [decodedToken.role];

    return userRoles.some(role => roles.includes(role));

  }


  private isAdmin(token: string) : boolean {
    let decodedToken = this.jwtHelper.decodeToken(token);


    if(decodedToken.role?.includes("Administrator"))
    return true

    return false
  }

  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

 handelError(errorRes)
 {
  let errorMessage = 'An unknown error occurred!'

  if(!errorRes.error||!errorRes.error.result)
  {
    return throwError(() => { return new Error(errorMessage)});
  }

  switch (errorRes.error.result) {
    case 'INVALID_CREDENTIALS':
      errorMessage = "Invalid Login Credentials";
      break;
    case 'EMAIL_EXISTS':
      errorMessage = "An Account Already Exists With That Email";
      break;

    case 'EMAIL_NOT_CONFIRMED':
      errorMessage = "This user has not yet been activated. Please check your email to find the activation email before logging in.";
      break;

      case 'ACCOUNT_DISABLED':
        errorMessage = "Your account has been disabled, please contact a PFT admin";
        break;


    case'Invalid client request':
      this.logout();
      this.router.navigateByUrl('/login');
      break;

    default:
      break;
  }

  return throwError(() => { return new Error(errorMessage)});

 }

}
