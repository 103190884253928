<div class="manage-property card card--black">
  <div class="card-title">
    <h3>MANAGE PROPERTY</h3>
  </div>
  <div class="card-icon">
  </div>

    <app-property-select class="manage-property__select-container"></app-property-select>

  <button [routerLink]="['/map','createProperty']" class="button button--add-prop button--black ">
    CREATE PROPERTY
  </button>
  <button [routerLink]="['/map','editProperty']" class="button button--manage-prop button--black ">
    MANAGE PROPERTIES
  </button>
</div>
