<div class="card card--black">
  <div class="news">
    <div class="card-title">
      <h3>NEWS UPDATES</h3>
    </div>
    <div class="card-icon">
    </div>
    <div class="news_item-one news_container">
      this is where some news will go
    </div>
    <div class="news_item-two news_container">
      this is where some news will go
    </div>
  </div>
</div>
