import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivateComponent } from './components/activate/activate.component';
import { AuthGuard } from './guards/auth.guard';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { LoginComponent } from './components/login/login.component';
import { MapComponent } from './components/map/map.component';
import { RegisterComponent } from './components/register/register.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { InputsComponent } from './components/npv-calculator/inputs/inputs.component';
import { AdminComponent } from './components/admin/admin.component';
import { RoleGuard } from './guards/role.guard';
import { IsCalculatorDataSavedGuard } from './guards/is-calculator-data-saved.guard';
import { FarmWoodlotToolComponent } from './components/farm-woodlot-tool/farm-woodlot-tool.component';
import { NotFoundComponent } from './not-found/not-found.component';

const guards = [AuthGuard];

const routes: Routes = [
  {
    path: "woodlot-tool", canActivate: guards, component: FarmWoodlotToolComponent,
  },
  {
    path: 'dashboard', canActivate: guards, component: DashboardComponent
  },
  {
    path: 'map', canActivate: guards, component: MapComponent
  },
  {
    path: 'map/:tool', canActivate: guards, component: MapComponent
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'admin',canActivate: [AuthGuard, RoleGuard],data: {requiredRoles: ['Administrator']}, loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },

  {
    path: 'register', component: RegisterComponent
  },
  {
    path: 'calculator', canActivate: [AuthGuard, RoleGuard], component: InputsComponent, canDeactivate: [IsCalculatorDataSavedGuard], data: { requiredRoles: ['Administrator'] }
  },
  {
    path: 'account/resetPassword', component: ResetPasswordComponent
  },
  {
    path: 'account/forgotPassword', component: ForgotPasswordComponent
  },
  {
    path: 'account/confirm', component: ActivateComponent
  },
  {
    path: '', redirectTo: 'map', pathMatch: "full",
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
