<div class="uk-column uk-padding-small@m uk-padding-remove@m" [formGroup]="form">
    <div class="uk-column-1-1">
        <p class="uk-text-lead uk-margin-small">Property information</p>
    </div>

    <div class="uk-column-1-3@m uk-flex-row medium-flex">
        <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1">
                Land Purchase Price per Hectare (ha)
                <input class="uk-input" formControlName="landPurchasePricePerHectare" type="number">
            </label>
            <!-- <span class="error-message"
                *ngIf='!form.get("LandPurchasePricePerHectare").valid && form.get("LandPurchasePricePerHectare").touched'>
                Please enter a valid land price
                Required
            </span> -->
        </div>

        <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1">
                Type of Forestry Operation
                <select formControlName="typeOfForestryOperation" class="uk-select">
                    <option *ngFor="let forestry of forestyOperations" [value]="forestry">{{forestry}}</option>
                </select>
            </label>
            <!--<span class="error-message"
                *ngIf='!form.get("TypeOfForestryOperation").valid && form.get("TypeOfForestryOperation").touched'>
                Please enter Type of Forestry Operation
                Required
            </span> -->
        </div>

        <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <!-- <label class="uk-flex-1">
                Distance from buyer (km)
                <input class="uk-input" formControlName="DistanceFromBuyer" type="number">
            </label> -->
            <!--  <span class="error-message"
                *ngIf='!form.get("DistanceFromBuyer").valid && form.get("DistanceFromBuyer").touched'>
                Please distance from buyer
                Required
            </span>-->
        </div>
    <!-- </div>

    <div class="uk-column-1-2@m uk-flex-row medium-flex"> -->
        <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1">
                Land size (ha)
                <input class="uk-input" formControlName="landSize" type="number">
            </label>
            <!-- <span class="error-message" *ngIf='!form.get("LandSize").valid && form.get("LandSize").touched'>
                Please enter Land size
                Required
            </span> -->
        </div>

        <!-- <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1">
                Total Potential Volume of Logs Harvested  (tonne)
                <input class="uk-input" formControlName="TotalPotentialVolumeOfLogsHarvestedPerYear" type="number"
                    [value]="calculatTotalPotentialVolumeOfLogsHarvestedPerYear()">
            </label>
        </div> -->

        <!-- <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1">
                Sawlog Price ($/tonne)
                <input class="uk-input" formControlName="SawlogPrice" type="number">
            </label>
        </div> -->


        <!-- <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1">
                Price Mark-Up/Decrease
                <input class="uk-input" formControlName="PriceMarkUpDecrease" type="number">
            </label>
        </div> -->
    </div>


    <div class="uk-column-1-3@m uk-flex-row medium-flex">
        <!-- <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1">
                Peeler Log Price (Tonne)
                <input class="uk-input" formControlName="PeelerLogPrice" type="number">
            </label>
        </div>

        <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1">
                Arisings Price (Tonne)
                <input class="uk-input" formControlName="ArisingsPrice" type="number">
            </label>
        </div> -->

    </div>
</div>
