import { Control } from "ol/control";



declare global {
  interface Window {
    eskToolBar: any
  }
}

export function GetPropsTitle(props, layerName)
{

  let keys = Object.keys(props);

  let names = keys.filter( key => {
    if(key != 'FEAT_NAME')
      return key.match(/\Bname|name\B/);
  });

  let possibleName = names.forEach(name => {

    let title = props[name] !== undefined && props[name] !== null ? props[name] : null;

      if(title != null)
      {
        return title;
      }
  });

  let title = props['FEAT_NAME'] != null ? props['FEAT_NAME'] : possibleName != null ? possibleName : layerName === 'Property boundaries' ? `Property ID: ${props['PID']}` : 'No Title Specified';

  return title;
}

const toolSettingsKey = 'toolSettings';

export function GetToolSettings() : object
{
  let toolSettings : object = JSON.parse(localStorage.getItem(toolSettingsKey));

  toolSettings = toolSettings == null ? {} : toolSettings;

  return toolSettings;
}

export function UpdateToolSettings(toolSettings : object)
{
  localStorage.removeItem(toolSettingsKey);
  localStorage.setItem(toolSettingsKey, JSON.stringify(toolSettings));
}

export function UpdateActiveTool(tool) : void
{
  const activeTool = window?.eskToolBar?.activeTool;
  window.eskToolBar = window.eskToolBar || {};

  if (activeTool && activeTool !== tool) {
    activeTool.deSelect();
  }

  if(activeTool === tool)
  {
    window.eskToolBar.activeTool = null;
  }
  else
  {
    window.eskToolBar.activeTool = tool
  }
}

export function UpdatePassiveTool(tool) : void
{
  const tools = window?.eskToolBar?.passiveTools as Array<any> || [];

  window.eskToolBar = window.eskToolBar || {};

  if(tools.includes(tool))
  {
    let index = tools.indexOf(tool);
    tools.splice(index,1);
  }
  else{
    tools.push(tool);
  }

  window.eskToolBar.passiveTools = tools
}

export function RemoveActiveTools()
{
  const tools = window?.eskToolBar?.passiveTools as Array<any> || [];
  window.eskToolBar = window.eskToolBar || {};

  tools.forEach(tool => {
    tool.deSelect();
  })
  window.eskToolBar.passiveTools = [];
}


const CSS_CLASSES =
{

  tool: 'esk-tool',
  toolDisabled: 'esk-tool--disabled',
  toolActive: 'esk-tool--active',
  toolTip: 'esk-toolbar-tooltip',


}


export function rankCrops(A)
{

    // create rank vector
    let R = new Array(A.length).fill(0)

    // Create an auxiliary array of tuples
    // Each tuple stores the data as well
    // as its index in A
    let T = new Array(A.length);
    for(let i = 0; i < A.length; i++)
    {
        T[i] = [A[i].cropRankValue, i]
    }

    // T[][0] is the data and T[][1] is
    // the index of data in A

    let rank = 1, n = 1,i = 0

    while(i < A.length){
        let j = i

        // Get no of elements with equal rank
        while(j < A.length - 1 && T[j][0] == T[j + 1][0])
            j += 1

        n = j - i + 1
        for(let j=0;j<n;j++){

          // rank === 4 N === 2  * .05
            // For each equal element use formula
            // obtain index of T[i+j][0] in A
            let idx = T[i+j][1]
            R[idx] = Math.floor(rank)
        }

        // Increment rank and i
        rank += 1;
        i += n
    }
    return R
}



export default CSS_CLASSES;

