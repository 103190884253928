import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toPrecision'
})
export class ToPrecisionPipe implements PipeTransform {

  transform(value: number, ...args: number[]): string {
    return this.precise(value);
  }

  precise(x) {
    // return Number(x.toPrecision(4));
    const roundedNumber  = Math.round(x * 10) / 10
    return roundedNumber.toFixed(1);
  }

}
