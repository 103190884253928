export var landuseDefinition = [
  {
    landUseTypeID: 1,
    name: 'Cropping'
  },
  {
    landUseTypeID: 2,
    name: 'Grazing'
  },
  {
    landUseTypeID: 3,
    name: 'Horticulture'
  },
  {
    landUseTypeID: 4,
    name: 'Native Forest'
  },
  {
    landUseTypeID: 5,
    name: 'Native Vegetation'
  },
  {
    landUseTypeID: 6,
    name: 'Plantation'
  },
  {
    landUseTypeID: 7,
    name: 'Other'
  }
];

export var cropTypeDefinition = [
  {enterpriseTypeID:1,name:"Cereals",landUseTypeID:1},
  {enterpriseTypeID:2,name:"Cotton",landUseTypeID:1},
  {enterpriseTypeID:3,name:"Hay/silage",landUseTypeID:1},
  {enterpriseTypeID:4,name:"Poppy",landUseTypeID:1},
  {enterpriseTypeID:5,name:"Pulses",landUseTypeID:1},
  {enterpriseTypeID:6,name:"Pyrethrum",landUseTypeID:1},
  {enterpriseTypeID:7,name:"Other",landUseTypeID:1},
  {enterpriseTypeID:8,name:"Beef",landUseTypeID:2},
  {enterpriseTypeID:9,name:"Dairy",landUseTypeID:2},
  {enterpriseTypeID:10,name:"Sheep",landUseTypeID:2},
  {enterpriseTypeID:11,name:"Other",landUseTypeID:2},
  {enterpriseTypeID:12,name:"Berry",landUseTypeID:3},
  {enterpriseTypeID:13,name:"Flower/bulb",landUseTypeID:3},
  {enterpriseTypeID:14,name:"Fruit",landUseTypeID:3},
  {enterpriseTypeID:15,name:"Nut",landUseTypeID:3},
  {enterpriseTypeID:16,name:"Vegetable",landUseTypeID:3},
  {enterpriseTypeID:17,name:"Vineyard",landUseTypeID:3},
  {enterpriseTypeID:18,name:"Other",landUseTypeID:3},
  {enterpriseTypeID:19,name:"Blackwood",landUseTypeID:4},
  {enterpriseTypeID:20,name:"Environmental Planting",landUseTypeID:4},
  {enterpriseTypeID:21,name:"Mature Eucalypt Forest",landUseTypeID:4},
  {enterpriseTypeID:22,name:"Regenerating Eucalypt Forest",landUseTypeID:4},
  {enterpriseTypeID:23,name:"Regrowth Eucalypt Forest",landUseTypeID:4},
  {enterpriseTypeID:24,name:"Tea Tree",landUseTypeID:4},
  {enterpriseTypeID:25,name:"Wattle",landUseTypeID:4},
  {enterpriseTypeID:26,name:"Other",landUseTypeID:4},
  {enterpriseTypeID:27,name:"Environmental Planting",landUseTypeID:5},
  {enterpriseTypeID:28,name:"Native Grass",landUseTypeID:5},
  {enterpriseTypeID:29,name:"Swamp",landUseTypeID:5},
  {enterpriseTypeID:30,name:"Woody Scrub (< 5m tall)",landUseTypeID:5},
  {enterpriseTypeID:31,name:"Other",landUseTypeID:5},
  {enterpriseTypeID:32,name:"Environmental Planting",landUseTypeID:6},
  {enterpriseTypeID:33,name:"Eucalyptus globulus",landUseTypeID:6},
  {enterpriseTypeID:34,name:"Eucalyptus nitens",landUseTypeID:6},
  {enterpriseTypeID:35,name:"Pinus radiata",landUseTypeID:6},
  {enterpriseTypeID:36,name:"Shelterbelt",landUseTypeID:6},
  {enterpriseTypeID:37,name:"Other",landUseTypeID:6},
  {enterpriseTypeID:38,name:"Rehabilitating Land",landUseTypeID:7},
  {enterpriseTypeID:39,name:"Unusable Land",landUseTypeID:7},
  {enterpriseTypeID:40,name:"Weed",landUseTypeID:7},
  {enterpriseTypeID:41,name:"Other",landUseTypeID:7},


]
