import OlMap from 'ol/Map';
import View from 'ol/View';
import ScaleLine from 'ol/control/ScaleLine';
import Attribution from 'ol/control/Attribution';
import * as olProj from 'ol/proj';
import { defaults as defaultInteractions, PinchZoom } from 'ol/interaction';
import { Injectable, NgZone } from '@angular/core';
import SidebarEsk, { SidebarEsk as Sidebar } from '../_helpers/sidebar_esk';
import LayerGroup from 'ol/layer/Group';
import {
  default as LayerSwitcher,
  BaseLayerOptions,
  GroupLayerOptions
} from '../_helpers/ol-layerswitcher_esk';
import { populateLayerGroup } from '../_helpers/LayerBuilder';
import { mapProjEPSG } from '../_helpers/projection_setup';
import EsriJSON from 'ol/format/EsriJSON';
import XYZ from 'ol/source/XYZ';
import { createXYZ } from 'ol/tilegrid';
import { base_map_defs, defaultLayers } from '../_helpers/basemaps';
import { Fill, Stroke, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { tile as tileStrategy } from 'ol/loadingstrategy';

import $ from 'jquery';
import { Collection, Feature } from 'ol';
import {
  BehaviorSubject,
  lastValueFrom,
  Observable,
  of,
  switchMap
} from 'rxjs';
import {
  Buffer,
  ConvertFeatureToGeoJson,
  ConvertGeoJsonToFeature,
  ConvertGeoJsonToFeatureCollection,
  ConvertFeatureCollectionToGeoJson,
  Union,
  Intersect,
  CloneProperties,
  CalculateArea,
  Difference,
  Intersects,
  CalculateLength,
  ConvertFeatureToWKT,
  ConvertWktToFeature,
  ConvertFeatureCollectionToWkt,
  ConvertWktToFeatureCollection
} from '../_helpers/transformations';
import { LIST_MAP_DEFS, VERSATILITY_NAMES } from '../_helpers/otherMaps';
import { ClippedLayerService } from './BaseLayerServices/clippedLayer.service';
import {
  fireAlertStyleIcon,
  firePermitStyleIcon,
  getCadasterStyle,
  getFcCodeStyle,
  getFenceStyle,
  getPaddockStyle,
  getPropertiesStyle,
  getTopexStyle,
  getVersatilityStyle,
  styleMap
} from '../_helpers/layer.styles';
import { PropertyService } from './property.service';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';

import { AuthService } from './auth.service';
import { User } from '../models/user.model';
import { PaddockService } from './paddock.service';
import { FenceDexie } from '../models/fence.model';
import { feature, FeatureCollection, featureEach } from '@turf/turf';
import VectorImageLayer from 'ol/layer/VectorImage';

import VectorTileLayer from 'ol/layer/VectorTile';
import { computeStartOfLinePositions } from '@angular/compiler-cli/src/ngtsc/sourcemaps/src/source_file';
import { DataTile, OSM, TileJSON } from 'ol/source';
import { rankCrops } from '../_helpers/helperFunctions-esk';
import { EventBusService } from './EventBus.service';
import { EventData } from '../_helpers/event.class';
import { FenceService } from './fence.service';
import { PopperHelpService } from './popperHelp.service';
import { ProcessState, TreeMapperCustomEvents } from 'src/app/_helpers/enums';
import { SpatialService } from './spatial.service';
import {
  ClippedLayer_Paddock,
  ClippedPropertyLayer
} from '../models/clippedLayers.model';
import GeoJSON from 'ol/format/GeoJSON';
import { bbox as bboxStrategy } from 'ol/loadingstrategy';
import { style } from '@angular/animations';
import { DexieDatabaseService } from './dexieIndexDB.service';
import { PropertyDexie } from '../models/property.model';

import { tap, map } from 'rxjs/operators';
import { PaddockDexie } from '../models/paddock.model';
import {
  EnterpriseReport,
  EnterpriseReportRow
} from '../models/enterpriseReport.model';
import { EnterpriseReportService } from './enterpriseReport.service';
import { EnterpriseService } from './enterprise.service';
import { Enterprise } from '../models/enterprise.model';
import { TopexReport } from '../models/topexReport.model';
import { TopexReportService } from './topexReport.service';
import { ForestClass } from '../models/Forest Description/forestClass.model';
import { ForestDescriptionReport } from '../models/Forest Description/forestReport.model';
import { ForestClassReportService } from './forestClassesReport.service';
import { ExportableMap } from '../_helpers/ExportableMap';
import  {Tile as TileLayer} from 'ol/layer';

@Injectable({
  providedIn: 'root'
})
export class mapService {
  ProgressDetail = new BehaviorSubject<string>(null);

  topexVectorSource_Property: VectorSource<any> = new VectorSource();
  topexVectorSource_Paddock: VectorSource<any> = new VectorSource();


  forestClassesVectorSource_Property: VectorSource<any> = new VectorSource();
  forestClassesVectorSource_Paddock: VectorSource<any> = new VectorSource();


  propertiesVectorSource: VectorSource<any> = new VectorSource();
  propertiesLayer: VectorLayer<any> = new VectorLayer({
    source: this.propertiesVectorSource,
    visible:false,
    style: (feature, resolution) => {
      return getPropertiesStyle(feature, resolution, '');
    }
  });

  ProcessingState = new BehaviorSubject<ProcessState>(
    ProcessState.Not_Processing
  );
  createPaddockFeature = new BehaviorSubject<Feature<any>>(null);

  map: ExportableMap;

  proj = olProj.get(mapProjEPSG);

  esrijsonFormat = new EsriJSON();

  propAliasMap: Map<string, string> = new Map();

  listLayerMap: Map<VectorSource<any>, VectorLayer<any>> = new Map();

  cadasterLayer: VectorImageLayer<any>;

  basemapGroup;

  layerSwitcher;

  propertyLayer = new VectorLayer({
    source: new VectorSource(),
    style: styleMap.get('Property')
  } as any);

  paddockLayer = new VectorLayer({
    source: new VectorSource({}),
    /* A function that returns a style object. */
    visible: true,
    style: (feature, resolution) => {
      return getPaddockStyle(feature, resolution, '');
    }
  });

  topexLayerProperty = new VectorLayer({
    source:this.topexVectorSource_Property,
    /* A function that returns a style object. */
    visible: false,
    style: (feature, resolution) => {
      return getTopexStyle(feature, resolution, '');
    }
  });


  topexLayerPaddock = new VectorLayer({
    source:this.topexVectorSource_Paddock,
    /* A function that returns a style object. */
    visible: false,
    style: (feature, resolution) => {
      return getTopexStyle(feature, resolution, '');
    }
  });


  forestClassesLayerProperty = new VectorLayer({
    source:this.forestClassesVectorSource_Property,
    /* A function that returns a style object. */
    visible: false,
    style: (feature, resolution) => {
      return getFcCodeStyle(feature, resolution, '');
    }
  });


  forestClassesLayerPaddock = new VectorLayer({
    source:this.forestClassesVectorSource_Paddock,
    /* A function that returns a style object. */
    visible: false,
    style: (feature, resolution) => {
      return getFcCodeStyle(feature, resolution, '');
    }
  });

  fenceLayer = new VectorLayer({
    source: new VectorSource({}),
    style: (feature, resolution) => {
      return getFenceStyle(feature, resolution, '', this.map);
    },
    updateWhileAnimating: true,
    updateWhileInteracting: true
  });

  firePermitsLayer: VectorLayer<any> = new VectorLayer({
    source: new VectorSource(),
    style: styleMap.get('Fire Permits')
  });

  fireAlertsLayer: VectorLayer<any> = new VectorLayer({
    source: new VectorSource(),
    style: styleMap.get('Fire Alerts')
  });

  fireBurntAreaLayer: VectorLayer<any> = new VectorLayer({
    source: new VectorSource(),
    style: styleMap.get('Fire Permits')
  });

  enterpriseSuitibilityGroupProperty: LayerGroup = new LayerGroup({
    title: 'Analysis Output - Property',
    fold: 'open'
  } as any);

  enterpriseSuitibilityGroupPaddock: LayerGroup = new LayerGroup({
    title: 'Analysis Output - Paddock',
    fold: 'open'
  } as any);

  propertyLayerGroup: LayerGroup = new LayerGroup({
    title: 'Property Features',
    fold: 'open',
    layers: [this.propertyLayer, this.paddockLayer, this.fenceLayer]
  } as any);

  alertsGroup: LayerGroup = new LayerGroup({
    title: 'Alerts',
    fold: 'open',
    layers: [
      this.firePermitsLayer,
      this.fireAlertsLayer,
      this.fireBurntAreaLayer
    ]
  } as any);

  clippedLayerGroup: LayerGroup = new LayerGroup({
    title: 'Reference Layers',
    fold: 'open'
  } as any);

  clippedLayerMap: Map<string, VectorLayer<any>> = new Map();

  versatilityVectorSource_Property: VectorSource<any> = new VectorSource();
  versatilityVectorSource_Paddock: VectorSource<any> = new VectorSource();

  private _createPropertyToolActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);


  clippedLayers: Collection<any> = new Collection();

  paddocks: Array<Feature<any>> = [];

  eskTools: Array<any> = [];

  user: User;
  activeProperty: PropertyDexie;
  activePropertyFeature: Feature<any>;


  paddockFeatureMap: Map<string, Feature<any>> = new Map();
  fenceFeatureMap: Map<string, Feature<any>> = new Map();
  hasActiveProperty: boolean = false;
  sidebar: SidebarEsk;

  enterprises: Array<Enterprise> = [];




  constructor(
    public baseLayerService: ClippedLayerService,
    public propertyService: PropertyService,
    private auth: AuthService,
    private paddockService: PaddockService,
    private eventBusService: EventBusService,
    private fenceService: FenceService,
    private popperHelpService: PopperHelpService,
    private spatialService: SpatialService,
    private zone: NgZone,
    private dexieService: DexieDatabaseService,
    private enterpriseReportService: EnterpriseReportService,
    private enterpriseService: EnterpriseService,
    private topexReportService: TopexReportService,
    private forestDescriptionReportService: ForestClassReportService
  ) {
    this.propertyLayer.set('title', 'Boundary');
    this.paddockLayer.set('title', 'Paddocks');
    this.fenceLayer.set('title', 'Fences');
    this.topexLayerProperty.set('title', 'Topex Property')
    this.topexLayerPaddock.set('title', 'Topex Paddock')
    this.forestClassesLayerProperty.set('title', 'Forest Classes Property')
    this.forestClassesLayerPaddock.set('title', 'Forest Classes Paddock')
    this.firePermitsLayer.set('title', 'Fire Permits');
    this.firePermitsLayer.set(
      'iconStyle',
      firePermitStyleIcon.getImage(2).toDataURL()
    );
    this.fireAlertsLayer.set('title', 'Current Fire Alerts (with details)');
    this.fireAlertsLayer.set(
      'iconStyle',
      fireAlertStyleIcon.getImage(2).toDataURL()
    );
    this.fireBurntAreaLayer.set(
      'title',
      'Current Fire Burnt Area Polygons (with details)'
    );

    this.alertsGroup.setVisible(false);

    LIST_MAP_DEFS.forEach((def) => {
      let lyr = new VectorLayer({
        source: new VectorSource(),
        style: styleMap.get(def.name)
      });

      lyr.set('title', def.name);

      this.clippedLayerMap.set(def.name, lyr);
      this.clippedLayers.push(lyr);
    });

    this.clippedLayerGroup.setLayers(this.clippedLayers);

    this.fenceService.fences.subscribe((fences) => {
      this.loadFences(fences);
    });

    this.basemapGroup = new LayerGroup({
      layers: [],
      title: 'Background Maps',
      fold: 'open'
    } as any);



    VERSATILITY_NAMES.forEach((crop) => {
      let layer = new VectorLayer({
        source: this.versatilityVectorSource_Property,
        style: (feature, resolution) => {
          return getVersatilityStyle(feature, resolution, crop.name);
        },
        visible: false
      });

      layer.set('title', crop.name);
      layer.set('label', crop.label);

      this.enterpriseSuitibilityGroupProperty.getLayers().push(layer);
    });

    VERSATILITY_NAMES.forEach((crop) => {
      let layer = new VectorLayer({
        source: this.versatilityVectorSource_Paddock,
        style: (feature, resolution) => {
          return getVersatilityStyle(feature, resolution, crop.name);
        },
        visible: false
      });

      layer.set('title', crop.name);
      layer.set('label', crop.label);

      this.enterpriseSuitibilityGroupPaddock.getLayers().push(layer);
    });

    this.zone.runOutsideAngular(() => {
      this.map = new ExportableMap({
        layers: [
          this.basemapGroup,

          this.alertsGroup,
          this.clippedLayerGroup,
          this.propertyLayerGroup,
          this.enterpriseSuitibilityGroupPaddock,
          this.enterpriseSuitibilityGroupProperty,
    new LayerGroup({layers: [          this.topexLayerProperty,
      this.topexLayerPaddock,this.forestClassesLayerPaddock,this.forestClassesLayerProperty,       this.propertiesLayer]}),

        ],
        view: new View({
          center: [494095, 5343594],
          projection: this.proj,
          zoom: 5,
          maxZoom: 23
        }),
        pixelRatio: window.devicePixelRatio,
        maxTilesLoading: 256,
        controls: [
          new Attribution(),
        ]
      });

      this.enterpriseSuitibilityGroupProperty.setVisible(false);
      this.enterpriseSuitibilityGroupPaddock.setVisible(false);

      this.enterpriseSuitibilityGroupPaddock.on('change:visible', (event) => {
        if (this.enterpriseSuitibilityGroupPaddock.getVisible()) {
          this.enterpriseSuitibilityGroupProperty.setVisible(false);
        }
      });

      this.enterpriseSuitibilityGroupProperty.on('change:visible', (event) => {
        if (this.enterpriseSuitibilityGroupProperty.getVisible()) {
          this.enterpriseSuitibilityGroupPaddock.setVisible(false);
        }
      });
    });

    this.addTfsLayers();


    this.propertyService.activeProperty
      .pipe(
        tap((property) => {
          if (property == null) {
            this.clearLayers();
            this.propertyLayerGroup.getLayers().forEach((layer:VectorLayer<any>) => {
              layer.getSource().clear();
            })
            this.propertyLayer.getSource().clear();
            this.hasActiveProperty = false;
            console.log(this.propertyLayer.getSource().getFeatures())
            return;
          }
          this.clearLayers();
          this.activeProperty = property;
          this.activePropertyFeature = this.addProperty(property.wkt);
          this.zoomToActiveProperty();
          this.hasActiveProperty = true;
        }),
        switchMap(
          (
            property
          ):
            | Observable<Array<ClippedPropertyLayer>>
            | Observable<Array<any>> => {
            return property == null
              ? of([])
              : this.baseLayerService.getClippedLayers_Property(
                  property.propertyID
                );
          }
        )
      )
      .subscribe(async (clippedLayers) => {
        this.loadClippedPropertyLayers(clippedLayers);
      });

      this.propertyService.properties.subscribe((properties: PropertyDexie[]) => {
      this.propertiesLayer.getSource().clear();
      properties.forEach(property => {
        const _feature = ConvertWktToFeature(property.wkt);
        _feature.set('propertyID', property.propertyID);
        _feature.set('name', property.name);
        this.propertiesVectorSource.addFeature(_feature);
      })
      });

    this.paddockService.paddocks.subscribe(async (paddocks) => {
      this.paddockLayer.getSource().clear();
      this.versatilityVectorSource_Paddock.clear();
      this.paddockFeatureMap.clear();

      if (paddocks == null) {
        return;
      }

      for (let index = 0; index < paddocks.length; index++) {
        const paddock = paddocks[index];

        let feature = ConvertWktToFeature(paddock.wkt);

        for (const property in paddock) {
          if (
            (property != 'geom' && property != 'wkt') ||
            typeof paddock[property] !== 'object'
          ) {
            feature.set(property, paddock[property]);
          }
        }

        this.paddockFeatureMap.set(paddock.paddockID, feature);
        this.paddockLayer.getSource().addFeature(feature);
      }
    });

    this.auth.user.subscribe((user) => {
      this.user = user;
    });

    this.enterpriseService.enterprises.subscribe(data => this.enterprises = data);

  }

  get createPropertyToolActive(): Observable<boolean> {
    return this._createPropertyToolActive$.pipe((tap((active) => {

      if(!active)
      {
        this.propertiesLayer.setVisible(false);
        return;
      }

      this.propertiesLayer.setVisible(true);

    })));
  }

  setCreatePropertyToolActive(value: boolean) {
    this._createPropertyToolActive$.next(value);
  }

  async downloadClippedLayers(paddocks: Array<PaddockDexie>)
  {
    this.ProcessingState.next(ProcessState.Clipping_Layers);

    await this.processProperty(ConvertWktToFeature(this.activeProperty.wkt),this.activeProperty);

    let propertyClippedLayers = await this.baseLayerService.getClippedLayers_Property_Async(this.activeProperty.propertyID)

    this.loadClippedPropertyLayers(propertyClippedLayers);

    for (let index = 0; index < paddocks.length; index++) {
      const paddock = paddocks[index];
      const feature = this.paddockFeatureMap.get(paddock.paddockID);
      await this.getPaddockVersatilityLayer(feature, paddock);
      await this.getPaddockTopexLayer(feature, paddock);

      let clippedLayers = await this.baseLayerService.getClippedLayers_Paddock(
        paddock.paddockID
      );
      this.loadClippedPaddockLayers(clippedLayers);
    }

    this.ProcessingState.next(ProcessState.Not_Processing);

  }

  togglePropertyStyle(edit: boolean) {
    if (edit) {
      this.propertyLayer.setStyle(styleMap.get('editProperty'));
    } else {
      this.propertyLayer.setStyle(styleMap.get('Property'));
    }
  }

  async loadSelectedPaddocks(paddocks: Array<PaddockDexie>) {
    this.versatilityVectorSource_Paddock.clear();
    this.topexVectorSource_Paddock.clear();
    this.forestClassesVectorSource_Paddock.clear();

    for (let index = 0; index < paddocks.length; index++) {
      const paddock = paddocks[index];
      let clippedLayers = await this.baseLayerService.getClippedLayers_Paddock(
        paddock.paddockID
      );
      this.loadClippedPaddockLayers(clippedLayers);
    }

    this.map.render();
  }

  /**
   * Sets the view to the accordant zoom and center.
   *
   * @param zoom Zoom.
   * @param center Center in long/lat.
   */
  setView(zoom: number, center: [number, number]) {
    //this.map.getView().setZoom(10);
  }

  zoomToPaddock(paddock: PaddockDexie) {
    this.paddockFeatureMap.has(paddock.paddockID);
    {
      let feature = this.paddockFeatureMap.get(paddock.paddockID);
      this.zoomToFeature(feature);
    }
  }

  async loadFences(fences: FenceDexie[]) {
    this.fenceFeatureMap.clear();
    this.fenceLayer.getSource().clear();

    fences.forEach((fence) => {
      let feature = ConvertWktToFeature(fence.wkt);
      feature.set('existing', fence.existing);
      feature.set('wallabyProof', fence.wallabyProof);
      feature.set('shape_length', fence.length);
      feature.set('FEAT_NAME', fence.name);
      this.fenceFeatureMap.set(fence.fenceID, feature);
      this.fenceLayer.getSource().addFeature(feature);
    });
  }

  setActiveProperty(property: PropertyDexie) {
    this.propertyService.activeProperty.next(property);
  }

  zoomToFence(fence: FenceDexie) {
    this.fenceFeatureMap.has(fence.fenceID);
    {
      let feature = this.fenceFeatureMap.get(fence.fenceID);
      this.zoomToFeature(feature);
    }
  }

  /**
   * Updates target and size of the map.
   *
   * @param target HTML container.
   */
  updateSize(target = 'map') {
    this.map.setTarget(target);
    this.map.updateSize();
  }

  addDefaultControls(target = 'ol-toolbar') {
    //this.map.addControl(EskZoomTool(target));
  }

  addDefaultInteractions() {
    this.map.addInteraction(new PinchZoom());
    let _defaultInteractions = defaultInteractions();
    _defaultInteractions.forEach((interaction) => {
      this.map.addInteraction(interaction);
    });
  }

  addSideBar(target = 'sidebar_div') {
    this.zone.runOutsideAngular(() => {
      this.sidebar = new Sidebar({ target: target, position: 'right' });
      this.sidebar.setOpenCallback(this.sidebarToggleOpen);
      this.sidebar.setCloseCallback(this.sidebarToggleClose);
      this.map.addControl(this.sidebar);
    });

    return this.sidebar;
  }

  async CreatePaddock(feature: Feature<any>) {
    let paddock = new PaddockDexie();

    let ClippedPaddock = await Intersect(feature, this.activePropertyFeature);

    let paddockFeatures = this.paddockLayer.getSource().getFeatures();

    for (let index = 0; index < paddockFeatures.length; index++) {
      const PADDOCK_FEATURE = paddockFeatures[index];

      ClippedPaddock = await Difference(ClippedPaddock, PADDOCK_FEATURE);
    }

    feature.setGeometry(ClippedPaddock.getGeometry());
    let area = CalculateArea(feature);

    paddock.name = `Paddock ${this.paddockFeatureMap.size + 1}`;
    paddock.propertyID = this.activeProperty.propertyID;
    paddock.wkt = ConvertFeatureToWKT(feature);
    paddock.area = Math.round((area + Number.EPSILON) * 100) / 100;

    return paddock;
    // this.eventBusService.emit(new EventData(TreeMapperCustomEvents.PaddockFeatureCreated, paddock));
  }

  async getIntersectedPaddocks(feature: Feature<any>): Promise<Array<string>> {
    let intersectedPaddockIds = [];

    this.paddockFeatureMap.forEach((value, key, map) => {
      let intersects = Intersects(feature, value);
      if (intersects) {
        intersectedPaddockIds.push(key);
      }
    });

    return intersectedPaddockIds;
  }

  async createFence(fence: FenceDexie) {
    await this.fenceService.save(fence);
  }

  zoomToActiveProperty()
  {
    this.zoomToFeature(this.activePropertyFeature);
  }

  async updateFence(fence: FenceDexie) {
    this.fenceService.update(fence);
  }

  async deleteFence(fence: FenceDexie) {
    this.fenceService.remove(fence);
  }

  async createPaddock(paddock: PaddockDexie) {
    this.ProcessingState.next(ProcessState.Creating_Paddock);
    let feature = ConvertWktToFeature(paddock.wkt);
    await this.paddockService.save(paddock);
    this.ProcessingState.next(ProcessState.Clipping_Layers);
    this.ProgressDetail.next('Suitability Index');
    await this.getPaddockVersatilityLayer(feature, paddock);
    await this.getPaddockTopexLayer(feature, paddock);
    await this.getPaddockForestDescriptionLayer(feature, paddock);

    let _clippedLayers = await this.baseLayerService.getClippedLayers_Paddock(
      paddock.paddockID
    );

    let topexFeaturesGeoJson = _clippedLayers.find(layer => layer.layerName == 'Topex').geom;
    let versatilityFeaturesGeoJson = _clippedLayers.find(layer => layer.layerName == 'Versatility').geom;

    let forestClassesGeoJson = _clippedLayers.find(layer => layer.layerName == 'ForestClasses').geom;

    this.loadClippedPaddockLayers(_clippedLayers);

    this.ProcessingState.next(ProcessState.Generating_Report);

    let topexReport = await this.generateTopexReport(topexFeaturesGeoJson);
    topexReport.paddockID = paddock.paddockID;

    let enterpriseReport = await this.generateSuitabilityReport(versatilityFeaturesGeoJson);

    enterpriseReport.paddockID = paddock.paddockID;

    let forestClassReport = await this.generateForestClassesReport(forestClassesGeoJson);
    forestClassReport.paddockID = paddock.paddockID;


    await this.forestDescriptionReportService.save(forestClassReport);


    await this.enterpriseReportService.save(enterpriseReport);

    await this.topexReportService.save(topexReport);

    await this.paddockService.update(paddock);
    this.ProcessingState.next(ProcessState.Not_Processing);
  }

  async generateSuitabilityReport(
   features: string
  ): Promise<EnterpriseReport> {
    return new Promise(async (resolve, reject) => {
      let report = new EnterpriseReport();

      let reportData = await this.generateSuitabilityReportData(features);

      reportData.sort(function (a, b) {
        return b.cropRankValue - a.cropRankValue;
      });

      let ranks = rankCrops(reportData);
      reportData.forEach((data, index) => {
        data.rank = ranks[index];
      });

      report.data = reportData;

      resolve(report);
    });
  }

  async generateSuitabilityReportData(
   features: string
  ): Promise<Array<EnterpriseReportRow>> {
    return new Promise(async (resolve, reject) => {
      if (typeof Worker !== 'undefined') {
        // Create a new
        const worker = new Worker(
          new URL('src/app/workers/reportGenerator.worker', import.meta.url)
        );
        worker.onmessage = ({ data }) => {
          resolve(data);
          worker.terminate();
        };

        worker.postMessage({ features: features, enterprises: this.enterprises });
      } else {
        reject('WORKER_NOT_SUPPORTED');
      }
    });
  }

  async generateTopexReport(features: String): Promise<TopexReport>
  {
    return new Promise(async (resolve, reject) => {

      if (typeof Worker !== 'undefined') {
        // Create a new
        const worker = new Worker(
          new URL('src/app/workers/topexReportGenerator.worker', import.meta.url)
        );
        worker.onmessage = ({ data }) => {
          resolve(data);
          worker.terminate();
        };

        worker.postMessage({ features: features});
      } else {
        reject('WORKER_NOT_SUPPORTED');
      }
    });
  }


  async generateForestClassesReport(features: String): Promise<ForestDescriptionReport>
  {
    return new Promise(async (resolve, reject) => {

      if (typeof Worker !== 'undefined') {
        // Create a new
        const worker = new Worker(
          new URL('src/app/workers/forestClassesReportGenerator.worker', import.meta.url)
        );
        worker.onmessage = ({ data }) => {
          resolve(data);
          worker.terminate();
        };

        worker.postMessage({ features: features});
      } else {
        reject('WORKER_NOT_SUPPORTED');
      }
    });
  }

  async showCrop(cropName: string, type: PaddockDexie | PropertyDexie) {
    if (type instanceof PaddockDexie) {
      this.enterpriseSuitibilityGroupPaddock.setVisible(true);

      let clippedLayers = await this.baseLayerService.getClippedLayers_Paddock(
        type.paddockID
      );
      this.versatilityVectorSource_Paddock.clear();
      this.loadClippedPaddockLayers(clippedLayers);

      this.zoomToPaddock(type);
      this.enterpriseSuitibilityGroupProperty
        .getLayers()
        .forEach(function (layer) {
          layer.setVisible(false);
        });
      this.enterpriseSuitibilityGroupPaddock
        .getLayers()
        .forEach(function (layer) {
          if (
            layer.get('title') != undefined &&
            layer.get('title') === cropName
          ) {
            layer.setVisible(true);
          }
        });
    } else if (type instanceof PropertyDexie) {
      this.enterpriseSuitibilityGroupProperty.setVisible(true);

      this.zoomToFeature(this.activePropertyFeature);
      this.enterpriseSuitibilityGroupPaddock.setVisible(false);
      this.enterpriseSuitibilityGroupProperty
        .getLayers()
        .forEach(function (layer) {
          if (
            layer.get('title') != undefined &&
            layer.get('title') === cropName
          ) {
            layer.setVisible(true);
          }
        });
    }
  }



  async getPaddockVersatilityLayer(feature: Feature<any>, paddock: PaddockDexie) {
    let _features = this.versatilityVectorSource_Property.getFeatures();

    let featureArray: Array<Feature<any>> = [];

    for (let i = 0; i < _features.length; i++) {
      let _intersect = await Intersect(_features[i], feature);

      if (_intersect != null) {
        _intersect = CloneProperties(_features[i], _intersect);

        featureArray.push(_intersect);
      }
    }

    let _clippedLayer = new ClippedLayer_Paddock();
    _clippedLayer.paddockID = paddock.paddockID;
    _clippedLayer.layerName = 'Versatility';
    _clippedLayer.geom = ConvertFeatureCollectionToGeoJson(featureArray);

    await this.baseLayerService.addClippedLayers_Paddock(_clippedLayer);
  }

  async getPaddockTopexLayer(feature: Feature<any>, paddock: PaddockDexie) {
    let _features = this.topexVectorSource_Property.getFeatures();

    let featureArray: Array<Feature<any>> = [];

    for (let i = 0; i < _features.length; i++) {
      let _intersect = await Intersect(_features[i], feature);

      if (_intersect != null) {
        _intersect = CloneProperties(_features[i], _intersect);

        featureArray.push(_intersect);
      }
    }

    let _clippedLayer = new ClippedLayer_Paddock();
    _clippedLayer.paddockID = paddock.paddockID;
    _clippedLayer.layerName = 'Topex';
    _clippedLayer.geom = ConvertFeatureCollectionToGeoJson(featureArray);

    await this.baseLayerService.addClippedLayers_Paddock(_clippedLayer);
  }

    async getPaddockForestDescriptionLayer(feature: Feature<any>, paddock: PaddockDexie) {
    let _features = this.forestClassesVectorSource_Property.getFeatures();

    let featureArray: Array<Feature<any>> = [];

    for (let i = 0; i < _features.length; i++) {
      let _intersect = await Intersect(_features[i], feature);

      if (_intersect != null) {
        _intersect = CloneProperties(_features[i], _intersect);

        featureArray.push(_intersect);
      }
    }

    let _clippedLayer = new ClippedLayer_Paddock();
    _clippedLayer.paddockID = paddock.paddockID;
    _clippedLayer.layerName = 'ForestClasses';
    _clippedLayer.geom = ConvertFeatureCollectionToGeoJson(featureArray);

    await this.baseLayerService.addClippedLayers_Paddock(_clippedLayer);
  }

  unionFeatures(features: Array<Feature<any>>, options?) {
    let feature = Union(features);

    feature = Buffer(feature, { amount: options.bufferAmount });

    return feature;
  }

  convertToGeoJSON(feature: Feature<any>) {
    return ConvertFeatureToGeoJson(feature);
  }

  removeControls() {
    let interactions = this.map.getInteractions();

    let layers = this.map.getAllLayers();
    layers.forEach((layer) => {
      this.map.removeLayer(layer);
    });

    interactions.forEach((interaction) => {
      this.map.removeInteraction(interaction);
    });

    let controls = this.map.getControls();

    controls.forEach((control) => {
      this.map.removeControl(control);
    });

    this.eskTools = [];
  }

  addLayerSwitcher(target = 'layers') {
    // this.map.addControl(layerSwitcher);
  }

  sidebarToggleOpen(openID: string, closeID: string) {}

  sidebarToggleClose() {}

  async GetVersatilityLayer(feature: Feature<any>, property: PropertyDexie) {
    let features = await this.spatialService.getSuitabilityLayer(feature);

    let _clippedLayer = new ClippedPropertyLayer();
    _clippedLayer.propertyID = property.propertyID;
    _clippedLayer.layerName = 'Versatility';
    _clippedLayer.wkt = ConvertFeatureCollectionToGeoJson(features);

    return await this.baseLayerService.addClippedLayers_Property(_clippedLayer);
  }

  async GetTopexLayer(feature: Feature<any>, property: PropertyDexie) {
    let features = await this.spatialService.getTopexLayer(feature);

    let _clippedLayer = new ClippedPropertyLayer();
    _clippedLayer.propertyID = property.propertyID;
    _clippedLayer.layerName = 'Topex';
    _clippedLayer.wkt = ConvertFeatureCollectionToGeoJson(features);

    return await this.baseLayerService.addClippedLayers_Property(_clippedLayer);
  }


  async GetForestClassesLayer(feature: Feature<any>, property: PropertyDexie) {
    let features = await this.spatialService.getForestClassesLayer(feature);

    let _clippedLayer = new ClippedPropertyLayer();
    _clippedLayer.propertyID = property.propertyID;
    _clippedLayer.layerName = 'ForestClasses';
    _clippedLayer.wkt = ConvertFeatureCollectionToGeoJson(features);

    return await this.baseLayerService.addClippedLayers_Property(_clippedLayer);
  }



  async addTfsLayers() {
    let permitsResponse = await fetch(
      'https://tfsfeed.eskmapping.com.au/geoserver/tfsfeed/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=tfsfeed%3Apermits_detailed&outputFormat=application%2Fjson&srsname=EPSG:28355'
    );
    let permitsData = await permitsResponse.json();

    let permitFeatures = ConvertGeoJsonToFeatureCollection(permitsData);

    this.firePermitsLayer.getSource().addFeatures(permitFeatures);

    let fireBurntResponse = await fetch(
      'https://tfsfeed.eskmapping.com.au/geoserver/tfsfeed/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=tfsfeed%3Aburnt_area_detail&outputFormat=application%2Fjson&srsname=EPSG:28355'
    );
    let fireBurntData = await fireBurntResponse.json();

    let fireBurntFeatures = ConvertGeoJsonToFeatureCollection(fireBurntData);

    this.fireBurntAreaLayer.getSource().addFeatures(fireBurntFeatures);

    let fireAlertsResponse = await fetch(
      'https://tfsfeed.eskmapping.com.au/geoserver/tfsfeed/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=tfsfeed%3Aalert_summary_detailed&outputFormat=application%2Fjson&srsname=EPSG:28355'
    );
    let fireAlertsData = await fireAlertsResponse.json();

    let fireAlertsFeatures = ConvertGeoJsonToFeatureCollection(fireAlertsData);

    this.fireAlertsLayer.getSource().addFeatures(fireAlertsFeatures);
  }

  addCadastreLayer() {
    const serviceUrl =
      'https://services.thelist.tas.gov.au/arcgis/rest/services/Public/CadastreAndAdministrative/MapServer/';

    const layer = '38';

    const esrijsonFormat = new EsriJSON();

    const vectorSource = new VectorSource({
      loader: async (extent, resolution, projection, success, failure) => {
        let encodedComponent = encodeURIComponent(
          '{"xmin":' +
            extent[0] +
            ',"ymin":' +
            extent[1] +
            ',"xmax":' +
            extent[2] +
            ',"ymax":' +
            extent[3] +
            ',"spatialReference":{"wkid":28355}}'
        );

        const url = `${serviceUrl + layer}/query/?where=PID${encodeURIComponent(
          '>0'
        )}+AND+MEAS_AREA${encodeURIComponent(
          '>1000'
        )}+OR+COMP_AREA${encodeURIComponent(
          '>1000'
        )}+AND+PID${encodeURIComponent(
          '>0'
        )}&f=json&returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=${encodedComponent}&geometryType=esriGeometryEnvelope&inSR=28355&outFields=*&outSR=28355`;

        try {
          let response = await fetch(url);

          if (response.ok) {
            let featuresJson = await response.json();

            for (const property in featuresJson.fieldAliases) {
              if (this.propAliasMap.has(property)) continue;

              this.propAliasMap.set(
                property,
                featuresJson.fieldAliases[property]
              );
            }

            const features = esrijsonFormat.readFeatures(featuresJson, {
              featureProjection: projection
            });
            if (features.length > 0) {
              features.forEach((element) => {
                let props = element.getProperties();
                element.setId(props.OBJECTID);
              });

              vectorSource.addFeatures(features);
            }

            success(features);
          }
        } catch (error) {
          failure();
        }
      },
      strategy: tileStrategy(
        createXYZ({
          tileSize: 1028
        })
      )
    });

    this.cadasterLayer = new VectorImageLayer({
      imageRatio: 2,
      source: vectorSource,
      style: (feature, resolution) => {
        return getCadasterStyle(feature, resolution, '');
      },
      minZoom: 9
    });

    this.cadasterLayer.set('title', 'Cadastre Land Parcles');
    this.cadasterLayer.setVisible(false);

    this.map.addLayer(this.cadasterLayer);

    this.rebuildLegend();
  }

  removeCadastreLayer() {
    this.map.removeLayer(this.cadasterLayer);
  }

  async createProperty(property: PropertyDexie, features: Array<Feature<any>>) {
    this.ProcessingState.next(ProcessState.Creating_Property);

    let feature = this.unionFeatures(features, {
      bufferAmount: 0
    });

    property.wkt = ConvertFeatureToWKT(feature);
    property.userID = this.user.Id;
    property.address = '';
    property.area = CalculateArea(feature);
    property.pid = property.pid.replace(',', '');


    await this.propertyService.add(property);

    await this.processProperty(feature, property);

    //this.activeProperty = property;
    //this.activePropertyFeature = this.addProperty(property.wkt);
    this.baseLayerService
      .getClippedLayers_Property(property.propertyID)
      .subscribe(async (clippedLayers) => {

        let topexFeaturesGeoJson = clippedLayers.find(layer => layer.layerName == 'Topex').wkt;
        let SuitabilityFeaturesGeoJson = clippedLayers.find(layer => layer.layerName == 'Versatility').wkt;

        let forestClassesGeoJson = clippedLayers.find(layer => layer.layerName == 'ForestClasses').wkt;

       // this.loadClippedPropertyLayers(clippedLayers);
        this.ProcessingState.next(ProcessState.Generating_Report);

        let enterpriseReport = await this.generateSuitabilityReport(SuitabilityFeaturesGeoJson);
        enterpriseReport.propertyID = property.propertyID;


        let topexReport = await this.generateTopexReport(topexFeaturesGeoJson);
        topexReport.propertyID = property.propertyID;


        let forestClassReport = await this.generateForestClassesReport(forestClassesGeoJson);
        forestClassReport.propertyID = property.propertyID;


        await this.topexReportService.save(topexReport);
        await this.forestDescriptionReportService.save(forestClassReport);
        await this.enterpriseReportService.save(enterpriseReport);

        this.ProcessingState.next(ProcessState.Not_Processing);

        this.propertyService.activeProperty.next(property);
      })
  }

  async updateProperty(property: PropertyDexie) {
    this.propertyService.update(property);
  }

  async deleteProperty(property: PropertyDexie) {
    await this.propertyService.remove(property);
  }

  /**
   * It takes a feature, loops through a list of map definitions, fetches the data from the map
   * definition, intersects the fetched data with the feature, and then saves the intersected data to a
   * database.
   *
   * The problem is that the function is taking a long time to run.
   *
   * @param feature - Feature<any> - The feature that is being processed
   * @param {Property} property - This is the property that the user has selected.
   */
  private async processProperty(
    feature: Feature<any>,
    property: PropertyDexie
  ) {
    this.ProcessingState.next(ProcessState.Clipping_Layers);

    let extent = feature.getGeometry().getExtent();

    let clippedLayersToBeSaved = [];

    for (let index = 0; index < LIST_MAP_DEFS.length; index++) {
      const MAP_DEF = LIST_MAP_DEFS[index];

      this.ProgressDetail.next(MAP_DEF.name);

      const API_URL = `${MAP_DEF.url}${
        MAP_DEF.id
      }/query/?f=json&returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=${encodeURIComponent(
        '{"xmin":' +
          extent[0] +
          ',"ymin":' +
          extent[1] +
          ',"xmax":' +
          extent[2] +
          ',"ymax":' +
          extent[3] +
          ',"spatialReference":{"wkid":28355}}'
      )}&geometryType=esriGeometryEnvelope&inSR=28355&outFields=*&outSR=28355`;

      let response = await fetch(API_URL);

      if (!response.ok) {
        response = await fetch(API_URL);
      }

      let features = await response.json();

      this.updateAttributeMap(features);

      const _features = this.esrijsonFormat.readFeatures(features, {
        featureProjection: this.map.getView().getProjection()
      });

      if (_features == null || _features.length == 0) {
        continue;
      }

      let featureArray: Array<Feature<any>> = [];

      for (let i = 0; i < _features.length; i++) {
        let _intersect = await Intersect(_features[i], feature);

        if (_intersect != null) {
          try {
            _intersect = CloneProperties(_features[i], _intersect);

            featureArray.push(_intersect);
          } catch (error) {
            // Handle the error here, for example by logging it
            console.error(error);
          }
        }
      }

      let _clippedLayer = new ClippedPropertyLayer();
      _clippedLayer.propertyID = property.propertyID;
      _clippedLayer.layerName = MAP_DEF.name;
      _clippedLayer.wkt = ConvertFeatureCollectionToGeoJson(featureArray);

      clippedLayersToBeSaved.push(_clippedLayer);
    }

    await this.baseLayerService.addClippedLayers_Property(
      clippedLayersToBeSaved
    );

    this.ProgressDetail.next('Versatility Index');
    await this.GetVersatilityLayer(feature, property);

    this.ProgressDetail.next('Topex Scores');
    await this.GetTopexLayer(feature, property);

    this.ProgressDetail.next('Forest Classes');
    await this.GetForestClassesLayer(feature, property);


  }

  /**
   * "If the property is not in the map, add it to the map."
   *
   * The function is called in the following code:
   * @param feature - The feature object that contains the attributes.
   */
  private updateAttributeMap(feature) {
    for (const property in feature.fieldAliases) {
      if (this.propAliasMap.has(property)) continue;

      this.propAliasMap.set(property, feature.fieldAliases[property]);
    }
  }

  /**
   * It loops through all the layers in the clippedLayerGroup and clears the source of each layer
   */
  clearLayers() {
    this.clippedLayerGroup.getLayersArray().forEach((lyr) => {
      lyr.getSource().clear();
    });

    this.versatilityVectorSource_Paddock.clear();
    this.versatilityVectorSource_Property.clear();

    this.topexVectorSource_Paddock.clear();
    this.topexVectorSource_Property.clear();

    this.forestClassesVectorSource_Paddock.clear();
    this.forestClassesVectorSource_Property.clear();
  }

  /**
   * It takes an array of clipped layers, converts each layer's geometry to a feature collection, and
   * adds the feature collection to the layer's source.
   *
   * The function is called from the following function: baseLayers.subscribe();
   * @param layers - Array<ClippedLayer>
   */
  loadClippedPropertyLayers(layers: Array<ClippedPropertyLayer>) {
    layers.forEach((layer) => {

      let source;
      if (layer.layerName == 'Versatility') {
        let _feature = ConvertGeoJsonToFeatureCollection(layer.wkt);
        source = this.versatilityVectorSource_Property;
        source.addFeatures(_feature);
      }
      else if(layer.layerName == 'Topex')
      {
        let _feature = ConvertGeoJsonToFeatureCollection(layer.wkt);
        source = this.topexVectorSource_Property;
        source.addFeatures(_feature);

      }

      else if(layer.layerName == 'ForestClasses')
      {
        let _feature = ConvertGeoJsonToFeatureCollection(layer.wkt);
        source = this.forestClassesVectorSource_Property;
        source.addFeatures(_feature);

      }

      else {
        let lyr = this.clippedLayerMap.get(layer.layerName);
        let _feature = ConvertGeoJsonToFeatureCollection(layer.wkt);
        source = lyr.getSource();
        source.addFeatures(_feature);
      }


    });
  }

  loadClippedPaddockLayers(layers: Array<any>) {
    layers.forEach((layer) => {
      let _feature = ConvertGeoJsonToFeatureCollection(layer.geom);
      let source;
      if (layer.layerName == 'Versatility') {
        source = this.versatilityVectorSource_Paddock;
      }
      else if(layer.layerName == 'Topex')
      {
        source = this.topexVectorSource_Paddock;
      }
      else if(layer.layerName == 'ForestClasses')
      {
        source = this.forestClassesVectorSource_Paddock;
      }

      source.addFeatures(_feature);
    });
  }

  /**
   * It takes a GeoJSON string or a Feature object and adds it to the map.
   * @param {string | Feature<any>} property - string | Feature<any>
   * @returns The feature that was added to the map.
   */
  addProperty(property: string | Feature<any>) {
    if (property == null) {
      return null;
    }

    let feature: Feature<any> = null;

    if (typeof property === 'string') {
      feature = ConvertWktToFeature(property);
    } else {
      feature = property;
    }

    this.propertyLayer.getSource().clear();

    this.propertyLayer.getSource().addFeature(feature);

    return feature;
  }

  /**
   * When the user clicks on a feature, zoom to the feature's geometry.
   * @param feature - Feature<any>
   */
  zoomToFeature(feature: Feature<any>) {
    if (feature == null) {
      return;
    }
    let polygon = feature.getGeometry();

    this.map.getView().fit(polygon, { padding: [150, 450, 150, 0] });
  }

  setupBaseMaps() {
    if (this.basemapGroup.values_.layers.array_.length == 0)
    {
      populateLayerGroup(base_map_defs, this.basemapGroup, this.rebuildLegend);

    }

    else return;
    //populateLayerGroup(defaultLayers, this.testGroup, this.rebuildLegend);
  }

  rebuildLegend() {
    // this.something.renderPanel();
    //this.layerSwitcher.renderPanel();
  }
}
