import { Injectable } from '@angular/core';
import { liveQuery } from 'dexie';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Repository } from 'typeorm';
import { User } from '../models/user.model';
import { AuthService } from './auth.service';
import { DexieDatabaseService } from './dexieIndexDB.service';
import { PropertyService } from './property.service';

@Injectable({
  providedIn: 'root'
})
export class DropDownService {

  public landuseTypes = liveQuery(() => this.database.landUseTypes.toArray())
  public enterpriseTypes = liveQuery(() => this.database.enterpriseTypes.toArray())


  private userSub: Subscription;

  private databaseSub: Subscription;

  user: User


  constructor(private auth: AuthService, private database:DexieDatabaseService, private propertyService: PropertyService) {

    this.userSub = auth.user.subscribe(user => {
      this.user = user;
    });


   }

}
