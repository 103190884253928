<a (click)="close($event)" id="popup-closer" class="ol-popup-closer"></a>
<div id="popup-content">
  <table>
    <thead>
      <th>Attribute</th>
      <th>Value</th>
    </thead>
    <tbody>
      <tr *ngFor="let row of attributes; index as i">
        <td>{{row.attribute}}</td>
        <td>{{row.value}}</td>
      </tr>
    </tbody>
  </table>
</div>
