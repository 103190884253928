<table class="uk-table uk-table-hover uk-table-divider uk-table uk-table-justify">
    <tbody>
        <tr>
            <td>Total Actual Volume of Logs Harvested (tonne)</td>
            <td>{{totalActualVolumeLogsHarvestedPerYear | number}}</td>
        </tr>
        <tr>
            <td>Plantation and Planting Costs ($/tonne)</td>
            <td>{{weedingCosts}}</td>
        </tr>
        <tr>
            <td>Monetary Value</td>
            <td>{{calculateMonetaryValue()| currency}}</td>
        </tr>
        <tr>
            <td>Discount Rate</td>
            <td>{{discountRate}}</td>
        </tr>
        <tr>
            <td>Harvest Lifespan</td>
            <td>{{harvestLifespan}}</td>
        </tr>
        <tr>
            <td>PV(C)</td>
            <td>{{calculatePVC()| currency}}</td>
        </tr>
    </tbody>
</table>