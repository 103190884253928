<h2 mat-dialog-title>Forest Description Report</h2>
<mat-dialog-content class="mat-typography">

  <mat-tab-group>
    <mat-tab *ngFor="let report of reports; first as isFirst" >
      <ng-template mat-tab-label>
        <span class="header-spacer" *ngIf="isFirst"><i class="fa fa-house-damage"></i>{{report.target.name}}</span>
        <span class="header-spacer" *ngIf="!isFirst"><i class="fa fa-vector-square"></i>{{report.target.name}}</span>
    </ng-template>
      <section class="reporting-section" >
        <header class="reporting-header">
          <span>Reporting Area: {{report.target.area | round:1}}</span>
          <div class="reporting-header__actions">
            <button (click)="viewOnMap(report.target)" class="button button--blue">View on Map</button>
            <button (click)="export(report)" class="button button--blue">Export to PDF</button>

          </div>

          <table>
            <tr>
              <th>Estimate of Standing Total Recoverable Volume (TRV):</th>
              <td><div class="inline-block" *ngIf="report.report.estimatedTotalRecoverableVolume > 0">{{report.report.estimatedTotalRecoverableVolume | round:1 | numberFormat}}</div> <div class="inline-block" *ngIf="report.report.estimatedTotalRecoverableVolume <= 0">No native forest mapped for this property/paddock</div>
                <button class="button-style" (click)="showDisclaimer()"><i class="fa fa-exclamation-triangle"></i></button></td>
            </tr>
            <tr>
              <th>% Sawlog:</th>
              <td>{{report.report.sawLog | round:1 }}%</td>
            </tr>
            <tr>
              <th>% Industrial Grade Peeler Log:</th>
              <td>{{report.report.industrialGradePeeler | round:1}}%</td>
            </tr>
            <tr>
              <th>% Pulp Quality Log:</th>
              <td>{{report.report.pulpQualityLog | round:1}}%</td>
            </tr>
          </table>
        </header>
        <main>
          <table mat-table [dataSource]="report.report.forestClasses" class="mat-elevation-z8">
            <ng-container matColumnDef="fc_Code">
                <th mat-header-cell *matHeaderCellDef> Forest Class Code </th>
                <td mat-cell *matCellDef="let element"> {{element.fc_Code}} </td>
              </ng-container>

              <ng-container matColumnDef="fc_desc">
                <th mat-header-cell *matHeaderCellDef> Forest Class Description </th>
                <td mat-cell *matCellDef="let element"> {{element.fc_Desc}} </td>
              </ng-container>

              <ng-container matColumnDef="area">
                <th mat-header-cell *matHeaderCellDef> Area (ha) </th>
                <td mat-cell *matCellDef="let element"> {{element.area | round:1}} </td>
              </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

        </main>
      </section>
    </mat-tab>
  </mat-tab-group>




</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      close
    </button>
  </footer>
</mat-dialog-actions>
