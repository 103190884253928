<table class="uk-table uk-table-hover uk-table-divider uk-table uk-table-justify">
  <tbody>
    <tr>
      <td>Distance from Buyer (km)</td>
      <td>{{distanceFromBuyer}}</td>
    </tr>
    <tr>
      <td>Total Actual Volume of Logs Harvested (tonne)</td>
      <!-- <td>{{calculateActualVolumnOfLogsHarvested()| currency}}</td> -->
      <td>{{totalActualVolumeLogsHarvestedPerYear | number}}</td>
    </tr>
    <tr>
      <td>Transport Cost ($/tonne)</td>
      <td>{{transportationCost| currency}}</td>
    </tr>
    <tr>
      <td>Monetary Value</td>
      <td>{{calculateMonetaryValue()| currency}}</td>
    </tr>
    <tr>
      <td>Discount Rate</td>
      <td>{{discountRate}}</td>
    </tr>
    <tr>
      <td>Harvest Lifespan</td>
      <td>{{harvestLifespan}}</td>
    </tr>
    <tr>
      <td>PV(C)</td>
      <td>{{calculatePVC()| currency}}</td>
    </tr>
  </tbody>
</table>
