import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Host, HostBinding, OnInit, Output } from '@angular/core';
import { switchMap, of, map, Observable, scan, startWith, merge, mapTo, BehaviorSubject, Subject } from 'rxjs';
import { ClippedLayerService } from 'src/app/services/BaseLayerServices/clippedLayer.service';
import { PropertyService } from 'src/app/services/property.service';

@Component({
  selector: 'app-active-property',
  templateUrl: './active-property.component.html',
  styleUrls: ['./active-property.component.css'],
})

export class ActivePropertyComponent implements OnInit {

  @HostBinding('class.property-select')
  @Output() downloadLayers = new EventEmitter<boolean>();

  needsDownloading$: Observable<boolean>;
  downloadButtonClick$ = new Subject<void>();
  test: boolean = true;

  constructor(private propertyService: PropertyService, private clippedLayerService: ClippedLayerService) { }





  ngOnInit(): void {



// Create an Observable named 'needsDownloading$'
this.needsDownloading$ = merge(
  // Merge two Observables:
  // 1. An Observable that listens to 'activeProperty' changes
  this.propertyService.activeProperty.pipe(
    // When 'activeProperty' changes, fetch the clipped layers for the property
    switchMap((property) => {
      // If the property is null, emit 'null'
      // Otherwise, fetch clipped layers using the property's ID
      return property == null
        ? of(null)
        : this.clippedLayerService.getClippedLayers_Property(property.propertyID);
    }),
    // Determine if the layers need downloading
    map((layers) => {
      // If 'layers' is null (no active property), no need to download, return 'false'
      if (layers === null) {
        return false;
      }
      // If 'layers' is an empty array, it needs downloading, return 'true'
      if (layers.length == 0) {
        return true;
      }
      // If there's no layer with the name 'topex', it needs downloading, return 'true'
      // Otherwise, return 'false'
      return layers.find((lyr) => lyr.layerName.toLowerCase() == "topex") == null;
    })
  ),
  // 2. An Observable that listens to 'downloadButtonClick$' events
  // When the button is clicked, emit 'false' (no need to download)
  this.downloadButtonClick$.pipe(mapTo(false))
);


  }

  downloadLayersClick(): void {
    this.downloadButtonClick$.next();
    this.downloadLayers.emit(true);
  }


}
