<app-nav></app-nav>
<div class="main-content">
  <div class="dashboard-container">

    <div class="title">
      <h3 class="title-text">Welcome</h3>
    </div>

    <app-manage-paddocks class="manage-pad-container"></app-manage-paddocks>

    <app-manage-property class="manage-property-container"></app-manage-property>

    <app-user-details class="user-details-container"></app-user-details>

    <app-tools class="tools-container"></app-tools>

  </div>
</div>
<app-footer></app-footer>
