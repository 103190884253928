import { section } from "src/app/interfaces/JsPDF.extended.interface";
import { PaddockDexie } from "src/app/models/paddock.model";
import { PropertyDexie } from "src/app/models/property.model";


export const ForestDescriptionReportSections: Array<section> = [
    {
      title: "Important information about this Volume Estimation",
      paragraphs: [
      "The actual volume of timber that can be extracted from your area of native forest will typically be less than the estimated total standing volume figure shown. The actual volume available for harvest could be anywhere from 75% to 25% of that standing volume figure, and in some cases none of your forest might be available.",
      "The sections below provide a brief overview as to reasons why the actual volume available for harvest might be considerably lower than the total standing volume that has been estimated and it is recommended you seek professional advice should you wish to harvest your forest.",
      "Forest Practices Code\n\nThe Forest Practices Code provides the guidelines under which all forest practices can be undertaken in Tasmania including forest harvesting (including firewood), forest regeneration, forest clearing, tree fern harvesting, and the construction of roads and quarries for such purposes. The Forest Practices Code and associated planning tools are used to identify areas of the forest, or even individual trees, that may need to be retained during harvesting operations to maintain flora, fauna, soil, water and other environmental values present in the local landscape. The Forest Practices Code is one component of the forest practices system which under the Forest Practices Act 1985 supports sustainable management of Crown and private forests with due care for the environment and taking into account social, economic and environmental outcomes. The net present values (NPV) calculator provides an indication of the costs of compliance, accounting for management under the forest practices system.",
      "Threatened Native Vegetation Communities\n\nSome forests have been identified as requiring additional protection within the Forest Practices Code management requirements. The extent of these Threatened Native Vegetation Communities (TNVC) can be displayed as a layer in the map in this app. If your harvest areas includes TNVC please contact the Forest Practices Authority for more information.",
      "Selective Harvesting\n\nThe majority of native forest on private land is harvested with selective harvesting techniques to ensure successful forest regeneration post-harvest. This might mean that only 75% to 50% of the trees available for harvest under the Forest Practices Code can actually be extracted, the other 25-50% left standing to assist with regenerating the forest after harvesting has been completed.",
      "Recent Harvesting Activity\n\nAny forest regeneration after recent harvesting might take up to 80 years to achieve a size that would be available for harvest again.",
      "Local Planning Scheme\n\nUnless the forest is within a Private Timber Reserve, some local planning schemes may prevent you from harvesting native forest.",
      "Accessibility\n\nSteep slopes, inadequate access across a river and other limitations to access to the forest with harvesting machinery might reduce the area of forest available for harvesting.",
      "Fire\n\nFire can not only kill trees but can also cause significant fire damage which might affect their quality in terms of utility for solid wood products, such as veneer logs or sawlogs, and in some cases might even affect their being used for pulp wood products. In such cases, they might still be used for fire wood. Although fire events have been mapped, the actual effects of such fires on your forest might not be accurately represented in the areas or standing volume figures presented. You should independently assess the effect of any known fires on the quality of the forest when estimating how much of the standing timber is of a quality available for use.",
      "Significant weather events\n\nSignificant weather events (e.g. windthrow, drought) can fell trees and these events might not have been captured in the forest description mapping or standing volume estimates. You should independently assess the effect of any known weather events on the quality of the forest when estimating how much of the standing timber is of a quality available for use.",
      "Market\n\nNot all the tree species, timber quality or log types available for extraction from a forest have a market value, and this can change over time. Additionally, the market may be too far from your property to allow some or all of the available timber to be transported in a cost effective manner. You should seek advice as to the current market value of the timber in your forest, and the likely cost for it to be hauled to that market, as part of any due diligence into the financial return likely to be achieved from harvest.",
      "Mapping Accuracy\n\nThe forest description used to estimate the total standing volume of your timber was first mapped in 1995 using aerial imagery, and although this mapping has been regularly reviewed and updated to maintain currency, there is no guarantee that the condition or extent of your particular area of native forest has been correctly described at this present time. You should independently assess the effect of any known harvesting, fire damage, wind damage, insect damage, etc on the extent and quality of the forest when estimating how much of the standing timber is of a quality available for use."
    ]

    },
    {
      title: "Disclaimers",
      paragraphs: [
        "This report was generated by the Forest Description Tool from within the Farm & Forest Mapper app, built by Esk Spatial on behalf of Private Forests Tasmania and the Forest Practices Authority.",
        "Some of the information within this report has been prepared by Private Forests Tasmania (PFT).",
        "Every reasonable endeavour has been used to ensure that the material was accurate at the time of publication. No representation or warranty is made by PFT for the accuracy, completeness, or relevance of such information to the user’s purpose.\n\nYou agree and accept that any use of the information is entirely at your own risk. PFT, the Crown, its officers, employees and agents do not accept any liability howsoever arising (including, without limitation, negligence) for any loss in connection with or arising out of any use of or reliance upon the information. Anyone considering any investment project should seek independent professional advice on the particular matter before making a decision.",
        "For further information please contact:",
        "Private Forests Tasmania\nPO Box 180, Kings Meadows Tas 7249\nTelephone: (03) 6777 2720\nEmail: admin@pft.tas.gov.au"
      ]
    }
  ];

export function getForestDescriptionOverViewSection(target: PropertyDexie | PaddockDexie, report) : section
{
    let section:section = {title:"",paragraphs:[]}
    section.title = "Overview";
    section.paragraphs =
    [
        `Report Target: ${target.name}`,
        `Type: ${target instanceof PropertyDexie ? 'Property' : 'Paddock'} `,
        `Area Analyzed: ${target.area.toFixed(1)}(ha)`,
        `Estimate of Standing Total Recoverable Volume (TRV): ${report.estimatedTotalRecoverableVolume.toFixed(1)}t`,
        `% Sawlog: ${report.sawLog.toFixed(1)}`,
        `% Industrial Grade Peeler Log: ${report.industrialGradePeeler.toFixed(1)}`,
        `% Pulp Quality Log: ${report.pulpQualityLog.toFixed(1)}`
    ]

      return section;
}
