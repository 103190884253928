import { section } from "src/app/interfaces/JsPDF.extended.interface";
import { PaddockDexie } from "src/app/models/paddock.model";
import { PropertyDexie } from "src/app/models/property.model";


export const TopexPdfSections: Array<section> = [
    {
      title: "Important information about these TOPEX Scores",
      paragraphs: [
        "TOPEX readings are an assessment of exposure for a location and are provided in this tool as one of many inputs used in assessing the wind-throw hazard class for a site. A TOPEX score is based on the fact that the degree of exposure is influenced by the topographic features surrounding the site.",
        "The TOPEX scores tabulated above were modelled from a statewide 3D terrain model, a TOPEX score being generated at each and every intersection of a 50m x 50m grid laid out across Tasmania. At each location on the 50m grid, the 3D terrain model was distance limited to a 5km radius and the actual score was calculated as the sum of angles of inclination to the modelled horizon at the eight compass points from that modelled position in the landscape. The tabulated values above are a summary of the amount of area classified under each TOPEX score that make up your target area.",
        "The values provided are a guide only and you can undertake this same analysis on your property on ground to improve the exposure estimates. For more information on wind-throw risk in forests refer here."
      ]

    },
    {
      title: "Disclaimers",
      paragraphs: [
        "This report was generated by the Topex Tool from within the Farm & Forest Mapper app, built by Esk Spatial on behalf of Private Forests Tasmania and the Forest Practices Authority.",
        "Some of the information within this report has been prepared by Private Forests Tasmania (PFT).",
        "Every reasonable endeavour has been used to ensure that the material was accurate at the time of publication. No representation or warranty is made by PFT for the accuracy, completeness, or relevance of such information to the user’s purpose.\n\nYou agree and accept that any use of the information is entirely at your own risk. PFT, the Crown, its officers, employees and agents do not accept any liability howsoever arising (including, without limitation, negligence) for any loss in connection with or arising out of any use of or reliance upon the information. Anyone considering any investment project should seek independent professional advice on the particular matter before making a decision.",
        "For further information please contact:",
        "Private Forests Tasmania\nPO Box 180, Kings Meadows Tas 7249\nTelephone: (03) 6777 2720\nEmail: admin@pft.tas.gov.au"
      ]
    }
  ];

export function getTopexOverViewSection(target: PropertyDexie | PaddockDexie) : section
{
    let section:section = {title:"",paragraphs:[]}
    section.title = "Overview";
    section.paragraphs =
    [
        `Report Target: ${target.name}`,
        `Type: ${target instanceof PropertyDexie ? 'Property' : 'Paddock'} `,
        `Area Analyzed: ${target.area.toFixed(1)}(ha)`
    ]

      return section;
}
