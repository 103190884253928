
        <h2 matDialogTitle>Change Password</h2>

  <mat-dialog-content>
    <form  [formGroup]="resetPasswordForm"  (ngSubmit)="onSubmit()" class="reset-password-form">
      <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" type="password">
          <mat-error *ngIf="resetPasswordForm.get('password').invalid && (resetPasswordForm.get('password').dirty || resetPasswordForm.get('password').touched)">
            <div *ngIf="!resetPasswordForm.get('password').errors['required']">
              Password is required.
            </div>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Repeat password</mat-label>
          <input matInput formControlName="rptPassword" type="password">
          <mat-error *ngIf="resetPasswordForm.get('rptPassword').invalid && (resetPasswordForm.get('rptPassword').dirty || resetPasswordForm.get('rptPassword').touched)">
            <div *ngIf="!resetPasswordForm.valid && resetPasswordForm.get('password').touched  && resetPasswordForm.get('rptPassword').touched">
              Your passwords don't match
            </div>
          </mat-error>
        </mat-form-field>

        <app-password-complexity [passwordControl]="resetPasswordForm.get('password')"></app-password-complexity>
    <div class="actions">
      <button  [matDialogClose]="false" class="button button--danger">Cancel</button>
      <button type="submit" [disabled]="!resetPasswordForm.valid" class="button button--brand">Reset Password</button>

    </div>

    </form>
  </mat-dialog-content>
