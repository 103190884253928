import { v4 as uuidv4 } from 'uuid';

export class PropertyDexie {

  public propertyID: string;
  public name: string;
  public wkt: string;
  public userID: number;
  public address?: string;
  public notes?: string;
  public area: number;
  public pid?: string;

  uploaded:boolean = false;

  createdOn: string = new Date().toUTCString();

  modifiedOn: string = new Date().toUTCString();


  constructor() {

    this.propertyID = uuidv4();
  }
}
