import { Injectable } from "@angular/core";
import GeoJSON from 'ol/format/GeoJSON';
import { Feature } from "ol";
import { environment } from "src/environments/environment";
import { CalculateArea } from "../_helpers/transformations";
import { BehaviorSubject, startWith } from "rxjs";
import { trueDependencies } from "mathjs";
import { OnlineStatusService, OnlineStatusType } from "ngx-online-status";

@Injectable({
  providedIn: 'root'
})
export class OfflineService {

  isOnline: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(private onlineStatusService: OnlineStatusService) {
    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      // Retrieve Online status Type
      this.emitNavigatorChange(Boolean(status));
    });
  }

   emitNavigatorChange(status:boolean)
   {
    this.isOnline.next(status);
   }
  }
