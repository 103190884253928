import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChange } from '@angular/core';
import { FormGroup, FormGroupDirective } from "@angular/forms"

@Component({
  selector: 'app-primary-inputs',
  templateUrl: './primary-inputs.component.html',
  styleUrls: ['./primary-inputs.component.css']
})
export class PrimaryInputsComponent implements OnInit {

  @Input() totalPotentialVolumeOfLogsHarvestedPerYear: string;
  @Input() formGroupName: string;
  form: FormGroup


  forestyOperations: string[] = ["", "Native forest - Clearfelling", "Native forest - Thinning", "Plantation - Clearfelling", "Plantation - Thinning"]

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  ngOnChanges(C: SimpleChange) {
    if (C?.['totalPotentialVolumeOfLogsHarvestedPerYear']) {
      setTimeout(() => {
        this.form.controls['totalPotentialVolumeOfLogsHarvestedPerYear'].setValue(this.calculatTotalPotentialVolumeOfLogsHarvestedPerYear());
        return;
      }, 0);
    }
  }

  calculatTotalPotentialVolumeOfLogsHarvestedPerYear() {
    if (this.totalPotentialVolumeOfLogsHarvestedPerYear === "Native forest - Clearfelling") {
      return 300 * 40;

    } else if (this.totalPotentialVolumeOfLogsHarvestedPerYear === "Native forest - Thinning") {
      return 125 * 40;

    } else if (this.totalPotentialVolumeOfLogsHarvestedPerYear === "Plantation - Clearfelling") {
      return 300 * 40;

    } else if (this.totalPotentialVolumeOfLogsHarvestedPerYear === "Plantation - Thinning") {
      return 125 * 40;

    } else {
      return 0;
    }
  }

}
