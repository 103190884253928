import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
  ElementRef,
} from '@angular/core';
import { concatMap, from, of, Subscription, switchMap } from 'rxjs';
import { Feature } from 'ol';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/user.model';

import { AuthService } from 'src/app/services/auth.service';
import { mapService } from 'src/app/services/map.service';
import { PropertyService } from 'src/app/services/property.service';

import { PaddockService } from 'src/app/services/paddock.service';

import { DropDownService } from 'src/app/services/dropdown.service';
import SidebarEsk from 'src/app/_helpers/sidebar_esk';
import { ModalService } from 'src/app/services/modal.service';
import { EventBusService } from 'src/app/services/EventBus.service';
import { FenceService } from 'src/app/services/fence.service';
import { PopperHelpService } from 'src/app/services/popperHelp.service';
import { ReportingService } from 'src/app/services/reporting.service';
import {ReportingToolType, TreeMapperCustomEvents} from 'src/app/_helpers/enums';
import { DigitizePaddockComponent } from './tools/digitize-paddock/digitize-paddock.component';
import { DigitizeFenceComponent, FenceCreatedEvent } from './tools/digitize-fence/digitize-fence.component';
import { SelectPropertyComponent } from './tools/select-property/select-property.component';
import VectorLayer from 'ol/layer/Vector';
import { MatDialog } from '@angular/material/dialog';
import { WelcomeDialogComponent, WelcomeDialogResult } from './dialogs/welcome/welcome-dialog.component';
import { CreatePropertyComponent } from './dialogs/property/create-property/create-property.component';
import { EditPropertyComponent } from './dialogs/property/edit-property/edit-property.component';
import { CreatePaddockComponent } from './dialogs/paddock/create-paddock/create-paddock.component';
import { EditPaddockComponent } from './dialogs/paddock/edit-paddock/edit-paddock.component';
import { CreateFenceComponent } from './dialogs/fence/create-fence/create-fence.component';
import { EditFenceComponent } from './dialogs/fence/edit-fence/edit-fence.component';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { boolean } from 'mathjs';
import { AttributeOverlayComponent } from './tools/attribute-overlay/attribute-overlay.component';
import { CalculateArea, ConvertWktToFeature } from 'src/app/_helpers/transformations';

import VectorSource from 'ol/source/Vector';
import { PropertyDexie } from 'src/app/models/property.model';
import { PaddockDexie } from 'src/app/models/paddock.model';
import { FenceDexie } from 'src/app/models/fence.model';
import { EnterpriseReportService } from 'src/app/services/enterpriseReport.service';
import { EnterpriseReport } from 'src/app/models/enterpriseReport.model';
import { TopexReportService } from 'src/app/services/topexReport.service';
import { TopexReportComponent } from './dialogs/topex-report/topex-report.component';
import { ITopexReport, ITopexReportData } from 'src/app/interfaces/topexReport.interface';
import { TopexReport } from 'src/app/models/topexReport.model';
import { IDssReport } from 'src/app/interfaces/dssReport.Interface';
import { DssReportComponent } from './dialogs/dss-report/dss-report.component';
import { OfflineService } from 'src/app/services/offline.service';
import { IForestDescriptionReport } from 'src/app/interfaces/forestDescriptionReport.Interface';
import { ForestClassReportService } from 'src/app/services/forestClassesReport.service';
import { ForestDescriptionReportComponent } from './dialogs/forest-description-report/forest-description-report.component';
import { AboutDialogComponent } from '../../shared/components/about-dialog/about-dialog.component';
import { trigger, state, style, transition, animate } from '@angular/animations';

interface report {
  name: string;
  data: EnterpriseReport;
}




@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  animations: [
    trigger('slideFadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'translateY(100%)',
        })
      ),
      transition('void => *', animate('200ms ease-in-out')),
      transition('* => void', animate('200ms ease-in-out')),
    ]),]
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  geolocation: Geolocation;
  propertyTool: boolean;

  private subscriptions: Subscription = new Subscription();

  zoomLevel: Number = 0;
  cadasterRequiredZoomLevel: Number = 9;

  @ViewChild(DigitizePaddockComponent)
  digitizePaddockComponent: DigitizePaddockComponent;

  @ViewChild(DigitizeFenceComponent)
  digitizeFenceComponent: DigitizeFenceComponent;

  @ViewChild(SelectPropertyComponent)
  selectPropertyComponent: SelectPropertyComponent;



  @ViewChild('addAttributeOverlayHere', {static : false, read : ViewContainerRef}) attributeOverlayTarget: ViewContainerRef;
  private attributeComponentRef: ComponentRef<AttributeOverlayComponent>;

  @ViewChild('mapElementRef', { static: true }) mapElementRef: ElementRef;

  @ViewChild('sidebarElementRef', { static: true }) sidebarElementRef: ElementRef;


  reportingTargets: Set<PropertyDexie | PaddockDexie> = new Set();

  reportToolType: any = 0;
  alertMessage: string;
  user: User;
  featureSelected: boolean;
  selectedPropertyFeatures: Array<Feature<any>>;

  property: PropertyDexie = new PropertyDexie();
  activeProperty: PropertyDexie = new PropertyDexie();
  activePaddock: PaddockDexie = new PaddockDexie();
  activeFence: FenceDexie = new FenceDexie();

  reportingToolType: ReportingToolType = ReportingToolType.CROP_REPORT;

  activeFeature: Feature<any>;
  processingWindow: HTMLElement;
  processing: boolean = false;
  processingText: any;
  processingLayerText: string = '';
  paddocks: Array<PaddockDexie> = [];
  fences: Array<FenceDexie> = [];
  properties: Array<PropertyDexie> = [];
  sidebar: SidebarEsk;
  isSelectPropertyActive: boolean = false;
  selectedBoundariesCount: Number = 0;
  reports: Array<report> = [];
  helpStage: number = 1;
  selectedPaddocks: Map<string, PaddockDexie> = new Map();


  constructor(
    public mapService: mapService,
    private authService: AuthService,
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    public paddockService: PaddockService,
    public fenceService:FenceService,
    private modalService: ModalService,
    private eventBusService: EventBusService,
    private popperHelpService: PopperHelpService,
    private router: Router,
    private reportingService: ReportingService,
    public dialog: MatDialog,
    private tokenService: TokenStorageService,
    private enterpriseReportService:EnterpriseReportService,
    private topexReportService: TopexReportService,
    public connectionService: OfflineService,
    private forestService: ForestClassReportService
  ) {}

  ngOnInit() {
    this.geolocation = navigator.geolocation;
    this.propertyTool = false;

    this.mapService.createPropertyToolActive.subscribe();



    this.eventBusService.on(TreeMapperCustomEvents.FencesLoaded, (fences: FenceDexie[]) => {
      this.fences = fences;
    });

    this.eventBusService.on(TreeMapperCustomEvents.PropertyFeaturesSelected, (features)=>{
      this.populateCreatePropertyDefaults(features);
     });

    (window as any).ol_map = this.mapService;



    let createPropertyStatusSub$ =
      this.mapService.ProcessingState.subscribe((state) => {
        switch (state) {
          case 1:
            this.processing = false;
            break;
          case 2:
            this.processing = true;
            this.processingText = 'Creating Property';
            break;
          case 3:
            this.processing = true;
            this.processingText = 'Creating Paddock';
            break;
          case 4:
            this.processing = true;
            this.processingText = 'Clipping Layers';
            break;
          case 5:
            this.processing = true;
            this.processingText = 'Calculating Crop Suitability';
            break;
        }
      });

    let clippingProgressLayerNameSub$ =
      this.mapService.ProgressDetail.subscribe((name) => {
        if (name == null) {
          return;
        }

        this.processingLayerText = name;
      });

    let userSub$ = this.authService.user.subscribe((user) => {
      this.user = user;
    });

    let propertySub$ = this.propertyService.activeProperty.subscribe(
      (property) => {

        this.reportingTargets.clear();
        this.activeProperty = property;
      }
    );

    let propertiesSub$ = this.propertyService.properties.subscribe(
      (properties: any) => {
        if (properties == null) {
          return;
        }

        this.properties = properties;
      }
    );


    let paddocksSub$ = this.paddockService.paddocks.subscribe((paddocks: any) => {
      this.selectedPaddocks.clear();

      this.paddocks = paddocks;
    });


    this.subscriptions.add(createPropertyStatusSub$);
    this.subscriptions.add(clippingProgressLayerNameSub$);
    this.subscriptions.add(userSub$);
    this.subscriptions.add(propertySub$);
    this.subscriptions.add(propertiesSub$);
    this.subscriptions.add(paddocksSub$);
    this.mapService.addCadastreLayer();

    this.mapService.map.setTarget(this.mapElementRef.nativeElement);
    this.mapService.map.updateSize();

    this.sidebar = this.mapService.addSideBar(this.sidebarElementRef.nativeElement);

    this.route.paramMap.subscribe((params) => {
      let tool = params.get('tool');
      switch (tool) {
        case 'createProperty':
          this.propertyTool = true;
          this.sidebar.open('createProperty');
          break;
        case 'editProperty':
          this.propertyTool = true;
          this.sidebar.open('editProperty');
          break;
        case 'report':
          this.sidebar.open('reporting');
          break;
        case 'report-dss':
          this.sidebar.open('reporting');
          this.reportingToolType = 1;
          break;
        case 'report-npv':
          this.sidebar.open('reporting');
          this.reportingToolType = 2;
          break;

          case 'report-topex':
            this.sidebar.open('reporting');
            this.reportingToolType = 3;
                   break;

        case 'report-woodlot':
          this.sidebar.open('reporting');
          this.reportingToolType = 4;
            break;


        case 'report-forest':
          this.sidebar.open('reporting');
          this.reportingToolType = 5;
            break;

            case 'help':
            this.sidebar.open('help');


      }
    });

  }


  ngAfterViewInit() {

    let showWelcome = true;

    if(localStorage.getItem('showWelcome'))
    {
      showWelcome = boolean(localStorage.getItem('showWelcome'));
    }
    else
    {
      localStorage.setItem('showWelcome',String(showWelcome));
    }


   if(showWelcome == true)
   {
    const dialogRef = this.dialog.open(WelcomeDialogComponent,{hasBackdrop:true,closeOnNavigation:true, width:'70%', height:'80%'});

    dialogRef.afterClosed().subscribe(result=> {
     let welcomeResult = result as WelcomeDialogResult;

     if(welcomeResult.startHelp == true)
     {
       this.showHelpGuide();
     }

     if(welcomeResult.hideOnStart == true)
     {
      localStorage.setItem('showWelcome',"false");
     }
    });
   }




    this.mapService.map.on('moveend', () => {
        this.zoomLevel= this.mapService.map.getView().getZoom();
});

    this.sidebar.setOpenCallback( (openId)=> {
      if(openId == 'createProperty')
      {
        this.propertyService.activeProperty.next(null);
        this.mapService.setCreatePropertyToolActive(true);
        this.selectPropertyComponent.toggleSelectProperty();
      }
      else if(openId == 'paddocks')
      {
         if(!this.digitizePaddockComponent.isActive)
        this.digitizePaddockComponent.toggleDigitizePaddock();
      }
      else if(openId == 'fences')
      {/* Checking if the digitizeFenceComponent is active. */

        if(!this.digitizeFenceComponent.isActive)
        this.digitizeFenceComponent.toggleDigitizeFence();
      }
      else
      {
        this.mapService.setCreatePropertyToolActive(false);

        if(this.selectPropertyComponent.active)
        this.selectPropertyComponent.toggleSelectProperty();

        if(this.digitizePaddockComponent.isActive)
        this.digitizePaddockComponent.toggleDigitizePaddock();

        if(this.digitizeFenceComponent.isActive)
        this.digitizeFenceComponent.toggleDigitizeFence();

      }
      });

    this.sidebar.setCloseCallback(() =>{

      this.mapService.setCreatePropertyToolActive(false);

        if(this.selectPropertyComponent.active)
        this.selectPropertyComponent.toggleSelectProperty();

      });

    this.mapService.addLayerSwitcher();
    this.mapService.setupBaseMaps();
    this.mapService.addDefaultControls();
    this.mapService.addDefaultInteractions();


    if (this.propertyTool) {
      //this.openSidebarPane('createProperty');
    } else {
    }


    if(this.activeProperty == null)
    {

    }
  }
  paddockToolClicked(active:boolean)
  {
    this.mapService.togglePropertyStyle(active);

    if(active)
    {
      this.sidebar.open('paddocks');
    }
  }

  fenceToolClicked(active:boolean)
  {
    this.mapService.togglePropertyStyle(active);

    if(active)
    {
      this.sidebar.open('fences');
    }
  }

  ngOnDestroy(): void {
    this.mapService.updateSize(null);
    this.mapService.removeControls();
    this.popperHelpService.hide();
    this.popperHelpService.clear();
    this.subscriptions.unsubscribe();
  }


  fenceCreated(data: FenceCreatedEvent)
  {
    this.activeFence = data.fence;
    this.activeFence.paddocks = [];

    this.activeFence.type = '';

    this.paddocks.forEach((paddock) => {
      if (data.intersectedPaddockIds.includes(paddock.paddockID)) {
       this.activeFence.paddocks.push(paddock.paddockID);
      }
    });

    const dialogRef = this.dialog.open(CreateFenceComponent,{hasBackdrop:true,data: {fence: this.activeFence, paddocks:this.paddocks},width:'40%'});

    dialogRef.afterClosed().subscribe( async result => {
      if(result === false)
      {
        return;
      }

      if(result instanceof FenceDexie)
      {
        this.activeFence = result;
        await this.saveFence();
      }
    })


  }

  reRenderMap(){
    this.mapService.map.render();
  }

  onFeatureSelected(feature)
  {

    this.attributeComponentRef = this.attributeOverlayTarget.createComponent(AttributeOverlayComponent);
    this.attributeComponentRef.instance.map = this.mapService.map;
    this.attributeComponentRef.instance.selectedFeature = feature;
    this.attributeComponentRef.instance.attributeAliasMap = this.mapService.propAliasMap;

  }

  populateCreatePropertyDefaults(selectedFeatures: Array<Feature<any>>)
  {
    this.selectedBoundariesCount =
    selectedFeatures?.length != null ? selectedFeatures.length : 0;

  if (selectedFeatures != null && selectedFeatures.length > 0) {
    let propertyIds = '',
      totalArea = 0;

    selectedFeatures.forEach((element) => {
      let props = element.getProperties();

      if (props.PID == null) {
      } else {

        propertyIds += !propertyIds.includes(props.PID)
          ? `${props.PID},`
          : '';
        totalArea =
          props.MEAS_AREA == 0
            ? totalArea + props.COMP_AREA
            : totalArea + props.MEAS_AREA;
      }
    });



    this.property.pid = propertyIds;
    this.property.area = parseFloat((totalArea / 10000).toFixed(2));
    this.featureSelected = true;
    this.selectedPropertyFeatures = selectedFeatures;
  } else {
    this.selectedPropertyFeatures = null;
    this.featureSelected = false;
    this.property = new PropertyDexie();
  }
  }

  toggleCreateProperty() {
    this.isSelectPropertyActive = !this.isSelectPropertyActive;

    this.selectPropertyComponent.toggleSelectProperty();

  }


  paddockChecked(paddock: PaddockDexie) {
    if (this.selectedPaddocks.has(paddock.paddockID)) {
      this.selectedPaddocks.delete(paddock.paddockID);
    } else {
      this.selectedPaddocks.set(paddock.paddockID, paddock);
    }
  }




   async showCropReport(targets: Array<PropertyDexie | PaddockDexie>) {

    let reports : Array<IDssReport> = [];

    for (let index = 0; index < targets.length; index++) {
      const element = targets[index];

      let reportData: EnterpriseReport;

      if(element instanceof PropertyDexie)
    {
      reportData  = await this.enterpriseReportService.getPropertyReport(element.propertyID);
    }
    else{
      reportData = await this.enterpriseReportService.getPaddockReport(element.paddockID);
    }

    let report: IDssReport = {
      name: element.name,
      target: element,
      enterpriseReport: reportData,
      totalAreaNotCovered:0
    };

    report.totalAreaNotCovered = parseFloat((report.target.area - (report.enterpriseReport.data[0].wellSuited + report.enterpriseReport.data[0].unsuitable
      + report.enterpriseReport.data[0].moderatelySuitable + report.enterpriseReport.data[0].suitable)).toFixed(3));

    reports.push(report);
    }


    const dialogRef = this.dialog.open(DssReportComponent,{hasBackdrop:true,data:reports, width:'70%'});

    dialogRef.afterClosed().subscribe((result) => {

      if(result == false || result == null)
      {
        return;
      }
    })
  }

  openSidebarPane = (id) => {
    this.sidebar?.open(id);

    if(id == 'createProperty')
    {
      this.propertyService.activeProperty.next(null);
      this.mapService.setCreatePropertyToolActive(true);
      this.selectPropertyComponent.toggleSelectProperty();
    }

  };


  ZoomToPaddock(paddock: PaddockDexie) {
    this.mapService.zoomToPaddock(paddock);
  }


  openAbout()
  {
    this.dialog.open(AboutDialogComponent,{width:'30%'}).afterClosed().subscribe(result => {});
  }



/**
 * It opens a dialog box, and when the dialog box is closed, it checks if the result is false, if it
 * is, it returns, if it's not, it checks if the result is an instance of PropertyDexie, if it is, it
 * sets the property to the result, and then calls the createProperty function.
 */
  async saveProperty() {
    const dialogRef = this.dialog.open(CreatePropertyComponent,{hasBackdrop:true,data: this.property,width:'40%'});

    dialogRef.afterClosed().subscribe( async result => {
      if(result === false)
      {
        return;
      }

      if(result instanceof PropertyDexie)
      {
        this.property = result;
        await this.createProperty();
      }
    })
  }

  async createProperty() {
    await this.mapService.createProperty(this.property,this.selectedPropertyFeatures);
    this.property = new PropertyDexie();
    this.toggleCreateProperty();
    this.openSidebarPane('paddocks');
  }

  async editProperty(property: PropertyDexie) {
    this.propertyService.activeProperty.next(property);
    const dialogRef = this.dialog.open(EditPropertyComponent,{hasBackdrop:true,closeOnNavigation:true,data:property,width:'40%'});

    dialogRef.afterClosed().subscribe((result) => {

      if(result == false || result == null)
      {
        return;
      }

      if(result instanceof PropertyDexie)
      {
        this.updateProperty(result)
      }
      else if(result instanceof Object)
      {
        if(result.deleteProperty == true)
        {
          this.deleteProperty();
        }
      }
    })

  }

  async createPaddock(feature:Feature<any>)
  {
   let paddock = await this.mapService.CreatePaddock(feature);

   const dialogRef = this.dialog.open(CreatePaddockComponent,{hasBackdrop:true,data: paddock,width:'40%'});

    dialogRef.afterClosed().subscribe( async result => {
      if(result === false)
      {
        return;
      }

      if(result instanceof PaddockDexie)
      {
        await this.savePaddock(result);
      }
    });
  }

  async savePaddock(paddock: PaddockDexie) {
    this.digitizePaddockComponent.toggleDigitizePaddock();
    await this.mapService.createPaddock(paddock);
  }

  async editPaddock(paddock: PaddockDexie) {
    this.activePaddock = paddock;

    const dialogRef = this.dialog.open(EditPaddockComponent,{hasBackdrop:true,closeOnNavigation:true,data:paddock,width:'40%'});

    dialogRef.afterClosed().subscribe((result) => {

      if(result == false || result == null)
      {
        return;
      }

      if(result instanceof PaddockDexie)
      {
        this.updatePaddock(result)
      }
      else if(result instanceof Object)
      {
        if(result.deletePaddock == true)
        {
          this.deletePaddock(paddock);
        }
      }
    })
  }


  async deletePaddock(paddock: PaddockDexie) {
    this.paddockService.remove(paddock);
  }

  async discardFence() {
    this.activeFence = new FenceDexie();
  }

  async saveFence()
  {
    this.digitizeFenceComponent.toggleDigitizeFence();
    await this.mapService.createFence(this.activeFence);
  }
  async editFence(fence: FenceDexie) {
    this.activeFence = fence;

    const dialogRef = this.dialog.open(EditFenceComponent,{hasBackdrop:true,data:{fence:this.activeFence, paddocks:this.paddocks}});

    dialogRef.afterClosed().subscribe((result) => {

      if(result == false || result == null)
      {
        return;
      }

      if(result instanceof FenceDexie)
      {
        this.mapService.updateFence(result);
      }
      else if(result instanceof Object)
      {
        if(result.deleteFence == true)
        {
         this.mapService.deleteFence(this.activeFence)
        }
      }
    })
  }
  async updateFence()
  {
     await this.mapService.updateFence(this.activeFence);
  }

  cancelPaddockCreation() {
    this.mapService.createPaddockFeature.next(null);
  }
  updatePaddock(paddock) {
    this.paddockService.update(paddock);
  }

  updateProperty(property: PropertyDexie) {
    this.mapService.updateProperty(property);
  }

  deleteProperty() {

    this.mapService.deleteProperty(this.activeProperty);
  }


  showNPVCalculator(targets: PropertyDexie | PaddockDexie) {
    this.reportingService.setReportTarget(targets);
    this.router.navigate(['/calculator']);
  }

  showWoodlotTool(targets: PropertyDexie | PaddockDexie) {

    this.reportingService.setReportTarget(targets);
      this.router.navigate(['/woodlot-tool']);



  }



  async showTopexReport(targets: Array<PropertyDexie | PaddockDexie>)
  {
    let reports: Array<ITopexReportData> = [];


  for (let index = 0; index < targets.length; index++) {
    const target = targets[index];

     let report = target instanceof PropertyDexie ?
     await this.topexReportService.getPropertyReport(target.propertyID) :
     await this.topexReportService.getPaddockReport(target.paddockID);

     let data = this.generateTopexReportDto(report, target);

     reports.push({target: target, data: data})

  }
    const dialogRef = this.dialog.open(TopexReportComponent,{hasBackdrop:true,data:reports, width:'70%'});

    dialogRef.afterClosed().subscribe((result) => {

      if(result == false || result == null)
      {
        return;
      }
    })
  }


    async showForestReport(targets: Array<PropertyDexie | PaddockDexie>)
  {
    let reports: Array<IForestDescriptionReport> = [];


  for (let index = 0; index < targets.length; index++) {
    const target = targets[index];

     let report = target instanceof PropertyDexie ?
     await this.forestService.getPropertyReport(target.propertyID) :
     await this.forestService.getPaddockReport(target.paddockID);

     report.forestClasses =  report.forestClasses ? report.forestClasses.filter(c => c.area >= 0.1) : [];

     let object: IForestDescriptionReport = {target: target, report:report};


     reports.push(object);

  }
    const dialogRef = this.dialog.open(ForestDescriptionReportComponent,{hasBackdrop:true,data:reports, width:'70%'});

    dialogRef.afterClosed().subscribe((result) => {

      if(result == false || result == null)
      {
        return;
      }
    })
  }

  private generateTopexReportDto(report: TopexReport, target)
  {
    return [
      {topexScore: 10,exposureDescription: 'Severely Exposed', area: report.severelyExposed, percentage: ((report.severelyExposed / target.area) * 100) },
      {topexScore: 7, exposureDescription: 'Very Exposed', area: report.veryExposed, percentage: ((report.veryExposed / target.area) * 100)},
      {topexScore: 3, exposureDescription: 'Moderately Exposed', area: report.moderatelyExposed, percentage: ((report.moderatelyExposed / target.area) * 100)},
      {topexScore: 2, exposureDescription: 'Moderately Sheltered', area: report.moderatelySheltered, percentage: ((report.moderatelySheltered / target.area) * 100)},
      {topexScore: 0, exposureDescription: 'Very Sheltered', area: report.verySheltered, percentage: ((report.verySheltered / target.area) * 100)},
    ]
  }



  showHelpGuide()
  {
    this.popperHelpService.startHelp(Number(this.helpStage));
  }

  togglePropertyStyle()
  {

  }
  async open(id) {
    this.modalService.open(id);
  }

  locate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.mapService.setView(10, [
          position.coords.longitude,
          position.coords.latitude,
        ]);
      });
    }
  }

  getSelectLayers()
  {
    return [
...this.mapService.map.getAllLayers()
      ]
  }
}
