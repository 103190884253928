import { Component, OnInit } from '@angular/core';
import { PrintPdfFilesService } from '../print-pdf-files.service';

@Component({
  selector: 'app-ptr-guidelines',
  templateUrl: './ptr-guidelines.component.html',
  styleUrls: ['./ptr-guidelines.component.css']
})
export class PtrGuidelinesComponent implements OnInit {

  constructor(public pdfGenService: PrintPdfFilesService) { }

  ngOnInit(): void {
  }

}
