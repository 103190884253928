
import jsPDF, { ImageCompression, RGBAData, TextOptionsLight } from "jspdf";
import { UserOptions } from "jspdf-autotable";

export interface jsPDFCustom extends jsPDF {
  autoTable: (options: UserOptions) => void;
  lastAutoTable: any;
  currentY: number;



}


export type section = {
  title: string;
  paragraphs: string[];
}

export function addSubHeader(headerText: string, pdf: jsPDFCustom, margin: number, yPos?: number) {
  pdf.setFontSize(24);

  const yPosition = yPos ?? 0;
  const lineWidth = pdf.internal.pageSize.width - 2 * margin;
  const headerLines = pdf.splitTextToSize(headerText, lineWidth);
  const lineHeight = pdf.getTextDimensions(headerText).h;
  const headerTextHeight = headerLines.length * lineHeight;

  pdf.setFillColor(109, 203, 153);
  pdf.rect(0, yPosition + headerTextHeight - 5, pdf.internal.pageSize.width, 0.5, "F");

  const leading = 1.2; // Adjust this value to control the line spacing
  let lineY = yPosition;
  for (let i = 0; i < headerLines.length; i++) {
    pdf.text(headerLines[i], margin, lineY);
    lineY += lineHeight * leading;
  }
  pdf.setFontSize(12);

  return headerTextHeight;
}

export function addHeader(headerText: string, pdf: jsPDFCustom, margin: number, headerHeight: number = 30) {

  let oldFontSize = pdf.getFontSize();

  pdf.setFontSize(14);

  let currentDateTime = new Date();
let options = {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true  // 12-hour format. Remove this line for 24-hour format.
} as any;

let humanReadableDateTime = currentDateTime.toLocaleString(undefined, options);
  pdf.setTextColor(40,56,53);
  pdf.setFillColor(109,203,153);
   pdf.rect(0, 0, pdf.internal.pageSize.width, headerHeight, "F");
 // Add the image to the header
 var logo = new Image();
 logo.src = 'assets/images/PFT-Logo-Dark.png';
 pdf.addImage(logo, "png", margin, 2,100, 25);

 let titleWidth = pdf.internal.pageSize.width - pdf.getTextWidth(headerText) - margin;
 let titleHeight = pdf.getTextDimensions(headerText).h;


 pdf.text(headerText, titleWidth, 27 - titleHeight  );
 pdf.setFontSize(12);
 let dateWidth = pdf.internal.pageSize.width - pdf.getTextWidth(humanReadableDateTime) - margin;
 pdf.text(humanReadableDateTime, dateWidth, 27 );

 pdf.setFontSize(oldFontSize);

 return headerHeight;
}

export function addFooter(pdf, margin) {

  let oldFontColor = pdf.getTextColor();
  let oldFontSize = pdf.getFontSize();

  // Footer background
  const footerHeight = 15;
  const footerY = pdf.internal.pageSize.height - footerHeight;
  const pageWidth = pdf.internal.pageSize.width;

  pdf.setFillColor(84, 88, 89);
  pdf.rect(0, footerY, pageWidth, footerHeight, "F");

  // Footer text
  pdf.setFontSize(10);
  pdf.setTextColor(255); // Set the text color to white

  const leftText = "Farm & Forest Mapper";
  const copyrightText = "Copyright " + String.fromCharCode(169) + " Esk Mapping & GIS 2023";
  const pageNumberText = "Page " + pdf.internal.getNumberOfPages();

  const textHeight = pdf.getTextDimensions(pageNumberText).h;
  const textY = footerY + (footerHeight + textHeight) / 2;

  pdf.text(leftText, margin, textY); // Left justified
  pdf.text(copyrightText, pageWidth / 2, textY, { align: "center" }); // Centered
  pdf.text(pageNumberText, pageWidth - margin, textY, { align: "right" }); // Right justified

  pdf.setTextColor(oldFontColor);
  pdf.setFontSize(oldFontSize);
}


export function addParagraph(pdf: jsPDFCustom, text, x, y, width, margin, addPageCallback: Function) {
  const lines = pdf.splitTextToSize(text, width) as string[];
  const lineHeight = pdf.getTextDimensions(lines[0]).h;
  const pageHeight = pdf.internal.pageSize.height;

  for (let i = 0; i < lines.length; i++) {
    // Check if the content will go off the page
    if (y + lineHeight > pageHeight - margin) {
      addPageCallback();

      y = margin + 30; // Reset the y position on the new page
    }

    pdf.text(lines[i], x, y);
    y += lineHeight;
  }

  return y;
}


export function checkSectionOffPage(pdf, margin, section: section, y: number )
{
  let sectionHeight = 0;
  for (const paragraph of section.paragraphs) {
    const paragraphLines = pdf.splitTextToSize(paragraph, pdf.internal.pageSize.width - margin - margin);
    const paragraphHeight = paragraphLines.length * pdf.getTextDimensions(paragraph).h;
    sectionHeight += paragraphHeight + 5; // Add the paragraph height plus some spacing
  }

  // Check if the majority of the section will go to a new page
  const pageHeight = pdf.internal.pageSize.height;
  const remainingSpace = pageHeight - y;
  if (sectionHeight > 0.3 * pageHeight && sectionHeight > remainingSpace) {
 return true
  }
  return false;
}
