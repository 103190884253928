import { Component, Input, OnInit } from '@angular/core';
import { Directive, ElementRef } from '@angular/core';
import { PopperHelpService } from 'src/app/services/popperHelp.service';



@Directive({
  selector: '[popperHelp]'
})
export class PopperHelpDirective {
  @Input() helpOrder = '';
  @Input() helpContent = '';
  @Input() helpStage = '';
  @Input() helpPlacement = '';
  @Input() helpTitle = '';
  @Input() helpPreShowFunction: Function;
  @Input() helpPreShowFunctionParams: any
  @Input() helpPostShowFunction: Function;
  @Input() helpPostShowFunctionParams: any

  constructor(private eleRef: ElementRef,private popperHelpService: PopperHelpService) {
  }

  ngOnInit() {
    this.popperHelpService.add({element: this.eleRef.nativeElement, options: {
      order:Number(this.helpOrder),
      stage:Number(this.helpStage),
      content: this.helpContent,
      placement: 'left',
      title: this.helpTitle,
      preShowCallback: this.helpPreShowFunction,
      preShowCallbackParams: this.helpPreShowFunctionParams,
      postShowCallback: this.helpPostShowFunction,
      postShowCallbackParams: this.helpPostShowFunctionParams,
    }});
  }
}


@Component({
  selector: 'app-popover-help',
  templateUrl: './popover-help.component.html',
  styleUrls: ['./popover-help.component.css']
})
export class PopoverHelpComponent implements OnInit {



  constructor() { }

  ngOnInit(): void {
  }

}
