import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit , OnDestroy {


  loginForm: UntypedFormGroup;
  snackbarRef: MatSnackBarRef<any> = null;


  constructor(private router:Router, private authService: AuthService, private tokenService: TokenStorageService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {

    this.loginForm = new UntypedFormGroup({
      'email': new UntypedFormControl(null, [CustomValidators.required, CustomValidators.email]),
      'password': new UntypedFormControl(null, CustomValidators.required)
  });
  }

  ngOnDestroy(): void {
    this.snackbarRef?.dismiss();
  }

  onSubmit(): void {

    let credentials = {
      email: this.loginForm.get("email").value,
      password:  this.loginForm.get("password").value
    }

    this.authService.login(credentials).subscribe(
      {
        next: (data) => {
          this.tokenService.saveToken(data.accessToken);
          this.tokenService.saveUser(data.user);
          this.router.navigate([""]);
        },
        error: (error: Error) => {
          this.snackbarRef = this.snackBar.open(error.message, '', {
            duration: 500,
            panelClass: ['error-snackbar', 'centered-text-snackbar'],
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
          });
        }
      }
    );

  }

}
