import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, Renderer2 } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-measure-settings',
  templateUrl: './measure-settings.component.html',
  styleUrls: ['./measure-settings.component.css']
})
export class MeasureSettingsComponent implements AfterViewInit {

  private isDragging = false;
  private startX: number;
  private startY: number;
  private currentX: number;
  private currentY: number;
  form: FormGroup;
  colorCtr: AbstractControl = new FormControl(null);
  public selection: string = 'LineString';

  @Output() selectionChanged = new EventEmitter();

  constructor(private el: ElementRef, private renderer: Renderer2) {

    this.selectionChanged.emit(this.selection);

    this.form = new FormGroup({
      'measureColor': new FormControl('rgba(255,255,200,1)'),
      'measureType': new FormControl('LineString')
    });



  }

  ngAfterViewInit()
  {
    this.selectionChanged.emit(this.form.getRawValue())
  }


  @HostListener('mousedown')
  onMouseDown(event: MouseEvent) {

    if(event === undefined)
    {
return;

    }

    this.isDragging = true;
    this.startX = event.clientX  - this.el.nativeElement.offsetLeft;
    this.startY = event.clientY - this.el.nativeElement.offsetTop;


  }
  @HostListener('mouseup')
  onMouseUp(event: MouseEvent) {

    this.isDragging = false;
  }
  @HostListener('mousemove')
  onMouseMove(event: MouseEvent) {

    if(event === undefined)
    {
      return;
    }
    if (this.isDragging ) {

      this.currentX = event.clientX - this.startX;
      this.currentY = event.clientY - this.startY;
      this.renderer.setStyle(this.el.nativeElement, 'top', `${this.currentY}px`);
      this.renderer.setStyle(this.el.nativeElement, 'left', `${this.currentX}px`);
    }
  }

  @HostListener('document:mouseup')
  onMouseLeave() {
    this.isDragging = false;
  }

  submit() {

    this.selectionChanged.emit(this.form.getRawValue())
  }
}
