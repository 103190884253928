import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { provideProtractorTestingSupport } from '@angular/platform-browser';
import { liveQuery } from 'dexie';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concatAll,
  from,
  startWith,
  Subscription,
  switchMap,
  throwError,
  tap
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { Connection, Repository } from 'typeorm';
import { ClippedPropertyLayer } from '../models/clippedLayers.model';
import {
  Action,
  DatabaseSyncAction,
  EntityType
} from '../models/databaseSync.model';
import { EnterpriseReport } from '../models/enterpriseReport.model';
import { FenceDexie } from '../models/fence.model';
import { PaddockDexie } from '../models/paddock.model';
import { PropertyDexie } from '../models/property.model';
import { User } from '../models/user.model';
import { AuthService } from './auth.service';
import { ClippedLayerService } from './BaseLayerServices/clippedLayer.service';
import { DexieDatabaseService } from './dexieIndexDB.service';
import { OfflineService } from './offline.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  public activeProperty = new BehaviorSubject<PropertyDexie>(null);

  public properties = this.auth.user.pipe(
    switchMap((value) => {
      return value == null
        ? []
        : liveQuery(() =>
            this.dexieService.properties
              .where({
                userID: this.user.Id
              })
              .toArray()
          );
    }),
    tap((value) => {
      value as Array<any>;

      if (value.length == 1) {
        this.activeProperty.next(value[0]);
      }
    })
  );

  private api = environment.apiURL;

  private userSub: Subscription;

  private databaseSub: Subscription;

  user: User;

  isOnline: boolean = true;

  constructor(
    private auth: AuthService,
    private clippedLayerService: ClippedLayerService,
    private http: HttpClient,
    private dexieService: DexieDatabaseService,
    private connectionService: OfflineService,
    private toastService: ToastService
  ) {
    connectionService.isOnline.subscribe((status) => (this.isOnline = status));
    this.userSub = auth.user.subscribe((user) => {
      this.user = user;
    });
  }

  async add(property: PropertyDexie) {
    await this.dexieService.properties.add(property);

    if (this.isOnline) {
      this.http
        .post(this.api + 'Property/add', property)
        .pipe(catchError(this.handelError))
        .subscribe(resp =>  this.toastService.showSuccess('Property created and synced'));
    } else {
      this.dexieService.databaseSyncActions.add(
        new DatabaseSyncAction(
          EntityType.Property,
          property.propertyID,
          Action.Insert
        )
      );

      this.toastService.showSuccess('Property created Locally')
    }

    this.activeProperty.next(property);
  }

  async remove(property: PropertyDexie) {
    //await this.clippedLayerService.deleteClippedLayers_Property(property.propertyID);

    await this.dexieService.properties.delete(property.propertyID);

    let paddocks = await this.dexieService.paddocks.where({
      propertyID: property.propertyID
    });

    paddocks.eachPrimaryKey(async (key) => {
      await this.dexieService.paddockClippedLayers
        .where({ paddockID: key })
        .delete();
      await this.dexieService.enterpriseReports
        .where({ paddockID: key })
        .delete();

      await this.dexieService.npvReports
      .where({paddockID: key })
      .delete();

      await this.dexieService.topexReports.where({ paddockID: key }).delete();
    });

    await paddocks.delete();

    await this.dexieService.fences
      .where({ propertyID: property.propertyID })
      .delete();
    await this.dexieService.enterpriseReports
      .where({ propertyID: property.propertyID })
      .delete();
    await this.dexieService.topexReports
      .where({ propertyID: property.propertyID })
      .delete();
    await this.dexieService.propertyClippedLayers
      .where({ propertyID: property.propertyID })
      .delete();

      await this.dexieService.npvReports
      .where({ propertyID: property.propertyID })
      .delete();

    if (this.isOnline) {
      this.http
        .post(
          this.api + `Property/delete?propertyID=${property.propertyID}`,
          ''
        )
        .pipe(catchError(this.handelError))
        .subscribe(resp =>  this.toastService.showSuccess('Property deleted and synced'));
    } else {
      this.dexieService.databaseSyncActions.add(
        new DatabaseSyncAction(
          EntityType.Property,
          property.propertyID,
          Action.Remove
        )
      );

      this.toastService.showSuccess('Property deleted Locally')
    }

    this.activeProperty.next(null);
  }

  async update(property: PropertyDexie) {
    property.modifiedOn = new Date().toUTCString();

    await this.dexieService.properties.update(property.propertyID, property);

    if (this.isOnline) {
      return this.http
        .post(this.api + 'Property/update', property)
        .pipe(catchError(this.handelError)).subscribe(resp => {
          this.toastService.showSuccess('Property updated and synced')
        });
    } else {
      this.dexieService.databaseSyncActions.add(
        new DatabaseSyncAction(
          EntityType.Property,
          property.propertyID,
          Action.Update
        )
      );

      this.toastService.showSuccess('Property Updated Locally')
    }
  }

  async load() {
    let loadedProperties = await this.dexieService.properties
      .where({
        userID: this.user.Id
      })
      .toArray();

    if (loadedProperties.length == 1) {
      this.activeProperty.next(loadedProperties[0]);
    }
  }

  loadProperties() {
    return from(
      this.dexieService.properties
        .where({
          userID: this.user.Id
        })
        .toArray()
    );
  }

  getClippedLayers(propertyID) {
    return this.http
      .get<Array<ClippedPropertyLayer>>(
        this.api + 'ClippedLayers/property/get' + `?propertyID=${propertyID}`
      )
      .pipe(catchError(this.handelError));
  }

  getPropertyReport(propertyID) {
    return this.http
      .get<EnterpriseReport>(
        this.api + 'EnterpriseReport/property/get' + `?propertyID=${propertyID}`
      )
      .pipe(catchError(this.handelError));
  }

  getPaddocks(propertyID) {
    return this.http
      .get<Array<PaddockDexie>>(
        this.api + 'Paddock/getAll' + `?propertyID=${propertyID}`
      )
      .pipe(catchError(this.handelError));
  }

  getFences(propertyID) {
    return this.http
      .get<Array<FenceDexie>>(
        this.api + 'Fence/getAll' + `?propertyID=${propertyID}`
      )
      .pipe(catchError(this.handelError));
  }

  getPropertiesFromDatabase() {
    return this.http
      .get<Array<PropertyDexie>>(this.api + 'Property/getAll')
      .pipe(catchError(this.handelError));
  }

  handelError(errorRes) {
    let errorMessage = 'An unknown error occurred!';

    if (!errorRes.error || !errorRes.error.result) {
      return throwError(() => {
        return new Error(errorMessage);
      });
    }

    switch (errorRes.error.result) {
      case 'INVALID_CREDENTIALS':
        errorMessage = 'Invalid Login Credentials';
        break;
      case 'EMAIL_EXISTS':
        errorMessage = 'An Account Already Exists With That Email';
        break;

      case 'EMAIL_NOT_CONFIRMED':
        errorMessage =
          'This user has not yet been activated. Please check your email to find the activation email before logging in.';
        break;

      case 'ACCOUNT_DISABLED':
        errorMessage =
          'Your account has been disabled, please contact a PFT admin';
        break;

      default:
        break;
    }

    return throwError(() => {
      return new Error(errorMessage);
    });
  }
}
