import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Select } from 'ol/interaction';
import { Fill, Stroke, Style } from 'ol/style';
import * as condition from 'ol/events/condition';
import { Feature, PluggableMap } from 'ol';
import { Control } from 'ol/control';
import { SpatialTool } from 'src/app/models/spatial-tools-mdoel';
import { SpatialToolsService } from 'src/app/services/spatial-tools.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit,AfterViewInit,OnDestroy,SpatialTool {

  @Input() map: PluggableMap;
  @Input() layers: Array<any>;
  @Output() FeatureSelected = new EventEmitter<Feature<any>>();


  interaction: Select;
  active = false;
  selectedFeatureStyle: Style;
  control: Control;

  spatialToolSub$: Subscription = new Subscription();


  constructor(private spatialToolService: SpatialToolsService,private elRef: ElementRef) {

    this.spatialToolSub$ = this.spatialToolService.activeTool.subscribe(activeTool => {
      if(activeTool == null)
      return;

      if(activeTool != this)
      {
        this.deSelect();
      }
    });

   }

  ngOnInit(): void {

    this.interaction = new Select({
      multi: false,
      condition: condition.click,
      layers: this.layers,
      hitTolerance: 10
    });

    this.interaction.on('select',this.featureSelected.bind(this));

  }

  ngAfterViewInit(): void {
    this.control = new Control({target: this.elRef.nativeElement.parentNode, element: this.elRef.nativeElement});
    this.map.addControl(this.control);
  }

  ngOnDestroy(): void {
    if(this.control == null)
    return;

    this.spatialToolSub$.unsubscribe();
    this.map.removeControl(this.control);
    this.deSelect();
  }

  toggleSelect()
  {
    if(this.active)
    {
      this.deSelect();
      return;
    }

    this.map.addInteraction(this.interaction);
    this.active = true;
    this.spatialToolService.activeTool.next(this);

  }

  private featureSelected(event){

    if(event.selected.length <= 0)
    return;

    let selectedFeature = event.selected[0];
    let properties = selectedFeature.getProperties();
    let featureId = selectedFeature.getId();



    // if(this.restrictToLayers)
    // {
    //   if(featureSelected)
    //   {
    //     this.restrictToLayers.forEach(lyr =>{

    //       let features = lyr.getSource().getFeatures() as Feature<any>[] | undefined;
    //       features.forEach(feature => {
    //         let props = feature.getProperties();
    //         let featureId = feature.getId();

    //         if(props[this.matchedProp] === selectedFeatureProperties[this.matchedProp] && (selectedFeatureId !== featureId))
    //         {
    //          this.interaction.getFeatures().push(feature);
    //         }

    //       });
    //     })
    //   }

    //   if(featureDeselected)
    //   {
    //     let props = evt.deselected[0]?.getProperties();
    //     this.interaction.getFeatures().clear();
    //   }
    // }

    let selectedFeatures = this.interaction.getFeatures().getArray();

    this.FeatureSelected.emit(selectedFeatures[0]);
  }

   deSelect() {
    if (!this.active) return;

    this.map.removeInteraction(this.interaction);
    this.interaction.getFeatures().clear();
    this.active = false;
  }
}
