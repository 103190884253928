import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import Draw from 'ol/interaction/Draw';
import Overlay from 'ol/Overlay';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { unByKey } from 'ol/Observable';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import CircleStyle from 'ol/style/Circle';
import { Feature, PluggableMap } from 'ol';
import { Control } from 'ol/control';
import { SpatialTool } from 'src/app/models/spatial-tools-mdoel';
import { Subscription } from 'rxjs';
import { SpatialToolsService } from 'src/app/services/spatial-tools.service';
import { LineString, Polygon } from 'ol/geom';
import { getArea, getLength } from 'ol/sphere';

@Component({
  selector: 'app-measure',
  templateUrl: './measure.component.html',
  styleUrls: ['./measure.component.css']
})
export class MeasureComponent implements OnInit,AfterViewInit,OnDestroy,SpatialTool {

  @Input() map: PluggableMap;

  active: boolean = false;
  interaction: Draw;
  toolTips: Array<Overlay> = [];
  layers: Array<VectorLayer<any>> = [];
  control: Control;

  spatialToolSub$: Subscription = new Subscription();

  constructor(private spatialToolService: SpatialToolsService, private elRef: ElementRef) {

    this.spatialToolSub$ = this.spatialToolService.activeTool.subscribe(activeTool => {
      if(activeTool == null)
      return;

      if(activeTool != this)
      {
        this.deSelect();
      }
    });

   }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.control = new Control({target: this.elRef.nativeElement.parentNode, element: this.elRef.nativeElement});
    this.map.addControl(this.control);
  }

  ngOnDestroy(): void {
    if(this.control == null)
    return;

    this.spatialToolSub$.unsubscribe();
    this.map.removeControl(this.control);
    this.deSelect();
  }

  deSelect() {

    if (!this.active) return;

    this.map.removeInteraction(this.interaction);
    this.active = false;

  }

  clear = () => {

    this.toolTips.forEach((element) => {
      this.map.removeOverlay(element);
    });

    const currentLayer = this.layers.pop();

    currentLayer.getSource().clear();

    this.layers.forEach((layer) => {
      this.map.removeLayer(layer);
    });

    this.toolTips = [];
    this.layers = [currentLayer];

  }

  toggleMeasure() {
    if (this.active) {
      this.deSelect();

    } else {
      this.active = !this.active;
      this.spatialToolService.activeTool.next(this);

    }


  }

  selectMeasureTool(selection: {measureType: string, measureColor: {hex: string, rgba:string} })  {

    if (this.interaction) {
      this.map.removeInteraction(this.interaction);
    }

    let layerSource = new VectorSource();

    let layer = new VectorLayer({source:layerSource});

    const styles = [
      new Style({
        image: new Circle({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
          }),
          stroke: new Stroke({
            color: selection.measureColor.rgba,
          }),
          radius: 5,
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, .5)',
        }),
        stroke: new Stroke({
          color: selection.measureColor.rgba,
          lineDash: [2, 5],
          width: 2,
        }),
      }),
    ];

    this.interaction = new Draw({
      source: layerSource,
      type: selection.measureType,
      style: styles,
    });


    this.map.addInteraction(this.interaction);
    this.map.addLayer(layer);
    this.layers.push(layer);

    this.interaction.on('drawstart', (event) => {
      const feature = event.feature;

      const measureTooltipElement = document.createElement('div');
      measureTooltipElement.className = 'esk-toolbar-tooltip';

      const measureTooltipOverlay = new Overlay({
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: 'bottom-center',
      });

      this.toolTips.push(measureTooltipOverlay);
      this.map.addOverlay(measureTooltipOverlay);

      feature.attributes = {
        tooltipElement: measureTooltipElement,
        tooltipOverlay: measureTooltipOverlay,
      };

      feature.getGeometry().on('change', () => {
        this.onFeatureChange(feature);
      });

      // User defined callback from constructor
    });

    this.interaction.on('drawend', (event) => {
      const feature = event.feature;

      unByKey(feature.attributes.onChangeListener);

      feature.attributes.tooltipElement.className = 'esk-toolbar-tooltip';
      feature.attributes.tooltipOverlay.setOffset([0, -7]);

      feature.setStyle(styles);

    });

    this.interaction.on('drawabort', (event) => {
      const feature = event.feature;
      this.map.removeOverlay(feature.attributes.tooltipOverlay);
    });

    this.interaction.on('error',  (event) => {

    });
  };



   onFeatureChange(feature: any) {

      // Check if feature has attributes and tooltipElement property, if not, it has no tooltip to update
      const hasTooltip = feature?.attributes?.tooltipElement;
      if(!hasTooltip) {
          return;
      }

      const geometry = event.target;

      let tooltipText;
      let tooltipPosition;

      if(geometry instanceof Polygon) {
          tooltipText = this.formatArea(geometry);
          tooltipPosition = geometry.getInteriorPoint().getCoordinates();
      }else if(geometry instanceof LineString) {
          tooltipText = this.formatLength(geometry);
          tooltipPosition = geometry.getLastCoordinate();
      }

      feature.attributes.tooltipElement.innerHTML = tooltipText;
      feature.attributes.tooltipOverlay.setPosition(tooltipPosition);
  }

   formatLength(line) {
      const length = getLength(line);

      if(length > 100) {
          return Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
      }

      return Math.round(length * 100) / 100 + ' ' + 'm';
  };

   formatArea(polygon) {
      const area = getArea(polygon, {projection: 'EPSG:28355'});

      if(area > 10000) {
          return (area / 1000000) * 100 / 100 + ' ' + 'km<sup>2</sup>';
      }

      return area * 100 / 100 + ' ' + 'm<sup>2</sup>';
  };



}
