<ng-container *ngIf="paddocks && property">
<form class="reporting-form" [formGroup]="form">


  <ng-container *ngIf="multi; else singleSelect">
    <div class="form-property-container">
      <h3>Property</h3>
      <mat-divider></mat-divider>
      <mat-checkbox formControlName="property"> {{ property.name }} </mat-checkbox>
    </div>

  <div class="form-paddocks-container">
  <h3>Paddocks</h3>
  <mat-divider></mat-divider>
  <div class="form-paddocks_items">

    <div *ngFor="let item of paddocks; let i = index" formGroupName="paddocks">
      <mat-checkbox formControlName="{{i}}" class="example-margin">{{ item.name }}</mat-checkbox>
    </div>

  </div>

  </div>

  </ng-container>

<ng-template #singleSelect>
  <mat-radio-group [formControl]="selectedTargetControl" aria-label="Select an option">

  <ng-container
  >
  <div class="form-property-container">
    <h3>Property</h3>
    <mat-divider></mat-divider>
      <mat-radio-button [checked]="true" [value]="property" class="example-margin">{{ property.name }}</mat-radio-button>
  </div>

  <div class="form-paddocks-container">
    <h3>Paddocks</h3>
    <mat-divider></mat-divider>
    <div class="form-paddocks_items">


    <mat-radio-button  *ngFor="let item of paddocks; let i = index" [value]="item" class="example-margin">{{ item.name }}</mat-radio-button>
  </div>
  </div></ng-container>
</mat-radio-group>

</ng-template>



<div class="form-details-container">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Details
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-content></ng-content>
  </mat-expansion-panel>
</div>


<div class="form-actions-container">
  <button (click)="onClick()" [disabled]="!form.valid" class="button button--brand">Run Report</button>
</div>
    <div *ngIf="form.errors?.['atLeastOneSelected']">This report requires at-least one paddock or property to be selected.</div>
  </form>
</ng-container>
