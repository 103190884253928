
import { v4 as uuidv4 } from 'uuid';

export class TopexReport {

  topexReportID: string = uuidv4();

  paddockID?: String;

  propertyID?: String;

  severelyExposed: number = 0;

  veryExposed: number = 0;

  moderatelyExposed: number = 0;

  moderatelySheltered: number = 0;

  verySheltered: number = 0;

  uploaded:boolean = false;

  createdOn: string = new Date().toUTCString();

  modifiedOn: string = new Date().toUTCString();

}
