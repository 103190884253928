<nav>
  <ul class="nav">
    <div class="flex-ible">
      <li class="nav--fullwidth"><a routerLink="/dashboard">Dashboard</a></li>
      <li class="nav--fullwidth"><a routerLink="/map">Map</a></li>
    </div>
    <li class="nav-help"><a (click)=" openAbout()">About</a></li>




    <li class="nav-item nav-login" ngbDropdown *ngIf="isAuthenticated">
      <button mat-button [matMenuTriggerFor]="menu">{{userFullName}}</button>
      <mat-menu #menu="matMenu">
        <button routerLink="/admin" appRole="['Administrator']" mat-menu-item>Admin</button>
        <button (click)="logout()"  mat-menu-item>Logout</button>
      </mat-menu>
    </li>


    <li class="nav-login" *ngIf="!isAuthenticated">
      <a routerLink="login">Login</a>
    </li>
  </ul>
</nav>
