import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    const formattedNumber = new Intl.NumberFormat('en-US').format(value);
    return `${formattedNumber}t`;
  }

}
