<div
  class="uk-column uk-padding-small@m uk-padding-remove@m"
  [formGroup]="form"
>
  <div class="uk-column-1-1">
    <p class="uk-margin-small uk-text-lead">
      Characteristics of threatened species in the area:
    </p>
  </div>
  <div class="uk-column-1-3@m uk-flex-row medium-flex">
    <div
      class="uk-column uk-flex-column uk-flex-1 uk-flex-bottom uk-margin-bottom"
    >
      <label class="fw-label uk-flex uk-flex-between overflow-hidden vertically-center-them"
        >Mobility
        <button
          type="button"
          class="icon-button btn btn-outline-secondary me-2 uk-button uk-button-link uk-padding-small uk-padding-remove-vertical"
          placement="top"
          ngbTooltip="More mobile species impose higher costs from being able to identify them and preventing risks posed to them in an environment"
        >
          <i class="fa fa-info"></i>
        </button>
      </label>
      <div class="uk-form-controls">
        <select
          formControlName="mobility"
          class="uk-select"
          id="form-horizontal-select"
        >
          <option *ngFor="let mb of mobility; let i = index" [value]="mb">
            {{ mb }}
          </option>
        </select>
      </div>
    </div>
    <div class="uk-column-1-3@m uk-flex-row medium-flex">
        <div class="uk-column uk-flex-column uk-flex-1 uk-flex-bottom uk-margin-bottom">
            <label class="fw-label uk-flex uk-flex-between overflow-hidden">Mobility
                <button type="button"
                    class="icon-button btn btn-outline-secondary me-2 uk-button uk-button-link uk-padding-small uk-padding-remove-vertical"
                    placement="top"
                    uk-tooltip="title:More mobile species impose higher costs from being able to identify them and preventing risks posed to them in an environment; pos: left; animation:uk-animation-slide-left;">
                    <i class="fa fa-info"></i>
                </button>
            </label>
            <div class="uk-form-controls">
                <select formControlName="Mobility" class="uk-select" id="form-horizontal-select">
                    <option *ngFor="let mb of mobility; let i = index" [value]="mb">{{mb}}</option>
                </select>
            </div>
        </div>
        <div class="uk-column uk-flex-column uk-flex-1 uk-flex-bottom uk-margin-bottom">
            <label class="fw-label uk-flex uk-flex-between overflow-hidden">Threatened Status
                <button type="button"
                    class="icon-button btn btn-outline-secondary me-2 uk-button uk-button-link uk-padding-small uk-padding-remove-vertical"
                    placement="top"
                    uk-tooltip="title:Critically endangered species and endangered species are likely to have stricter prescriptions taking into account the greater cost associated with harming their habitat; pos: left; animation:uk-animation-slide-left;">
                    <i class="fa fa-info"></i>
                </button>
            </label>
            <div class="uk-form-controls">
                <select formControlName="status" class="uk-select" id="form-horizontal-select">
                    <option *ngFor="let st of status; let i = index" [value]="st">{{st}}</option>
                </select>
            </div>
        </div>
        <div class="uk-column uk-flex-column uk-flex-1 uk-flex-bottom uk-margin-bottom">
            <label class="fw-label uk-flex uk-flex-between overflow-hidden">Uncertainity
                <button type="button"
                    class="icon-button btn btn-outline-secondary me-2 uk-button uk-button-link uk-padding-small uk-padding-remove-vertical"
                    placement="top"
                    uk-tooltip="title:Higher uncertainty requires stricter prescriptions as a precautionary approach; pos: left; animation:uk-animation-slide-left;">
                    <i class="fa fa-info"></i>
                </button>
            </label>
            <div class="uk-form-controls">
                <select formControlName="uncertainity" class="uk-select" id="form-horizontal-select">
                    <option *ngFor="let uc of uncertainty; let i = index" [value]="uc">{{uc}}</option>
                </select>
            </div>
        </div>
    </div>
    <div
      class="uk-column uk-flex-column uk-flex-1 uk-flex-bottom uk-margin-bottom"
    >
      <label class="fw-label uk-flex uk-flex-between overflow-hidden vertically-center-them"
        >Uncertainity
        <button
          type="button"
          class="icon-button btn btn-outline-secondary me-2 uk-button uk-button-link uk-padding-small uk-padding-remove-vertical"
          placement="top"
          ngbTooltip="Higher uncertainty requires stricter prescriptions as a precautionary approach"
        >
          <i class="fa fa-info"></i>
        </button>
      </label>
      <div class="uk-form-controls">
        <select
          formControlName="uncertainity"
          class="uk-select"
          id="form-horizontal-select"
        >
          <option *ngFor="let uc of uncertainty; let i = index" [value]="uc">
            {{ uc }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="uk-column uk-flex-column uk-flex-1 uk-flex-bottom uk-margin-bottom">
    <label class="fw-label uk-flex uk-flex-between overflow-hidden vertically-center-them">
      Total Actual Volume of Logs Available for Harvest after Mobility and
      Uncertainty Discounts applied (tonnes)
    </label>
    <div class="uk-form-controls">
      <input
        class="uk-input"
        formControlName="totalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount"
        type="number"
      />
    </div>
  </div>
</div>
