import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalculatorModalService {

  exitModalVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  confimExit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  navigateToURL: string | null;
  localStorageKey: string;

  constructor(private router: Router) {
    this.exitModalVisible.next(false)
    this.confimExit.next(false)
  }

  exitModalVisiblePopupHandler() {
    this.exitModalVisible.next(true)
  }

  confirmExitModalPopupHandler(localStorageItemKey: string) {
    this.confimExit.next(true)
    localStorage.removeItem(localStorageItemKey)
    if (this.navigateToURL) {
      this.router.navigate([this.navigateToURL])
    }

  }

  setShowExitModal(yesOrNo: boolean) {
    this.exitModalVisible.next(yesOrNo)
  }

  setConfirmExit(yesOrNo: boolean) {
    this.confimExit.next(yesOrNo)
  }

  clearLocalStorageAndReturnTrue() {
    localStorage.removeItem(this.localStorageKey)
    return true;
  }

  validateExitRequest(requestedURL) {
    this.navigateToURL = requestedURL;

    if (this.confimExit.value) {
      return true;
    } else {

      //  check if modal is visible
      if (!this.exitModalVisible.value) {
        this.exitModalVisible.next(true);
        return false;
      }
    }
  }
}
