import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {getMatInputUnsupportedTypeError } from '@angular/material/input';
import { PaddockDexie } from '../../../../models/paddock.model';
import { PropertyDexie } from '../../../../models/property.model';
import { CustomValidators } from '../../../../_helpers/customValidators';
import { BaseReportForm } from '../baseReportForm';

@Component({
  selector: 'app-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.css']
})
export class ReportCardComponent implements OnChanges, OnDestroy {
  form: FormGroup;
  @Input() property: PropertyDexie;
  @Input() paddocks: Array<PaddockDexie>;
  @Output() runReport = new EventEmitter();
  selectedTargetControl: FormControl;
  @Input() multi: boolean = false;


  constructor(private cdRef: ChangeDetectorRef) {

   }


ngOnChanges(changes: SimpleChanges): void {


    if(this.multi)
    {
        this.multiSelectForm();
    }
    else
    {
        this.singleSelectForm();
    }


}

ngOnDestroy(): void {
  this.form.removeControl('selectedTarget');
}





  private singleSelectForm(){
    this.selectedTargetControl = new FormControl<PaddockDexie| PropertyDexie>(this.property, CustomValidators.required)
    this.form = new FormGroup({
      'selectedTarget': this.selectedTargetControl,
    },[CustomValidators.required]);





  }

  private multiSelectForm(){
    const paddockFormControls = this.paddocks.map(paddock => new FormControl(true));

    this.form = new FormGroup({
      'property': new FormControl<boolean>(true),
      'paddocks': new FormArray(paddockFormControls)
    },[this.atLeastOneSelected]);

    this.form.valueChanges.subscribe((value) => {
      console.log(this.selectedPaddocks);
    });

  }



  get reportingTargets():Array<PaddockDexie | PropertyDexie> | PropertyDexie | PaddockDexie {

    if(this.multi)
    {
      let reportingTargets : Array<PaddockDexie | PropertyDexie> = this.form.get('property').value ? [this.property] : [];


      return [...reportingTargets, ...this.selectedPaddocks];
    }

    else
    {


      return this.form.get('selectedTarget').value;
    }


  }

  atLeastOneSelected(formGroup: FormGroup) {
      let totalSelected = formGroup.controls.paddocks.value
        .map(control => control)
        .reduce((prev, next) => next ? prev + next : prev, 0);

        totalSelected = totalSelected + formGroup.controls.property.value ? 1 : 0;



      return totalSelected >= 1 ? null : { atLeastOneSelected: true };
    }

     /**
 * It returns a sorted array of paddocks that have been selected in the form
 * @returns An array of paddocks that have been selected by the user.
 */
get selectedPaddocks() {
  return this.paddocks.filter((item, i) => this.form.value.paddocks[i]).sort((a,b) => {

    let nameA = a.name.toUpperCase();
    let nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
}




onClick()
{
this.runReport.emit(this.reportingTargets);

}

}
