import { Component, Input, OnInit, Output, EventEmitter, SimpleChange, OnChanges, SimpleChanges } from '@angular/core';
import { NpvCalculatorDataStorageService } from 'src/app/services/npv-calculator-data-storage.service';


@Component({
  selector: 'app-harvesting-haulage-costs',
  templateUrl: './harvesting-haulage-costs.component.html',
  styleUrls: ['./harvesting-haulage-costs.component.css']
})
export class HarvestingHaulageCostsComponent implements OnInit, OnChanges {

  @Input() typeOfForestryOperation: number;
  @Input() totalPotentialLogsHarvestedPerYear: number;
  @Input() totalActualVolumeLogsHarvestedPerYear: number;
  @Input() harvestingHaulageCosts: string;

  @Input() plantationCostInputsClearfelling: number = 0;
  @Input() plantationCostInputsThinning: number = 0;

  @Input() discountRate: number;
  @Input() harvestLifespan: number | string;

  @Output() calculationsValueEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private npvCalcService: NpvCalculatorDataStorageService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

    // Passing values to the parent component from
    if (changes) {
      const calculationData = {
        "Harvesting-Haulage Cost": {
          "Type of Forestry Operation": this.typeOfForestryOperation,
          "Total Actual Volume of Logs Harvested (tonne)": this.totalActualVolumeLogsHarvestedPerYear,
          "Harvesting and Haulage Costs ($/tonne)": this.calculateHarvestHaulageValue(),
          "Monetary Value": this.calculateMonetaryValue(),
          "Discount Rate": this.discountRate,
          "Harvest Lifespan": this.harvestLifespan,
          "PV(C)": this.calculatePVC().toFixed(2),
        }
      }
      this.calculationsValueEmitter.emit(calculationData)
    }

  }

  calculateActualVolumnOfLogsHarvested() {
    return this.totalPotentialLogsHarvestedPerYear * (1 - this.totalActualVolumeLogsHarvestedPerYear);
  }

  calculateHarvestHaulageValue() {
    // const monetaryValue = this.calculateActualVolumnOfLogsHarvested() * this.harvestingHaulageCosts
    let valueForCalculation = 0;

    //Mod Luke - There was no null check here, also should this be a pipe?
    if(this.harvestingHaulageCosts === null || this.harvestingHaulageCosts === undefined)
    return valueForCalculation;


    if (this.harvestingHaulageCosts && this.harvestingHaulageCosts.toLowerCase().includes("clearfelling")) {
      valueForCalculation = this.plantationCostInputsClearfelling
    } else {
      valueForCalculation = this.plantationCostInputsThinning
    }
    return valueForCalculation;
  }

  calculateMonetaryValue() {
    // const monetaryValue = this.calculateActualVolumnOfLogsHarvested() * this.harvestingHaulageCosts



    let valueForCalculation = 0;


        //Mod Luke - There was no null check here, also should this be a pipe?
        if(this.harvestingHaulageCosts === null || this.harvestingHaulageCosts === undefined)
        return valueForCalculation;

    if (this.harvestingHaulageCosts && this.harvestingHaulageCosts.toLowerCase().includes("clearfelling")) {
      valueForCalculation = this.plantationCostInputsClearfelling
    } else {
      valueForCalculation = this.plantationCostInputsThinning
    }

    const monetaryValue = this.totalActualVolumeLogsHarvestedPerYear * valueForCalculation
    return monetaryValue
  }

  // =IF($C32="Indefinite",$C30/$C31,$C30*((1-(1+$C31)^-$C32)/$C31))
  calculatePVC() {

    if (this.harvestLifespan === "Indefinate") {

      const cPVC = this.calculateMonetaryValue() * (this.discountRate / 100)
      this.npvCalcService.updateCosts({ key: 'harvesting-haulage-costs', value: cPVC })
      return cPVC;

    } else {

      // const cPVC = this.calculateMonetaryValue() * ((1 - (1 + (this.discountRate / 100)) ** -this.harvestLifespan) / (this.discountRate / 100))
      const cPVC = this.calculateMonetaryValue() * ((1 - (1 + (this.discountRate / 100)) ** -(this.harvestLifespan)) / (this.discountRate / 100))
      this.npvCalcService.updateCosts({ key: 'harvesting-haulage-costs', value: cPVC })
      return cPVC;
    }
  }

}
