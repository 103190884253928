/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import {  MatSelectChange } from '@angular/material/select';
import {  MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSliderDragEvent } from '@angular/material/slider';
import { Feature } from 'ol';
import { Layer } from 'ol/layer';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import { switchMap, of, map } from 'rxjs';
import { PaddockDexie } from 'src/app/models/paddock.model';
import { ClippedLayerService } from 'src/app/services/BaseLayerServices/clippedLayer.service';
import { PropertyService } from 'src/app/services/property.service';
import { versatilityGradientColors } from 'src/app/_helpers/layer.styles';
import { ChildEntity } from 'typeorm';

@Component({
  selector: 'app-analysis-group',
  templateUrl: './analysis-group.component.html',
  styleUrls: ['./analysis-group.component.css']
})
export class AnalysisGroupComponent implements OnInit {

  @Input() layerGroup: LayerGroup;

  @Input() paddockSelect: boolean = false;

  @Input() paddocks: Array<PaddockDexie>;

  @Output() showPaddocks = new EventEmitter<Array<PaddockDexie>>();
  @Output() downloadLayers = new EventEmitter<boolean>();

  activeLayer: VectorLayer<any> | Layer<any,any> = null;
  paddocksForm = new FormControl();
  colorIndex = [];

  $needsDownloading

  constructor(private clippedLayerService: ClippedLayerService, private propertyService: PropertyService) {

    this.$needsDownloading = this.propertyService.activeProperty.pipe(switchMap(property => {

      return property == null ? of([]) : this.clippedLayerService.getClippedLayers_Property(property.propertyID)} ),map((layers)=> {
        return  layers.length == 0 ? true : layers.find(lyr => lyr.layerName.toLowerCase() == 'topex') == null ? true : false;
      }));

  }

  ngOnInit(): void {

    for (const [key, value] of Object.entries(versatilityGradientColors)) {

      let colorIndex = {
        name: key,
        color: `rgba(${value})`
      }

      this.colorIndex.push(colorIndex);
    }

    let ChildLayers = this.layerGroup.getLayersArray();

    this.activeLayer = ChildLayers.find((layer) => {return layer.get('label') == 'Radiata Pine'});

    if(this.activeLayer != null)
    {
      this.activeLayer.setVisible(true);
    }

    ChildLayers.forEach(layer => {
      layer.on('change:visible', this.childLayerVisibilityChanged.bind(this));
    })
  }

  onPaddockSelected(isOpen)
  {

    if(!isOpen)
    {
     this.showPaddocks.emit(this.paddocksForm.value);
    }
  }

  private childLayerVisibilityChanged(event) {
    let childLayer = event.target;

    if (!childLayer.getVisible()) return;

    this.layerGroup
      .getLayers()
      .getArray()
      .forEach((l) => {
        if (l != childLayer) {
          l.setVisible(false);
        }
      });

    if(this.activeLayer != childLayer)
    {
      this.activeLayer = childLayer;
    }
  }

  selectionChanged(selection: MatSelectChange)
  {
    let layer = selection.value !== null ? selection.value as VectorLayer<any> : null;

    if(layer)
    {
      layer.setVisible(true);
    }

  }


  getLayerGroupOpacityLabel() : string
  {
    return (this.layerGroup.getOpacity() * 100) + '%';
  }

  getLayerGroupOpacity(): Number {
    return (this.layerGroup.getOpacity() * 100);
  }

  setLayerGroupOpacity(event: MatSliderDragEvent) : void
  {
    this.layerGroup.setOpacity(event.value / 100);
  }

  getLayerGroupVisibility(): boolean
  {
    return this.layerGroup.getVisible();
  }

  setLayerGroupVisibility(event: MatSlideToggleChange) : void
  {
    this.layerGroup.setVisible(event.checked);
  }

  formatLabel(value: number) {
    return value + '%';
  }

}
