<div class="container-fluid p-0">
  <div id="app-market-guidelines">
    <div class="row">
      <h4>Choose a Market Type and Topic to gain more information.</h4>

      <div class="col-12 my-2">
        <label class="form-label">Market Type</label>
        <select
          (change)="marketGuideService.marketTypeChangeHandler($event)"
          class="form-select"
          aria-label="Service label"
        >
          <option value="" selected>-- select service type ---</option>
          <option
            *ngFor="let service of marketGuideService.marketTypeList"
            value="{{ service }}"
          >
            {{ service }}
          </option>
        </select>
      </div>

      <div
        [@inOutAnimation]
        *ngIf="marketGuideService.selectedMarketType"
        class="col-12 my-2"
      >
        <label class="form-label">Topic</label>
        <select
          (change)="
            marketGuideService.selectTopicHandler($event); passValuesToParent()
          "
          class="form-select"
          aria-label="Topic label"
        >
          <option value="" selected>-- select topic --</option>
          <option
            *ngFor="let topic of marketGuideService.marketTopicList"
            value="{{ topic }}"
          >
            {{ topic }}
          </option>
        </select>
      </div>

      <div
        [@inOutAnimation]
        *ngIf="marketGuideService.selectedTopic"
        class="col-12 my-2"
      >
        <div class="col-12 my-2">
          <h5 class="mt-3">Overview</h5>
          <p [innerHTML]="marketGuideService.overviewDescription"></p>
          <p>
            <a target="_blank" href="{{ marketGuideService.overviewURL }}"
              >Learn more</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row">
        <div [@inOutAnimation] *ngIf="marketGuideService.selectedTopic" class="col mt-3">
            <button (click)="previewPDFHandler()" class="btn btn-primary">Preview PDF</button>
        </div>
    </div> -->
</div>
