import { Component, OnInit } from '@angular/core';
import {AbstractControl, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { AuthService } from 'src/app/services/auth.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  errorMessage: string = null;
  registerForm: UntypedFormGroup;
  submitted: boolean = false;
  error: boolean = false;

  constructor(private authService: AuthService,private _accountService: AccountService, private router: Router) { }



  ngOnInit(): void {

    this.registerForm = new UntypedFormGroup({
      'firstName': new UntypedFormControl(null, CustomValidators.required),
      'surname': new UntypedFormControl(null, CustomValidators.required),
      'email': new UntypedFormControl(null, {
        validators: [CustomValidators.required, CustomValidators.email],
        asyncValidators: this.forbiddenEmails,
        updateOn: 'blur'
      }),
      'passwordData' : new UntypedFormGroup({
        'password': new UntypedFormControl(null, [CustomValidators.passwordHasUpper, CustomValidators.minLength(8), CustomValidators.passwordHasSpecial, CustomValidators.required]),
        'rptPassword': new UntypedFormControl(null,CustomValidators.required)
      },{validators: [CustomValidators.passwordsMatch]})
  })

}

  onSubmit(){
    this.submitted = true;
    this.authService.register({
      firstName: this.registerForm.get('firstName').value as string,
      surname: this.registerForm.get('surname').value as string,
      email: this.registerForm.get('email').value as string,
      password: this.registerForm.get('passwordData.password').value as string
    }).subscribe(
      {
        next: (data) => {

        },
        error: (error) => {
          this.errorMessage = error.message;
          this.error = true;
        }
      }
    );
  }

  forbiddenEmails: AsyncValidatorFn = (control: AbstractControl):  Promise<any> | Observable<any> =>
  {
    return this._accountService.checkEmail(control.value).pipe
    (
      map((exists: any) => {
        return exists.result ? {emailExists: true}: null
      }
        )
    );
  }




}
