<div class="uk-column uk-padding-small@m uk-padding-remove@m uk-margin-small-bottom" [formGroup]="form">

    <div class="uk-column-1-2@m uk-flex-row medium-flex">
        <div class="uk-column uk-flex-column uk-flex-1 uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1 fw-label uk-flex uk-flex-between overflow-hidden vertically-center-them">
                Fixed cost of prescriptions - Base Level ($)
                <button type="button"
                    class="icon-button btn btn-outline-secondary me-2 uk-button uk-button-link uk-padding-small uk-padding-remove-vertical"
                    placement="top"
                    uk-tooltip="title:Estimated cost of base level prescriptions; pos: left; animation:uk-animation-slide-left;">
                    <i class="fa fa-info"></i>
                </button>
            </label>
            <input class="uk-input" formControlName="fixedCostOfPrescriptionsBaseLayer" type="number" placeholder="">
        </div>
        <div class="uk-column uk-flex-column uk-flex-1 uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1 fw-label uk-flex uk-flex-between overflow-hidden vertically-center-them">
                Cost of FPP ($/tonne)
                <button type="button"
                    class="icon-button btn btn-outline-secondary me-2 uk-button uk-button-link uk-padding-small uk-padding-remove-vertical"
                    placement="top"
                    uk-tooltip="title:Estimated cost of base level Forest Practices Plan (FPP) preparation; pos: left; animation:uk-animation-slide-left;">
                    <i class="fa fa-info"></i>
                </button>
            </label>
            <input class="uk-input" formControlName="costOfFPPBaseLayer" type="number" placeholder="">
        </div>
    </div>

    <ng-content></ng-content>

    <div class="uk-column-1-2@m uk-flex-row medium-flex">
        <div class="uk-column uk-flex-column uk-flex-1 uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1 fw-label uk-flex uk-flex-between overflow-hidden vertically-center-them">
                Fixed Cost of Prescriptions - Accounting for Threatened Species ($)
                <button type="button"
                    class="icon-button btn btn-outline-secondary me-2 uk-button uk-button-link uk-padding-small uk-padding-remove-vertical"
                    placement="top"
                    uk-tooltip="title:Estimated increase in cost of prescriptions accounting for mobility, level of endangerment and uncertainty for Threatened Species; pos: left; animation:uk-animation-slide-left;">
                    <i class="fa fa-info"></i>
                </button>
            </label>
            <input class="uk-input" formControlName="fixedCostOfPrescriptionsBaseLayerAccounting" type="number" placeholder="">
        </div>
        <div class="uk-column uk-flex-column uk-flex-1 uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1 fw-label uk-flex uk-flex-between overflow-hidden vertically-center-them">
                Cost of FPP - Accounting for Threatened Species ($/tonne)
                <button type="button"
                    class="icon-button btn btn-outline-secondary me-2 uk-button uk-button-link uk-padding-small uk-padding-remove-vertical"
                    placement="top"
                    uk-tooltip="title:Estimated increase in cost of Forest Practices Plan (FPP) preparation accounting for mobility, level of endangerment and uncertainty for Threatened Species; pos: left; animation:uk-animation-slide-left;">
                    <i class="fa fa-info"></i>
                </button>
            </label>
            <input class="uk-input" formControlName="costOfFPPBaseLayerAccounting" type="number" placeholder="">
        </div>
    </div>
</div>
