import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest, map } from 'rxjs';
import { EnterpriseType } from 'src/app/models/enterpriseType.model';
import { LandUseType } from 'src/app/models/landUseType.model';
import { PaddockDexie } from 'src/app/models/paddock.model';
import { DropDownService } from 'src/app/services/dropdown.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';

@Component({
  selector: 'app-create-paddock',
  templateUrl: './create-paddock.component.html',
  styleUrls: ['./create-paddock.component.css']
})
export class CreatePaddockComponent implements OnInit {


  createPaddockForm: UntypedFormGroup;

  filteredEnterprises$;

  constructor(public dialogRef: MatDialogRef<CreatePaddockComponent>,@Inject(MAT_DIALOG_DATA) public paddock: PaddockDexie, public dropdownService: DropDownService) { }

  ngOnInit(): void {

    this.dialogRef.disableClose = true;



    this.createPaddockForm = new UntypedFormGroup(
      {
      'cropType': new UntypedFormControl(null, CustomValidators.required),
      'landuseType': new UntypedFormControl(null, CustomValidators.required),
      'name': new UntypedFormControl(null, CustomValidators.required),
      'notes': new UntypedFormControl(null)
    },
    );

    this.filteredEnterprises$ = combineLatest([this.dropdownService.enterpriseTypes,this.createPaddockForm.get('landuseType').valueChanges]).pipe(
      map(
        ([enterpriseTypes,landUseType])=>
        enterpriseTypes.filter(
          (enterpriseType:EnterpriseType)=>
          enterpriseType.landUseTypeID == landUseType.landUseTypeID
        )
       )
    );

  }

  submit()
  {
    this.paddock.name = this.createPaddockForm.get('name').value;
    this.paddock.enterpriseType = this.createPaddockForm.get('cropType').value.name;
    this.paddock.landUseType = this.createPaddockForm.get('landuseType').value.name;
    this.paddock.notes = this.createPaddockForm.get('notes').value;

    this.dialogRef.close(this.paddock);
  }

}
