import { Injectable } from '@angular/core';
import localforage from 'localforage'; // this works!!!
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpatialToolsService {

  activeTool = new BehaviorSubject(null)
  ;
  constructor() {

  }


}
