import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { CalculatorNavigationService } from 'src/app/services/calculator-navigation.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {

  @Input() progressMade: number;
  @Input() activeStage: number;
  @Output() notifyParent = new EventEmitter();
  navigationStages: string[] = [];
  selectedStage: number;

  constructor(private navigationService: CalculatorNavigationService) { }

  ngOnInit(): void {
    this.navigationService.validStages
    for (let x in this.navigationService.validStages) {
      this.navigationStages.push(this.navigationService.validStages[x])
    }
  }

  ngAfterViewInit(): void {

  }

  setStageHandler(stageNumber: number) {
    this.notifyParent.emit(stageNumber + 1)
    this.selectedStage = stageNumber
  }

}
