import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroupDirective, FormGroup } from '@angular/forms';
import { TreeRotationService } from 'src/app/services/tree-rotation.service';

@Component({
  selector: 'app-return-from-forestry',
  templateUrl: './return-from-forestry.component.html',
  styleUrls: ['./return-from-forestry.component.css']
})
export class ReturnFromForestryComponent implements OnInit {

  // Props
  @Input() actualVolumnOfLogsHarvested: number = 0;
  @Input() markUp: number = 0;
  @Input() impactOnHarvestRatePercentages: number[];
  @Input() potentialVolumnOfLogsHarvested: number;
  @Input() landSize: number;
  @Input() harvetDuration: number;
  @Input() typeOfForestry: string;
  @Input() formGroupName: string;

  form: FormGroup;
  labelCalculationNumber: number = 0;
  labelLifeSpanNumber: number = 0;

  constructor(private rootFormGroup: FormGroupDirective, private treeRotationService: TreeRotationService) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  ngOnChanges(C: SimpleChanges) {
    // const potentialValue = C["potentialVolumnOfLogsHarvested"].currentValue + (this.landSize * sumOfOperation);
    const sumOfOperation = this.sumOfForestryOpeartionSum(this.typeOfForestry)
    const potentialValue = (this.landSize * sumOfOperation);

    /**
     * if the typeOfForestry === "Native forest - Clearfelling"
     * if the typeOfForestry === "Native forest - Thinning"
     * if the typeOfForestry === "Plantation - Clearfelling"
     * if the typeOfForestry === "Plantation - Thinning"
    */

    if (potentialValue) {
      this.form["controls"]["TotalPotentialVolumeOfLogsAvailableForHarvest"].setValue(potentialValue);
      this.form["controls"]["totalPotentialVolumeOfLogsAvailableForHarvest"].setValue(potentialValue)
    }

    if (C["impactOnHarvestRatePercentages"]) {
      let sum = 0;
      this.impactOnHarvestRatePercentages.forEach(percentage => {
        sum += percentage;
      })
    }

    // Rechecking labelCalculationNumber if it has been updated
    this.labelCalculationNumber = this.treeRotationService.breakRepitition[this.typeOfForestry];
    this.labelLifeSpanNumber = this.treeRotationService.maxDuration;

  }

  sumOfForestryOpeartionSum(typeOfForestry: string): number {
    const numberRange = this.treeRotationService.tonnerPerHectar_Range[typeOfForestry];
    let sum = 0;
    for (let index = 0; index < this.harvetDuration; index++) {
      const element = numberRange[index];
      sum += element;
    }
    return sum;
  }

  setSawlogPrice(e) {
  }

  setArisingsPrice(e) {
  }

  setPeelerLogPrice(e) {
  }

}
