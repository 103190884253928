<div class="full-screen-container">
  <section class="forgot-password">

    <form
      [formGroup]="forgotPasswordForm"
      (ngSubmit)="onSubmit()"
      class="forgot-password_form"
      *ngIf="!submitted; else showSuccess"
    >
    <header>
      <h2>Forgot Password</h2>
    </header>
    <fieldset>
      <div class="grid">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput placeholder="example@example.com.au">
          <mat-error *ngIf="forgotPasswordForm.get('email').invalid && (forgotPasswordForm.get('email').dirty || forgotPasswordForm.get('email').touched)">
            <div *ngIf="forgotPasswordForm.get('email').errors?.['required']">
              Email is required.
            </div>
            <div *ngIf="forgotPasswordForm.get('email').errors?.['email']">
              Please enter a valid email address.
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </fieldset>
          <button [disabled]="!forgotPasswordForm.valid" type="submit" class="button button--brand">
            Submit
          </button>
    </form>

    <ng-template #showSuccess>
      <div class="success">
        <i class="fa fa-5x fa-check-circle"></i>
        <p>An email has been sent with a link to reset your password</p>
      </div>
    </ng-template>


</section>

</div>

