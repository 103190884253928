<table class="uk-table uk-table-responsive uk-table-striped">
  <thead>
    <tr>
      <th scope="col" >Id</th>
      <th scope="col">First Name</th>
      <th scope="col">
Surname
      </th>
      <th scope="col">
        Email
      </th>
      <th scope="col">
        Email Confirmed
      </th>
      <th scope="col">
        Enabled
      </th>
      <th scope="col">
        Roles
      </th>
      <th scope="col">
        Last Login Date
      </th>
      <th scope="col">
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let user of users; index as i">
      <td>{{user.userID}}</td>
      <td>{{user.firstName}}</td>
      <td>{{user.surname}}</td>
      <td>{{user.email}}</td>
      <td>{{user.isEmailConfirmed}}</td>
      <td>{{user.isEnabled}}</td>
      <td>{{user.roles}}</td>
      <td>{{convertToLocalDate(user.lastLoginDate)}}</td>
      <td><button class="button button--blue" (click)="requestPasswordReset(user.email)">Reset Password</button></td>
    </tr>
  </tbody>
</table>


