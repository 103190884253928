import { PaddockDexie } from "../models/paddock.model"
import { PropertyDexie } from "../models/property.model"

export interface ITopexReport {
  data: Array<ITopexReportData>
}

export interface ITopexReportData {
target: PropertyDexie | PaddockDexie;
data: Array<object>
}
