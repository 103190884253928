<div class="container-fluid p-0">
  <div class="row">
    <div class="col">
      <ng-content></ng-content>
      <div class="container-fluid p-0">
        <!-- <div class="row">
            <h2 class="">Species and Planting Materials required for "Target Property/Paddock"</h2>
          </div> -->
        <h4>Introduction</h4>

        <div class="row">
          <p class="">
            This form provides guidance on the types of tree species you can
            plant for different end-uses, materials likely required at the
            time of establishment, and advice on sourcing seedlings and planting
            labour. Note that you will likely have to order seedlings up to a
            year in advance, so good planning is essential.
          </p>
        </div>

        <div class="row">
          <p class="">
            For any timber production enterprise, genetics matter so be sure to
            discuss genetics options with your supplier prior to purchase.
          </p>
        </div>

        <div class="row">
          <div class="my-2">
            <label class="form-label fs-6">Primary Use</label>
            <select
              (change)="
                questionGenrationService.generateSecondaryUseItemList($event)
              "
              class="form-select"
              aria-label="Default select example"
            >
              <option selected>Select the primary use</option>
              <option
                *ngFor="let item of questionGenrationService.primaryUseArray"
                value="{{ item }}"
              >
                {{ item }}
              </option>
            </select>
          </div>

          <div
            [@inOutAnimation]
            *ngIf="questionGenrationService.secondaryUseArray.length > 0"
            class="my-2"
          >
            <label class="form-label fs-6">Secondary Use</label>
            <select
              (change)="
                questionGenrationService.generateRecommendedSpeciesUseItemList(
                  $event
                )
              "
              class="form-select"
              aria-label="Default select example"
            >
              <option selected>Select the Secondary use</option>
              <option
                *ngFor="let item of questionGenrationService.secondaryUseArray"
                value="{{ item }}"
              >
                {{ item }}
              </option>
            </select>
          </div>

          <div
            [@inOutAnimation]
            *ngIf="questionGenrationService.recommendedSpeciesArray.length > 0"
            class="my-2"
          >
            <label class="form-label fs-6">Recommended species</label>
            <select
              (change)="
                questionGenrationService.generateRecommendedSpeciesTable(
                  $event
                );
                passValuesToParent()
              "
              class="form-select"
              aria-label="Default select example"
            >
              <option selected>Select recommended species</option>
              <option
                *ngFor="
                  let item of questionGenrationService.recommendedSpeciesArray
                "
                value="{{ item }}"
              >
                {{ item }}
              </option>
            </select>
          </div>
        </div>

        <!-- <div [@inOutAnimation] *ngIf="questionGenrationService.selectedRecommendedSpecies" class="row mt-3">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Species Description</th>
                  <th scope="col">Recommended Planting Layout</th>
                  <th scope="col">Along Row Spacing</th>
                  <th scope="col">Between Row Spacing</th>
                  <th scope="col">URL to Info Sheet</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">{{questionGenrationService.selectedRecommendedSpeciesTable["Species Description"]}}</td>
                  <td scope="row">
                    {{questionGenrationService.selectedRecommendedSpeciesTable["Recommended Planting Layout"]}}</td>
                  <td scope="row">{{questionGenrationService.selectedRecommendedSpeciesTable["Along Row Spacing"]}}</td>
                  <td scope="row">{{questionGenrationService.selectedRecommendedSpeciesTable["Between Row Spacing"]}}</td>
                  <td scope="row">{{questionGenrationService.selectedRecommendedSpeciesTable["URL to Info Sheet"]}}1</td>
                </tr>
              </tbody>
            </table>
          </div> -->

        <div
          [@inOutAnimation]
          *ngIf="questionGenrationService.selectedRecommendedSpecies"
          class="row mt-4"
        >
          <h5 class="">
            Guidelines for planting layout based on your chosen use and species
          </h5>
        </div>

        <div
          class="row mt-3"
          [@inOutAnimation]
          *ngIf="questionGenrationService.selectedRecommendedSpecies"
        >
          <div class="col col-12 col-sm-12 col-md-9">
            <div class="mb-3">
              <label class="form-label fw-bolder">Species Description</label>
              <p
                [innerHTML]="
                  questionGenrationService.selectedRecommendedSpeciesTable[
                    'Species Description'
                  ]
                "
              ></p>
            </div>
          </div>

          <div class="col col-12 col-sm-12 col-md-3">
            <div class="mb-3 text-wrap-something">
              <label class="form-label fw-bolder">More information</label>
              <p>
                <a
                  target="_blank"
                  [href]='questionGenrationService.selectedRecommendedSpeciesTable["URL to Info Sheet"]'
                  >Species Guides</a
                >
              </p>
            </div>
          </div>

          <div
            class="formGroupElement row pe-sm-0 pe-md-auto"
            *ngIf="questionGenrationService.materialSpeciesPlantingForm"
            [formGroup]="questionGenrationService.materialSpeciesPlantingForm"
          >
            <div class="col-12 col-sm-12 col-lg-4">
              <div class="mb-3">
                <label class="form-label fw-bolder"
                  >Recommended planting layout</label
                >
                <!-- <input type="text" class="form-control" (keyup)="questionGenrationService.recalculateCalculationsValues()" formControlName="recommendedPlantingLayout"
                    placeholder="Recommended planting layout"> -->
                <p>
                  {{
                    questionGenrationService.selectedRecommendedSpeciesTable[
                      'Recommended Planting Layout'
                    ]
                  }}
                </p>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-lg-4">
              <div class="mb-3">
                <label class="form-label fw-bolder">Along row spacing:</label>
                <input
                  appNumbersOnlyInputDirective
                  type="number"
                  class="form-control"
                  (keyup)="
                    questionGenrationService.recalculateCalculationsValues();
                    passValuesToParent()
                  "
                  formControlName="alongRowSpacing"
                  placeholder="Along row spacing "
                />
              </div>
            </div>

            <div class="col-12 col-sm-12 col-lg-4">
              <div class="mb-3">
                <label class="form-label fw-bolder">Between row spacing:</label>
                <input
                  appNumbersOnlyInputDirective
                  type="number"
                  class="form-control"
                  (keyup)="
                    questionGenrationService.recalculateCalculationsValues();
                    passValuesToParent()
                  "
                  formControlName="betweenRowSpacing"
                  placeholder="Between row spacing"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          [@inOutAnimation]
          *ngIf="questionGenrationService.selectedRecommendedSpecies"
          class="row mt-4"
        >
          <h5 class="">Calculations</h5>
        </div>

        <div
          class="row mt-3"
          [@inOutAnimation]
          *ngIf="questionGenrationService.selectedRecommendedSpecies"
        >
          <div class="col">
            <div class="mb-3">
              <label class="form-label fw-bolder"
                >Stems per hectare (SPH)</label
              >
              <p class="fs-5">
                {{ questionGenrationService.stemsPerHectare | toPrecision }}
              </p>
            </div>
          </div>

          <div class="col">
            <div class="mb-3">
              <label class="form-label fw-bolder"
                >Number seedlings required</label
              >
              <p class="fs-5">
                {{ questionGenrationService.numberSeedingsRequired | number }}
              </p>
            </div>
          </div>

          <div class="col">
            <div class="mb-3">
              <label class="form-label fw-bolder"
                >Number tree socks required</label
              >
              <p class="fs-5">
                {{ questionGenrationService.numberTreeStocksRequired | number }}
              </p>
            </div>
          </div>

          <div class="col">
            <div class="mb-3">
              <label class="form-label fw-bolder"
                >Amount of fertiliser required (kg)
              </label>
              <p class="fs-5">
                {{ questionGenrationService.amountFertiliserRequired | number }}
              </p>
            </div>
          </div>

          <div class="col-12">
            <p class="fs-6">
              Fertiliser amount is based on an assumed 125g DAP (Di Ammonium
              Phosphate) per seedling. Please note that although fertiliser
              application at planting is a standard practice in the forestry
              industry, it is optional, and you should seek further advice as to
              whether this is required or not for your site. Generally,
              ex-pasture sites do not require fertiliser application.
            </p>
          </div>

          <!-- <div class="col mt-3">
                <button class="btn btn-primary">Preview PDF</button>
              </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
