import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { QuestionGenerationService } from '../question-generation.service';
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'app-materials-species-planting',
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, maxHeight: 0 }),
            animate('0.75s ease-in',
              style({ opacity: 1, maxHeight: 1000 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1, maxHeight: 1000 }),
            animate('0.25s ease-out',
              style({ maxHeight: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ],
  templateUrl: './materials-species-planting.component.html',
  styleUrls: ['./materials-species-planting.component.css']
})
export class MaterialsSpeciesPlantingComponent implements OnInit {

  @Output() parentEventTransmitter = new EventEmitter();
  constructor(public questionGenrationService: QuestionGenerationService) { }

  ngOnInit(): void {
  }

  passValuesToParent() {
    this.parentEventTransmitter.emit({
      stage: 1,
      values: {
        "primaryUse": this.questionGenrationService.selectedPrimaryUse,
        "secondaryUse": this.questionGenrationService.selectedSecondaryUse,
        "recommendedSpecies": this.questionGenrationService.selectedRecommendedSpecies,

        // Species detail
        "speciesDescription": this.questionGenrationService.selectedRecommendedSpeciesTable["Species Description"],
        "urlInfo": this.questionGenrationService.selectedRecommendedSpeciesTable["URL to Info Sheet"],
        "recommendedPlantingLayout": this.questionGenrationService.materialSpeciesPlantingForm.controls["recommendedPlantingLayout"].value,
        "alongRowSpacing": this.questionGenrationService.materialSpeciesPlantingForm.controls["alongRowSpacing"].value,
        "betweenRowSpacing": this.questionGenrationService.materialSpeciesPlantingForm.controls["betweenRowSpacing"].value,

        // Calculations
        "stemsPerHectare": this.questionGenrationService.stemsPerHectare,
        "numberSeedingsRequired": this.questionGenrationService.numberSeedingsRequired,
        "numberTreeStocksRequired": this.questionGenrationService.numberTreeStocksRequired,
        "amountFertiliserRequired": this.questionGenrationService.amountFertiliserRequired,
      }
    })
  }

}
