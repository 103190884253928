import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { PluggableMap, View } from 'ol';
import { Layer } from 'ol/layer';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import OlMap from 'ol/Map';
import { ITopexReportData } from 'src/app/interfaces/topexReport.interface';
import { base_map_defs } from 'src/app/_helpers/basemaps';
import { topexColors, versatilityGradientColors } from 'src/app/_helpers/layer.styles';
import { populateLayerGroup } from 'src/app/_helpers/LayerBuilder';

@Component({
  selector: 'app-topex-map-overlay',
  templateUrl: './topex-map-overlay.component.html',
  styleUrls: ['./topex-map-overlay.component.css']
})
export class TopexMapOverlayComponent implements OnInit, AfterViewInit, AfterViewChecked {

  map: PluggableMap;
  active = true;
  colorIndex = [];

  basemapGroup = new LayerGroup({
    layers: [],
    title: 'Background Maps',
    fold: 'open',
  } as any);

  @ViewChild('map', {static:true})
  mapElement : ElementRef;

  constructor(private elRef: ElementRef, public dialogRef: MatDialogRef<TopexMapOverlayComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    for (const [key, value] of Object.entries(topexColors)) {

      let colorIndex = {
        name: key,
        color: `rgba(${value})`
      }

      this.colorIndex.push(colorIndex);
    }

this.setupBaseMaps();
  }

  setupBaseMaps() {
    if (this.basemapGroup.getLayersArray().length == 0)
      populateLayerGroup(base_map_defs, this.basemapGroup, ()=>{});
    else return;
    //populateLayerGroup(defaultLayers, this.testGroup, this.rebuildLegend);
  }

  ngAfterViewInit(): void {


    this.map = new OlMap({
      layers: [this.basemapGroup,...this.data.layers] ,
      view: new View({
        center: [494095, 5343594],
        projection: this.data.map.getView().getProjection(),
      }),
      controls:[],
      pixelRatio: window.devicePixelRatio,
      maxTilesLoading: 256,
      target:this.mapElement.nativeElement
    });

    this.map.getView().fit(this.data.map.getView().calculateExtent(this.data.map.getSize()));

    this.map.updateSize();

    this.data.layers.forEach(layer => {
      layer.getSource().changed();
    })

    this.map.render();


  }

  ngAfterViewChecked(): void {
    this.map.getView().getZoom() + 1;
    this.map.getView().getZoom() - 1;
  }

}
