

<div class="app-container">
  <app-nav></app-nav>
  <div class="main-content">
    <div class="uk-container-fluid uk-flex uk-flex-column uk-column-1-2 uk-padding-vertical-small" id="npvCalculator"
    [formGroup]="npvCalculator" uk-height-viewport="expand: true">
    <div class="uk-column uk-flex uk-flex-column uk-flex-start uk-width-1-1 calculator-fields"
      uk-height-viewport="expand: true">
      <div class="uk-flex uk-flex-column uk-padding-small uk-padding-remove-bottom uk-padding-remove-horizontal@m">
        <app-progress-bar (notifyParent)="setActiveStageFromNavigation($event)" [activeStage]="formStageFlag"
          [progressMade]="progress" class="app-progress-bar"></app-progress-bar>
      </div>
      <!-- Debugging -->
      <!--
      <div class="uk-container">
        {{ allStatus() | json }}
      </div>-->

      <div class="uk-container">
        <!-- {{ npvCalculator.getRawValue()["characteristicsOfThreatenedSpeciesInTheArea"] | json }} -->
        <!-- {{ npvCalculator.getRawValue() | json }} -->
        <!-- {{ npvCalculator.getRawValue()["returnFromForestryEnterprises"] | json }} -->
      </div>


      <div class="uk-flex uk-flex-column uk-padding-small uk-padding-remove-bottom uk-padding-remove-horizontal@m">
        <h2>Baseline Costs & Benefits for {{reportTarget?.name ? reportTarget?.name : "nothing" }}</h2>
        <!-- <h2>NPV calculator for property-name {{'nothing'}}</h2> -->
      </div>


      <!-- Stage 1 -->
      <div class="uk-flex uk-flex-column uk-padding-small uk-padding-remove-vertical uk-padding-remove-horizontal@m"
        [ngStyle]="{ display: formStageFlag === 1 ? 'flex' : 'none' }">
        <div class="uk-column">
          <app-primary-inputs formGroupName="primaryInputs"
            [totalPotentialVolumeOfLogsHarvestedPerYear]="npvCalculator.get('primaryInputs.typeOfForestryOperation').value">
          </app-primary-inputs>
        </div>
        <div class="uk-column">
          <app-time-inputs formGroupName="timeInputs"></app-time-inputs>
        </div>

      </div>
      <!-- Stage 4 -->
      <!-- [potentialVolumnOfLogsHarvested]="npvCalculator.get('primaryInputs.TotalPotentialVolumeOfLogsHarvestedPerYear').value"> -->
      <div class="uk-flex uk-flex-column uk-padding-small uk-padding-remove-vertical uk-padding-remove-horizontal@m"
        [ngStyle]="{ display: formStageFlag === 2 ? 'flex' : 'none' }">
        <div class="uk-column">
          <app-return-from-forestry formGroupName="returnFromForestryEnterprises"
            [markUp]="npvCalculator.get('primaryInputs.priceMarkUpDecrease').value"
            [potentialVolumnOfLogsHarvested]="npvCalculator.get('primaryInputs.totalPotentialVolumeOfLogsHarvestedPerYear').value"
            [landSize]="npvCalculator.get('primaryInputs.landSize').value"
            [harvetDuration]="npvCalculator.get('timeInputs.harvestLifespan').value"
            [typeOfForestry]="npvCalculator.get('primaryInputs.typeOfForestryOperation').value">
          </app-return-from-forestry>
        </div>
      </div>
      <!-- Stage 2 -->
      <div class="uk-flex uk-flex-column uk-padding-small uk-padding-remove-vertical uk-padding-remove-horizontal@m"
        [ngStyle]="{ display: formStageFlag === 4 ? 'flex' : 'none' }">
        <div class="uk-column">
          <app-threatened-species-in-the-area formGroupName="characteristicsOfThreatenedSpeciesInTheArea"
            [potentialVolumnOfLogsHarvested]="npvCalculator.get('returnFromForestryEnterprises.totalPotentialVolumeOfLogsAvailableForHarvest').value"
            [landSize]="npvCalculator.get('primaryInputs.landSize').value"
            [decreseInMobility]="npvCalculator.get('impactOnHarvestRates.mobility').value"
            [decreaseInUncertainity]="npvCalculator.get('impactOnHarvestRates.uncertainity').value"
            [impactOnForestyPolicy]="[npvCalculator.get('costImpactOnForestryPolicyPrescriptions.mobilityImpact').value,npvCalculator.get('costImpactOnForestryPolicyPrescriptions.uncertainityImpact').value]"
            [impactOnHarvestRatePercentages]="[npvCalculator.get('impactOnHarvestRates.mobility').value,npvCalculator.get('impactOnHarvestRates.uncertainity').value]">
          </app-threatened-species-in-the-area>
        </div>
        <div class="uk-column">
          <app-cost-impact-forestry-policy-prescription formGroupName="costImpactOnForestryPolicyPrescriptions"
            [mobileImpact]="
                npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.mobility').value === 'Highly Mobile'? 25.00
              : npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.mobility').value === 'Somewhat Mobile'? 16.66
              : npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.mobility').value === 'Somewhat Fixed'? 8.66
              : npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.mobility').value === 'Fixed'? 0.00
              : npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.mobility').value === ''? 0.00
              : 0.00" [statusImpact]="npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.status').value === 'Critically Endangered'? 25.00
              : npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.status').value === 'Endangered'? 12.50
              : npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.status').value === 'Not Endangered'? 0.00
              : npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.status').value === 'Threatened'? 0.00
              : npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.status').value === ''? 0.00
              : 0.00" [uncertainityImpact]="
              npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.uncertainity').value === 'High Uncertainty'? 25.00
              : npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.uncertainity').value === 'Medium Uncertainty'? 12.5
              : npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.uncertainity').value === 'No Uncertainty'? 0.00
              : npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.uncertainity').value === ''? 0.00
              : 0.00">
          </app-cost-impact-forestry-policy-prescription>
        </div>
        <div class="uk-column">
          <app-impact-harvest-rate formGroupName="impactOnHarvestRates"
            [mobility]="npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.mobility').value"
            [certainity]="npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.uncertainity').value">
          </app-impact-harvest-rate>
        </div>
      </div>
      <!-- Stage 3 -->
      <div class="uk-flex uk-flex-column uk-padding-small uk-padding-remove-vertical uk-padding-remove-horizontal@m"
        [ngStyle]="{ display: formStageFlag === 3 ? 'flex' : 'none' }">
        <div class="uk-column">
          <app-property-specifications formGroupName="propertySpecifications"
            [purchasePricePerHectare]="npvCalculator.get('primaryInputs.landPurchasePricePerHectare').value| toPrecision"
            [parentForm]="npvCalculator"
            [typeOfForestryOperation]="npvCalculator.get('primaryInputs.typeOfForestryOperation').value">
          </app-property-specifications>
          <!-- 2 * Math.sqrt( npvCalculator.get('plantationCostInputs.LandPurchasePricePerHectare').value * 10000 / 3.14) -->
        </div>

        <div class="uk-column">
          <app-plantation-cost formGroupName="plantationCostInputs"
            [typeOfForestOperationValue]="npvCalculator.get('primaryInputs.typeOfForestryOperation').value"
            [parentForm]="npvCalculator"
            [plannedFenceLength]="npvCalculator.get('propertySpecifications.plannedFenceLength').value"
            [existingFenceLength]="npvCalculator.get('propertySpecifications.existingFenceLength').value">
          </app-plantation-cost>
        </div>
      </div>
      <!-- Stage 5
          [increaseInCostToMobility]="npvCalculator.get('costImpactOnForestryPolicyPrescriptions.MobilityImpact').value"
          [increaseInCostToThreatenedStatus]="npvCalculator.get('costImpactOnForestryPolicyPrescriptions.StatusImpact').value"
          [increaseInCostToUncertainity]="npvCalculator.get('costImpactOnForestryPolicyPrescriptions.UncertainityImpact').value"
      -->
      <div class="uk-flex uk-flex-column uk-padding-small uk-padding-remove-vertical uk-padding-remove-horizontal@m"
        [ngStyle]="{ display: formStageFlag === 5 ? 'flex' : 'none' }">
        <div class="uk-column">
          <app-forestry-policy-prescription formGroupName="forestryPolicyPrescriptions"
            refFormGroupName="characteristicsOfThreatenedSpeciesInTheArea"
            [fixedCostOfPrescriptions]="npvCalculator.get('forestryPolicyPrescriptions.fixedCostOfPrescriptionsBaseLayer').value"
            [costFPP]="npvCalculator.get('forestryPolicyPrescriptions.costOfFPPBaseLayer').value">

            <app-compensation formGroupName="compensation"></app-compensation>
          </app-forestry-policy-prescription>
          <!-- </div>
        <div class="uk-column"> -->
        </div>
      </div>
      <!-- Button container -->
      <div class="uk-padding-small uk-padding-remove-horizontal@m uk-flex uk-flex-between">
        <!-- <button type="submit" class="uk-button uk-button-default" *ngIf="npvCalculator.status !== 'INVALID'">Back</button> -->
        <button type="submit" class="uk-button uk-button-default" (click)="formStageDecrementHandler()"
          *ngIf="formStageFlag > 1">Back</button>
        <span *ngIf="formStageFlag === 1"></span>
        <!-- [ngClass]="stageValid === true ? disabled  : ''"
              *ngIf="stageValid === true; then disabled; else ''" -->
        <button [disabled]="!validateNextButton()" type="submit" class="uk-button uk-button-default green-buthon"
          (click)="formStageIncrementHandler()" *ngIf="formStageFlag < totalFormStages">Next</button>
        <button [disabled]="!validateNextButton()" type="submit" class="uk-button uk-button-default green-buthon"
          (click)="formSubmitHandler()" *ngIf="formStageFlag === 5 && allStagesValid">Submit</button>
      </div>
    </div>
  </div>


  <div
    class="uk-container-fluid uk-flex uk-flex-column uk-flex-middle uk-column-1-2 uk-padding-vertical-small custom-light-background"
    [formGroup]="npvCalculator" uk-height-viewport="expand: true">
    <div
      class="uk-column uk-flex uk-flex-column uk-flex-start uk-flex-2 revenue-sidebar uk-width-3-4 uk-padding uk-padding-remove-horizontal uk-padding-remove-bottom scroll-me-vertically"
      uk-height-viewport="expand: true">
      <ul class="sticky-element-top" uk-tab uk-switcher>
        <li class="uk-active">
          <a class="uk-text-capitalize" href="#benefits">Benefits</a>
        </li>
        <li>
          <a class="uk-text-capitalize" href="#costs">Costs</a>
        </li>
      </ul>
      <ul class="uk-switcher uk-margin">
        <li class="benefits-list-item">
          <div class="uk-column-1-1">
            <div class="uk-flex uk-flex-between">
              <h3 class="uk-margin-remove">Benefits breakdown</h3>
              <button *ngIf="formStageFlag === 5" (click)="generateCostsBenefitsReportHandler()"
                class="uk-button uk-button-secondary">Generate Report</button>
            </div>
            <ul uk-accordion>
              <li>
                <a class="uk-accordion-title" href="#">
                  <span class="uk-accordion-title-flexible">
                    <span>Sawlog Revenue</span>
                    <span>{{ getSpecificValue("sawlog-revenue") | currency }}</span>
                    <!-- <span>{{ revenues["sawlogRevenues"] | currency }}</span> -->
                  </span>
                </a>
                <div class="uk-accordion-content">
                  <app-sawlog-revenue
                    [impactOnHarvest]="(npvCalculator.get('impactOnHarvestRates.mobility').value +npvCalculator.get('impactOnHarvestRates.uncertainity').value) / 100"
                    [totalActualLogsHarvested]="npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.totalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount').value"
                    [harvestThatAreSawlogs]="npvCalculator.get('returnFromForestryEnterprises.harvestThatAreSawlogs').value"
                    [sawlogPrice]="npvCalculator.get('returnFromForestryEnterprises.sawlogPrice').value"
                    [discountRate]="npvCalculator.get('timeInputs.discountRate').value"
                    [harvestLifespan]="npvCalculator.get('timeInputs.harvestLifespan').value"
                    (calculationsValueEmitter)="calculationChildToParentEmitter($event)"></app-sawlog-revenue>
                </div>
              </li>
              <li>
                <a class="uk-accordion-title" href="#">
                  <span class="uk-accordion-title-flexible">
                    <span>Peeler log Revenue</span>
                    <span>{{ getSpecificValue("peeler-log-revenue") | currency }}</span>
                    <!-- <span>{{ revenues["peelerlogRevenues"] | currency }}</span> -->

                  </span>
                </a>
                <div class="uk-accordion-content">
                  <app-peeler-log-revenue
                    [impactOnHarvest]="(npvCalculator.get('impactOnHarvestRates.mobility').value +npvCalculator.get('impactOnHarvestRates.uncertainity').value) /100"
                    [totalActualLogsHarvested]="npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.totalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount').value"
                    [harvestThatArePeelerlogs]="npvCalculator.get('returnFromForestryEnterprises.harvestThatArePeelerLogs').value"
                    [peelerPrice]="npvCalculator.get('returnFromForestryEnterprises.peelerLogPrice').value"
                    [discountRate]="npvCalculator.get('timeInputs.discountRate').value"
                    [harvestLifespan]="npvCalculator.get('timeInputs.harvestLifespan').value"
                    (calculationsValueEmitter)="calculationChildToParentEmitter($event)"></app-peeler-log-revenue>
                </div>
              </li>
              <li>
                <a class="uk-accordion-title" href="#">
                  <span class="uk-accordion-title-flexible">
                    <span>Arisings Revenue</span>
                    <span>{{ getSpecificValue("arisings-revenue") | currency }}</span>
                    <!-- <span>{{ revenues["arisingsRevenues"] | currency }}</span> -->
                  </span>
                </a>
                <div class="uk-accordion-content">
                  <app-arisings-revenue
                    [impactOnHarvest]="(npvCalculator.get('impactOnHarvestRates.mobility').value +npvCalculator.get('impactOnHarvestRates.uncertainity').value) /100"
                    [totalActualLogsHarvested]="npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.totalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount').value"
                    [harvestThatAreArisings]="npvCalculator.get('returnFromForestryEnterprises.harvestThatAreArisings').value"
                    [arisingsPrice]="npvCalculator.get('returnFromForestryEnterprises.arisingsPrice').value"
                    [discountRate]="npvCalculator.get('timeInputs.discountRate').value"
                    [harvestLifespan]="npvCalculator.get('timeInputs.harvestLifespan').value"
                    (calculationsValueEmitter)="calculationChildToParentEmitter($event)"></app-arisings-revenue>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="costs-list-item">
          <div class="uk-column-1-1">
            <div class="uk-flex uk-flex-between">
              <h3 class="uk-margin-remove">Costs breakdown</h3>
              <button *ngIf="formStageFlag === 5" (click)="generateCostsBenefitsReportHandler()"
                class="uk-button uk-button-secondary">Generate Report</button>
            </div>
            <ul uk-accordion="collapsible: false">
              <ul uk-accordion>
                <li>
                  <a class="uk-accordion-title" href="#">
                    <span class="uk-accordion-title-flexible">
                      <span>Foregone Land Sale Revenue</span>
                      <span>{{ getSpecificCost("foregone-land-sale-revenue") | currency }}</span>
                    </span>
                  </a>
                  <div class="uk-accordion-content">
                    <app-foregone-land-sale-revenue [forestSize]="npvCalculator.get('primaryInputs.landSize').value"
                      [purchasePrisePerHectare]="npvCalculator.get('primaryInputs.landPurchasePricePerHectare').value| toPrecision"
                      (calculationsValueEmitter)="calculationChildToParentEmitter($event)">
                    </app-foregone-land-sale-revenue>
                  </div>
                </li>
                <li>
                  <a class="uk-accordion-title" href="#">
                    <span class="uk-accordion-title-flexible">
                      <span>Fencing Cost</span>
                      <span>{{ getSpecificCost("fencing-costs") | currency }}</span>
                    </span>
                  </a>
                  <div class="uk-accordion-content">
                    <app-fencing-cost
                      [fencingLength]="npvCalculator.get('propertySpecifications.plannedFenceLength').value"
                      [fencing]="npvCalculator.get('plantationCostInputs.fencing').value"
                      [monetaryValue]="npvCalculator.get('plantationCostInputs.isFencingRequired').value"
                      (calculationsValueEmitter)="calculationChildToParentEmitter($event)">
                    </app-fencing-cost>
                  </div>
                </li>
                <li>
                  <a class="uk-accordion-title" href="#">
                    <span class="uk-accordion-title-flexible">
                      <span>Fencing Maintenance</span>
                      <span>{{ getSpecificCost("fencing-maintenance") | currency }}</span>
                    </span>
                  </a>
                  <div class="uk-accordion-content">
                    <app-fencing-maintenance
                      [plannedFencing]="npvCalculator.get('propertySpecifications.plannedFenceLength').value"
                      [existingFencing]="npvCalculator.get('propertySpecifications.existingFenceLength').value"
                      [fencing]="npvCalculator.get('plantationCostInputs.fencingMaintenance').value"
                      [monetaryValue]="npvCalculator.get('plantationCostInputs.isFencingRequired').value"
                      [discountRate]="npvCalculator.get('timeInputs.discountRate').value"
                      [harvestLifespan]="npvCalculator.get('timeInputs.harvestLifespan').value"
                      (calculationsValueEmitter)="calculationChildToParentEmitter($event)">
                    </app-fencing-maintenance>
                  </div>
                </li>
                <li>
                  <a class="uk-accordion-title" href="#">
                    <span class="uk-accordion-title-flexible">
                      <span>Ploughing</span>
                      <span>{{ getSpecificCost("ploughing-costs") | currency }}</span>
                    </span>
                  </a>
                  <div class="uk-accordion-content">
                    <app-ploughing [forestSize]="npvCalculator.get('primaryInputs.landSize').value"
                      [ploughing]="npvCalculator.get('plantationCostInputs.ploughing').value"
                      [discountRate]="npvCalculator.get('timeInputs.discountRate').value"
                      [harvestLifespan]="npvCalculator.get('timeInputs.harvestLifespan').value"
                      (calculationsValueEmitter)="calculationChildToParentEmitter($event)"></app-ploughing>
                  </div>
                </li>
                <li>
                  <a class="uk-accordion-title" href="#">
                    <span class="uk-accordion-title-flexible">
                      <span>Harvesting and Haulage Costs</span>
                      <span>{{ getSpecificCost("harvesting-haulage-costs") | currency }}</span>
                    </span>
                  </a>
                  <div class="uk-accordion-content">
                    <!-- [totalActualVolumeLogsHarvestedPerYear]="npvCalculator.get('impactOnHarvestRates.mobility').value /100 +npvCalculator.get('impactOnHarvestRates.uncertainity').value /100" -->
                    <app-harvesting-haulage-costs
                      [typeOfForestryOperation]="npvCalculator.get('primaryInputs.typeOfForestryOperation').value"
                      [totalPotentialLogsHarvestedPerYear]="npvCalculator.get('primaryInputs.totalPotentialVolumeOfLogsHarvestedPerYear').value"
                      [totalActualVolumeLogsHarvestedPerYear]="npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.totalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount').value"
                      [harvestingHaulageCosts]="npvCalculator.get('primaryInputs.typeOfForestryOperation').value"
                      [plantationCostInputsClearfelling]="npvCalculator.get('plantationCostInputs.harvestingAndHaulageCostsForClearfell').value"
                      [plantationCostInputsThinning]="npvCalculator.get('plantationCostInputs.harvestingAndHaulageCostsForThinning').value"
                      [discountRate]="npvCalculator.get('timeInputs.discountRate').value"
                      [harvestLifespan]="npvCalculator.get('timeInputs.harvestLifespan').value"
                      (calculationsValueEmitter)="calculationChildToParentEmitter($event)">
                    </app-harvesting-haulage-costs>
                  </div>
                </li>
                <li>
                  <a class="uk-accordion-title" href="#">
                    <span class="uk-accordion-title-flexible">
                      <span>Transport Costs</span>
                      <span>{{ getSpecificCost("transportation-costs") | currency }}</span>
                    </span>
                  </a>
                  <div class="uk-accordion-content">
                    <!-- [totalActualVolumeLogsHarvestedPerYear]="npvCalculator.get('impactOnHarvestRates.mobility').value /100 +npvCalculator.get('impactOnHarvestRates.uncertainity').value /100" -->
                    <app-transport-costs
                      [distanceFromBuyer]="npvCalculator.get('plantationCostInputs.distanceFromBuyer').value"
                      [totalActualVolumeLogsHarvestedPerYear]="npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.totalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount').value"
                      [totalPotentialLogsHarvestedPerYear]="npvCalculator.get('primaryInputs.totalPotentialVolumeOfLogsHarvestedPerYear').value"
                      [discountRate]="npvCalculator.get('timeInputs.discountRate').value"
                      [harvestLifespan]="npvCalculator.get('timeInputs.harvestLifespan').value"
                      [transportationCost]="npvCalculator.get('plantationCostInputs.transportCode').value"
                      (calculationsValueEmitter)="calculationChildToParentEmitter($event)">
                    </app-transport-costs>
                  </div>
                </li>
                <li>
                  <a class="uk-accordion-title" href="#">
                    <span class="uk-accordion-title-flexible">
                      <span>Plantation and Planting Costs</span>
                      <span>{{ getSpecificCost("ploughing-plantation-costs") | currency }}</span>
                    </span>
                  </a>
                  <div class="uk-accordion-content">
                    <app-ploughing-plantation-costs
                      [harvestedPecentage]="npvCalculator.get('impactOnHarvestRates.mobility').value /100 +npvCalculator.get('impactOnHarvestRates.uncertainity').value /100"
                      [totalPotentialLogsHarvestedPerYear]="npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.totalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount').value"
                      [plantationPlantingCosts]="npvCalculator.get('plantationCostInputs.plantationAndPlantingVosts').value"
                      [discountRate]="npvCalculator.get('timeInputs.discountRate').value"
                      [harvestLifespan]="npvCalculator.get('timeInputs.harvestLifespan').value"
                      (calculationsValueEmitter)="calculationChildToParentEmitter($event)">
                    </app-ploughing-plantation-costs>
                  </div>
                </li>
                <li>
                  <a class="uk-accordion-title" href="#">
                    <span class="uk-accordion-title-flexible">
                      <span>Weeding and Herbicide Costs</span>
                      <span>{{ getSpecificCost("weeding-herbicide-costs") | currency }}</span>
                    </span>
                  </a>
                  <div class="uk-accordion-content">
                    <!-- [totalActualVolumeLogsHarvestedPerYear]="npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.totalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount').value"                    [plantationPlantingCosts]="npvCalculator.get('plantationCostInputs.WeedingHerbicideCosts').value" -->
                    <app-weeding-herbicide-costs
                      [harvestedPecentage]="npvCalculator.get('impactOnHarvestRates.mobility').value /100 +npvCalculator.get('impactOnHarvestRates.uncertainity').value /100"
                      [totalActualVolumeLogsHarvestedPerYear]="npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.totalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount').value"
                      [weedingCosts]="npvCalculator.get('plantationCostInputs.weedingHerbicideCosts').value"
                      [discountRate]="npvCalculator.get('timeInputs.discountRate').value"
                      [harvestLifespan]="npvCalculator.get('timeInputs.harvestLifespan').value"
                      (calculationsValueEmitter)="calculationChildToParentEmitter($event)">
                    </app-weeding-herbicide-costs>
                  </div>
                </li>
                <li class="uk-open">
                  <a class="uk-accordion-title" href="#">
                    <span class="uk-accordion-title-flexible">
                      <span>Forestry Policy Prescription Costs</span>
                      <span>{{ getSpecificCost("foregone-policy-prescription-costs") | currency }}</span>
                    </span>
                  </a>
                  <div class="uk-accordion-content">
                    <!-- [totalActualVolumeLogsHarvestedPerYear]="npvCalculator.get('primaryInputs.TotalPotentialVolumeOfLogsHarvestedPerYear').value ? npvCalculator.get('primaryInputs.TotalPotentialVolumeOfLogsHarvestedPerYear').value : 0" -->
                    <app-forestry-policy-prescription-costs
                      [mobilityPPC]="npvCalculator.get('impactOnHarvestRates.mobility').value"
                      [uncertainityPPC]="npvCalculator.get('impactOnHarvestRates.uncertainity').value"
                      [fixedCostPrescriptions]="npvCalculator.get('forestryPolicyPrescriptions.fixedCostOfPrescriptionsBaseLayerAccounting').value * (1 + 0)"
                      [harvestedPecentage]="(npvCalculator.get('impactOnHarvestRates.mobility').value / 100) + (npvCalculator.get('impactOnHarvestRates.uncertainity').value / 100)"
                      [totalActualVolumeLogsHarvestedPerYear]="npvCalculator.get('characteristicsOfThreatenedSpeciesInTheArea.totalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount').value"
                      [costOfFPP]="npvCalculator.get('forestryPolicyPrescriptions.costOfFPPBaseLayerAccounting').value * (1 + 0)"
                      [socialContribution]="npvCalculator.get('compensation.socialContribution').value"
                      [discountRate]="npvCalculator.get('timeInputs.discountRate').value"
                      [harvestLifespan]="npvCalculator.get('timeInputs.harvestLifespan').value"
                      (calculationsValueEmitter)="calculationChildToParentEmitter($event)">
                    </app-forestry-policy-prescription-costs>
                  </div>
                </li>
              </ul>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <app-total></app-total>

  <app-exit-confirmation [localStorageKeyProp]="storageKey"></app-exit-confirmation>

  </div>
</div>
