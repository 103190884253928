import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FenceDexie } from '../models/fence.model';
import { PaddockDexie } from '../models/paddock.model';
import { PropertyDexie } from '../models/property.model';
import { DexieDatabaseService } from './dexieIndexDB.service';
import { PropertyService } from './property.service';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  private propertySub$: Subscription;

  public reportTarget = new BehaviorSubject<PropertyReportingTarget | PaddockReportingTarget>(null);

  constructor(propertyService: PropertyService, private localDB: DexieDatabaseService) {

   }

   async setReportTarget(target: PropertyDexie | PaddockDexie)
   {

    let fences = await this.localDB.fences.where({propertyID: target.propertyID}).toArray();

     fences = target instanceof PropertyDexie ?  fences : fences.filter(fence => fence.paddocks.includes(target.paddockID));

    if(target instanceof PropertyDexie)
    {
      let newTarget: PropertyReportingTarget = new PropertyReportingTarget();
      newTarget.fences = fences;
      Object.assign(newTarget, target);
      this.reportTarget.next(newTarget);
    }
    else
    {
      let newTarget: PaddockReportingTarget = new PaddockReportingTarget();

      newTarget.fences = fences;
      Object.assign(newTarget, target);
      this.reportTarget.next(newTarget);
    }



   }

}

export class PropertyReportingTarget extends PropertyDexie
{
  fences: Array<FenceDexie> = [];

}

export class PaddockReportingTarget extends PaddockDexie
{
  fences: Array<FenceDexie> = [];
}
