
import { v4 as uuidv4 } from 'uuid';

export class FenceDexie {

    fenceID: string = uuidv4();

    name: string;

    wkt: string;

    type: string;

    existing: boolean;

    wallabyProof?: boolean

    length: number;

    notes?: string;

    propertyID: string;

    paddocks?: Array<string>;

    uploaded:boolean = false;

    createdOn: string = new Date().toUTCString();

    modifiedOn: string = new Date().toUTCString();

}
