import { Injectable } from '@angular/core';


interface IObjectKeys {
  [key: string | number]: string | number | IObjectKeys;
}

@Injectable({
  providedIn: 'root'
})
export class MarketGuidelinesService {

  sourceTableInformation: any = {
    "Timber/Wood Product Market": {
      "What are my Trees Worth": {
        content: `The amount paid to the forest owner by the timber purchaser is known as the stumpage <i>(also called ‘royalty’)</i>.
        <br>
        The stumpage excludes the costs for planning, harvesting and carting the forest products to a processing plant. In general, timber processors value logs that have uniform characteristics (shape, size, clearwood amount or small knot size) as these logs are more easily processed, have higher recovery rates and yield higher value products`,
        url: "https://pft.tas.gov.au/volumes/documents/Selling-your-wood/What_are_my_trees_worth_-_V2_Dec_2021_approved.pdf"
      },

      "Utilisation of Farm Grown Wood": {
        content: `Farm forests, whether native forests, special species woodlots, shelterbelts, sawlog or fibre plantations have many uses, current and potential. Uses may be considered products of the forest and include shelter, tourism and aesthetics. Products grown in the forest include nonwood products (bush foods, native plants, seed, landscape material and game) and wood products (from posts and poles, firewood, pulpwood, to sawlogs and veneer logs). These products, log categories and their multiple subsets provide a broad range of options for the private forest owner. The range will be limited if you have entered some form of forest lease or joint venture, however, if you are the owner of the plantation or native forest the range of options is much greater. Market availability will restrict the commercial reality of what is profitable to produce for off-farm sale, however, for on farm use the options may be greater`,
        url: "https://pft.tas.gov.au/volumes/documents/Farming-Guides/Information_Series_-_No._13_-_Utilisation_of_farm_grown_wood_-_Version_3_-_August_2020.pdf"
      },

      "How to sell wood from your private forests": {
        content: `The process of selling your timber can be very complex and this resource provides an overview of the  options that are available, and the processes involved.`,
        url: "https://pft.tas.gov.au/volumes/documents/Selling-your-wood/How_to_Sell_Wood_from_your_Private_Forests_V2_Dec_2021_approved.pdf"
      },

      "Marketing and Harvesting of Small Volumes of Wood": {
        content: `The following information (and Harvesting & Marketing flowchart page 11) is primarily concerned with the marketing and harvesting of minor species. However, the processes described can equally be used to market and harvest small volumes of high value major commercial timbers, such as pruned Radiata pine or veneer grade eucalypts, to ensure maximum returns to the grower`,
        url: "https://pft.tas.gov.au/harvesting"
      },

      "Consultant Forestry Advisors": {
        content: `Private Forests Tasmania recommends the use of consulting foresters for landholders wishing to grow trees for commercial and other purposes. While PFT can provide some initial advice and guidance this will be unlikely to cover all your needs from tree selection, establishment and management through to harvest. We do not recommend specific individuals or companies, but we do provide a list from which you may choose, as per the link here.`,
        url: "https://pft.tas.gov.au/volumes/documents/Selling-your-wood/Why_do_I_need_a_consulting_forester_and_how_do_I_choose_-_V2_Dec_2021_approved.pdf"
      }
    },
    "Carbon Market": {

      "Assessing Carbon Project Options for Land": {
        content: `The CSIRO have developed LOOC-C (Landscape Options and Opportunities for Carbon abatement Calculator) which allows you to quickly assess options on the land for certain projects offered under Australia's federal carbon emissions programme, the Emissions Reduction Fund (ERF).`,
        url: "https://looc-c.farm/"
      },

      "Carbon Farming Advice Rebate Pilot Program": {
        content: `Carbon far​​ming and opportunities for primary producers to enter the carbon credit market can be complex to consider.
      The Carbon Farming Advice Pilot Rebate Program provides primary producers with rebates of up to $10,000 for advice sought about the​​ costs and benefits of accessing carbon credits, auditing requirements, and on-ground actions that are eligible for carbon credits`,
        url: "https://nre.tas.gov.au/agriculture/government-and-community-programs/carbon-farming-opportunities"
      }
    },
    "On-site Co-benefits": {

      "Using Trees to Shelter Livestock": {
        content: `Farm shelter is promoted as a means of reducing wind speed, ameliorate the local microclimate and increasing agricultural yields. High wind speeds lead to chilling of livestock
        and physical damage to crops through abrasion, drying and wind throw. Well placed and
        well managed shelterbelts can therefore be used to increase agricultural productivity`,
        url: "/Information_Series_-_No._2_-_Farm_Shelter_-_Version_3_-_August_2020.pdf"
      },

      "Using Trees to Increase Crop or Pasture Yields": {
        content: `Farm shelter is promoted as a means of reducing wind speed, ameliorate the local microclimate and increasing agricultural yields. High wind speeds lead to chilling of livestock
      and physical damage to crops through abrasion, drying and wind throw. Well placed and
      well managed shelterbelts can therefore be used to increase agricultural productivity`,
        url: "/Information_Series_-_No._2_-_Farm_Shelter_-_Version_3_-_August_2020.pdf"
      }
    },
  }

  overViewDescriptionObject = {

  }

  marketTypeList: string[] = [];
  marketTopicList: string[] = [];
  selectedMarketType: string = "";
  selectedTopic: string = "";
  overviewDescription?: string;
  overviewURL?: string;

  constructor() {
    this.marketTypeList = Object.keys(this.sourceTableInformation)
  }

  marketTypeChangeHandler(evt: Event) {
    const element = evt.target as HTMLInputElement;
    const elemValue = element.value;


    // Check if the current selection is the previos selection
    if (elemValue !== this.selectedMarketType) {
      // Clearing out the values
      this.selectedTopic = "";
      this.marketTopicList = [];
    }

    if (elemValue && elemValue.trim().length > 0) {
      this.selectedMarketType = elemValue;

      // Geting the values for the topic section
      this.marketTopicList = Object.keys(this.sourceTableInformation[elemValue]);

    } else {

      // Clearing out the values
      this.selectedMarketType = "";
      this.selectedTopic = "";
      // this.marketTypeList = [];
      this.marketTopicList = [];
    }

  }

  selectTopicHandler(evt: Event) {
    const element = evt.target as HTMLInputElement;
    const elemValue = element.value;

    const marketType = this.selectedMarketType;
    this.selectedTopic = elemValue;

    // Check if the current selection is the previos selection
    if (elemValue !== this.selectedTopic) {
      // Clearing out the values
      this.marketTopicList = [];
    }

    if (elemValue && elemValue.trim().length > 0) {
      this.selectedTopic = elemValue;

      this.overviewDescription = this.sourceTableInformation[marketType][this.selectedTopic].content;
      this.overviewURL = this.sourceTableInformation[marketType][this.selectedTopic].url;
    }

  }
}
