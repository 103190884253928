import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, concatMap, debounce, debounceTime, filter, from, Subscription, switchMap, tap } from 'rxjs';
import { PaddockDexie } from 'src/app/models/paddock.model';
import { PropertyDexie } from 'src/app/models/property.model';
import { PrintPdfFilesService } from './print-pdf-files.service';
import { FenceService } from 'src/app/services/fence.service';
import { PaddockReportingTarget, PropertyReportingTarget, ReportingService } from 'src/app/services/reporting.service';
import { thomsonCrossSectionDependencies } from 'mathjs';
import { TreeProtectionService } from './tree-protection.service';
import { MatDialog } from '@angular/material/dialog';
import { FarmWoodlotToolWelcomeMessageComponent } from './farm-woodlot-tool-welcome-message/farm-woodlot-tool-welcome-message.component';

interface StageCompletedObject {
  [key: string | number]: string | boolean | number | StageCompletedObject;
}

@Component({
  selector: 'app-farm-woodlot-tool',
  templateUrl: './farm-woodlot-tool.component.html',
  styleUrls: ['./farm-woodlot-tool.component.css']
})
export class FarmWoodlotToolComponent implements OnInit {

  private activePropertySub$: Subscription;
  reportTarget: PropertyReportingTarget | PaddockReportingTarget;
  fenceLength: number;
  propertyPaddockID: string;
  showPDFModal: boolean = false;
  todayNumber: number = Date.now();

  stageCompletedTemplate: StageCompletedObject = {
    1: {
      completed: false,
      data: {}
    },
    2: {
      completed: false,
      data: {}
    },
    3: {
      completed: true,
      data: {}
    },
    4: {
      completed: false,
      data: {}
    },
    5: {
      completed: false,
      data: {}
    },
    6: {
      completed: true,
      data: {}
    },
  }

  // fencingMaterialTableInformation

  constructor(private reportingService: ReportingService, private fenceService: FenceService, public printPDF: PrintPdfFilesService, private router: Router, treeProtectionService: TreeProtectionService, private dialog:MatDialog) { }

  async ngOnInit(): Promise<void> {

    this.activePropertySub$ = this.reportingService.reportTarget.subscribe(async reportTarget => {
      if (reportTarget == null) {
        return;
      }

      this.reportTarget = reportTarget;
      this.propertyPaddockID = reportTarget["propertyID"];

      this.fenceLength = reportTarget.fences.reduce((accumulator, fence) => accumulator + fence.length, 0);
    });

  }

  setStageTemplateData(data: { stage: number, values: StageCompletedObject, willResetForm?: boolean }) {

    const stage = data.stage;
    const stageData = data.values;

    if (data.willResetForm) {
      this.stageCompletedTemplate[stage]["completed"] = false;
    } else {

      // Checks for stage validity if the reset command hasn't been sent.
      if (this.stageCompletedTemplate[stage]["completed"] === false) {
        this.stageCompletedTemplate[stage]["completed"] = true;
      }

    }

    this.stageCompletedTemplate[stage]["data"] = stageData;

    // console.log(this.stageCompletedTemplate[stage]);

  }

  showWelcome()
  {
    this.dialog.open(FarmWoodlotToolWelcomeMessageComponent, {width:'1000px'});
  }

  generatePDFHandler() {
    const reportName = this.reportTarget ? this.reportTarget.name : "Report name"
    const areaHa = this.reportTarget ? this.reportTarget.area : 0.0
    const pdfFooter = document.querySelector("#pdf-footer") as HTMLElement
    // pdfFooter.style.display = "block"
    this.printPDF.generateNewPDF(reportName, areaHa, "pdf-modal-container");
    // pdfFooter.style.display = "none"
  }

  showPDFModalHandler() {
    this.showPDFModal = true;
  }

  hidePDFModalHandler() {
    this.showPDFModal = false;
  }

}
