import { EnvironmentInjector, Injectable } from '@angular/core';
import { BehaviorSubject, from, Subscription,switchMap } from 'rxjs';
import { Repository } from 'typeorm';
import { EventData } from '../_helpers/event.class';
import { TreeMapperCustomEvents } from '../_helpers/enums';
import { AuthService } from './auth.service';
import { EventBusService } from './EventBus.service';
import { PropertyService } from './property.service';
import { PropertyDexie } from '../models/property.model';
import { FenceDexie } from '../models/fence.model';
import { DexieDatabaseService } from './dexieIndexDB.service';
import { liveQuery } from 'dexie';
import { HttpClient } from '@angular/common/http';
import { Enterprise } from '../models/enterprise.model';
import { EnterpriseReport } from '../models/enterpriseReport.model';
import { PaddockDexie } from '../models/paddock.model';
import { TopexReport } from '../models/topexReport.model';
import { environment } from 'src/environments/environment';
import { first } from 'rxjs/operators';
import { OnlineStatusService } from 'ngx-online-status';
import { DatabaseSyncAction, EntityType, Action } from '../models/databaseSync.model';
import { OfflineService } from './offline.service';
import { ForestDescriptionReport } from '../models/Forest Description/forestReport.model';

@Injectable({
  providedIn: 'root'
})
export class ForestClassReportService {

  api = environment.apiURL + 'ForestDescriptionReport';

  isOnline = true;

  constructor(private auth: AuthService, private dexieService:DexieDatabaseService, private eventBusService: EventBusService, private propertyService: PropertyService, private http: HttpClient, private connectionService: OfflineService) {
    connectionService.isOnline.subscribe(status => this.isOnline = status);
   }

   async getPropertyReport(id: string): Promise<ForestDescriptionReport>
   {
    return this.dexieService.forestDescriptionReports.where("propertyID").equals(id).first();
   }

   async getPaddockReport(id: string): Promise<ForestDescriptionReport>
   {
    return this.dexieService.forestDescriptionReports.where("paddockID").equals(id).first();
   }

   async save(report: ForestDescriptionReport)
   {
    await this.dexieService.forestDescriptionReports.add(report);

    if(this.isOnline)
    {
      this.http.post<ForestDescriptionReport>(this.api + (report.paddockID == null ? '/property/add' : '/paddock/add'), report).subscribe();
    }

    else
    {
     this.dexieService.databaseSyncActions.add(new DatabaseSyncAction(EntityType.ForestDescriptionReport, report.forestDescriptionReportID, Action.Insert));
    }

   }

   async remove(report: ForestDescriptionReport)
   {
     await this.dexieService.forestDescriptionReports.delete(report.forestDescriptionReportID);

   }

   async update(report: ForestDescriptionReport)
   {
    await this.dexieService.forestDescriptionReports.update(report.forestDescriptionReportID, report);

   }

  }
