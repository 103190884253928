import Map from 'ol/Map';
import View from 'ol/View';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { OSM, Vector as VectorSource } from 'ol/source';
import { fromLonLat } from 'ol/proj';

export class ExportableMap extends Map {
    constructor(options) {
      super(options);
    }

    exportToImage() : Promise<string> {
      return new Promise((resolve) => {
        this.once('rendercomplete', () => {
          const mapCanvas = document.createElement('canvas');
          const size = this.getSize();
          mapCanvas.width = size[0];
          mapCanvas.height = size[1];
          const mapContext = mapCanvas.getContext('2d');

          Array.prototype.forEach.call(document.querySelectorAll('.ol-layer canvas'), (canvas) => {
            if (canvas.width > 0) {
              const opacity = canvas.parentNode.style.opacity;
              mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
              const transform = canvas.style.transform;
              const matrix = transform.match(/^matrix\(([^\(]*)\)$/)[1].split(',').map(Number);
              CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
              mapContext.drawImage(canvas, 0, 0);
            }
          });

          resolve(mapCanvas.toDataURL('image/png'));
          mapCanvas.width = 0;
          mapCanvas.height = 0;
        });

        this.renderSync();
      });
    }
  }
