<h2 mat-dialog-title>Farm Woodlot Tool</h2>
<mat-dialog-content class="mat-typography">
  <p>The Farm Woodlot Tool provides a set of simple calculators and reference material to help you understand
    the main requirements to achieve a successful tree enterprise from establishment through management to
    harvest and marketing</p>
  <p>Like any agricultural enterprise it is wise to prepare a simple management plan considering establishment
    costs, market location, product requirements, wood prices and plantation management options. The Farm
    Woodlot Tool provides a set of guidelines and calculators to assist with successful establishment of
    trees on farm land, including indication of likely yields and markets.</p>
  <p>If you need more background information on why and how you might grow trees on your property, or a basic overview of the likely timing and planning required for tree establishment, please click <a target="_blank" href="https://pft.tas.gov.au/6-steps-to-growing">here</a>.

    If in doubt, seek professional advice from Private Forests Tasmania.</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <footer>
      <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
        close
      </button>
    </footer>
  </mat-dialog-actions>
