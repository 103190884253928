import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceBeforeCap'
})
export class SpaceBeforeCapPipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(/([A-Z])/g, ' $1').trim()
  }

}
