import { Component, OnInit, Input } from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'app-farm-woodlot-tool-welcome-message',
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.75s ease-in',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.25s ease-out',
              style({ maxHeight: 0 }))
          ]
        )
      ]
    )
  ],
  templateUrl: './farm-woodlot-tool-welcome-message.component.html',
  styleUrls: ['./farm-woodlot-tool-welcome-message.component.css']
})
export class FarmWoodlotToolWelcomeMessageComponent implements OnInit {

  @Input() modalVisible: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  showModalPopup() {
    this.modalVisible = true;
  }

  closeModalPopup() {
    this.modalVisible = false;
  }

}
