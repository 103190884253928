<mat-toolbar color="primary" class="breadcrumb-highlight admin-toolbar-primary">
    <ng-container *ngFor="let breadcrumb of breadcrumbs$ | async; let last = last">
      <ng-container *ngIf="!last; else lastBreadcrumb">
        <a [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
        <mat-icon>chevron_right</mat-icon>
      </ng-container>
      <ng-template #lastBreadcrumb>
        <span>{{ breadcrumb.label }}</span>
      </ng-template>
    </ng-container>
  </mat-toolbar>
