import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PropertyDexie } from 'src/app/models/property.model';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { DeletePropertyComponent } from './delete-property/delete-property.component';

@Component({
  selector: 'app-edit-property',
  templateUrl: './edit-property.component.html',
  styleUrls: ['./edit-property.component.css']
})
export class EditPropertyComponent implements OnInit {

  editPropertyForm: UntypedFormGroup;


  constructor(public dialogRef: MatDialogRef<EditPropertyComponent>,@Inject(MAT_DIALOG_DATA) public property: PropertyDexie,public dialog: MatDialog) { }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;

    this.editPropertyForm = new UntypedFormGroup(
      {
      'name': new UntypedFormControl(null, CustomValidators.required),
      'notes': new UntypedFormControl(null)
      },);

    this.editPropertyForm.patchValue(this.property);
  }

  openDeleteConfirmationDialog(){
  let confirmationDialog = this.dialog.open(DeletePropertyComponent,{data:this.property.name});

  confirmationDialog.afterClosed().subscribe(result => {
    if(result)
    {
      this.dialogRef.close({deleteProperty: true});
    }
    else
    {
      return;
    }
  })
  }

  submit(){
    Object.assign(this.property, this.editPropertyForm.value);

    this.dialogRef.close(this.property);
  }

}
