import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Feature, Overlay, PluggableMap } from 'ol';

@Component({
  selector: 'app-attribute-overlay',
  templateUrl: './attribute-overlay.component.html',
  styleUrls: ['./attribute-overlay.component.css'],
   host: {'class': 'ol-popup'}
})
export class AttributeOverlayComponent implements OnInit,OnDestroy {

  @Input() selectedFeature: Feature<any>;
  @Input() map: PluggableMap
  @Input() attributeAliasMap: Map<any,any>;

  attributes = [];

  overlay: Overlay;

  constructor(private elRef:ElementRef) { }

  ngOnInit(): void {

    this.overlay = new Overlay({
      element: this.elRef.nativeElement,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });

    this.setupAttributesArray();

    this.overlay.setPosition(this.selectedFeature.getGeometry().getFirstCoordinate());
    this.map.addOverlay(this.overlay);
  }

  ngOnDestroy(): void {
      this.map.removeOverlay(this.overlay);
  }

  setupAttributesArray()
  {

    let props = this.selectedFeature.getProperties();

    for (const property in props) {
      let name = property;
      let value = props[property] === null ? '' : props[property];

      if (this.attributeAliasMap) {
        name =
          this.attributeAliasMap.get(property) == null ||
          this.attributeAliasMap.get(property) == undefined
            ? property
            : this.attributeAliasMap.get(property);
      }

      if (property === 'geometry') continue;

      let row = {
        attribute: name,
        value: value
      }

      this.attributes.push(row)
    }

  }

  close(event)
  {
    this.overlay.setPosition(undefined);
    event.target.blur();
  }

}
