import { v4 as uuidv4 } from 'uuid';

export class NpvReport {
  public npvReportID: string =  uuidv4();
  public propertyID?: string;
  public paddockID?: string;
  public primaryInputs: PrimaryInputs = new PrimaryInputs();
  public timeInputs: TimeInputs = new TimeInputs();
  public propertySpecifications: PropertySpecifications =
    new PropertySpecifications();
  public returnFromForestryEnterprises: ReturnFromForestryEnterprises =
    new ReturnFromForestryEnterprises();
  public characteristicsOfThreatenedSpeciesInTheArea: CharacteristicsOfThreatenedSpeciesInTheArea =
    new CharacteristicsOfThreatenedSpeciesInTheArea();
  public costImpactOnForestryPolicyPrescriptions: CostImpactOnForestryPolicyPrescriptions =
    new CostImpactOnForestryPolicyPrescriptions();
  public plantationCostInputs: PlantationCostInputs =
    new PlantationCostInputs();
  public impactOnHarvestRates: ImpactOnHarvestRates =
    new ImpactOnHarvestRates();
  public forestPolicyPrescriptions: ForestPolicyPrescriptions =
    new ForestPolicyPrescriptions();
  public compensation: Compensation = new Compensation();

    uploaded:boolean = false;

  createdOn: string = new Date().toUTCString();

  modifiedOn: string = new Date().toUTCString();
}

export class PrimaryInputs {
  public landPurchasePricePerHectare: number = 10354;
  public typeOfForestryOperation = null;
  public landSize: number = 50;
  public totalPotentialVolumeOfLogsHarvestedPerYear = null;
  public priceMarkUpDecrease: number = 0;
}

export class TimeInputs {
  public discountRate: number = 8.25;
  public harvestLifespan: number = 40;
}

export class PropertySpecifications {
  public forestSize = null;
  public plannedFenceLength: number = 0;
  public existingFenceLength: number = 0;
}

export class ReturnFromForestryEnterprises {
  public sawlogPrice: number = 120;
  public peelerLogPrice: number = 80;
  public arisingsPrice: number = 50;
  public harvestThatAreSawlogs: number = 16.66;
  public harvestThatArePeelerLogs: number = parseFloat(
    (33.33 - 16.66).toFixed(2)
  );
  public harvestThatAreArisings: number = 2 * 16.66 + 2 * (33.33 - 16.66);
  public totalPotentialVolumeOfLogsAvailableForHarvest = null;
}

export class CharacteristicsOfThreatenedSpeciesInTheArea {
  public mobility = null;
  public status = null;
  public uncertainity = null;
  public totalActualVolumeOfLogsAvailableForHarvestedAfterMobilityAndDiscount =
    null;
}

export class CostImpactOnForestryPolicyPrescriptions {
  public mobilityImpact: number = 0;
  public statusImpact: number = 0;
  public uncertainityImpact: number = 0;
}

export class PlantationCostInputs {
  public isFencingRequired = null;
  public fencing: number = 16.1;
  public fencingMaintenance: number = 0.5;
  public ploughing: number = 435;
  public harvestingAndHaulageCostsForClearfell: number = 25.84;
  public harvestingAndHaulageCostsForThinning: number = 30.4;
  public plantationAndPlantingVosts: number = 1.5;
  public weedingHerbicideCosts: number = 0.3;
  public distanceFromBuyer: number = 100;
  public transportCode: number = 20;
  public contingency: number = 5;
}

export class ImpactOnHarvestRates {
  public mobility: number = 0;
  public uncertainity: number = 5;
}

export class ForestPolicyPrescriptions {
  public fixedCostOfPrescriptionsBaseLayer: number = 2500;
  public costOfFPPBaseLayer: number = 0.1;
  public fixedCostOfPrescriptionsBaseLayerAccounting: number = 2500;
  public costOfFPPBaseLayerAccounting: number = 0.1;
}

export class Compensation {
  public socialContribution: number = 10;
}
