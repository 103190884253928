import { v4 as uuidv4 } from 'uuid';
import { ForestClass } from './forestClass.model';

export class ForestDescriptionReport
{
    forestDescriptionReportID: string = uuidv4();

    paddockID?: String = null;

    propertyID?: String;

    forestClasses: Array<ForestClass> = [];

    estimatedTotalRecoverableVolume: number = 0;

    industrialGradePeeler: number = 0;

    sawLog: number = 0;

    pulpQualityLog: number = 0;

    totalArea: number = 0;
}
