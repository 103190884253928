import { AfterContentChecked, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest, map, switchMap, tap } from 'rxjs';
import { EnterpriseType } from 'src/app/models/enterpriseType.model';
import { LandUseType } from 'src/app/models/landUseType.model';
import { PaddockDexie } from 'src/app/models/paddock.model';
import { DropDownService } from 'src/app/services/dropdown.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { DeletePaddockComponent } from './delete-paddock/delete-paddock.component';

@Component({
  selector: 'app-edit-paddock',
  templateUrl: './edit-paddock.component.html',
  styleUrls: ['./edit-paddock.component.css']
})
export class EditPaddockComponent implements OnInit {

  landuseTypes: Array<LandUseType> = [];
  cropTypes: Array<EnterpriseType> = [];
  editPaddockForm: UntypedFormGroup;
  filteredEnterprises$;

  constructor(public dialogRef: MatDialogRef<EditPaddockComponent>,@Inject(MAT_DIALOG_DATA) public paddock: PaddockDexie,public dialog: MatDialog,public dropdownService:DropDownService) { }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;

    this.editPaddockForm = new UntypedFormGroup(
      {
      'cropType': new UntypedFormControl(null, CustomValidators.required),
      'landuseType': new UntypedFormControl(null, CustomValidators.required),
      'name': new UntypedFormControl(null, CustomValidators.required),
      'notes': new UntypedFormControl(null)
    },
    );


    combineLatest([this.dropdownService.landuseTypes,this.dropdownService.enterpriseTypes]).pipe(tap(([landuseTypes, enterpriseTypes]) => {

      this.landuseTypes = landuseTypes;
      this.editPaddockForm.get('landuseType').setValue(landuseTypes.find( x => x.name == this.paddock.landUseType))
      this.editPaddockForm.get('name').setValue(this.paddock.name);
      this.editPaddockForm.get('notes').setValue(this.paddock.notes);

    })).subscribe();




    this.filteredEnterprises$ = combineLatest([this.dropdownService.enterpriseTypes,this.editPaddockForm.get('landuseType').valueChanges]).pipe(
      map(
        ([enterpriseTypes,landUseType])=>
        enterpriseTypes.filter(
          (enterpriseType:EnterpriseType)=>
          enterpriseType.landUseTypeID == landUseType.landUseTypeID
        )
       )
    );

    this.filteredEnterprises$.subscribe(enterprises => {
      this.cropTypes = enterprises;

this.editPaddockForm.get('cropType').setValue(enterprises.find(x => x.name == this.paddock.enterpriseType));
    })

  }


  openDeleteConfirmationDialog(){
    let confirmationDialog = this.dialog.open(DeletePaddockComponent,{data:this.paddock.name});

    confirmationDialog.afterClosed().subscribe(result => {
      if(result)
      {
        this.dialogRef.close({deletePaddock: true});
      }
      else
      {
        return;
      }
    })
    }

    updatePaddockAndClose(){
      this.paddock.name = this.editPaddockForm.get('name').value;
      this.paddock.enterpriseType = this.editPaddockForm.get('cropType').value.name;
      this.paddock.landUseType = this.editPaddockForm.get('landuseType').value.name;
      this.paddock.notes = this.editPaddockForm.get('notes').value;

      this.dialogRef.close(this.paddock);
    }


}
