import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, ChangeDetectorRef } from '@angular/core';
import { FormGroupDirective, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-forestry-policy-prescription',
  templateUrl: './forestry-policy-prescription.component.html',
  styleUrls: ['./forestry-policy-prescription.component.css']
})
export class ForestryPolicyPrescriptionComponent implements OnInit {

  @Input() formGroupName: string;
  @Input() refFormGroupName: string;

  @Input() fixedCostOfPrescriptions: number;
  @Input() costFPP: number;

  increaseInCostToMobility: number = 0;
  increaseInCostToThreatenedStatus: number = 0;
  increaseInCostToUncertainity: number = 0;

  form: FormGroup
  refForm: FormGroup

  simpleChange: Subject<SimpleChange> = new Subject<SimpleChange>();

  @Output() valueChange = new EventEmitter();

  constructor(private rootFormGroup: FormGroupDirective, changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.refForm = this.rootFormGroup.control.get(this.refFormGroupName) as FormGroup;

    this.refForm.valueChanges.subscribe(val => {

      if (val["mobility"]) {
        const value = val["mobility"]

        if (value === "Highly Mobile") {
          this.increaseInCostToMobility = 25.00;
        }
        else if (value === "Somewhat Mobile") {
          this.increaseInCostToMobility = 16.66;
        }
        else if (value === "Somewhat Fixed") {
          this.increaseInCostToMobility = 8.66;
        }
        else {
          this.increaseInCostToMobility = 0.00;
        }

        this.setCostFpp(this.costFPP)
        this.setFixedCost(this.fixedCostOfPrescriptions)
      }

      if (val["Status"]) {
        const value = val["status"]

        if (value === "Critically Endangered") {
          this.increaseInCostToThreatenedStatus = 25.00;
        }
        else if (value === "Endangered") {
          this.increaseInCostToThreatenedStatus = 12.50;
        }
        else {
          this.increaseInCostToThreatenedStatus = 0.00;
        }

        this.setCostFpp(this.costFPP)
        this.setFixedCost(this.fixedCostOfPrescriptions)
      }

      if (val["Uncertainity"]) {
        const value = val["uncertainity"]

        if (value === "High Uncertainty") {
          this.increaseInCostToUncertainity = 25.00;
        }
        else if (value === "Medium Uncertainty") {
          this.increaseInCostToUncertainity = 12.50;
        }
        else {
          this.increaseInCostToUncertainity = 0.00;
        }

        this.setCostFpp(this.costFPP)
        this.setFixedCost(this.fixedCostOfPrescriptions)
      }

    });

    this.simpleChange.subscribe(C => {
      this.valueChange.emit(true)

      if (C["fixedCostOfPrescriptions"]) {
        this.setFixedCost(C["fixedCostOfPrescriptions"].currentValue)
      }

      if (C["costFPP"]) {
        this.setCostFpp(C["costFPP"].currentValue)
      }

      if (C["increaseInCostToMobility"]) {
        this.setFixedCost(C["fixedCostOfPrescriptions"].currentValue)
        this.setCostFpp(C["costFPP"].currentValue)
      }

      if (C["increaseInCostToThreatenedStatus"]) {
        this.setFixedCost(C["fixedCostOfPrescriptions"].currentValue)
        this.setCostFpp(C["costFPP"].currentValue)
      }

      if (C["increaseInCostToUncertainity"]) {
        this.setFixedCost(C["fixedCostOfPrescriptions"].currentValue)
        this.setCostFpp(C["costFPP"].currentValue)
      }
    })
  }

  setFixedCost(val: number) {
    const newFixedCost = val * (1 + (this.increaseInCostToMobility / 100 + this.increaseInCostToThreatenedStatus / 100 + this.increaseInCostToUncertainity / 100))
    this.form.controls["fixedCostOfPrescriptionsBaseLayerAccounting"].setValue(newFixedCost.toFixed(2));
  }

  setCostFpp(val: number) {
    const newCostFpp = val * (1 + (this.increaseInCostToMobility / 100 + this.increaseInCostToThreatenedStatus / 100 + this.increaseInCostToUncertainity / 100))
    this.form.controls["costOfFPPBaseLayerAccounting"].setValue(newCostFpp.toFixed(2));
  }

  ngOnChanges(C: SimpleChange) {

    this.simpleChange.next(C);

  }
}
