import { Component, OnInit } from '@angular/core';
import { Alert, AlertsService } from 'src/app/services/alerts.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  bomAlerts: Array<Alert> = [];
  constructor(private alertService:AlertsService) { }

  async ngOnInit(): Promise<void> {
this.alertService.bomAlerts.subscribe(alerts => {
  this.bomAlerts = alerts;
})
  }

}
