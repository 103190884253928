import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { liveQuery } from 'dexie';
import { BehaviorSubject, startWith, Subscription, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Repository } from 'typeorm';
import { ThreatenedSpeciesInTheAreaComponent } from '../components/npv-calculator/inputs/threatened-species-in-the-area/threatened-species-in-the-area.component';
import { DatabaseSyncAction, EntityType, Action } from '../models/databaseSync.model';
import { PaddockDexie } from '../models/paddock.model';
import { PropertyDexie } from '../models/property.model';
import { User } from '../models/user.model';
import { AuthService } from './auth.service';
import { ClippedLayerService } from './BaseLayerServices/clippedLayer.service';
import { DexieDatabaseService } from './dexieIndexDB.service';
import { OfflineService } from './offline.service';
import { PropertyService } from './property.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class PaddockService {


  paddocks = this.propertyService.activeProperty.pipe(
    switchMap((value) => {
      return value == null ? ([]) : liveQuery(() => this.dexieService.paddocks.where({propertyID: value.propertyID}).toArray())
    }),
    startWith([])
  )


  private userSub: Subscription;

  private databaseSub: Subscription;

  user: User;
  activeProperty: PropertyDexie;
  isOnline = true;

  constructor(
    private auth: AuthService,
    private propertyService: PropertyService,
    private clippedLayerService: ClippedLayerService,
    private dexieService: DexieDatabaseService,
    private http:HttpClient,private connectionService: OfflineService,
    private toastService:ToastService) {
      connectionService.isOnline.subscribe(status => this.isOnline = status);
    this.userSub = auth.user.subscribe((user) => {
      this.user = user;
    });

  }

  async save(paddock: PaddockDexie) {
    await this.dexieService.paddocks.add(paddock);

    if(this.isOnline)
    {
       this.http.post(environment.apiURL + 'Paddock/add', paddock).pipe(
        ) .subscribe(resp =>  this.toastService.showSuccess('Paddock created and synced'));
    }
    else
    {
      this.dexieService.databaseSyncActions.add(new DatabaseSyncAction(EntityType.Paddock, paddock.paddockID, Action.Insert));
      this.toastService.showSuccess('Paddock created locally')
    }

  }

  async remove(paddock: PaddockDexie) {
    await this.dexieService.paddocks.delete(paddock.paddockID);

    await this.dexieService.paddockClippedLayers.where({paddockID: paddock.paddockID}).delete()
    await this.dexieService.enterpriseReports.where({paddockID:paddock.paddockID}).delete();
    await this.dexieService.topexReports.where({paddockID:paddock.paddockID}).delete();
          await this.dexieService.npvReports
      .where({paddockID: paddock.paddockID })
      .delete();

    if(this.isOnline)
    {
       this.http.post(environment.apiURL + `Paddock/delete?paddockID=${paddock.paddockID}`,'').pipe(
        ).subscribe(resp =>  this.toastService.showSuccess('Paddock deleted and synced'));
    }
    else
    {
      this.dexieService.databaseSyncActions.add(new DatabaseSyncAction(EntityType.Paddock, paddock.paddockID, Action.Remove));
      this.toastService.showSuccess('Paddock deleted locally')
    }


  }

  async removeAll(property)
  {
    let loadedPaddocks = await this.dexieService.paddocks.where({ propertyID: property.propertyID }).toArray();
      await this.dexieService.paddocks.bulkDelete(loadedPaddocks.map(paddock => paddock.paddockID));

  }

  async update(paddock: PaddockDexie) {
    await this.dexieService.paddocks.update(paddock.paddockID, paddock);

    if(this.isOnline)
    {
       this.http.post(environment.apiURL + 'Paddock/update', paddock).pipe(
        ).subscribe(resp =>  this.toastService.showSuccess('Paddock updated and synced'));
    }
    else
    {
      this.dexieService.databaseSyncActions.add(new DatabaseSyncAction(EntityType.Paddock, paddock.paddockID, Action.Update));
      this.toastService.showSuccess('Paddock updated locally')
    }
  }


}
