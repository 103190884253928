
import { v4 as uuidv4 } from 'uuid';
import { Enterprise } from './enterprise.model';

export class EnterpriseReport {
  enterpriseReportID: string = uuidv4();

  paddockID?: String = null;

  propertyID?: String;

  data: Array<EnterpriseReportRow> = [];

  uploaded:boolean = false;

  createdOn: string = new Date().toUTCString();

  modifiedOn: string = new Date().toUTCString();

}

export class EnterpriseReportRow {
  crop: Enterprise;
  cropRankValue: number;
  rank: number;
  wellSuited: number;
  suitable: number;
  moderatelySuitable:number;
  unsuitable: number;
}
