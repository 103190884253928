import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { PropertyDexie } from 'src/app/models/property.model';
import { PropertyService } from 'src/app/services/property.service';

@Component({
  selector: 'app-property-select',
  templateUrl: './property-select.component.html',
  styleUrls: ['./property-select.component.css'],
})
export class PropertySelectComponent implements OnInit, OnDestroy {
  private combinedSub: Subject<void> = new Subject<void>();

  propertySelect: FormControl = new FormControl('');

  properties: Array<PropertyDexie> = [];

  $property: Observable<PropertyDexie>;

  constructor(public propertyService: PropertyService, private changeDetectorRef:ChangeDetectorRef) {

    this.$property = this.propertyService.activeProperty.pipe(distinctUntilChanged());
  }

  ngOnInit(): void {
    this.propertySelect.valueChanges
    .pipe(distinctUntilChanged())
    .subscribe((property) => {
      this.propertyService.activeProperty.next(property);
    });


    combineLatest([this.$property, this.propertyService.properties])
      .pipe(takeUntil(this.combinedSub))
      .subscribe(([activeProperty, properties]) => {
        this.properties = properties;
        if (properties.length > 0 && this.propertySelect.value !== activeProperty && activeProperty != null ) {
          this.propertySelect.setValue( this.properties.find((p) => p.propertyID === activeProperty.propertyID));
        }
      });
  }

  ngOnDestroy(): void {
    this.combinedSub.next();
    this.combinedSub.complete();
  }
}
