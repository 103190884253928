import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { AppRoutingModule } from './app-routing.module';

import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';


import { TokenStorageService } from './services/token-storage.service';
import { HttpClientModule } from '@angular/common/http';

import { mapService } from './services/map.service';

import { AccountService } from './services/account.service';

import { authInterceptorProviders } from './interceptors/auth.interceptor';
import { ActivateComponent } from './components/activate/activate.component';
import { AppComponent } from './app.component';
import { AlertComponent } from './components/dashboard/alert/alert.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ManagePaddocksComponent } from './components/dashboard/manage-paddocks/manage-paddocks.component';
import { ManagePropertyComponent } from './components/dashboard/manage-property/manage-property.component';
import { NewsComponent } from './components/dashboard/news/news.component';
import { ToolsComponent } from './components/dashboard/tools/tools.component';
import { UserDetailsComponent } from './components/dashboard/user-details/user-details.component';
import { FooterComponent } from './components/footer/footer.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { MapComponent } from './components/map/map.component';
import { NavComponent } from './components/nav/nav.component';
import { PropertySelectComponent } from './components/property-select/property-select.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { GeocodeComponent } from './components/map/geocode/geocode.component';
import { ModalComponent } from './components/shared/modal/modal.component';
import { PopoverHelpComponent, PopperHelpDirective } from './components/map/popover-help/popover-help.component';
import { ArisingsRevenueComponent } from './components/npv-calculator/benefits/arisings-revenue/arisings-revenue.component';
import { PeelerLogRevenueComponent } from './components/npv-calculator/benefits/peeler-log-revenue/peeler-log-revenue.component';
import { SawlogRevenueComponent } from './components/npv-calculator/benefits/sawlog-revenue/sawlog-revenue.component';
import { FencingCostComponent } from './components/npv-calculator/costs/fencing-cost/fencing-cost.component';
import { FencingMaintenanceComponent } from './components/npv-calculator/costs/fencing-maintenance/fencing-maintenance.component';
import { ForegoneLandSaleRevenueComponent } from './components/npv-calculator/costs/foregone-land-sale-revenue/foregone-land-sale-revenue.component';
import { ForestryPolicyPrescriptionCostsComponent } from './components/npv-calculator/costs/forestry-policy-prescription-costs/forestry-policy-prescription-costs.component';
import { HarvestingHaulageCostsComponent } from './components/npv-calculator/costs/harvesting-haulage-costs/harvesting-haulage-costs.component';
import { PloughingPlantationCostsComponent } from './components/npv-calculator/costs/ploughing-plantation-costs/ploughing-plantation-costs.component';
import { PloughingComponent } from './components/npv-calculator/costs/ploughing/ploughing.component';
import { TransportCostsComponent } from './components/npv-calculator/costs/transport-costs/transport-costs.component';
import { WeedingHerbicideCostsComponent } from './components/npv-calculator/costs/weeding-herbicide-costs/weeding-herbicide-costs.component';
import { HeaderComponent } from './components/npv-calculator/header/header.component';
import { CompensationComponent } from './components/npv-calculator/inputs/compensation/compensation.component';
import { CostImpactForestryPolicyPrescriptionComponent } from './components/npv-calculator/inputs/cost-impact-forestry-policy-prescription/cost-impact-forestry-policy-prescription.component';
import { ForestryPolicyPrescriptionComponent } from './components/npv-calculator/inputs/forestry-policy-prescription/forestry-policy-prescription.component';
import { ImpactHarvestRateComponent } from './components/npv-calculator/inputs/impact-harvest-rate/impact-harvest-rate.component';
import { InputsComponent } from './components/npv-calculator/inputs/inputs.component';
import { PlantationCostComponent } from './components/npv-calculator/inputs/plantation-cost/plantation-cost.component';
import { PropertySpecificationsComponent } from './components/npv-calculator/inputs/property-specifications/property-specifications.component';
import { ReturnFromForestryComponent } from './components/npv-calculator/inputs/return-from-forestry/return-from-forestry.component';
import { ThreatenedSpeciesInTheAreaComponent } from './components/npv-calculator/inputs/threatened-species-in-the-area/threatened-species-in-the-area.component';
import { TimeInputsComponent } from './components/npv-calculator/inputs/time-inputs/time-inputs.component';
import { PrimaryInputsComponent } from './components/npv-calculator/primary-inputs/primary-inputs.component';
import { ProgressBarComponent } from './components/npv-calculator/progress-bar/progress-bar.component';
import { RevenueTabsComponent } from './components/npv-calculator/revenue/revenue-tabs/revenue-tabs.component';
import { TotalComponent } from './components/npv-calculator/total/total.component';
import { LayerSwitcherComponent } from './components/map/layer-switcher/layer-switcher.component';
import { LayerComponent } from './components/map/layer-switcher/layer/layer.component';
import { LayerGroupComponent } from './components/map/layer-switcher/layer-group/layer-group.component';
import { ToolbarComponent } from './components/map/toolbar/toolbar.component';
import { GpsComponent } from './components/map/tools/gps/gps.component';
import { MeasureComponent } from './components/map/tools/measure/measure.component';
import { GraticleComponent } from './components/map/tools/graticle/graticle.component';
import { ZoomComponent } from './components/map/tools/zoom/zoom.component';
import { SelectComponent } from './components/map/tools/select/select.component';
import { CommonModule, DecimalPipe } from '@angular/common';
import { SelectPropertyComponent } from './components/map/tools/select-property/select-property.component';
import { DigitizePaddockComponent } from './components/map/tools/digitize-paddock/digitize-paddock.component';
import { DigitizeFenceComponent } from './components/map/tools/digitize-fence/digitize-fence.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivePropertyComponent } from './components/map/active-property/active-property.component';

import { AnalysisGroupComponent } from './components/map/layer-switcher/analysis-group/analysis-group.component';
import { BaseGroupComponent } from './components/map/layer-switcher/base-group/base-group.component';
import { WelcomeDialogComponent } from './components/map/dialogs/welcome/welcome-dialog.component';
import { CreatePropertyComponent } from './components/map/dialogs/property/create-property/create-property.component';
import { EditPropertyComponent } from './components/map/dialogs/property/edit-property/edit-property.component';
import { DeletePropertyComponent } from './components/map/dialogs/property/edit-property/delete-property/delete-property.component';
import { CreatePaddockComponent } from './components/map/dialogs/paddock/create-paddock/create-paddock.component';
import { EditPaddockComponent } from './components/map/dialogs/paddock/edit-paddock/edit-paddock.component';
import { DeletePaddockComponent } from './components/map/dialogs/paddock/edit-paddock/delete-paddock/delete-paddock.component';
import { CreateFenceComponent } from './components/map/dialogs/fence/create-fence/create-fence.component';
import { EditFenceComponent } from './components/map/dialogs/fence/edit-fence/edit-fence.component';
import { DeleteFenceComponent } from './components/map/dialogs/fence/edit-fence/delete-fence/delete-fence.component';
import { AdminComponent } from './components/admin/admin.component';
import { UserManagementComponent } from './components/admin/user-management/user-management.component';
import { RoleGuard } from './guards/role.guard';
import { AttributeOverlayComponent } from './components/map/tools/attribute-overlay/attribute-overlay.component';
import { BaseFloatComponent } from './components/map/layer-switcher/base-float/base-float.component';
import { IsCalculatorDataSavedGuard } from './guards/is-calculator-data-saved.guard';
import { ExitConfirmationComponent } from './components/npv-calculator/exit-confirmation/exit-confirmation.component';
import { OfflineService } from './services/offline.service';

import { MathCeilPipe } from './pipes/math-ceil.pipe';
import { EnterpriseService } from './services/enterprise.service';
import { TopexLayerComponent } from './components/map/layer-switcher/topex-layer/topex-layer.component';
import { TopexReportComponent } from './components/map/dialogs/topex-report/topex-report.component';
import { RoundingPipe } from './pipes/rounding.pipe';
import { TopexMapOverlayComponent } from './components/map/dialogs/topex-report/topex-map-overlay/topex-map-overlay.component';
import { SpaceBeforeCapPipe } from './pipes/space_before_capitial.pipe';
import { DssReportComponent } from './components/map/dialogs/dss-report/dss-report.component';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { DssMapOverlayComponent } from './components/map/dialogs/dss-report/dss-map-overlay/dss-map-overlay.component';

import { FarmWoodlotToolComponent } from './components/farm-woodlot-tool/farm-woodlot-tool.component';
import { PtrGuidelinesComponent } from './components/farm-woodlot-tool/ptr-guidelines/ptr-guidelines.component';
import { MarketGuidelinesComponent } from './components/farm-woodlot-tool/market-guidelines/market-guidelines.component';
import { SitePreparationGuidelinesComponent } from './components/farm-woodlot-tool/site-preparation-guidelines/site-preparation-guidelines.component';
import { TreeProtectionComponent } from './components/farm-woodlot-tool/tree-protection/tree-protection.component';
import { FarmWoodlotToolWelcomeMessageComponent } from './components/farm-woodlot-tool/farm-woodlot-tool-welcome-message/farm-woodlot-tool-welcome-message.component';
import { MaterialsSpeciesPlantingComponent } from './components/farm-woodlot-tool/materials-species-planting/materials-species-planting.component';
import { IndicativeYieldsComponent } from './components/farm-woodlot-tool/indicative-yields/indicative-yields.component';
import { ToPrecisionPipe } from './pipes/to-precision.pipe';
import { ForestDescriptionReportComponent } from './components/map/dialogs/forest-description-report/forest-description-report.component';
import { ForestClassLayerComponent } from './components/map/layer-switcher/forest-class-layer/forest-class-layer.component';
import { ForestDescriptionMapOverlayComponent } from './components/map/dialogs/forest-description-report/forest-description-map-overlay/forest-description-map-overlay.component';
import { ForestDescriptionDisclaimerOverlayComponent } from './components/map/dialogs/forest-description-report/forest-description-disclaimer-overlay/forest-description-disclaimer-overlay.component';
import { SendFeedbackDialogComponent } from './components/shared/send-feedback-dialog/send-feedback-dialog.component';

import { ReportCardComponent } from './components/map/reporting/report-card/report-card.component';
import { MeasureSettingsComponent } from './components/map/tools/measure/measure-settings/measure-settings.component';
import { TooltipDirective } from './components/shared/directives/tooltip/tooltip.directive';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { RoleDirective } from './components/shared/directives/role/role.directive';
import { SharedModule } from './shared/shared.module';
import { DecimalFormattingDirective } from './directives/decimal-formatting.directive';
import { NotFoundComponent } from './not-found/not-found.component';



@NgModule({
  declarations: [
    ActivateComponent,
    NavComponent,
    DashboardComponent,
    ManagePaddocksComponent,
    ToolsComponent,
    NewsComponent,
    AlertComponent,
    LoginComponent,
    FooterComponent,
    ResetPasswordComponent,
    ManagePropertyComponent,
    UserDetailsComponent,
    ForgotPasswordComponent,
    MapComponent,
    RegisterComponent,
    AppComponent,
    GeocodeComponent,
    PopperHelpDirective,
    ModalComponent,
    PopoverHelpComponent,
    InputsComponent,
    HeaderComponent,
    TotalComponent,
    PrimaryInputsComponent,
    TimeInputsComponent,
    PropertySpecificationsComponent,
    ProgressBarComponent,
    ReturnFromForestryComponent,
    ForestryPolicyPrescriptionComponent,
    PlantationCostComponent,
    ThreatenedSpeciesInTheAreaComponent,
    CostImpactForestryPolicyPrescriptionComponent,
    ImpactHarvestRateComponent,
    CompensationComponent,
    SawlogRevenueComponent,
    RevenueTabsComponent,
    PeelerLogRevenueComponent,
    ArisingsRevenueComponent,
    ForegoneLandSaleRevenueComponent,
    FencingCostComponent,
    FencingMaintenanceComponent,
    PloughingComponent,
    HarvestingHaulageCostsComponent,
    TransportCostsComponent,
    PloughingPlantationCostsComponent,
    WeedingHerbicideCostsComponent,
    ForestryPolicyPrescriptionCostsComponent,
    LayerSwitcherComponent,
    LayerComponent,
    LayerGroupComponent,
    ToolbarComponent,
    GpsComponent,
    MeasureComponent,
    GraticleComponent,
    ZoomComponent,
    SelectComponent,
    PropertySelectComponent,
    SelectPropertyComponent,
    DigitizePaddockComponent,
    DigitizeFenceComponent,
    ActivePropertyComponent,
    AnalysisGroupComponent,
    BaseGroupComponent,
    WelcomeDialogComponent,
    CreatePropertyComponent,
    EditPropertyComponent,
    DeletePropertyComponent,
    CreatePaddockComponent,
    EditPaddockComponent,
    DeletePaddockComponent,
    CreateFenceComponent,
    EditFenceComponent,
    DeleteFenceComponent,
    AdminComponent,
    UserManagementComponent,
    AttributeOverlayComponent,
    BaseFloatComponent,
    ExitConfirmationComponent,
    MathCeilPipe,
    RoundingPipe,
    SpaceBeforeCapPipe,
    OrdinalPipe,
    ToPrecisionPipe,
    TopexLayerComponent,
    TopexReportComponent,
    TopexMapOverlayComponent,
    DssReportComponent,
    DssMapOverlayComponent,
    FarmWoodlotToolComponent,
    PtrGuidelinesComponent,
    MarketGuidelinesComponent,
    SitePreparationGuidelinesComponent,
    TreeProtectionComponent,
    FarmWoodlotToolWelcomeMessageComponent,
    MaterialsSpeciesPlantingComponent,
    IndicativeYieldsComponent,
    ForestDescriptionReportComponent,
    ForestClassLayerComponent,
    ForestDescriptionMapOverlayComponent,
    ForestDescriptionDisclaimerOverlayComponent,
    SendFeedbackDialogComponent,
    ReportCardComponent,
    MeasureSettingsComponent,
    TooltipDirective,
    TruncateTextPipe,
    NumberFormatPipe,
    RoleDirective,
    DecimalFormattingDirective,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    SharedModule

  ],
  providers: [AuthGuard, RoleGuard, authInterceptorProviders, AuthService, TokenStorageService, mapService, AccountService, IsCalculatorDataSavedGuard,OfflineService,EnterpriseService, DecimalPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
