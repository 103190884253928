import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Subscription,switchMap } from 'rxjs';
import { Repository } from 'typeorm';
import { EventData } from '../_helpers/event.class';
import { TreeMapperCustomEvents } from '../_helpers/enums';
import { AuthService } from './auth.service';
import { EventBusService } from './EventBus.service';
import { PropertyService } from './property.service';
import { PropertyDexie } from '../models/property.model';
import { FenceDexie } from '../models/fence.model';
import { DexieDatabaseService } from './dexieIndexDB.service';
import { liveQuery } from 'dexie';
import { HttpClient } from '@angular/common/http';
import { Enterprise } from '../models/enterprise.model';
import { EnterpriseReport } from '../models/enterpriseReport.model';
import { PaddockDexie } from '../models/paddock.model';
import { environment } from 'src/environments/environment';
import { DatabaseSyncAction, EntityType, Action } from '../models/databaseSync.model';
import { OfflineService } from './offline.service';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseReportService {


  enterprises = liveQuery(() => this.dexieService.enterpriseReports.toArray());

  api = environment.apiURL + 'EnterpriseReport';

  isOnline = true;

  constructor(private auth: AuthService, private dexieService:DexieDatabaseService, private eventBusService: EventBusService, private propertyService: PropertyService, private http: HttpClient, private connectionService: OfflineService) {
    connectionService.isOnline.subscribe(status => this.isOnline = status);
   }

   async getPropertyReport(id: string): Promise<EnterpriseReport>
   {
    return this.dexieService.enterpriseReports.where("propertyID").equals(id).first();
   }

   async getPaddockReport(id: string): Promise<EnterpriseReport>
   {
    return this.dexieService.enterpriseReports.where("paddockID").equals(id).first();
   }

   async save(report: EnterpriseReport)
   {
    await this.dexieService.enterpriseReports.add(report);



    if(this.isOnline)
    {
      this.http.post(this.api + (report.paddockID == null ? '/property/add' : '/paddock/add'), report).pipe(
        ).subscribe();
    }

    else
    {
      this.dexieService.databaseSyncActions.add(new DatabaseSyncAction(EntityType.EnterpriseReport, report.enterpriseReportID, Action.Insert));
    }
   }

   async remove(fence: FenceDexie)
   {
     await this.dexieService.fences.delete(fence.fenceID);

   }

   async update(fence: FenceDexie)
   {
    await this.dexieService.fences.update(fence.fenceID, fence);

   }


}
