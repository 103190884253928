import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MarketGuidelinesService } from '../market-guidelines.service';
import { PrintPdfFilesService } from '../print-pdf-files.service';
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';
import { number } from 'mathjs';

@Component({
  selector: 'app-market-guidelines',
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, maxHeight: 0 }),
            animate('0.75s ease-in',
              style({ opacity: 1, maxHeight: 1000 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1, maxHeight: 1000 }),
            animate('0.25s ease-out',
              style({ maxHeight: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ],
  templateUrl: './market-guidelines.component.html',
  styleUrls: ['./market-guidelines.component.css']
})
export class MarketGuidelinesComponent implements OnInit {

  @Output() parentEventTransmitter = new EventEmitter();

  filename: string = "Mareket Guideline";
  constructor(public marketGuideService: MarketGuidelinesService, public pdfService: PrintPdfFilesService) { }

  ngOnInit(): void {
  }

  generateReadablePDFFile() {
    // this.pdfService.generateNewPDF(this.filename);
  }

  passValuesToParent() {
    this.parentEventTransmitter.emit({
      stage: 5,
      values: {
        "selectedMarketType": this.marketGuideService.selectedMarketType,
        "selectedTopic": this.marketGuideService.selectedTopic,
        "overviewDescription": this.marketGuideService.overviewDescription,
        "overviewURL": this.marketGuideService.overviewURL,
      }
    })
  }

}
