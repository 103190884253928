import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NpvCalculatorDataStorageService } from 'src/app/services/npv-calculator-data-storage.service';

@Component({
  selector: 'app-ploughing-plantation-costs',
  templateUrl: './ploughing-plantation-costs.component.html',
  styleUrls: ['./ploughing-plantation-costs.component.css']
})
export class PloughingPlantationCostsComponent implements OnInit, OnChanges {

  @Input() totalPotentialLogsHarvestedPerYear: number;
  @Input() harvestedPecentage: number;
  @Input() plantationPlantingCosts: number;
  @Input() discountRate: number;
  @Input() harvestLifespan: number | string;
  @Output() calculationsValueEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private npvCalcService: NpvCalculatorDataStorageService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    // Passing values to the parent component from 
    if (changes) {
      const calculationData = {
        "Ploughing Plantation Cost": {
          "Total Actual Volume of Logs Harvested (tonne)": this.totalPotentialLogsHarvestedPerYear,
          "Plantation and Planting Costs ($/tonne)": this.plantationPlantingCosts,
          "Monetary Value": this.calculateMonetaryValue(),
          "Discount Rate": this.discountRate,
          "Harvest Lifespan": this.harvestLifespan,
          "PV(C)": this.calculatePVC().toFixed(2),
        }
      }
      this.calculationsValueEmitter.emit(calculationData)
    }

  }

  calculateActualVolumnOfLogsHarvested() {
    return this.totalPotentialLogsHarvestedPerYear * (1 - this.harvestedPecentage);
  }

  calculateMonetaryValue() {
    // return this.calculateActualVolumnOfLogsHarvested() * this.plantationPlantingCosts
    return this.totalPotentialLogsHarvestedPerYear * this.plantationPlantingCosts
  }

  calculatePVC() {

    if (this.harvestLifespan === "Indefinite") {

      const cPVC =   this.calculateMonetaryValue() / this.discountRate
      this.npvCalcService.updateCosts({ key: 'ploughing-plantation-costs', value: cPVC })
      return cPVC;

    }
    else {

      const cPVC =   this.calculateMonetaryValue() * ((1 - (1 + (this.discountRate / 100)) ** -this.harvestLifespan) / (this.discountRate / 100))
      this.npvCalcService.updateCosts({ key: 'ploughing-plantation-costs', value: cPVC })
      return cPVC;

    }

  }

}
