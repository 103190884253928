import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IndicativeYieldsService } from '../indicative-yields.service';
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';
import { EnterpriseReport, EnterpriseReportRow } from 'src/app/models/enterpriseReport.model';
import { EnterpriseReportService } from 'src/app/services/enterpriseReport.service';
import { from, map, of, switchMap, tap } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { Enterprise } from 'src/app/models/enterprise.model';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-indicative-yields',
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, maxHeight: 0 }),
            animate('0.75s ease-in',
              style({ opacity: 1, maxHeight: 1000 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1, maxHeight: 1000 }),
            animate('0.25s ease-out',
              style({ maxHeight: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ],
  templateUrl: './indicative-yields.component.html',
  styleUrls: ['./indicative-yields.component.css']
})
export class IndicativeYieldsComponent implements OnInit {

  @Output() parentEventTransmitter = new EventEmitter();
  @Input() id: string = '';

  $enterpriseReport;
  selectedCropSpecies: string = "";
  totalArea: number = 0;
  averageMAI: number = 0;
  harvestableVolume: number = 0;
  harvestableVolumePerHectare: number = 0;

  enterpriseSelect = new FormControl();

  indicativeYieldForm: FormGroup = new FormGroup({
    mappedWellSuited: new FormControl({ value: 10, disabled: true }, [Validators.required]),
    mappedSuitable: new FormControl({ value: 20, disabled: true }, [Validators.minLength(0)]),
    mappedModeratelySuitable: new FormControl({ value: 30, disabled: true }, [Validators.minLength(0)]),
    mappedUnsuitable: new FormControl({ value: 10, disabled: true }, [Validators.minLength(0)]),

    // https://dev.azure.com/eskmapping/Farm%20and%20Forest%20Mapper/_workitems/edit/145/
    estimatedWellSuited: new FormControl({ value: 20, disabled: false }, [Validators.required]),
    estimatedSuitable: new FormControl({ value: 17, disabled: false }, [Validators.minLength(0)]),
    estimatedModeratelySuitable: new FormControl({ value: 14, disabled: false }, [Validators.minLength(0)]),
    estimatedUnsuitable: new FormControl({ value: 0, disabled: false }, [Validators.minLength(0)]),
  });

  refIndicativeYieldForm: FormGroup = new FormGroup({
    mappedWellSuited: new FormControl({ value: 10, disabled: true }, [Validators.required]),
    mappedSuitable: new FormControl({ value: 20, disabled: true }, [Validators.minLength(0)]),
    mappedModeratelySuitable: new FormControl({ value: 30, disabled: true }, [Validators.minLength(0)]),
    mappedUnsuitable: new FormControl({ value: 10, disabled: true }, [Validators.minLength(0)]),

    // https://dev.azure.com/eskmapping/Farm%20and%20Forest%20Mapper/_workitems/edit/145/
    estimatedWellSuited: new FormControl({ value: 20, disabled: false }, [Validators.required]),
    estimatedSuitable: new FormControl({ value: 17, disabled: false }, [Validators.minLength(0)]),
    estimatedModeratelySuitable: new FormControl({ value: 14, disabled: false }, [Validators.minLength(0)]),
    estimatedUnsuitable: new FormControl({ value: 0, disabled: false }, [Validators.minLength(0)]),
  });

  finalHarvestAgeForm: FormGroup = new FormGroup({
    finalHarvestAge: new FormControl({ value: 15, disabled: false }, [Validators.required]),
  });

  constructor(
    private enterpriseReportService: EnterpriseReportService,
    private decPipe: DecimalPipe) {
  }

  ngOnInit(): void {
    this.$enterpriseReport = from(this.enterpriseReportService.getPropertyReport(this.id)).pipe(switchMap(report => of(report.data)), map(
      (report: Array<EnterpriseReportRow>) => {
        // console.log(report)
        return report.filter(value => value.crop.label.toLowerCase().includes("pine") || value.crop.label.toLowerCase().includes("eucalyptus"))
      }
    ));

    this.enterpriseSelect.valueChanges.pipe(tap((data: EnterpriseReportRow) => {

      if (data["crop"]["label"] === "Radiata Pine") {
        this.finalHarvestAgeForm.get('finalHarvestAge').setValue(30)
      } else {
        this.finalHarvestAgeForm.get('finalHarvestAge').setValue(15)
      }
      

      this.indicativeYieldForm.get('mappedWellSuited').setValue(this.toPrecision(data.wellSuited))
      this.indicativeYieldForm.get('mappedSuitable').setValue(this.toPrecision(data.suitable))
      this.indicativeYieldForm.get('mappedModeratelySuitable').setValue(this.toPrecision(data.moderatelySuitable))
      this.indicativeYieldForm.get('mappedUnsuitable').setValue(this.toPrecision(data.unsuitable))

    })).subscribe();

  }

  toPrecision(x) {
    return Number(x.toPrecision(5));
  }

  calculateTotalArea() {
    this.totalArea = 0;
    const formValues = this.indicativeYieldForm.getRawValue();
    for (const key in formValues) {
      if (Object.prototype.hasOwnProperty.call(formValues, key)) {
        const element = formValues[key];
        if (key.includes("mapped")) {
          this.totalArea += Number(element);
        }
      }
    }
  }

  // reset

  calculateMAI() {

    const formControls = this.indicativeYieldForm.controls;

    const wellSuited: number = formControls["mappedWellSuited"].value * formControls["estimatedWellSuited"].value;
    const suitable: number = formControls["mappedSuitable"].value * formControls["estimatedSuitable"].value
    const moderatelySuitable: number = formControls["mappedModeratelySuitable"].value * formControls["estimatedModeratelySuitable"].value
    const unsuited: number = formControls["mappedUnsuitable"].value * formControls["estimatedUnsuitable"].value

    this.averageMAI = this.toPrecision((wellSuited + suitable + moderatelySuitable + unsuited) / this.totalArea);
  }

  calculateHarvestableVolume() {
    const formData = this.finalHarvestAgeForm.controls;
    this.harvestableVolume = this.toPrecision(this.averageMAI * formData["finalHarvestAge"].value * this.totalArea);
  }

  calculateHarvestableVolumePerHectare() {
    this.harvestableVolumePerHectare = this.toPrecision(this.harvestableVolume / this.totalArea);
  }

  calculateHarvestableVolumeHandler() {
    this.calculateHarvestableVolume();
    this.calculateHarvestableVolumePerHectare();
  }

  formValueUpdateHandler() {
    this.calculateMAI();
    this.calculateHarvestableVolume();
    this.calculateHarvestableVolumePerHectare();
  }

  setIndicativeYield(evt: Event) {
    const elem = evt.target as HTMLSelectElement;
    const val = elem.value;

    this.selectedCropSpecies = val;

    this.calculateTotalArea();
    this.formValueUpdateHandler();
  }

  passValuesToParent() {
    this.parentEventTransmitter.emit({
      stage: 4,
      values: {
        "selectedCropSpecies": this.selectedCropSpecies,

        "mappedWellSuited": this.indicativeYieldForm.controls["mappedWellSuited"].value,
        "mappedSuitable": this.indicativeYieldForm.controls["mappedSuitable"].value,
        "mappedModeratelySuitable": this.indicativeYieldForm.controls["mappedModeratelySuitable"].value,
        "mappedUnsuitable": this.indicativeYieldForm.controls["mappedUnsuitable"].value,

        "estimatedWellSuited": this.indicativeYieldForm.controls["estimatedWellSuited"].value,
        "estimatedSuitable": this.indicativeYieldForm.controls["estimatedSuitable"].value,
        "estimatedModeratelySuitable": this.indicativeYieldForm.controls["estimatedModeratelySuitable"].value,
        "estimatedUnsuitable": this.indicativeYieldForm.controls["estimatedUnsuitable"].value,

        "totalArea": this.totalArea,
        "averageMAI": this.averageMAI,
        "harvestableVolume": this.harvestableVolume,
        "harvestableVolumePerHectare": this.harvestableVolumePerHectare,
      }
    })
  }

}
