<table class="uk-table uk-table-hover uk-table-divider uk-table uk-table-justify">
    <tbody>
        <tr>
            <td>Impact on Costs from FPP (% increase)</td>
            <td>{{(mobilityPPC /100 + uncertainityPPC/100)}}</td>
        </tr>
        <tr>
            <td>Fixed Cost of Prescriptions</td>
            <td>{{fixedCostPrescriptions| currency}}</td>
        </tr>
        <tr>
            <td>Total Actual Volume of Logs Harvested (tonne)</td>
            <!-- <td>{{calculateActualVolumnOfLogsHarvested()| currency}}</td> -->
            <td>{{totalActualVolumeLogsHarvestedPerYear| number}}</td>
        </tr>
        <tr>
            <td>Cost of FPP ($/tonne)</td>
            <td>{{costOfFPP}}</td>
        </tr>
        <tr>
            <td>Total FPP Costs (Excluding Compensation)</td>
            <td>{{calculateTotalFPPCost()| currency}}</td>
        </tr>

        <!-- <tr>
            <td>Legal Fees to Access Compensation</td>
            <td>{{legalFeesAccessCompensation}}</td>
        </tr> -->
        <tr>
            <td>Monetary Value</td>
            <td>{{calculateMonetaryValue()| currency}}</td>
        </tr>
        <tr>
            <td>Discount Rate</td>
            <td>{{discountRate}}</td>
        </tr>

        <tr>
            <td>Harvest Lifespan</td>
            <td>{{harvestLifespan}}</td>
        </tr>
        <tr>
            <td>PV(C)</td>
            <td>{{calculatePVC()| currency}}</td>
        </tr>
    </tbody>
</table>