import { Injectable } from '@angular/core';
import { Popover } from 'bootstrap';

type Placement = "auto" | "top" | "bottom" | "left" | "right";


interface PopperHelpOptions{
  content: string,
  placement: Placement,
  id?: string,
  order: Number,
  stage: Number,
  title?: string,
  preShowCallback? :Function,
  preShowCallbackParams?: any,
  postShowCallback? :Function,
  postShowCallbackParams?: any,
}

interface PopperHelpDefinition{
  element: HTMLElement,
  options : PopperHelpOptions
}

@Injectable({
  providedIn: 'root',
})
export class PopperHelpService {
  private popoverMap: Map<Number, Array<PopperHelpDefinition>> = new Map();
  private popoverStage: Array<PopperHelpDefinition> =[];
  private popover: Popover;
  private currentIndex = 1;
  private currentStage = 1;
  private closeHeaderId = 'popper_help_close';
  private closeFooterId = 'popper_help_close2';
  private countId = 'popper_help_count';
  private nextId = 'popper_help_next';
  private previousId = 'popper_help_previous';


  constructor() {
    document.addEventListener('click',(e) => {

      let target = e.target as HTMLElement;

      if(target && target.id == this.nextId){
           this.next();
       }
      else if(target && target.id == this.previousId)
      {
        this.previous();
      }
      else if(target && target.id == this.closeFooterId || target.id == this.closeHeaderId)
      {
        this.hide();
        this.finish();
        this.currentIndex = 0;
      }
   });
  }

  startHelp(stage: number = 1)
  {
    if(this.popoverMap.size > 0)
    {
      if(this.popover)
      {
        this.popover.enable();
      }
      this.currentIndex = 0;
      this.currentStage = stage;
      this.show();
    }
  }

/**
 * If the popper is not already in the map, add it to the map. If it is in the map, do nothing.
 * @param {PopperHelpDefinition} popper - PopperHelpDefinition
 * @returns The return value is the value of the last expression evaluated.
 */
  add(popper: PopperHelpDefinition) {
    if (!this.popoverMap.has(popper.options.stage)) {
      this.popoverMap.set(popper.options.stage, [popper]);
    }

   let stage = this.popoverMap.get(popper.options.stage);

   if(stage.includes(popper))
   {
     return;
   }

   stage.push(popper);

   stage = stage.sort((a ,b ) => {
    if (a.options.order < b.options.order) {
      return -1;
    }
    if (a.options.order > b.options.order) {
      return 1;
    }
    return 0;
   });

   this.popoverMap.set(popper.options.stage,stage);

  }

  show()
  {
    this.popoverStage = this.popoverMap.get(this.currentStage);
    let popover = this.popoverStage[this.currentIndex];
    let element = popover.element;
    let options = popover.options;

    let newPopover = this.generatePopover(element,options);

    if(this.popover)
    {
     this.hide();
    }

    this.popover = newPopover;

    if(options.preShowCallback)
    {
      options.preShowCallback(options.preShowCallbackParams);
    }

    this.popover.show();

    if(options.postShowCallback)
    {
      options.postShowCallback(options.postShowCallbackParams);
    }
  }

  hide()
  {
    if(this.popover)
    {
      this.popover.hide();
      this.popover.disable();
    }
  }

  clear()
  {
    this.popoverMap.clear();
    this.popover = null;
    this.popoverStage =[];
    this.currentIndex = 1;
    this.currentStage = 1;

  }

  private finish()
  {
    let lastPopover = this.popoverStage[this.popoverStage.length -1];

    if(lastPopover && lastPopover.options.postShowCallback != null)
    {
      lastPopover.options.postShowCallback(lastPopover.options.postShowCallbackParams);
    }
  }

  private next()
  {

    if(this.currentIndex == this.popoverStage.length -1)
    {
      this.hide();
      this.finish();
      this.currentIndex = 0;
    }
    else
    {
      this.currentIndex = this.currentIndex < this.popoverStage.length - 1 ? this.currentIndex + 1 : 0;

      this.show();
    }

  }

  private previous()
  {
    this.currentIndex = this.currentIndex > 0 ? this.currentIndex - 1 : null;
    this.show();
  }

  private generatePopover(element: HTMLElement, options:PopperHelpOptions) : Popover
  {
    let template = `<div class="popover popover-help-set" role="tooltip">\
    <div class="popover-arrow"></div>\
    <div class="popover-header-block"><h3 class="popover-header"></h3><i id="${this.closeHeaderId}" class="far fa-window-close"></i></div><div class="popover-body"></div>\
    <div class="popover-footer">\
        <div id="${this.previousId}" class="button button--brand">Previous</div>\
        <div id="${this.countId}">${this.currentIndex + 1} of ${this.popoverStage.length}</div>\
        <div id="${this.nextId}" class="button button--brand">${this.currentIndex < this.popoverStage.length - 1 ? 'Next' : 'Finish'}</div>\
    </div>
  </div>`;
    let popover = new Popover(element,{
      placement: options.placement,
      animation: true,
      html: true,
      content: options.content,
      template:template,
      title: options.title?? 'title'});

    return popover;
  }
}
