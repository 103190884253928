import { Component, Input, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Layer, Vector } from 'ol/layer';
import VectorLayer from 'ol/layer/Vector';
import { Style } from 'ol/style';

@Component({
  selector: 'app-layer',
  templateUrl: './layer.component.html',
  styleUrls: ['./layer.component.css']
})
export class LayerComponent implements OnInit {

  @Input() layer: VectorLayer<any>;

  isBaseMap: Boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.isBaseMap = this.layer.get('type') == 'base' ? true : false;
  }

  getLayerVisibility(): boolean
  {
    return this.layer.getVisible();
  }

  setLayerVisibility(event: MatSlideToggleChange) : void
  {
    this.layer.setVisible(event.checked);
  }

  getLayerIcon()
  {
    if(this.layer.get('iconStyle'))
    {
      return this.layer.get('iconStyle');
    }
  }

  layerHasIcon()
  {
    return this.layer.get('iconStyle') == null ? false : true;
  }

}
