import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Select } from 'ol/interaction';
import { Fill, Stroke, Style } from 'ol/style';
import * as condition from 'ol/events/condition';
import { Feature, PluggableMap } from 'ol';
import { Layer } from 'ol/layer';
import { SelectEvent } from 'ol/interaction/Select';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import { Control } from 'ol/control';
import { SpatialToolsService } from 'src/app/services/spatial-tools.service';
import { SpatialTool } from 'src/app/models/spatial-tools-mdoel';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-select-property',
  templateUrl: './select-property.component.html',
  styleUrls: ['./select-property.component.css']
})
export class SelectPropertyComponent implements OnInit,OnDestroy, SpatialTool {

  @Input() map: PluggableMap;
  @Input() layers: Array<VectorLayer<any>|VectorImageLayer<any>>;
  @Input() multiSelectAttribute: string;
  @Input() helpPreShowFunction: Function;

  @Output() PropertySelectChanged = new EventEmitter<Array<Feature<any>>>();


  interaction: Select;
  active = false;
  selectedFeatureStyle: Style;
  control: Control;

  spatialToolSub$: Subscription = new Subscription();

  constructor(private spatialToolService: SpatialToolsService,private elRef:ElementRef) {

    this.spatialToolSub$ = this.spatialToolService.activeTool.subscribe(activeTool => {
      if(activeTool == null)
      return;

      if(activeTool != this)
      {
        this.deSelect();
      }
    });

   }

  ngOnInit(): void {

    this.selectedFeatureStyle = new Style({
      fill: new Fill({
        color: 'rgba(242,247,250,0.5)',
      }),
      stroke: new Stroke({
        color: 'rgb(255,16,16)',
        width: 2,
      }),
    });


    this.interaction = new Select({
      style: this.selectedFeatureStyle,
      multi: true,
      condition: condition.click,
      toggleCondition: condition.click,
      layers: this.layers
    });

    this.interaction.on('select', (event) => {this.featureSelected(event)});

  }


  ngOnDestroy(): void {
    this.deSelect();
    this.spatialToolSub$.unsubscribe();
  }

  toggleSelectProperty()
  {
    if(this.active)
    {
      this.deSelect();
      return;
    }
    this.layers[0].setVisible(true);
    this.map.addInteraction(this.interaction);
    this.active = true;
    this.spatialToolService.activeTool.next(this);

  }

  private featureSelected(event: SelectEvent){

    if(event.selected.length <= 0 && event.deselected.length <= 0)
    return;

    let selectedFeature = event.selected.length > 0 ? event.selected[0] : null;

    if(selectedFeature != null)
      {
        let properties = selectedFeature.getProperties();
        let featureId = selectedFeature.getId();

          let features = this.layers[0].getSource().getFeatures() as Feature<any>[] | undefined;
          features.forEach(feature => {
            let props = feature.getProperties();
            let _featureId = feature.getId();

            if(props[this.multiSelectAttribute] === properties[this.multiSelectAttribute] && (featureId !== _featureId))
            {
             this.interaction.getFeatures().push(feature);
            }

          });

      }

    if(event.deselected.length > 0)
      {
        this.interaction.getFeatures().clear();
      }

    let selectedFeatures = this.interaction.getFeatures().getArray();

    this.PropertySelectChanged.emit(selectedFeatures);
  }

   deSelect() {
    if (!this.active) return;


    this.layers[0].setVisible(false);
    this.map.removeInteraction(this.interaction);
      // Set the style of all selected features back to the default style
  this.interaction.getFeatures().forEach(feature => {
    feature.setStyle(null);
  });

  // Clear the list of selected features
  this.interaction.getFeatures().clear();
    this.active = false;
  }
}
