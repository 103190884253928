import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { AuthService } from 'src/app/services/auth.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';

@Component({
  selector: 'app-password-reset-dialog',
  templateUrl: './password-reset-dialog.component.html',
  styleUrls: ['./password-reset-dialog.component.css']
})
export class PasswordResetDialogComponent {

  resetPasswordForm: UntypedFormGroup;
  error: string = null;
  requestSent = false;

  constructor(private route: ActivatedRoute, private accountService: AccountService, private dialogRef: MatDialogRef<PasswordResetDialogComponent>) {

     this.resetPasswordForm = new UntypedFormGroup({
      'password': new UntypedFormControl(null, [CustomValidators.passwordHasUpper, CustomValidators.minLength(8), CustomValidators.passwordHasSpecial, CustomValidators.required]),
      'rptPassword': new UntypedFormControl(null,CustomValidators.required),
    },{validators: [CustomValidators.passwordsMatch]});

    this.resetPasswordForm.valueChanges.subscribe((changes) => {
      this.error = null;
    });

    this.dialogRef.disableClose = true;

  }

  onSubmit(): void {
    let password = this.resetPasswordForm.get('password').value as string;

    this.dialogRef.close(password);
  }


}
