/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PropertyService } from 'src/app/services/property.service';

@Component({
  selector: 'app-manage-property',
  templateUrl: './manage-property.component.html',
  styleUrls: ['./manage-property.component.css']
})
export class ManagePropertyComponent implements OnInit, OnDestroy{

  private propertySub: Subscription;


  constructor(propertyService:PropertyService) {
    this.propertySub = propertyService.properties.subscribe(properties => {

    })
   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
      this.propertySub.unsubscribe();
  }

}
