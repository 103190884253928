<table class="uk-table uk-table-hover uk-table-divider uk-table uk-table-justify">
    <tbody>
        <tr>
            <td>Planned fenching length (m)</td>
            <td>{{plannedFencing}}</td>
        </tr>

        <tr>
            <td>Existing fenching length (m)</td>
            <td>{{existingFencing}}</td>
        </tr>

        <tr>
            <td>Fencing maintenance cost ($/m)</td>
            <td>{{fencing}}</td>
        </tr>

        <tr>
            <td>Monetary Value</td>
            <td>{{calculateMonetaryValue() | currency}}</td>
        </tr>

        <tr>
            <td>Discount Rate</td>
            <td>{{discountRate}}</td>
        </tr>

        <tr>
            <td>Harvest Lifespan</td>
            <td>{{harvestLifespan}}</td>
        </tr>

        <tr>
            <td>PV(C)</td>
            <td>{{calculatePVC()| currency}}</td>
        </tr>

    </tbody>
</table>