<div class="container-fluid p-0">
  <div class="row">
    <div class="col">
      <div class="container-fluid p-0" [formGroup]="materialTreeProtectionForm">
        <!-- <div class="row">
              <h2 class="">Species and Planting Materials required for "Target Property/Paddock"</h2>
            </div> -->
        <h4>Introduction</h4>
        <div class="row">
          <p class="">
            Fencing is likely required to protect young trees from vermin and
            stock alike. This form provides an estimate of fencing materials
            likely required to protect your trees.
          </p>
        </div>

        <div class="row">
          <p class="">
            In addition to fencing, browsing control can also be an effective
            means of protecting trees. For more information on browing control,
            please refer to this link:
          </p>
        </div>

        <div class="row mt-3">
          <h5 class="">User inputs</h5>
          <div class="col-12 col-sm-12 col-md-6">
            <div class="mb-3">
              <label for="chooseTheTypeOfFenceYouRequired" class="form-label">Choose the type of fence you required
              </label>
              <select (change)="
                  changeToFencingTypeHandler($event); passValuesToParent()
                " class="form-select" aria-label="chooseTheTypeOfFenceYouRequired">
                <option selected value="">-- Select --</option>
                <option *ngFor="let fenceType of fencingType" value="{{ fenceType }}">
                  {{ fenceType }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-6">
            <div class="mb-3">
              <label for="lengthOfFenceRequired" class="form-label">Length of Fence Required (m)</label>
              <input appNumbersOnlyInputDirective type="number" class="form-control" (keyup)="
                  inputChangePopulateFormDataHandler(); passValuesToParent()
                " formControlName="lengthOfFenceRequired" id="Fencing Requirements" />
            </div>
          </div>
        </div>

        <div [@inOutAnimation] *ngIf="selectedFencingType" class="row mb-4">
          <div class="mt-3 col-12 col-sm-12 col-md-6">
            <label for="lengthOfFenceRequired" class="form-label">Timing</label>
            <p>
              {{ selectedFencingTypeDetails['Timing'] }}
            </p>
          </div>

          <div class="mt-3 col-12 col-sm-12 col-md-6">
            <label for="otherConsideration" class="form-label">Other consideration</label>
            <p>
              {{
              selectedFencingTypeDetails['Other Considerations']
              ? selectedFencingTypeDetails['Other Considerations']
              : 'N/A'
              }}
            </p>
          </div>

          <div class="mt-2 col-12 col-sm-12 col-md-6 col-lg-3 d-none">
            <label for="postSpacing" class="form-label">Post Spacing (metres per post)</label>
            <input appNumbersOnlyInputDirective type="number" class="form-control" formControlName="postSpacing"
              id="Post Spacing" />
          </div>

          <div class="mt-2 col-12 col-sm-12 col-md-6 col-lg-3 d-none">
            <label for="ratioStarPosts" class="form-label">Ratio Star Posts to Steel/Treated Pine</label>
            <input appNumbersOnlyInputDirective type="number" class="form-control" (keyup)="
                inputChangePopulateFormDataHandler(); passValuesToParent()
              " formControlName="ratioStarPostToSteel" id="Ratio Star Posts to Steel" />
          </div>

          <div class="mt-2 col-12 col-sm-12 col-md-6 col-lg-3 d-none">
            <label for="standardWireRows" class="form-label">Standard Wire Rows Required (n)</label>
            <input appNumbersOnlyInputDirective type="number" class="form-control" (keyup)="
                inputChangePopulateFormDataHandler(); passValuesToParent()
              " formControlName="standardWireRows" id="Standard Wire Rows" />
          </div>

          <div class="mt-2 col-12 col-sm-12 col-md-6 col-lg-3 d-none">
            <label for="barbWireRows" class="form-label">Barb Wire Rows Required (n)</label>
            <input appNumbersOnlyInputDirective type="number" class="form-control" (keyup)="passValuesToParent()"
              formControlName="barbWireRows" id="Barb Wire Rows" />
          </div>
        </div>

        <div [@inOutAnimation] class="col mt-3" *ngIf="selectedFencingType">
          <h5 class="mt-3">Summary</h5>
          <table class="table mb-2">
            <thead>
              <tr>
                <th scope="col">Post Type:</th>
                <th scope="col">Star</th>
                <th scope="col">Steel/Wood</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Number Posts Required:</td>
                <td>{{ summaryNumberOfRollsRequired['star'] | toPrecision }}</td>
                <td>
                  {{ summaryNumberOfRollsRequired['steelWood'] | toPrecision }}
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table mb-2">
            <thead>
              <tr>
                <th scope="col">Wire type</th>
                <th scope="col">Standard Wire</th>
                <th scope="col">Barb Wire</th>
                <th scope="col">Wallaby Proof Mesh</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Roll Length (m):</th>
                <td>
                  {{
                  selectedFencingTypeDetails[
                  'Standard Wire Roll length - metres'
                  ] | toPrecision
                  }}
                </td>
                <td>
                  {{
                  selectedFencingTypeDetails['Barb Wire Roll Length - metres']
                  | toPrecision
                  }}
                </td>
                <td>
                  {{
                  selectedFencingTypeDetails[
                  'Wallaby Proof Mesh Roll Length - metres'
                  ] | toPrecision
                  }}
                </td>
              </tr>
              <tr>
                <th scope="row">Rolls Required:</th>
                <td>
                  {{ summaryNumberOfRollsRequired['standarddWire'] | toPrecision }}
                </td>
                <td>{{ summaryNumberOfRollsRequired['barbWire'] | toPrecision }}</td>
                <td>
                  {{
                  summaryNumberOfRollsRequired['wallabyProofMesh'] | toPrecision
                  }}
                </td>
              </tr>
            </tbody>
          </table>

          <!-- <div class="col mt-3">
                        <button (click)="previewPDFHandler()" class="btn btn-primary">Preview PDF</button>
                    </div> -->
        </div>

        <div class="row"></div>
      </div>
    </div>
  </div>
</div>
