import { Component, OnInit, Input, SimpleChanges, AfterViewInit, OnChanges } from '@angular/core';
import { Form, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-property-specifications',
  templateUrl: './property-specifications.component.html',
  styleUrls: ['./property-specifications.component.css']
})
export class PropertySpecificationsComponent implements OnInit {

  @Input() purchasePricePerHectare: number;
  @Input() typeOfForestryOperation: string;

  @Input() formGroupName: string;
  @Input() parentForm: FormGroup;
  formGroup: FormGroup

  // typeOfForestryOperationValues: string[] = ["", "Plantation", "Native forest - Clearfelling", "Native forest - Thinning"]

  constructor(private rootFormGroup: FormGroupDirective) { 
  }
  
  ngOnInit(): void {
    this.formGroup = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  ngOnChanges(ch: SimpleChanges): void {
  }

}
