<progress class="uk-progress uk-progress-success custom-progress" [value]="progressMade" max="1410"></progress>
<div class="uk-column-1-5 top-offset">
    <div class="column uk-flex uk-flex-center progress-stage" *ngFor="let stage of navigationStages; let i = index">
        <!-- <span class="stage">{{stage}}</span> -->
        <!-- [ngClass]="i + 1 <= activeStage ? 'active-stage' : 'inactive-stage'" -->
        <button [disabled]="!stage.status" [ngClass]="[
            i + 1 <= activeStage ? 'active-stage' : 'inactive-stage',
            selectedStage === i && activeStage === i + 1? 'selected-stage' : 'not-selected-stage',
            stage.status === true || stage.status === 'true' ? 'valid' : 'invalid'
            ]" class="uk-button progress-stage-button" (click)="setStageHandler(i)">
            {{i + 1}}
        </button>
        <p>{{stage.title}}</p>
    </div>
</div>
