<div class="dashboard-alert">
  <span class="alert-message">ALERTS</span>
</div>

<div class="alerts-container">
  <article class="alert" *ngFor="let alert of bomAlerts">
    <span>{{alert.title}} <sup> <a target="_blank" href="{{ alert.link }}"><i class="fa fa-info-circle"></i></a></sup></span>
  </article>
</div>

