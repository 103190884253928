import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { InputsComponent } from '../components/npv-calculator/inputs/inputs.component';
import { CalculatorModalService } from '../services/calculator-modal-service.service';

@Injectable({
  providedIn: 'root'
})
export class IsCalculatorDataSavedGuard  {

  constructor(private cMoSer: CalculatorModalService) {}

  canDeactivate(
    component: InputsComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return component.isFormValid() ?
      this.cMoSer.clearLocalStorageAndReturnTrue() :
      this.cMoSer.validateExitRequest(nextState["url"])
  }

}
