import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import jsPDF, { TextOptionsLight } from 'jspdf';
import { IForestDescriptionReport } from 'src/app/interfaces/forestDescriptionReport.Interface';
import { addFooter, addHeader, addParagraph, addSubHeader, checkSectionOffPage, jsPDFCustom, } from 'src/app/interfaces/JsPDF.extended.interface';
import { ITopexReportData } from 'src/app/interfaces/topexReport.interface';
import { ForestClass } from 'src/app/models/Forest Description/forestClass.model';
import { PaddockDexie } from 'src/app/models/paddock.model';
import { PropertyDexie } from 'src/app/models/property.model';
import { mapService } from 'src/app/services/map.service';
import { TopexMapOverlayComponent } from '../topex-report/topex-map-overlay/topex-map-overlay.component';
import { TopexReportComponent } from '../topex-report/topex-report.component';
import { ForestDescriptionDisclaimerOverlayComponent } from './forest-description-disclaimer-overlay/forest-description-disclaimer-overlay.component';
import { ForestDescriptionMapOverlayComponent } from './forest-description-map-overlay/forest-description-map-overlay.component';
import { ForestDescriptionReportSections, getForestDescriptionOverViewSection } from './forest-description-report-pdf-data';



@Component({
  selector: 'app-forest-description-report',
  templateUrl: './forest-description-report.component.html',
  styleUrls: ['./forest-description-report.component.css']
})
export class ForestDescriptionReportComponent implements OnInit {


  displayedColumns: string[] = ['fc_Code', 'fc_desc', 'area'];

  dataSource;
  @Output() loadPaddock = new EventEmitter<PaddockDexie>();


  constructor(public dialogRef: MatDialogRef<TopexReportComponent>,@Inject(MAT_DIALOG_DATA) public reports:
  Array<IForestDescriptionReport>, private mapService:mapService, private dialog: MatDialog) { }

  ngOnInit(): void {

  }

  showDisclaimer()
  {
    const dialogRef = this.dialog.open(ForestDescriptionDisclaimerOverlayComponent,{hasBackdrop:true, width:'70%'});
  }

  viewOnMap(target: PropertyDexie | PaddockDexie)
  {

    let topexLayer = target instanceof PropertyDexie ? this.mapService.forestClassesLayerProperty : this.mapService.forestClassesLayerPaddock;

    if(target instanceof PaddockDexie)
    {
      this.mapService.loadSelectedPaddocks([target]);
    }

    topexLayer.setVisible(true);

    const dialogRef = this.dialog.open(ForestDescriptionMapOverlayComponent,{hasBackdrop:true,data:{map: this.mapService.map, layers: [...this.mapService.propertyLayerGroup.getLayersArray(),topexLayer]}, width:'40%'});

    dialogRef.afterClosed().subscribe((result) => {

     this.mapService.loadSelectedPaddocks([]);

     topexLayer.setVisible(false);
    })
  }

  export(report: IForestDescriptionReport)
  {
    const pdf = new jsPDF() as jsPDFCustom;
    const margin = 10;
    const headerHeight = 30;
    const pageWidth = pdf.internal.pageSize.width;
    const textWidth = pageWidth - 2 * margin;

    let title = 'Forest Description Report';



    addHeader(title, pdf, margin, headerHeight);
    addFooter(pdf, margin);

    const paragraphNewPageCallback = () => {
            pdf.addPage();
            addHeader(title, pdf, margin, headerHeight);
            addFooter(pdf, margin)
            return headerHeight + margin;
          };


    let y = headerHeight + margin;



    let overviewSection = getForestDescriptionOverViewSection(report.target,report.report);

    y += addSubHeader(overviewSection.title, pdf, margin, y);
    y += margin;

    overviewSection.paragraphs.forEach(paragraph => {
      y = addParagraph(pdf, paragraph, margin, y, textWidth, margin, paragraphNewPageCallback);
      y += 5; // Space between paragraphs
    });

    y+= margin



    let headerData = addSubHeader("Data", pdf, margin, y);

    let destructuredData = [];

    destructuredData = report.report.forestClasses.map((obj: ForestClass) => {
      return [obj.fc_Code ,
        obj.fc_Desc,
        obj.area.toFixed(1),
      ]
    });


/* Adding a table to the pdf. */

    pdf.autoTable({
      head: [['Forest Class Code', 'Forest Class Description', 'Area (ha)']],
      body: destructuredData,
      startY:y + headerData + margin,
    },)

    ForestDescriptionReportSections.forEach(section => {

      let offPage = checkSectionOffPage(pdf, margin,section, y);

      if(offPage)
      {
        pdf.addPage();
        addHeader(title, pdf, margin, headerHeight);
        addFooter(pdf, margin)
        y = headerHeight + margin;
      }


      y += addSubHeader(section.title, pdf, margin, y);
      y += margin;

    section.paragraphs.forEach(paragraph => {
      y = addParagraph(pdf, paragraph, margin, y, textWidth, margin, paragraphNewPageCallback);
      y += 5; // Space between paragraphs
    });
      y+= margin
  });


    pdf.save(`forest-description-report-${report.target.name.replace(" ", '-')}-${new Date().toUTCString()}.pdf`);
  }



}
