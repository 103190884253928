import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FenceDexie } from 'src/app/models/fence.model';
import { PaddockService } from 'src/app/services/paddock.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { DeleteFenceComponent } from './delete-fence/delete-fence.component';

@Component({
  selector: 'app-edit-fence',
  templateUrl: './edit-fence.component.html',
  styleUrls: ['./edit-fence.component.css']
})
export class EditFenceComponent implements OnInit {

  wallabyProof = [
    { name: 'Yes', value: true, isChecked: false },
    { name: 'No', value: false, isChecked: false },
    { name: 'Unsure', value: null, isChecked: true },
  ];

  fenceState = [
    { label: 'Existing', value: true },
    { label: 'Planned', value: false },
  ];

  editFenceForm: UntypedFormGroup;


  constructor(public dialogRef: MatDialogRef<EditFenceComponent>,@Inject(MAT_DIALOG_DATA) public data: {fence: FenceDexie},public dialog: MatDialog, public paddockService: PaddockService) { }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;

    this.editFenceForm = new UntypedFormGroup(
      {
      'name': new UntypedFormControl(null, CustomValidators.required),
      'paddocks': new UntypedFormControl(null),
      'existing': new UntypedFormControl(null,CustomValidators.required)
      },
    );

    this.editFenceForm.get("paddocks").setValue(this.data.fence.paddocks);
    this.editFenceForm.get("existing").setValue(this.data.fence.existing);
    this.editFenceForm.get("name").setValue(this.data.fence.name)

  }



  openDeleteConfirmationDialog(){
    let confirmationDialog = this.dialog.open(DeleteFenceComponent,{data:this.data.fence.name});

    confirmationDialog.afterClosed().subscribe(result => {
      if(result)
      {
        this.dialogRef.close({deleteFence: true});
      }
      else
      {
        return;
      }
    })
    }

    updateFenceAndClose(){

    Object.assign(this.data.fence, this.editFenceForm.value);

    this.dialogRef.close(this.data.fence);
    }

}
