<h2 mat-dialog-title>About</h2>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography">
    <p>The processes involved in running small to medium farming and forestry enterprises can be very complex. This is why Private Forests Tasmania and the Forest Practices Authority have invested in the development of a Farm and Forest Mapper mobile application.</p>
    <p>The ‘Farm and Forest Mapper’ delivers a handful of mapping and calculator tools to provide immediate farm-planning benefit for landowners, consultants, planners, contractors, and land agents. The aim is to improve the ease of planning, mapping, and managing properties to expand the plantation estate and allow greater integration of forestry into the agricultural landscape.</p>
    <p>The app is compatible with all web-enabled desktops and tablets (but currently won’t work well on phones).  Once you have created a property in the system, all functionality is made available for offline usage (i.e. outside phone or Wi-Fi range).</p>
    <p> <a target="_blank" href="https://www.eskspatial.com.au/">Esk Spatial</a> designed and built the Farm &amp; Forest Mapper</p>
    <br>
    <h2>Disclaimer</h2>
    <p>
        The Farm and Forest Mapper is subject to the terms and conditions as detailed in the conditions of use below. Both Private Forests Tasmania and the Forest Practices Authority exclude all liability to any person arising directly or indirectly from using this site and any information or material available from it.
    </p>

    <p>
        Information at this site is general information provided to help you plan farming and forest practices projects on your property(s) and decide if you need further professional advice relevant to your particular circumstances is not a substitute for independent professional advice is subject to the uncertainties of scientific and technical research may not be accurate, current or complete is subject to change without notice may include the views or recommendations of third parties, which do not necessarily reflect the views of Private Forests Tasmania or the Forest Practices Authority or indicate a commitment to a particular course of action
    </p>

    <h3>Layers in the map</h3>
    <ul>
        <li>
            are compiled from a range of sources of varying accuracy, reliability, completeness and correctness which are outside of the control of Private Forests Tasmania and the Forest Practices Authority. No representation or warranty is made by Private Forests Tasmania or the Forest Practices Authority for the accuracy, completeness, or relevance of such mapping layers to the user’s purpose.
        </li>
        <li>
            are produced from computer models. The models are based on the best data available to Private Forests Tasmania and the Forest Practices Authority at the time the models were developed.
        </li>
        <li>
            are only indicative. They should not be relied upon for navigation purposes and may not reflect the situation on-ground.
        </li>
    </ul>

    <h3>Links to External Web Sites</h3>
    <p>
        Private Forests Tasmania and the Forest Practices Authority have no direct control over the content of any linked sites, or the changes that may occur to the content on those sites. It is the responsibility of the user to make their own decisions about the accuracy, currency, reliability and correctness of information contained in linked external web sites.
    </p>

    <p>
        Links to external web sites do not constitute an endorsement or a recommendation of any material on those sites or of any third party products or services offered by, from or through those sites. Users of links provided by this web site are responsible for being aware of which organisation is hosting the web site they visit.
    </p>

    <h3>For further information please contact:</h3>
    <p>
        Private Forests Tasmania<br>
        PO Box 180, Kingsmeadows Tas 7249<br>
        Telephone: (03) 6777 2720<br>
        Email: <a href="mailto:admin@pft.tas.gov.au">admin&#64;pft.tas.gov.au</a>
    </p>


</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
    <button mat-dialog-close="true" class="button button--brand">Close</button>
</mat-dialog-actions>
