import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NpvCalculatorDataStorageService } from 'src/app/services/npv-calculator-data-storage.service';


@Component({
  selector: 'app-weeding-herbicide-costs',
  templateUrl: './weeding-herbicide-costs.component.html',
  styleUrls: ['./weeding-herbicide-costs.component.css']
})
export class WeedingHerbicideCostsComponent implements OnInit, OnChanges {

  @Input() totalActualVolumeLogsHarvestedPerYear: number;
  @Input() harvestedPecentage: number;
  @Input() weedingCosts: number;
  @Input() weedingHerbicideCosts: number;
  @Input() discountRate: number;
  @Input() harvestLifespan: number | string;
  @Output() calculationsValueEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private npvCalcService: NpvCalculatorDataStorageService) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {

    // Passing values to the parent component from 
    if (changes) {
      const calculationData = {
        "Weeding Herbicide Costs": {
          "Total Actual Volume of Logs Harvested (tonne)": this.totalActualVolumeLogsHarvestedPerYear,
          "Weeding Costs ($/tonne)": this.weedingCosts,
          "Monetary Value": this.calculateMonetaryValue(),
          "Discount Rate": this.discountRate,
          "Harvest Lifespan": this.harvestLifespan,
          "PV(C)": this.calculatePVC().toFixed(2),
        }
      }
      this.calculationsValueEmitter.emit(calculationData)
    }

  }

  calculateActualVolumnOfLogsHarvested() {
    return this.totalActualVolumeLogsHarvestedPerYear * (1 - this.harvestedPecentage);
  }

  calculateMonetaryValue() {
    // return this.calculateActualVolumnOfLogsHarvested() * this.weedingCosts
    return this.totalActualVolumeLogsHarvestedPerYear * this.weedingCosts
  }

  calculatePVC() {

    if (this.harvestLifespan === "Indefinite") {

      const cPVC = this.calculateMonetaryValue() / this.discountRate
      this.npvCalcService.updateCosts({ key: 'weeding-herbicide-costs', value: cPVC })
      return cPVC;

    }
    else {

      const cPVC = this.calculateMonetaryValue() * ((1 - (1 + (this.discountRate / 100)) ** -this.harvestLifespan) / (this.discountRate / 100))
      this.npvCalcService.updateCosts({ key: 'weeding-herbicide-costs', value: cPVC })
      return cPVC;

    }

  }

}
