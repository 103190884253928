<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header >
      <mat-panel-title>
        <mat-slide-toggle
        (click)="$event.stopPropagation();"
        class="example-margin"
        [checked]="getLayerGroupVisibility()"
        (change)="setLayerGroupVisibility($event)"
        >
    </mat-slide-toggle>
      </mat-panel-title>
      <mat-panel-description>
        {{layerGroup.get('title')}}
      </mat-panel-description>
    </mat-expansion-panel-header>

    <span>Opacity:{{getLayerGroupOpacityLabel()}}</span>
    <mat-slider
    thumbLabel
    [displayWith]="formatLabel"
    tickInterval="10"
    step="10"
    min="0"
    max="100"
    aria-label="units">
    <input matSliderThumb  (input)="setLayerGroupOpacity($event)" [value]="getLayerGroupOpacity()" #slider>
  </mat-slider>


    <article *ngIf="layers.length > 0" class="layer-group_layers">
      <app-layer *ngFor="let layer of layers" [layer]="layer"></app-layer>
    </article>

    <app-layer-group *ngFor="let layerGroup of layerGroups" [layerGroup]="layerGroup"></app-layer-group>

  </mat-expansion-panel>
</mat-accordion>

