<h2 mat-dialog-title>Topex Report</h2>
<mat-dialog-content class="mat-typography">

  <mat-tab-group>
    <mat-tab *ngFor="let report of topexReports; first as isFirst" >
      <ng-template mat-tab-label>
        <span class="header-spacer" *ngIf="isFirst"><i class="fa fa-house-damage"></i>{{report.target.name}}</span>
        <span class="header-spacer" *ngIf="!isFirst"><i class="fa fa-vector-square"></i>{{report.target.name}}</span>
    </ng-template>
      <section class="reporting-section" >
        <header class="reporting-header">
          <span>Reporting Area: {{report.target.area | round:1}}</span>
          <div class="reporting-header__actions">
            <button (click)="viewOnMap(report.target)" class="button button--blue">View on Map</button>
            <button (click)="export(report)" class="button button--blue">Export to PDF</button>
          </div>
        </header>
        <main>
          <table mat-table [dataSource]="report.data" class="mat-elevation-z8">

            <ng-container matColumnDef="topexScore">
              <th mat-header-cell *matHeaderCellDef> Topex Score </th>
              <td mat-cell *matCellDef="let element"> {{element.topexScore}} </td>
            </ng-container>


            <ng-container matColumnDef="exposureDescription">
              <th mat-header-cell *matHeaderCellDef> Exposure Description </th>
              <td mat-cell *matCellDef="let element"> {{element.exposureDescription}} </td>
            </ng-container>


            <ng-container matColumnDef="area">
              <th mat-header-cell *matHeaderCellDef> Area (ha) </th>
              <td mat-cell *matCellDef="let element"> {{element.area | round:1}} </td>
            </ng-container>

            <ng-container matColumnDef="percentage">
              <th mat-header-cell *matHeaderCellDef> % of Reporting Area </th>
              <td mat-cell *matCellDef="let element"> {{element.percentage | round:1}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

        </main>
      </section>
    </mat-tab>
  </mat-tab-group>




</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      close
    </button>
  </footer>
</mat-dialog-actions>
