import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, tap, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { FeedbackDTO } from "../components/shared/send-feedback-dialog/models/feedbackDTO";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  authApi: string;

  constructor(private http: HttpClient) {
    this.authApi = environment.apiURL + 'Account/';

   }

  reset(credentials): any {

    return this.http.post<any>( this.authApi + 'forgotPasswordReset', {
      token: credentials.token,
      password: credentials.password
    }, httpOptions)
    .pipe(
      catchError(this.handelError), tap(resData =>
        {

        })
    )
  }

  changePassword(password): any {
    return this.http.post<any>( this.authApi + 'changePassword', {
      password: password
    }, httpOptions)
    .pipe(
      catchError(this.handelError), tap(resData =>
        {

        })
    )
  }

  sendFeedback(feedback:FeedbackDTO): any {

    return this.http.post<any>( environment.apiURL + 'sendFeedback', feedback);
  }

  checkEmail(email): any {
    return this.http.post<any>( this.authApi + `checkEmail?email=${email}`,{},httpOptions);
  }

  confirm(token): any {

    return this.http.post<any>( this.authApi + `confrim?token=${token}`, {
    },httpOptions).pipe(
      catchError(this.handelError), tap(resData =>
        {

        })
    )
  }

  requestReset(email): any {

    return this.http.post<any>( this.authApi + `forgotPassword?email=${email}`, {
    },httpOptions).pipe(
      catchError(this.handelError), tap(resData =>
        {

        })
    )
  }

  handelError(errorRes)
 {
  let errorMessage = 'An unknown error occurred!'

  if(!errorRes.error||!errorRes.error.result)
  {
    return throwError(() => { return new Error(errorMessage)});
  }

  switch (errorRes.error.result) {
    case 'INVALID_TOKEN':
      errorMessage = "Invalid Reset Token";
      break;
    case 'EMAIL_EXISTS':
      errorMessage = "An Account Already Exists With That Email";
      break;

    default:
      break;
  }

  return throwError(() => { return new Error(errorMessage)});

 }

}
