<div class="uk-column uk-padding-small@m uk-padding-remove@m" [formGroup]="form">
    <div class="uk-column-1-1@m uk-flex-row medium-flex">
        <div class="uk-column uk-flex-column-bottom uk-margin-bottom">
            <label class="uk-flex-1"> Total potential Volume of Logs Available for Harvest over entire Harvest Lifespan
                (tonnes)</label> <input class="uk-input" formControlName="totalPotentialVolumeOfLogsAvailableForHarvest"
                type="number">
            <!-- <label class="uk-form-label">Volume reported is the total from the {{labelCalculationNumber}} harvest events that are assumed by this model to be possible within the nominated {{labelLifeSpanNumber}} year Harvest Lifespan</label> -->
            <label class="uk-form-label">Volume reported is the total from the <b>{{labelCalculationNumber | mathCeil}} {{typeOfForestry && typeOfForestry.includes("Clearfelling") ? 'clearfell/reforestation' : 'thinning '}}</b> events that are assumed by this model to be possible within the nominated <b>{{labelLifeSpanNumber}}</b> year Harvest Lifespan</label>
        </div>
    </div>

    <div class="uk-column-1-3@m uk-flex-row medium-flex">
        <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1">
                Sawlog Price ($/tonne)
                <input class="uk-input" (change)="setSawlogPrice($event)" formControlName="sawlogPrice" type="number">
            </label>
        </div>

        <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1">
                Peeler Log Price ($/tonne)
                <input class="uk-input" (change)="setPeelerLogPrice($event)" formControlName="peelerLogPrice"
                    type="number">
            </label>
        </div>

        <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1">
                Arisings Price ($/tonne)
                <input class="uk-input" (change)="setArisingsPrice($event)" formControlName="arisingsPrice"
                    type="number">
            </label>
        </div>



    </div>


    <div class="uk-column-1-3@m uk-flex-row medium-flex">
        <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1">
                % of Harvest that are Sawlogs
                <input class="uk-input" formControlName="harvestThatAreSawlogs" type="number">
            </label>
        </div>

        <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1">
                % of Harvest that are Peeler Logs
                <input class="uk-input" formControlName="harvestThatArePeelerLogs" type="number">
            </label>
        </div>


        <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
            <label class="uk-flex-1">
                % of Harvest that are Arisings
                <input class="uk-input" formControlName="harvestThatAreArisings" type="number">
            </label>
        </div>
    </div>

</div>
