import { Component, HostBinding, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export interface WelcomeDialogResult{
  hideOnStart:boolean,
  startHelp:boolean
}

@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.css']
})
export class WelcomeDialogComponent implements OnInit {
  @HostBinding('class') classes = 'dialog-container';

  welcomeDialogResult: WelcomeDialogResult = {
    hideOnStart: false,
    startHelp: true
  }

  constructor(public dialogRef: MatDialogRef<WelcomeDialogComponent>) { }

  ngOnInit(): void {

    this.dialogRef.disableClose = true;
  }

  closeDialog() {
    this.dialogRef.close(this.welcomeDialogResult);
  }

}
