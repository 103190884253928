import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSliderDragEvent } from '@angular/material/slider';
import VectorLayer from 'ol/layer/Vector';
import { switchMap, of, map } from 'rxjs';
import { PaddockDexie } from 'src/app/models/paddock.model';
import { ClippedLayerService } from 'src/app/services/BaseLayerServices/clippedLayer.service';
import { PropertyService } from 'src/app/services/property.service';
import { ForestClassesLegend } from 'src/app/_helpers/layer.styles';

@Component({
  selector: 'app-forest-class-layer',
  templateUrl: './forest-class-layer.component.html',
  styleUrls: ['./forest-class-layer.component.css']
})
export class ForestClassLayerComponent implements OnInit {

  @Input() layer: VectorLayer<any>;

  @Input() paddockSelect: boolean = false;

  @Input() paddocks: Array<PaddockDexie>;

  @Output() showPaddocks = new EventEmitter<Array<PaddockDexie>>();

  @Output() downloadLayers = new EventEmitter<boolean>();

  paddocksForm = new FormControl();
  colorIndex = [];

  $needsDownloading

  constructor(private clippedLayerService: ClippedLayerService, private propertyService: PropertyService) {

   this.$needsDownloading = this.propertyService.activeProperty.pipe(switchMap(property => {

    return property == null ? of([]) : this.clippedLayerService.getClippedLayers_Property(property.propertyID)} ),map((layers)=> {
    let test = layers.length == 0 ? true : layers.find(lyr => lyr.layerName.toLowerCase() == 'topex') == null ? true : false;
      return test
    }));

  }

  ngOnInit(): void {



    for (const [key, value] of Object.entries(ForestClassesLegend)) {

      let colorIndex = {
        name: key,
        color: `rgba(${value})`
      }

      this.colorIndex.push(colorIndex);
    }

  }

  onPaddockSelected(isOpen)
  {

    if(!isOpen)
    {
     this.showPaddocks.emit(this.paddocksForm.value);
    }
  }



  getLayerGroupOpacityLabel() : string
  {
    return (this.layer.getOpacity() * 100) + '%';
  }

  getLayerGroupOpacity(): Number {
    return (this.layer.getOpacity() * 100);
  }

  setLayerGroupOpacity(event: MatSliderDragEvent) : void
  {
    this.layer.setOpacity(event.value / 100);
  }

  getLayerGroupVisibility(): boolean
  {
    return this.layer.getVisible();
  }

  setLayerGroupVisibility(event: MatSlideToggleChange) : void
  {
    this.layer.setVisible(event.checked);
  }

  formatLabel(value: number) {
    return value + '%';
  }
}

