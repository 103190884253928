import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FenceDexie } from 'src/app/models/fence.model';
import { PaddockDexie } from 'src/app/models/paddock.model';
import { FenceService } from 'src/app/services/fence.service';
import { PaddockService } from 'src/app/services/paddock.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';

@Component({
  selector: 'app-create-fence',
  templateUrl: './create-fence.component.html',
  styleUrls: ['./create-fence.component.css']
})
export class CreateFenceComponent implements OnInit {

  wallabyProof = [
    { name: 'Yes', value: true, isChecked: false },
    { name: 'No', value: false, isChecked: false },
    { name: 'Unsure', value: null, isChecked: true },
  ];

  fenceState = [
    { label: 'Existing', value: true },
    { label: 'Planned', value: false },
  ];

  createFenceForm: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<CreateFenceComponent>,@Inject(MAT_DIALOG_DATA) public data: {fence: FenceDexie}, public paddockService: PaddockService) { }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;

  this.createFenceForm = new UntypedFormGroup(
    {
    'name': new UntypedFormControl(null, CustomValidators.required),
    'paddocks': new UntypedFormControl(null),
    'existing': new UntypedFormControl(null,CustomValidators.required)
    },
  );

  this.createFenceForm.get("paddocks").setValue(this.data.fence.paddocks)

  }

  closeAndCreateFence()
  {

    Object.assign(this.data.fence, this.createFenceForm.value);

    this.dialogRef.close(this.data.fence);

  }

  updatePaddockAssignedToFence(paddock: PaddockDexie) {
    let removed = false;

    this.data.fence.paddocks?.forEach((_paddock) => {
      if (_paddock == paddock.paddockID) {
        let idx = this.data.fence.paddocks.indexOf(_paddock);

        this.data.fence.paddocks?.splice(idx, 1);
        removed = true;
      }
    });

    if (!removed) {
      this.data.fence.paddocks.push(paddock.paddockID);
    }
  }

  isPaddockOnFence(paddock: PaddockDexie) {

    let paddockAssingedToFence = false;
    this.data.fence.paddocks?.forEach((_paddock) => {
      if (_paddock == paddock.paddockID) {
        paddockAssingedToFence = true;
        return;
      }
    });

    return paddockAssingedToFence;
  }


}
