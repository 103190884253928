import { Directive, ElementRef, forwardRef, Host, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appDecimalFormatting]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DecimalFormattingDirective),
    multi: true
  }]
})
export class DecimalFormattingDirective {

  @Input() initValue: number;
  
  private onTouched: () => void;
  private onChange: () => void;
  
  constructor(private el: ElementRef) { 
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: number): void {
    const formattedValue = this.formatDecimal(value);
    this.el.nativeElement.value = formattedValue;
    // this.onChange(value);
  }

  @HostListener('ready', ['$event.target.value'])
  onLoad(value: number): void {
    console.log("load...")
    const formattedValue = this.formatDecimal(value);
    this.el.nativeElement.value = formattedValue;
    // this.onChange(value);
  }

  
  @HostListener('blur')
  onBlur(): void {
    this.onTouched();
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.el.nativeElement.value = this.formatDecimal(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  formatDecimal(val: number): string {
    const roundedNumber  = Math.round(val * 10) / 10
    return roundedNumber.toFixed(1);
  }

}
