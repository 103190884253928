
  <mat-expansion-panel >
    <mat-expansion-panel-header (mousedown)="onMouseDown($event)" (mouseup)="onMouseUp($event)" (mousemove)="onMouseMove($event)">
      <mat-panel-title>
        Measure Settings
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="form">
        <mat-form-field appearance="fill">
            <mat-label>Measure Type</mat-label>
            <mat-select formControlName="measureType">
              <mat-option value="Polygon">Area</mat-option>
              <mat-option value="LineString">Length</mat-option>
            </mat-select>
          </mat-form-field>


        <div class="actions">
            <button class="button button--blue" (click)="submit()">Save</button>
        </div>

    </form>


  </mat-expansion-panel>
