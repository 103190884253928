import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NpvCalculatorDataStorageService } from 'src/app/services/npv-calculator-data-storage.service';


@Component({
  selector: 'app-forestry-policy-prescription-costs',
  templateUrl: './forestry-policy-prescription-costs.component.html',
  styleUrls: ['./forestry-policy-prescription-costs.component.css']
})
export class ForestryPolicyPrescriptionCostsComponent implements OnInit, OnChanges {

  @Input() mobilityPPC: number = 0;
  @Input() uncertainityPPC: number = 0;

  @Input() fixedCostPrescriptions: number;
  @Input() harvestedPecentage: number;
  @Input() totalActualVolumeLogsHarvestedPerYear: number;
  @Input() costOfFPP: number;
  @Input() legalFeesAccessCompensation: number = 0;
  @Input() socialContribution: number;
  @Input() socialMonetaryValue: string;
  @Input() discountRate: number;
  @Input() harvestLifespan: number | string;
  @Output() calculationsValueEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private npvCalcService: NpvCalculatorDataStorageService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    // Passing values to the parent component from 
    if (changes) {
      const calculationData = {
        "Forestry Policy Prescription Costs": {
          "Impact on Costs from FPP (% increase)": (this.mobilityPPC /100 + this.uncertainityPPC/100),
          "Fixed Cost of Prescriptions": this.fixedCostPrescriptions,
          "Total Actual Volume of Logs Harvested (tonne)": this.totalActualVolumeLogsHarvestedPerYear,
          "Cost of FPP ($/tonne)": this.costOfFPP,
          "Total FPP Costs (Excluding Compensation)": this.calculateTotalFPPCost(),
          "Monetary Value": this.calculateMonetaryValue(),
          "Discount Rate": this.discountRate,
          "Harvest Lifespan": this.harvestLifespan,
          "PV(C)": this.calculatePVC().toFixed(2),
        }
      }
      this.calculationsValueEmitter.emit(calculationData)
    }

  }

  calculateActualVolumnOfLogsHarvested() {
    return this.totalActualVolumeLogsHarvestedPerYear * (1 - this.harvestedPecentage);
  }

  calculateTotalFPPCost() {
    return this.fixedCostPrescriptions + (this.calculateActualVolumnOfLogsHarvested() * this.costOfFPP)
  }

  /* calculateMonetaryValue() {
    if (this.socialMonetaryValue === "Yes") {
      return (this.socialContribution / 100) * this.calculateTotalFPPCost()
    }else {
      return this.calculateTotalFPPCost()
    }
  } */

  calculateMonetaryValue() {

    return this.calculateTotalFPPCost()

  }

  calculatePVC() {

    if (this.harvestLifespan === "Indefinite") {

      const cPVC = (this.calculateMonetaryValue()) / (this.discountRate / 100)
      this.npvCalcService.updateCosts({ key: 'foregone-policy-prescription-costs', value: cPVC })
      return cPVC;

    }

    else {

      const cPVC = this.calculateMonetaryValue() * ((1 - (1 + (this.discountRate / 100)) ** -this.harvestLifespan) / (this.discountRate / 100))
      this.npvCalcService.updateCosts({ key: 'foregone-policy-prescription-costs', value: cPVC })
      return cPVC;

    }

  }
}
