<table class="uk-table uk-table-hover uk-table-divider uk-table uk-table-justify">
    <tbody>
        <tr>
            <td>Forest Size (ha)</td>
            <td>{{forestSize| toPrecision}}</td>
        </tr>

        <tr>
            <td>Land Purchase Price ($/ha)</td>
            <td>{{purchasePrisePerHectare}}</td>
        </tr>

        <tr>
            <td>Monetary Value</td>
            <td>{{returnMonetoryValue()| currency}}</td>
        </tr>

        <tr>
            <td>PV(C)</td>
            <td>{{returnMonetoryValue()| currency}}</td>
        </tr>

    </tbody>
</table>