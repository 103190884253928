<h2 mat-dialog-title>Edit Paddock</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="editPaddockForm">
    <div class="grid">

      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input formControlName="name" matInput placeholder="">
      </mat-form-field>

      <mat-form-field disabled appearance="outline">
        <mat-label>Total Area (ha)</mat-label>
        <input type="number" readonly="true" matInput [value]=" paddock.area" >
        </mat-form-field>

      <!-- <mat-form-field *ngIf="paddock" appearance="outline">
        <mat-label>Fences</mat-label>
        <mat-chip-list aria-label="fences">

          <mat-chip title="{{fence.name}}" *ngFor="let fence of paddock.fences">
            {{fence.name}}
          </mat-chip>
        </mat-chip-list>
        </mat-form-field> -->

      <mat-form-field appearance="outline">
        <mat-label>Landuse</mat-label>
        <mat-select formControlName="landuseType">
          <option [value]="null" disabled>Choose Landuse</option>
          <mat-option *ngFor="let landuseType of landuseTypes"  [value]="landuseType">
            {{ landuseType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Enterprise</mat-label>
        <mat-select formControlName="cropType">

          <option [value]="null" disabled>Choose Enterprise</option>
          <mat-option *ngFor="let crop of cropTypes"  [value]="crop">
            {{ crop.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Notes</mat-label>
        <textarea formControlName="notes" cols="30"
        rows="10" matInput></textarea>
      </mat-form-field>

    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black" [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      type="button"
      class="button button--danger"
      (click)="openDeleteConfirmationDialog()"
    >
      Delete
    </button>
    <button
      type="button"
      class="button button--blue"
      (click)="updatePaddockAndClose()"
      [disabled]="!editPaddockForm.valid"
    >
      Update
    </button>
  </footer>
</mat-dialog-actions>
