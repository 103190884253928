import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatProgressBar } from '@angular/material/progress-bar';
import{tap} from 'rxjs/operators';

@Component({
  selector: 'app-password-complexity',
  templateUrl: './password-complexity.component.html',
  styleUrls: ['./password-complexity.component.css']
})
export class PasswordComplexityComponent implements AfterViewInit {

@Input() passwordControl: UntypedFormControl;
complexityProgress = 0;

passwordRequirements = {
  'noUpper': {name:'One or more uppercase characters.', met:false},
  'NoSpecial': {name:'One or more uppercase characters.', met:false},
  'minlength': {name:'One or more uppercase characters.', met:false},
}


colorMap = new Map<number,string>().set(100,'#6fc363').set(50,'#fcdc02').set(25,'red').set(75,'#2c9fd4');

@ViewChild(MatProgressBar) progressBar: MatProgressBar;


constructor() {

}

ngAfterViewInit(): void {
  this.passwordControl?.valueChanges.subscribe(value => {

    let errorCount = this.passwordControl.errors ?  Object.keys(this.passwordControl.errors).length : 0;

    this.complexityProgress = errorCount == 3 ? 25 : errorCount == 2 ? 50 : errorCount == 1 ? 75 : 100;


    this.progressBar._elementRef.nativeElement.style.setProperty('--mat-progress-bar-color', this.colorMap.get(this.complexityProgress));

  });
}


getPropertyValue()
{
  console.log();
}
}
