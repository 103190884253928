<footer>
  <div class="footer--pfa">
    <a href="https://www.treealliance.com.au/"><img class="footer--logo" src="assets/images/PFT-Logo-Light.png" alt=""></a></div>
  <div class="footer--esk"><a href="https://www.eskmapping.com.au/"><img class="footer--logo" src="assets/images/esk-logo-large-transparent-trimmed-white.png" alt=""></a></div>
  <div class="footer--fpa"><a href="https://www.fpa.tas.gov.au/"><img class="footer--logo" src="assets/images/fpa_white_logo_thick.png" alt=""></a></div>

  <div class="footer--links">
    <ul>
      <li><a href="#">TFGA</a></li>
      <li><a href="#">BOM</a></li>
      <li><a href="#">List Map</a></li>
    </ul>
  </div>
</footer>
