import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-cost-impact-forestry-policy-prescription',
  templateUrl: './cost-impact-forestry-policy-prescription.component.html',
  styleUrls: ['./cost-impact-forestry-policy-prescription.component.css']
})
export class CostImpactForestryPolicyPrescriptionComponent implements OnInit {

  @Input() mobileImpact: number;
  @Input() statusImpact: number;
  @Input() uncertainityImpact: number;

  @Input() formGroupName: string;
  form: FormGroup;

  constructor(private rootFromGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.form = this.rootFromGroup.control.get(this.formGroupName) as FormGroup
  }

  ngOnChange(C: SimpleChange) {
    console.log("change", C)
  }


}
