
import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from 'src/app/services/account.service';
import { ToastService } from 'src/app/services/toast.service';
import { PasswordResetDialogComponent } from '../../../shared/components/password-reset-dialog/password-reset-dialog.component';
import { FeedbackDTO } from '../../shared/send-feedback-dialog/models/feedbackDTO';
import { SendFeedbackDialogComponent } from '../../shared/send-feedback-dialog/send-feedback-dialog.component';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {

  closeResult = '';
  newPasswordForm: UntypedFormGroup;
  submitted: boolean = false;
private readonly toast: ToastService = inject(ToastService);

  constructor( private account:AccountService, private dialog:MatDialog) { }

  ngOnInit(): void {


  }

  getFeedback()
  {
    this.dialog.open(SendFeedbackDialogComponent,{width:'50%'}).afterClosed().subscribe(result => {
      if(!(result instanceof FeedbackDTO))
      {
        return;
      }

      this.account.sendFeedback(result).subscribe();
    });
  }

  open() {
    const dialogRef =  this.dialog.open(PasswordResetDialogComponent).afterClosed().subscribe((result) => {
      if(result)
      {
        this.account.changePassword(result).subscribe(
          {
            next: (data) => {
              this.toast.showSuccess("Password changed successfully");
            },
            error: (error) => {
              this.toast.showError("An error occurred while changing password");
            }
          }
        );
      }
    });
  }

  close()
  {

  }


}
