<div class="uk-section uk-section-primary uk-padding-small sticky-element-bottom uk-animation-slide-bottom"
    id="app-total-section">
    <div id="app-total-section-inner" class="uk-column uk-padding-remove uk-flex-middle uk-flex uk-flex-between">
        <h3 class="uk-margin-remove total-breakdown">Breakdown</h3>
        <div class="value-column">
            <div class="benefits-container">
                <a href="/calculator#benefits">
                    <p class="uk-margin-remove">Benefits</p>
                </a>

                <h3 class="uk-margin-remove">{{ returnBenefits()|
                    currency }}</h3>

            </div>
            <!-- <div class="minus" *ngIf="costsTotal && benefitsTotal">
                <h3> - </h3>
            </div> -->
            <div class="costs-container">
                <a href="/calculator#costs">
                    <p class="uk-margin-remove">Costs</p>
                </a>
                <h3 class="uk-margin-remove">{{ returnCosts() | currency }}</h3>
            </div>
            <!-- <div class="total" *ngIf="totalValue && costsTotal">
                <h3> = </h3>
            </div> -->
            <div class="total-container">
                <p class="uk-margin-remove">Total</p>
                <h3 class="uk-margin-remove">{{ returnTotalValue() | currency }}</h3>
            </div>
        </div>
    </div>
</div>