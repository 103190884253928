<h2 mat-dialog-title>Edit Fence</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="editFenceForm" >
    <div class="grid grid--auto-row gap-xs">


      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input formControlName="name" matInput placeholder="">
      </mat-form-field>

      <mat-form-field disabled appearance="outline">
        <mat-label>Length (m)</mat-label>
        <input type="number" readonly="true" matInput [value]="data.fence.length" >
        </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Assigned paddocks</mat-label>
        <mat-select formControlName="paddocks" multiple>
          <mat-option *ngFor="let paddock of paddockService.paddocks | async" [value]="paddock.paddockID">{{
            paddock.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>


          <mat-label>Fence Status *</mat-label>
          <mat-radio-group formControlName="existing" fxLayout="column">

            <mat-radio-button class="radio-button-padding" *ngFor="let option of fenceState"
            [value]="option.value" >{{option.label}}</mat-radio-button>
          </mat-radio-group>

    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black" [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      type="button"
      class="button button--danger"
      (click)="openDeleteConfirmationDialog()"
    >
      Delete
    </button>
    <button
      type="button"
      class="button button--blue"
      (click)="updateFenceAndClose()"
      [disabled]="!editFenceForm.valid"
    >
      Update
    </button>
  </footer>
</mat-dialog-actions>
