import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Subscription,switchMap } from 'rxjs';
import { Repository } from 'typeorm';
import { EventData } from '../_helpers/event.class';
import { TreeMapperCustomEvents } from '../_helpers/enums';
import { AuthService } from './auth.service';
import { EventBusService } from './EventBus.service';
import { PropertyService } from './property.service';
import { PropertyDexie } from '../models/property.model';
import { FenceDexie } from '../models/fence.model';
import { DexieDatabaseService } from './dexieIndexDB.service';
import { liveQuery } from 'dexie';
import { HttpClient } from '@angular/common/http';
import { Enterprise } from '../models/enterprise.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseService {


  enterprises = liveQuery(() => this.dexieService.enterprises.toArray());


  constructor(private auth: AuthService, private dexieService:DexieDatabaseService, private eventBusService: EventBusService, private propertyService: PropertyService, private http: HttpClient) {



      this.http.get<Array<Enterprise>>(environment.apiURL + 'Enterprises/getAll').pipe( switchMap(data =>  {
        return from(dexieService.enterprises.bulkPut(data))})).subscribe();
   }

   async getAll()
   {

   }

   async save(fence: FenceDexie)
   {
    await this.dexieService.fences.add(fence);

    this.AddToDatabaseTest(fence).subscribe();
   }

   async remove(fence: FenceDexie)
   {
     await this.dexieService.fences.delete(fence.fenceID);

   }

   async update(fence: FenceDexie)
   {
    await this.dexieService.fences.update(fence.fenceID, fence);

   }

   AddToDatabaseTest(fence: FenceDexie)
   {
    return this.http.post("https://localhost:7292/api/" + 'Fence/add', fence).pipe(
    );
   }

}
