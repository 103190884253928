import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TreeRotationService {
  tonnerPerHectar_Range = {
    "Native forest - Clearfelling": null,
    "Native forest - Thinning": null,
    "Plantation - Clearfelling": null,
    "Plantation - Thinning": null
  }

  tonnerPerHectar_YearCost = {
    "Native forest - Clearfelling": 300,
    "Native forest - Thinning": 125,
    "Plantation - Clearfelling": 300,
    "Plantation - Thinning": 125
  }

  breakDuration = {
    "Native forest - Clearfelling": 20,
    "Native forest - Thinning": 10,
    "Plantation - Clearfelling": 20,
    "Plantation - Thinning": 10
  }

  breakRepitition = {
    "Native forest - Clearfelling": 2,
    "Native forest - Thinning": 4,
    "Plantation - Clearfelling": 2,
    "Plantation - Thinning": 4
  }

  maxDuration = 100 // needs to be replaced by  harvest lifespan

  constructor() {
    for (const key in this.tonnerPerHectar_Range) {
      if (Object.hasOwnProperty.call(this.tonnerPerHectar_Range, key)) {
        const elKey = key;
        this.tonnerPerHectar_Range[key] = this.createRangeHandler(elKey);
      }
    }
  }

  createRangeHandler = (key) => {

    let valueRange = []

    let value = this.tonnerPerHectar_YearCost[key];
    let repetition = this.breakRepitition[key];
    let duration = this.breakDuration[key];

    // console.log(key, repetition, duration, value)

    for (let index = 1; index <= this.maxDuration; index++) {

      if (repetition > 0 && index === 1) {
        valueRange.push(value);
        --repetition;

      }

      else if (repetition > 0 && (index - 1) % duration === 0) {
        valueRange.push(value);
        --repetition;
      }

      else {
        valueRange.push(0)
      }
    }
    return valueRange;
  }


  setRepitition(maxHarvestVal: number) {

    this.tonnerPerHectar_Range = {
      "Native forest - Clearfelling": null,
      "Native forest - Thinning": null,
      "Plantation - Clearfelling": null,
      "Plantation - Thinning": null
    }

    this.maxDuration = maxHarvestVal;

    for (const key in this.breakRepitition) {
      this.breakRepitition[key] = maxHarvestVal / this.breakDuration[key]
    }

    for (const key in this.tonnerPerHectar_Range) {

      if (Object.hasOwnProperty.call(this.tonnerPerHectar_Range, key)) {
        const elKey = key;
        this.tonnerPerHectar_Range[key] = this.createRangeHandler(elKey);
      }

    }
  }

}
