<!-- <div class="uk-column-1-2@m uk-column uk-margin-bottom uk-padding-remove" [formGroup]="formGroup">
    <div class="uk-column"> -->

<div class="uk-column-1-2@m uk-margin-bottom" [formGroup]=" formGroup">
    <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
        <label class="uk-flex-1">
            Discount Rate %
            <!-- <input class="uk-input disabled" name="Discount Rate" [value]="discountRate + .85" type="number" disabled placeholder=""> -->
            <input class="uk-input disabled" formControlName="discountRate" type="number" placeholder="">
        </label>
    </div>

    <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
        <label class="uk-flex-1">
            Harvest Lifespan (Years)
            <input class="uk-input disabled" formControlName="harvestLifespan" (keyup)="minValuChangeHandler($event)" [min]="1" type="number" placeholder="">
        </label>
    </div>
</div>
