import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Feature, PluggableMap } from 'ol';
import { Control } from 'ol/control';
import { Draw, Interaction, Snap } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Subscription } from 'rxjs';
import { SpatialTool } from 'src/app/models/spatial-tools-mdoel';
import { PropertyService } from 'src/app/services/property.service';
import { SpatialToolsService } from 'src/app/services/spatial-tools.service';
import { DrawType } from 'src/app/_helpers/enums';

import * as conditions  from 'ol/events/condition'

@Component({
  selector: 'app-digitize-paddock',
  templateUrl: './digitize-paddock.component.html',
  styleUrls: ['./digitize-paddock.component.css']
})
export class DigitizePaddockComponent implements OnInit,AfterViewInit, OnDestroy,SpatialTool {

  @Input() map: PluggableMap;
  @Input() snapLayers: Array<VectorLayer<any>>;

  @Output() paddockDigitized = new EventEmitter<Feature<any>>();
    @Output() active = new EventEmitter<boolean>(false);


  disabled: boolean = false;
  isActive: boolean = false;
  interaction: Draw;
  snapInteractions: Array<Snap> = [];
  vectorSource: VectorSource<any>;
  control: Control;


  propertySub$: Subscription = new Subscription();
  spatialToolSub$: Subscription = new Subscription();

  constructor(private propertyService: PropertyService,private spatialToolService: SpatialToolsService, private elRef:ElementRef) { }

  ngOnInit(): void {
    this.propertySub$ = this.propertyService.activeProperty.subscribe(property => {
      this.disabled = property == null ? true : false;
    });

    this.spatialToolSub$ = this.spatialToolService.activeTool.subscribe(activeTool => {
      if(activeTool == null)
      return;

      if(activeTool != this)
      {
        this.deSelect();
      }
    });


    this.vectorSource = new VectorSource();
  }

  ngAfterViewInit(): void {
    this.control = new Control({target: this.elRef.nativeElement.parentNode, element: this.elRef.nativeElement});
    this.map.addControl(this.control);
  }

  ngOnDestroy(): void {
    if(this.control == null)
    return;

    this.propertySub$.unsubscribe();
    this.spatialToolSub$.unsubscribe();

    this.map.removeControl(this.control);
    this.deSelect();
  }


  toggleDigitizePaddock(){

    if (this.isActive)
    {
      this.deSelect();
    }
    else
    {
      this.draw();
      this.spatialToolService.activeTool.next(this);
      this.active.emit(this.isActive);
    }


  }


  private draw() {

    this.interaction = new Draw({
      source: this.vectorSource,
      type: DrawType.Polygon,
      snapTolerance: 5,
      clickTolerance:0
    });

      this.snapLayers.forEach(lyr => {
        let snap = new Snap(
          {
            source: lyr.getSource()
          }
        )

        this.snapInteractions.push(
          snap
          )
      });




    // this.snapInteractions.push(new Snap(
    //   {
    //     source: this.vectorSource
    //   }
    // ));



    this.map.addInteraction(this.interaction);

    this.snapInteractions.forEach(snap => {
      this.map.addInteraction(snap);
    })

    this.isActive = true;

    this.interaction.on('drawend', (event) => {
      var feature = event.feature as Feature<any>;

      this.paddockDigitized.emit(feature);
    });

  }

  deSelect() {
    if (!this.isActive) return;

    this.map.removeInteraction(this.interaction);
    this.snapInteractions.forEach(snap => {
      this.map.removeInteraction(snap);
    });

    this.isActive = false;
    this.active.emit(this.isActive)
  }

}
