import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, OnChanges, SimpleChanges } from '@angular/core';
import { NpvCalculatorDataStorageService } from 'src/app/services/npv-calculator-data-storage.service';


@Component({
  selector: 'app-ploughing',
  templateUrl: './ploughing.component.html',
  styleUrls: ['./ploughing.component.css']
})
export class PloughingComponent implements OnInit, OnChanges {

  @Input() forestSize: number;
  @Input() ploughing: number;
  @Input() discountRate: number;
  @Input() harvestLifespan: number | string;
  updatedMonetaryValue: number;
  @Output() calculationsValueEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private npvCalcService: NpvCalculatorDataStorageService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    // Passing values to the parent component from 
    if (changes) {
      const calculationData = {
        "Ploughing Cost": {
          "Forest Size (ha)": this.forestSize,
          "Ploughing Cost ($/ha)": this.ploughing,
          "Monetary Value": this.calculateMonetaryValue(),
          "Discount Rate": this.discountRate,
          "Harvest Lifespan": this.harvestLifespan,
          "PV(C)": this.calculatePVC().toFixed(2),
        }
      }
      this.calculationsValueEmitter.emit(calculationData)
    }

  }

  calculateMonetaryValue() {
    this.updatedMonetaryValue = this.forestSize * this.ploughing;
    return this.forestSize * this.ploughing;
  }


  calculatePVC() {
    if (this.harvestLifespan === "Indefinite") {

      const cPVC = this.updatedMonetaryValue / this.discountRate
      this.npvCalcService.updateCosts({ key: 'ploughing-costs', value: cPVC })
      return cPVC;

    } else {

      const cPVC = this.updatedMonetaryValue * ((1 - (1 + (this.discountRate / 100)) ** -this.harvestLifespan) / (this.discountRate / 100))
      this.npvCalcService.updateCosts({ key: 'ploughing-costs', value: cPVC })
      return cPVC;
    }
  }

}
