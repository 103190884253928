<div class="container-fluid p-0 ng-untouched ng-pristine ng-valid" ng-reflect-form="[object Object]">
    <h4>Introduction</h4>
    <div class="row">
        <p>The Enterprise Suitability ratings presented by the Crop Decision Tool can be used as a rough guide as to the
            likely growth rates your trees will achieve if planted on these sites. The tool below can be used to assign
            likely growth rates to the area under each rating, from which estimated volumes at harvest age can be
            derived. The Estimated Mean Annual Increment figures provided as defaults are indicative only, and you
            should seek advice from a professional as to the likely growth rates achievable on your site.</p>
    </div>
    <div class="row">
        <p>Note that pine species are generally thinned in Tasmania, either once or twice, to achieve a higher quality
            product at final harvest. </p>
    </div>

    <div class="row mt-3">
        <div class="col">
            <label for="speciesSelectionDropdown">Choose Species</label>
            <select #selectedCrop (change)="setIndicativeYield($event); passValuesToParent()" [formControl]="enterpriseSelect" class="form-select mt-1"
                id="speciesSelectionDropdown" aria-label="speciesSelectionDropdown">
                <option *ngFor="let row of $enterpriseReport | async" [ngValue]="row">{{row.crop.label}}</option>
            </select>
        </div>
    </div>


    <div [@inOutAnimation] *ngIf="selectedCropSpecies" class="row mt-3">

        <div [@inOutAnimation] class="row mt-3" [formGroup]="indicativeYieldForm">
            <div class="col-12">
                <h4 class="m-0 mb-1">Enterprise Suitability Rating</h4>
            </div>

            <div class="col-12 mt-3 mb-1">
                <h4 class="m-0">Well suited</h4>
            </div>
            <div class="col-12 col-sm-12 col-md-6">
                <label for="speciesSelectionDropdown">Mapped area (ha)</label>
                <input [appDecimalFormatting] class="form-control" disabled type="number" formControlName="mappedWellSuited">
            </div>
            <div class="col-12 col-sm-12 col-md-6 mt-2">
                <label for="speciesSelectionDropdown">Estimated Mean Annual Increment (t/ha/yr)</label>
                <input (keyup)="formValueUpdateHandler(); passValuesToParent()" class="form-control" type="number" name="" formControlName="estimatedWellSuited" id="">
            </div>


            <div class="col-12 mt-3 mb-1">
                <h4 class="m-0">Suitable</h4>
            </div>
            <div class="col-12 col-sm-12 col-md-6">
                <label for="speciesSelectionDropdown">Mapped area (ha)</label>
                <input [appDecimalFormatting] class="form-control" disabled type="number" formControlName="mappedSuitable">
            </div>
            <div class="col-12 col-sm-12 col-md-6">
                <label for="speciesSelectionDropdown">Estimated Mean Annual Increment (t/ha/yr)</label>
                <input (keyup)="formValueUpdateHandler(); passValuesToParent()" class="form-control" type="number" formControlName="estimatedSuitable">
            </div>


            <div class="col-12 mt-3 mb-1">
                <h4 class="m-0">Moderately Suitable</h4>
            </div>
            <div class="col-12 col-sm-12 col-md-6">
                <label for="speciesSelectionDropdown">Mapped area (ha)</label>
                <input [appDecimalFormatting] class="form-control" disabled type="number" formControlName="mappedModeratelySuitable">
            </div>
            <div class="col-12 col-sm-12 col-md-6">
                <label for="speciesSelectionDropdown">Estimated Mean Annual Increment (t/ha/yr)</label>
                <input (keyup)="formValueUpdateHandler(); passValuesToParent()" class="form-control" type="number" formControlName="estimatedModeratelySuitable">
            </div>


            <div class="col-12 mt-3 mb-1">
                <h4 class="m-0">UnSuitable</h4>
            </div>
            <div class="col-12 col-sm-12 col-md-6">
                <label for="speciesSelectionDropdown">Mapped area (ha)</label>
                <input [appDecimalFormatting] class="form-control" disabled type="number" formControlName="mappedUnsuitable">
            </div>
            <div class="col-12 col-sm-12 col-md-6">
                <label for="speciesSelectionDropdown">Estimated Mean Annual Increment (t/ha/yr)</label>
                <input (keyup)="formValueUpdateHandler(); passValuesToParent()" class="form-control" type="number" formControlName="estimatedUnsuitable">
            </div>

        </div>

        <div class="col-12 mt-5 mb-3">
            <div class="row">
                <div class="col">
                    <label for="speciesSelectionDropdown" class="pe-1">Total Area:</label>
                    <span>{{totalArea | toPrecision}}</span>
                </div>
            </div>
        </div>

        <div class="col-12 mt-3">
            <table [formGroup]="finalHarvestAgeForm" class="table align-middle">
                <thead>
                    <tr>
                        <th scope="col">Average MAI
                        </th>
                        <th scope="col">Final Harvest Age
                        </th>
                        <th scope="col">Total Harvestable Volume*
                        </th>
                        <th scope="col">Total Harvestable Volume Per Hectare*
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{{ averageMAI | toPrecision}}</th>
                        <td><input formControlName="finalHarvestAge" (change)="calculateHarvestableVolumeHandler()" class="form-control border border-0 w-50"
                                type="number">
                        </td>
                        <td>{{ harvestableVolume | toPrecision}}</td>
                        <td>{{ harvestableVolumePerHectare | toPrecision}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>

</div>
