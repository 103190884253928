<!-- <div class="uk-container uk-padding-remove uk-margin-bottom">
    <div class="uk-flex uk-flex-column uk-flex-between">
        <p class="uk-width-1-1 uk-flex uk-flex-between uk-column uk-margin-remove uk-padding-remove">
            Forest Perimeter
            <span>{{calculateForestPerimeter()| currency}}</span>

        </p>
        <p class="uk-width-1-1 uk-flex uk-flex-between uk-column uk-margin-remove uk-padding-remove">
            Fencing (m)
            <span>{{fencing}}</span>

        </p>
        <p class="uk-width-1-1 uk-flex uk-flex-between uk-column uk-margin-remove uk-padding-remove">
            Monetary Value
            <span>{{calculateMonetaryValue()| currency}}</span>

        </p>
        <p class="uk-width-1-1 uk-flex uk-flex-between uk-column uk-margin-remove uk-padding-remove">
            PV(C)
            <span>{{calculateMonetaryValue()| currency}}</span>

        </p>
    </div>
</div> -->

<table class="uk-table uk-table-hover uk-table-divider uk-table uk-table-justify">
    <tbody>
        <tr>
            <td>Fencing Length (m)</td>
            <td>{{fencingLength}}</td>
        </tr>
        <tr>
            <td>Fencing ($/m)</td>
            <td>{{fencing}}</td>
        </tr>
        <tr>
            <td>Monetary Value</td>
            <td>{{calculateMonetaryValue()| currency}}</td>
        </tr>
        <tr>
            <td>PV(C)</td>
            <td>{{calculateMonetaryValue()| currency}}</td>
        </tr>
    </tbody>
</table>