import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

interface IObjectKeys {
  [key: string]: string | number | IObjectKeys;
}

@Injectable({
  providedIn: 'root'
})

export class QuestionGenerationService {

  primaryUse: IObjectKeys = {
    "Environmental": {
      0: "Biodiversity",
      1: "Soil conservation",
    },
    "Shelterbelt": {
      0: "Shelter for grazing",
      1: "Shelter for neighbouring crop",
    },
    "Timber production": {
      0: "Short Rotation (Pulplog market)",
      1: "Long Rotation (Sawlog market)",
    }
  }

  secondaryUse: IObjectKeys = {
    "Biodiversity": {
      0: "Local native forest species",
      1: "Blackwood (Acacia melanoxylon)",
    },
    "Soil conservation": {
      0: "Radiata Pine (Pinus radiata)",
      1: "Shining Gum (Eucalyptus nitens)",
      2: "Local native forest species",
    },
    "Shelter for grazing": {
      0: "Radiata Pine (Pinus radiata)",
      1: "Shining Gum (Eucalyptus nitens)",
    },
    "Shelter for neighbouring crop": {
      0: "Radiata Pine (Pinus radiata)",
      1: "Shining Gum (Eucalyptus nitens)",
    },
    "Short Rotation (Pulplog market)": {
      0: "Shining Gum (Eucalyptus nitens)",
      1: "Blue Gum (Eucalyptus globulus)",
    },
    "Long Rotation (Sawlog market)": {
      0: "Radiata Pine (Pinus radiata)",
      1: "Cypress (Cupressus spp.)",
      2: "Douglas Fir (Psuedostuga menziesii)",
      3: "Blackwood (Acacia melanoxylon)",
      4: "Brown Stringybark/Tasmanian Oak (Eucalyptus obliqua)",
      5: "Black Peppermint (Eucalyptus amygdalina)",
    },
  }

  recommendedSpecies: IObjectKeys = {

    "Local native forest species": {
      "Species Description": "Lorem ipsum",
      "URL to Info Sheet": "https://pft.tas.gov.au/species-guides",
      "Along Row Spacing": 2.6,
      "Between Row Spacing": 3.4,
      "Recommended Planting Layout": "1100 SPH (2.6m x 3.4m spacing)",
    },

    "Shining Gum (Eucalyptus nitens)": {
      "Species Description": `Eucalyptus nitens is native to mainland Australia and in plantations is primarily grown for pulpwood production on short rotations of 12-20 years, depending upon site quality. Although Eucalyptus globulus produces a higher quality pulp than E. nitens, E.nitens are more widely grown in Tasmania due to greater frost tolerance and productivity, particularly on colder, higher elevation sites. These plantation eucalypts produce a higher grade of pulpwood than mature native forests.`,
      "URL to Info Sheet": "https://pft.tas.gov.au/species-guides",
      "Along Row Spacing": 2.5,
      "Between Row Spacing": 3.5,
      "Recommended Planting Layout": "1100 SPH (2.6m x 3.5m spacing)",
    },

    "Radiata Pine (Pinus radiata)": {
      "Species Description": `<p>Radiata pine (Pinus radiata) is an exotic plantation species that has become the most widely planted softwood species in the Southern Hemisphere. Chile and New Zealand have the largest plantation estates, with well in excess of 1 million hectares each. Radiata pine was introduced to Australia in the 1850&rsquo;s. There are now approximately 750,000 hectares growing mainly in southern Australia. Its outstanding success as a plantation species is due to the following: </p>
      <ol>
      <li>Australia is relatively free from most natural pests and diseases of Radiata pine.</li>
      <li>Radiata pine is robust and relatively easy species to grow.</li>
      <li>Growth rate is rapid on suitable sites.</li>
      <li>There is a history of breeding for improved characteristics.</li>
      <li>There is a wealth of scientifically-based information on all aspects of growing Radiata pine more than any other species.</li>
      <li>There is a well-established market with a wide range of end uses.</li>
      <li>There are proven economic returns in excess of inflation when grown on suitable sites and appropriately managed</li>
      </ol>`,
      "URL to Info Sheet": "https://pft.tas.gov.au/species-guides",
      "Along Row Spacing": 3.0,
      "Between Row Spacing": 3.0,
      "Recommended Planting Layout": "1100 SPH (3.0m x 3.0m spacing)",
    },

    "Blue Gum (Eucalyptus globulus)": {
      "Species Description": "Eucalyptus globulus is native to Tasmania and in plantations is primarily grown for pulpwood production on short rotations of 12-20 years, depending upon site quality. E.globulus produces a higher quality pulp than E.nitens, but due to frost intolerance should be planted on lower elevation sites. These plantation eucalypts produce a higher grade of pulpwood than mature native forests.",
      "URL to Info Sheet": "https://pft.tas.gov.au/species-guides",
      "Along Row Spacing": 2.5,
      "Between Row Spacing": 3.5,
      "Recommended Planting Layout": "1101 SPH (2.5m x 3.5m)",
    },

    "Cypress (Cupressus spp.)": {
      "Species Description": "Cypress are exotic coniferous softwoods belonging to the closely related genera of Cupressus and Chamaecyparis. The genus Cupressus (true cypresses) consists of some 19 species, of which C.macrocarpa (Macrocarpa) from California and C.lusitanica (Lusitanica), predominantly from Mexico, are considered commercial timber species. The genus Chamaecyparis (false cypresses) consist of 7 species, the important timber species being C.lawsoniana (Lawson cypress) from western USA, C.nootkatensis (Nootka cypress) from Canada and Alaska and C.obtusa (Hinoki) from Japan.",
      "URL to Info Sheet": "https://pft.tas.gov.au/species-guides",
      "Along Row Spacing": 2.6,
      "Between Row Spacing": 3.4,
      "Recommended Planting Layout": "1100 SPH (2.6m x 3.4m spacing)",
    },

    "Douglas Fir (Psuedostuga menziesii)": {
      "Species Description": "Lorem ipsum",
      "URL to Info Sheet": "https://pft.tas.gov.au/species-guides",
      "Along Row Spacing": 2.6,
      "Between Row Spacing": 3.4,
      "Recommended Planting Layout": "1100 SPH (2.6m x 3.4m spacing)",
    },

    "Blackwood (Acacia melanoxylon)": {
      "Species Description": `Blackwood (Acacia melanoxylon) is native to Australia. It is one of the largest and longest lived of the Acacias. It occurs throughout most of Tasmania and much of eastern Australia, from south-eastern South Australia to northern Queensland. Blackwood tolerates a wide range of soil types and rainfall zones. On dry, exposed sites with poor soils and low rainfall it can form a small shrub with no commercial value. On high quality sheltered sites with high rainfall it can form large commercial timber trees up to 40m in height and 1.5m in diameter. Native blackwood can be found growing in a wide range of environmental conditions, however, high quality commercial timber trees develop in the following forest types.`,
      "URL to Info Sheet": "https://pft.tas.gov.au/species-guides",
      "Along Row Spacing": 2.6,
      "Between Row Spacing": 3.4,
      "Recommended Planting Layout": "1100 SPH (2.6m x 3.4m spacing)",
    },

    "Brown Stringybark/Tasmanian Oak (Eucalyptus obliqua)": {
      "Species Description": "Lorem ipsum",
      "URL to Info Sheet": "https://pft.tas.gov.au/species-guides",
      "Along Row Spacing": 2.6,
      "Between Row Spacing": 3.4,
      "Recommended Planting Layout": "1100 SPH (2.6m x 3.4m spacing)",
    },

    "Black Peppermint (Eucalyptus amygdalina)": {
      "Species Description": "Lorem ipsum",
      "URL to Info Sheet": "https://pft.tas.gov.au/species-guides",
      "Along Row Spacing": 2.6,
      "Between Row Spacing": 3.4,
      "Recommended Planting Layout": "1100 SPH (2.6m x 3.4m spacing)",
    },

  }

  primaryUseArray: string[] = [];
  secondaryUseArray: string[] = [];
  recommendedSpeciesArray: string[] = [];

  public selectedPrimaryUse?: string | null;
  selectedSecondaryUse?: string | null;
  selectedRecommendedSpecies?: string | null;
  selectedRecommendedSpeciesTable?: any;

  materialSpeciesPlantingForm: FormGroup = new FormGroup({
    recommendedPlantingLayout: new FormControl("", [Validators.required]),
    alongRowSpacing: new FormControl(0, [Validators.minLength(0)]),
    betweenRowSpacing: new FormControl(0, [Validators.minLength(0)]),
  });

  // Form calculations
  stemsPerHectare: number = 0;
  numberSeedingsRequired: number = 0;
  numberTreeStocksRequired: number = 0;
  amountFertiliserRequired: number = 0;

  constructor() {

    // Generating primary use questions based on the keys
    this.primaryUseArray = Object.keys(this.primaryUse);

  }

  generateSecondaryUseItemList(primaryUseSelectElement: Event) {
    const element = primaryUseSelectElement.target as HTMLInputElement;
    const elementValue = element.value;
    this.selectedPrimaryUse = elementValue;
    const secondaryValues = Object.values(this.primaryUse[elementValue]);

    this.secondaryUseArray = secondaryValues;

    // clearing out the recommended species arrays
    this.recommendedSpeciesArray = [];
    this.selectedRecommendedSpecies = null;
    this.selectedRecommendedSpeciesTable = null;
    this.materialSpeciesPlantingForm = new FormGroup({
      recommendedPlantingLayout: new FormControl("", [Validators.required]),
      alongRowSpacing: new FormControl(0, [Validators.minLength(0)]),
      betweenRowSpacing: new FormControl(0, [Validators.minLength(0)]),
    });
  }

  generateRecommendedSpeciesUseItemList(secondaryUseSelectElement: Event) {

    // clearing out the previous selections
    this.recommendedSpeciesArray = [];
    this.selectedRecommendedSpecies = null;
    this.selectedRecommendedSpeciesTable = null;
    this.materialSpeciesPlantingForm = new FormGroup({
      recommendedPlantingLayout: new FormControl("", [Validators.required]),
      alongRowSpacing: new FormControl(0, [Validators.minLength(0)]),
      betweenRowSpacing: new FormControl(0, [Validators.minLength(0)]),
    });

    const element = secondaryUseSelectElement.target as HTMLInputElement;
    const elementValue = element.value;

    this.selectedSecondaryUse = elementValue;
    const recommendedSpeciesValues = Object.values(this.secondaryUse[elementValue]);

    this.recommendedSpeciesArray = recommendedSpeciesValues;

  }

  generateRecommendedSpeciesTable(selectedRecommendedSpecies: Event) {
    const element = selectedRecommendedSpecies.target as HTMLInputElement;
    const elementValue = element.value;

    this.selectedRecommendedSpecies = elementValue;
    this.selectedRecommendedSpeciesTable = this.recommendedSpecies[elementValue];

    const layout: string = this.selectedRecommendedSpeciesTable["Recommended Planting Layout"];
    const aSpacing: number = this.selectedRecommendedSpeciesTable["Along Row Spacing"];
    const bSpacing: number = this.selectedRecommendedSpeciesTable["Between Row Spacing"];

    this.formGenerationHandler(layout, aSpacing, bSpacing);

    this.stemsPerHectare = Number((10000 / (aSpacing * bSpacing)).toFixed(2));
    this.numberSeedingsRequired = Number((50 * 10000 / (aSpacing * bSpacing)).toFixed(2));
    this.numberTreeStocksRequired = Number((50 * 10000 / (aSpacing * bSpacing)).toFixed(2));
    this.amountFertiliserRequired = Number((125 * 50 * 10000 / (aSpacing * bSpacing) / 1000).toFixed(2));

  }

  formGenerationHandler(layout: string, alongSpacing: number, betweenSpacing: number) {
    this.materialSpeciesPlantingForm = new FormGroup({
      recommendedPlantingLayout: new FormControl(layout, [Validators.required]),
      alongRowSpacing: new FormControl(alongSpacing, [Validators.minLength(0)]),
      betweenRowSpacing: new FormControl(betweenSpacing, [Validators.minLength(0)]),
    })
  }

  recalculateCalculationsValues() {

    const alongSpacing: number = this.materialSpeciesPlantingForm.value["alongRowSpacing"];
    const betweenSpacing: number = this.materialSpeciesPlantingForm.value["betweenRowSpacing"];

    this.stemsPerHectare = Number((10000 / (alongSpacing * betweenSpacing)).toFixed(2));
    this.numberSeedingsRequired = Number((50 * 10000 / (alongSpacing * betweenSpacing)).toFixed(2));
    this.numberTreeStocksRequired = Number((50 * 10000 / (alongSpacing * betweenSpacing)).toFixed(2));
    this.amountFertiliserRequired = Number((125 * 50 * 10000 / (alongSpacing * betweenSpacing) / 1000).toFixed(2));
  }

}
