import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layer-switcher',
  templateUrl: './layer-switcher.component.html',
  styleUrls: ['./layer-switcher.component.css']
})
export class LayerSwitcherComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
