<h2 mat-dialog-title>Delete Fence</h2>
<mat-dialog-content class="mat-typography">
  <p>
    <strong
      >Are you sure you want to delete
      <span class="text-primary">"{{ fenceName }}"</span>?</strong
    >
  </p>
  <p>
    All information associated to this fence will be permanently deleted.
    <span class="text-danger">This operation can not be undone.</span>
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" [mat-dialog-close]="false" class="button button--black-outline">
      Cancel
    </button>
    <button
      type="button"
      class="button button--danger"
      [mat-dialog-close]="true"
    >
      Ok
    </button>
  </footer>
</mat-dialog-actions>
