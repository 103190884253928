
export class DatabaseSyncAction {
  public id: number;
  public entityType: EntityType;
  public entityID: string;
  public action: Action;

  /**
   *
   */
  constructor(entityType: EntityType, entityID: string, action: Action ) {
    this.action = action;
    this.entityID = entityID;
    this.entityType = entityType;
  }
}


export enum EntityType {
  Property,
  Paddock,
  Fence,
  NpvReport,
  TopexReport,
  EnterpriseReport,
  ForestDescriptionReport
}

export enum Action {
  Insert,
  Update,
  Remove
}
