<mat-toolbar>
  <span>Farm Woodlot Tool</span>
  <div class="actions">
<button type="button" (click)="showWelcome()" class="btn btn-primary" id="about-tool" data-bs-toggle="modal">
  About tool
</button>
    <a type="button" [routerLink]="['/map']" class="btn btn-primary" id="about-tool">
      Map
    </a>
  </div>

</mat-toolbar>
<div id="farm-woodlot-tool-container">
  <!-- <div class="btn btn-primary" (click)="generatePDFHandler()">Download PDF</div> -->

  <div class="d-block w-100 container pt-4">
    <div class="accordion m-0" id="accordionFarmWoodlotTool">
      <!-- stage 1 -->
      <div class="accordion-item">
        <h2 class="accordion-header collapsed" id="headingOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#SpeciesAndPlantingMaterials" aria-expanded="true" aria-controls="collapseOne">
            <h3>
              Species and Planting Materials required for "{{
              reportTarget.name
              }}"
            </h3>
          </button>
        </h2>

        <div id="SpeciesAndPlantingMaterials" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionFarmWoodlotTool">
          <div class="accordion-body">
            <app-materials-species-planting (parentEventTransmitter)="setStageTemplateData($event)">
            </app-materials-species-planting>

            <div class="col-12" *ngIf="stageCompletedTemplate[1]['completed'] === true">
              <div class="btn btn-primary" (click)="showPDFModalHandler()">
                Preview PDF
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- stage 2 -->
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#FencingMaterialsRequired" aria-expanded="false" aria-controls="collapseTwo">
            <h3>Fencing materials required for "{{ reportTarget.name }}"</h3>
          </button>
        </h2>
        <div id="FencingMaterialsRequired" class="accordion-collapse collapse" aria-labelledby="headingTwo"
          data-bs-parent="#accordionFarmWoodlotTool">
          <div class="accordion-body">
            <app-tree-protection (parentEventTransmitter)="setStageTemplateData($event)"
              [fenceSize]="fenceLength ? fenceLength : 0"></app-tree-protection>

            <div class="col-12 mt-3" *ngIf="stageCompletedTemplate[2]['completed'] === true">
              <div class="btn btn-primary" (click)="showPDFModalHandler()">
                Preview PDF
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- stage 3 -->
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#SiteEstablishemntAndWeedControlGuidelines" aria-expanded="false"
            aria-controls="collapseTwo">
            <h3>
              Site Establishment and Weed Control guidelines for "{{
              reportTarget.name
              }}"
            </h3>
          </button>
        </h2>
        <div id="SiteEstablishemntAndWeedControlGuidelines" class="accordion-collapse collapse"
          aria-labelledby="headingTwo" data-bs-parent="#accordionFarmWoodlotTool">
          <div class="accordion-body">
            <app-site-preparation-guidelines></app-site-preparation-guidelines>

            <div class="col-12" *ngIf="stageCompletedTemplate[3]['completed'] === true">
              <div class="btn btn-primary" (click)="showPDFModalHandler()">
                Preview PDF
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- stage 4 -->
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#EstimationsYields" aria-expanded="false" aria-controls="collapseTwo">
            <h3>
              Estimations of yields for final harvest of trees on
              {{ reportTarget.name }}
            </h3>
          </button>
        </h2>
        <div id="EstimationsYields" class="accordion-collapse collapse" aria-labelledby="headingTwo"
          data-bs-parent="#accordionFarmWoodlotTool">
          <div class="accordion-body">
            <app-indicative-yields (parentEventTransmitter)="setStageTemplateData($event)" [id]="propertyPaddockID">
            </app-indicative-yields>

            <div class="col-12" *ngIf="stageCompletedTemplate[4]['completed'] === true">
              <div class="btn btn-primary" (click)="showPDFModalHandler()">
                Preview PDF
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- stage 5 -->
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#MarketGuidelines" aria-expanded="false" aria-controls="collapseTwo">
            <h3>Market Guidelines</h3>
          </button>
        </h2>
        <div id="MarketGuidelines" class="accordion-collapse collapse" aria-labelledby="headingTwo"
          data-bs-parent="#accordionFarmWoodlotTool">
          <div class="accordion-body">
            <app-market-guidelines (parentEventTransmitter)="setStageTemplateData($event)"></app-market-guidelines>

            <div class="col-12" *ngIf="stageCompletedTemplate[5]['completed'] === true">
              <div class="btn btn-primary" (click)="showPDFModalHandler()">
                Preview PDF
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- stage 6 -->
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#PTRGuidelines" aria-expanded="false" aria-controls="collapseTwo">
            <h3>PTR Guidelines</h3>
          </button>
        </h2>
        <div id="PTRGuidelines" class="accordion-collapse collapse" aria-labelledby="headingTwo"
          data-bs-parent="#accordionFarmWoodlotTool">
          <div class="accordion-body">
            <app-ptr-guidelines></app-ptr-guidelines>

            <div class="col-12" *ngIf="stageCompletedTemplate[6]['completed'] === true">
              <div class="btn btn-primary" (click)="showPDFModalHandler()">
                Preview PDF
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--    -->
    </div>
  </div>

  <!-- <app-tree-protection></app-tree-protection> -->

  <div class="modal fade" [@inOutAnimation] [ngClass]="showPDFModal ? 'show' : ''"
    [ngStyle]="{ display: showPDFModal ? 'block' : 'none' }" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-labelledby="welcomeMessageModal" [attr.aria-modal]="showPDFModal ? true : null" role="dialog"
    [attr.aria-hidden]="showPDFModal ? false : null">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">PDF Content</h3>
          <button type="button" (click)="hidePDFModalHandler()" class="btn-close" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <div class="d-none" id="pdf-header-modal-container">
            <h2 class="m-0">Farm Woodlot Tool</h2>
            <h5 class="m-0">Date: {{ todayNumber | date }}</h5>
            <h3 class="m-0">{{ reportTarget.name }}</h3>
          </div>

          <div id="pdf-modal-container" class="shadow-component">
            <div class="stage-1-container mb-2" *ngIf="stageCompletedTemplate[1]['completed'] === true">
              <h4 class="mb-2">Species and Planting Materials required</h4>
              <div class="small-font row" class="">
                <p>
                  This form provides guidance on the types of tree species you can
                  plant for different end-uses, materials likely required at the
                  time of establishment, and advice on sourcing seedlings and
                  planting labour. Note that you will likely have to order
                  seedlings up to a year in advance, so good planning is
                  essential.
                </p>
                <p>
                  For any timber production enterprise, genetics matter so be sure
                  to discuss genetics options with your supplier prior to
                  purchase.
                </p>
              </div>

              <div class="small-font row">
                <p>
                  <b>Primary Use: </b>
                  {{ stageCompletedTemplate[1]['data']['primaryUse'] }}
                  <br />
                  <b>Secondary Use: </b>
                  {{ stageCompletedTemplate[1]['data']['secondaryUse'] }}
                  <br />
                  <b>Recommended species: </b>
                  {{ stageCompletedTemplate[1]['data']['recommendedSpecies'] }}
                </p>
              </div>

              <div class="small-font row">
                <p>
                  <b>Species Description:</b>
                  <br />
                  {{ stageCompletedTemplate[1]['data']['speciesDescription'] }}
                  <br /><br />
                  <b>More info:</b>
                  <br />
                  {{ stageCompletedTemplate[1]['data']['urlInfo'] }}
                </p>
              </div>

              <div  class="small-font line-height-1 table-responsive">
                <table class="table">
                  <tbody>
                    <tr>
                      <th scope="col" class="small-font">Recommended planting layout</th>
                      <td>
                        {{
                        stageCompletedTemplate[1]['data'][
                        'recommendedPlantingLayout'
                        ]
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Along row spacing:</th>
                      <td>
                        {{ stageCompletedTemplate[1]['data']['alongRowSpacing'] }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Between row spacing:</th>
                      <td>
                        {{
                        stageCompletedTemplate[1]['data']['betweenRowSpacing']
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h4 class="mt-2 ms-2">Calculations:</h4>
              <div  class="small-font line-height-1 table-responsive">
                <table class="table">
                  <tbody>
                    <tr>
                      <th scope="col" class="small-font">Stems per hectare (SPH)</th>
                      <td>
                        {{ stageCompletedTemplate[1]['data']['stemsPerHectare']| toPrecision }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Number seedlings required</th>
                      <td>
                        {{
                        stageCompletedTemplate[1]['data'][
                        'numberSeedingsRequired'
                        ]
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Number tree socks required</th>
                      <td>
                        {{
                        stageCompletedTemplate[1]['data'][
                        'numberTreeStocksRequired'
                        ]
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Amount of fertiliser required (kg)</th>
                      <td>
                        {{
                        stageCompletedTemplate[1]['data'][
                        'amountFertiliserRequired'
                        ]
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="small-font row">
                <p>
                  Fertiliser amount is based on an assumed 125g DAP (Di Ammonium
                  Phosphate) per seedling. Please note that although fertiliser
                  application at planting is a standard practice in the forestry
                  industry, it is optional, and you should seek further advice as
                  to whether this is required or not for your site. Generally,
                  ex-pasture sites do not require fertiliser application.
                </p>
              </div>
            </div>

            <div class="stage-2-container mb-2" *ngIf="stageCompletedTemplate[2]['completed'] === true">
              <h4 class="mb-2">Fencing materials required</h4>
              <div class="small-font row" >
                <p>
                  Fencing is likely required to protect young trees from vermin
                  and stock alike. This form provides an estimate of fencing
                  materials likely required to protect your trees.
                </p>
                <p>
                  In addition to fencing, browsing control can also be an
                  effective means of protecting trees. For more information on
                  browing control, please refer to this link:
                </p>
              </div>
              <div class="small-font row">
                <p>
                  Choose the type of fence you required:
                  {{ stageCompletedTemplate[2]['data']['selectedFencingType'] }}
                  <br />
                  Length of Fence Required (m):
                  {{ stageCompletedTemplate[2]['data']['lengthOfFenceRequired'] }}
                </p>
              </div>

              <div class="table-responsive">
                <table class="table">
                  <tbody>
                    <tr>
                      <th scope="col" class="small-font">Post Spacing (metres per post)</th>
                      <th scope="row">
                        {{
                        stageCompletedTemplate[4]['data']['selectedCropSpecies']
                        }}
                      </th>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Ratio Star Posts to Steel/Treated Pine</th>
                      <td>
                        {{
                        stageCompletedTemplate[4]['data']['mappedWellSuited']
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Standard Wire Rows Required (n)</th>
                      <td>
                        {{ stageCompletedTemplate[4]['data']['mappedSuitable'] }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Barb Wire Rows Required (n)</th>
                      <td>
                        {{
                        stageCompletedTemplate[4]['data'][
                        'mappedModeratelySuitable'
                        ]
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h4 class="mt-2 ms-2">Post Type:</h4>
              <div class="table-responsive">
                <table class="table">
                  <tbody>
                    <tr>
                      <th scope="col" class="small-font">Star</th>
                      <th scope="col" class="small-font">Steel/Wood</th>
                    </tr>

                    <tr>
                      <td>
                        {{ stageCompletedTemplate[2]['data']['star'] }}
                      </td>
                      <td>
                        {{ stageCompletedTemplate[2]['data']['steelWood'] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- <h4>Wire type:</h4>
              <h5>Roll Length (m):</h5>
              <div class="table-responsive">
                <table class="table">
                  <tbody>
                    <tr>
                      <th scope="col" class="small-font">Standard Wire</th>
                      <th scope="col" class="small-font">Barb Wire</th>
                      <th scope="col" class="small-font">Wallaby Proof Mesh</th>
                    </tr>

                    <tr>
                      <td>
                        {{
                          treeProtectionService[
                            'fencingMaterialTableInformation'
                          ][
                            stageCompletedTemplate[2]['data'][
                              'selectedFencingType'
                            ]
                          ]['Standard Wire Roll length - metres']
                        }}
                      </td>
                      <td>
                        {{
                          treeProtectionService[
                            'fencingMaterialTableInformation'
                          ][
                            stageCompletedTemplate[2]['data'][
                              'selectedFencingType'
                            ]
                          ]['Barb Wire Roll Length - metres']
                        }}
                      </td>
                      <td>
                        {{
                          treeProtectionService[
                            'fencingMaterialTableInformation'
                          ][
                            stageCompletedTemplate[2]['data'][
                              'selectedFencingType'
                            ]
                          ]['Wallaby Proof Mesh Roll Length - metres']
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> -->

              <h4 class="mt-2 ms-2">Wire Type:</h4>
              <div class="table-responsive">
                <table class="table">
                  <tbody>
                    <tr>
                      <th scope="col" class="small-font">Standard Wire</th>
                      <th scope="col" class="small-font">Barb Wire</th>
                      <th scope="col" class="small-font">Wallaby Proof Mesh</th>
                    </tr>

                    <tr>
                      <td>
                        {{ stageCompletedTemplate[2]['data']['standarddWire'] }}
                      </td>
                      <td>
                        {{ stageCompletedTemplate[2]['data']['barbWire'] }}
                      </td>
                      <td>
                        {{
                        stageCompletedTemplate[2]['data']['wallabyProofMesh']
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="stage-3-container mb-2" *ngIf="stageCompletedTemplate[3]['completed'] === true">
              <h4 class="mb-2">Establishment and Weed Control guidelines</h4>
              <div class="small-font" id="weed-control-guidelines">
                <div class="row">
                  <div class="col-12">
                    <p>
                      Preparation of the ground for any crop (including trees) is
                      essential for a successful crop and to protect the long-term
                      sustainability of the soil. Good preparation will:
                    </p>
                    <ul class="list-group list-group-flush" class="small-font">
                      <li class="list-group-item">
                        <span><b>1.</b></span> Minimise soil disturbance;
                      </li>
                      <li class="list-group-item">
                        <span><b>2.</b></span> Result in good seedling survival;
                      </li>
                      <li class="list-group-item">
                        <span><b>3.</b></span> Allow the crop to grow as uniformly
                        as possible;
                      </li>
                      <li class="list-group-item">
                        <span><b>4.</b></span> Increase the effectiveness of weed
                        control operations and minimise the use of chemicals;
                      </li>
                      <li class="list-group-item">
                        <span><b>5.</b></span> Mitigate against frost damage,
                        waterlogging or drought;
                      </li>
                      <li class="list-group-item">
                        <span><b>6.</b></span> Reduce risk of wind damage later in
                        the rotation;
                      </li>
                      <li class="list-group-item">
                        <span><b>7.</b></span> Reduce and cater for fire risk; and
                      </li>
                      <li class="list-group-item">
                        <span><b>8.</b></span> Provide good access for thinning
                        and pruning.
                      </li>
                    </ul>
                    <p class="mt-3">
                      In addition to ground preparation, completely removing
                      difficult to control or highly competitive weeds prior to
                      tree planting, allows the planted trees to grow as quickly
                      as possible so they can occupy the site
                    </p>
                    <p>
                      Weeds are a problem because they can compete with trees for
                      water, nutrients and light. By their nature, most weed
                      species are aggressive at occupying a site. If additional
                      nutrients are applied using fertilisers, or water is applied
                      by irrigation, weeds will generally benefit from these much
                      more than the trees. Weeds can also contribute to increased
                      frost damage, browsing susceptibility, fungal diseases and
                      insect attack in young tree seedlings.
                    </p>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <h5>More information</h5>
                    <ul class="list-group list-group-flush" class="small-font">
                      <li class="list-group-item border border-0">
                        <a href="https://www.treealliance.com.au/__data/assets/pdf_file/0020/260381/Information_Series_-_No._6_-_Site_Preparation_-_Version_3_-_August_2020.pdf"
                          target="_blank">Site Preparation Guidelines</a>
                      </li>
                      <li class="list-group-item border border-0">
                        <a href="https://www.treealliance.com.au/__data/assets/pdf_file/0003/260382/Information_Series_-_No._7_-_Weed_Control_-_Version_3_-_August_2020.pdf"
                          target="_blank">Weed Control Guidelines</a>
                      </li>
                      <li class="list-group-item border border-0">
                        <a href="https://www.treealliance.com.au/resources/farming_guides" target="_blank">Tree Farming
                          Guide
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="stage-4-container mb-2" *ngIf="stageCompletedTemplate[4]['completed'] === true">
              <h4 class="mb-2">Estimations of yields for final harvest of trees on</h4>
              <div class="small-font row">
                <p>
                  The Enterprise Suitability ratings presented by the Crop
                  Decision Tool can be used as a rough guide as to the likely
                  growth rates your trees will achieve if planted on these sites.
                  The tool below can be used to assign likely growth rates to the
                  area under each rating, from which estimated volumes at harvest
                  age can be derived. The Estimated Mean Annual Increment figures
                  provided as defaults are indicative only, and you should seek
                  advice from a professional as to the likely growth rates
                  achieveable on your site.
                </p>
              </div>
              <div class="small-font row" >
                <p>
                  Note that pine species are generally thinned in Tasmania, either
                  once or twice, to achieve a higher quality product at final
                  harvest.
                </p>
              </div>
              <div  class="small-font line-height-1 table-responsive">
                <table class="table">
                  <tbody>
                    <tr>
                      <th scope="col" class="small-font">Selected Crop Species</th>
                      <th scope="row">
                        {{
                        stageCompletedTemplate[4]['data']['selectedCropSpecies']
                        }}
                      </th>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Mapped area (ha) WellSuited</th>
                      <td>
                        {{
                        stageCompletedTemplate[4]['data']['mappedWellSuited']
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Mapped area (ha) Suitable</th>
                      <td>
                        {{ stageCompletedTemplate[4]['data']['mappedSuitable'] }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Mapped area (ha) Moderately Suitable</th>
                      <td>
                        {{
                        stageCompletedTemplate[4]['data'][
                        'mappedModeratelySuitable'
                        ]
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" class="small-font">Mapped area (ha) Unsuitable</th>
                      <td scope="row">
                        {{
                        stageCompletedTemplate[4]['data']['mappedUnsuitable']
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">
                        Estimated Mean Annual Increment (t/ha/yr) Well Suited
                      </th>
                      <td>
                        {{
                        stageCompletedTemplate[4]['data']['estimatedWellSuited']
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">
                        Estimated Mean Annual Increment (t/ha/yr) Suitable
                      </th>
                      <td>
                        {{
                        stageCompletedTemplate[4]['data']['estimatedSuitable']
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">
                        Estimated Mean Annual Increment (t/ha/yr) Moderately
                        Suitable
                      </th>
                      <td>
                        {{
                        stageCompletedTemplate[4]['data'][
                        'estimatedModeratelySuitable'
                        ]
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" class="small-font">
                        Estimated Mean Annual Increment (t/ha/yr) Unsuitable
                      </th>
                      <td scope="row">
                        {{
                        stageCompletedTemplate[4]['data']['estimatedUnsuitable']
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Total Area</th>
                      <td>
                        {{ stageCompletedTemplate[4]['data']['totalArea'] }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Average MAI</th>
                      <td>
                        {{ stageCompletedTemplate[4]['data']['averageMAI'] | toPrecision }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Harvestable Volume</th>
                      <td>
                        {{
                        stageCompletedTemplate[4]['data']['harvestableVolume']
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Harvestable Volume Per Hectare</th>
                      <td>
                        {{
                        stageCompletedTemplate[4]['data'][
                        'harvestableVolumePerHectare'
                        ]| toPrecision
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="stage-5-container mb-2" *ngIf="stageCompletedTemplate[5]['completed'] === true">
              <h3>Market Guideline</h3>
              <div  class=" small-font line-height-1 table-responsive">
                <table class="table">
                  <tbody>
                    <tr>
                      <th scope="col" class="small-font">Market Type</th>
                      <th scope="row">
                        {{
                        stageCompletedTemplate[5]['data']['selectedMarketType']
                        }}
                      </th>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Topic</th>
                      <td>
                        {{ stageCompletedTemplate[5]['data']['selectedTopic'] }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Overview</th>
                      <td>
                        {{
                        stageCompletedTemplate[5]['data']['overviewDescription']
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="col" class="small-font">Link</th>
                      <td>
                        {{ stageCompletedTemplate[5]['data']['overviewURL'] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="stage-6-container" *ngIf="stageCompletedTemplate[6]['completed'] === true">
              <h4 class="mb-2">PTR Guideline</h4>
              <div class="small-font" id="ptr-guideline-component">
                <div class="row">
                  <div class="col-12">
                    <p>
                      In Tasmania private timber reserves are an integral part of
                      the State's commitment to encouraging and fostering the
                      sustainable management of private forests. Forestry, as a
                      major statewide industry, is covered by a statewide planning
                      system in the form of the Forest Practices Act 1985 and
                      Forest Practices Code. Private timber reserves create a
                      secure environment for investment in private forestry within
                      the State and help to deliver commitments for the
                      sustainable management of private forests.
                    </p>

                    <p class="mt-2">
                      A private timber reserve is an area of private land set
                      aside for forestry purposes and registered on the title. It
                      is land or forest used to establish forests, or grow or
                      harvest timber in accordance with the Forest Practices Code.
                      <br />
                      A private timber reserve is a parcel of private land or
                      forest of at least five hectares in area set aside to grow
                      or harvest forests. It may be an area of:
                    </p>

                    <ul class="list-group list-group-flush line-before">
                      <li class="list-group-item">native forest; or</li>
                      <li class="list-group-item">a tree plantation; or</li>
                      <li class="list-group-item">
                        an area of land intended to be planted in the near future.
                      </li>
                    </ul>

                    <p class="mt-3">
                      On land declared a private timber reserve local government
                      approval is not required when undertaking forestry
                      operations. Under the Land Use Planning and Approvals Act
                      1993 section 20 (7), nothing in any planning scheme, or
                      interim order, affects the management of land declared as a
                      private timber reserve under the Forest Practices Act 1985
                    </p>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <h5>More information</h5>
                    <ul class="list-group list-group-flush" class="small-font">
                      <li class="list-group-item border border-0 px-0">
                        <a href="https://www.treealliance.com.au/resources/private_timber_reserves"
                          target="_blank">Private Timber Reserves</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div id="pdf-footer" class="d-none">
              <h4>General Disclaimer for Farm &amp; Forest Mapper (add link to Dashboard and to each Tool/Calculator
                panel in the map):</h4>

              <p>The Farm and Forest Mapper is subject to the terms and conditions as detailed in the conditions of use
                below. Both Private Forests Tasmania and the Forest Practices Authority exclude all liability to any
                person arising directly or indirectly from using this site and any information or material available
                from it.</p>

              <p><strong>Information at this site:</strong></p>

              <ul>
                <li>is general information provided to help you plan farming and forest practices projects on your
                  property(s) and decide if you need further professional advice relevant to your particular
                  circumstances</li>
                <li>is not a substitute for independent professional advice</li>
                <li>is subject to the uncertainties of scientific and technical research</li>
                <li>may not be accurate, current or complete</li>
                <li>is subject to change without notice</li>
                <li>may include the views or recommendations of third parties, which do not necessarily reflect the
                  views of Private Forests Tasmania or the Forest Practices Authority or indicate a commitment to a
                  particular course of action</li>
              </ul>

              <p><strong>Layers in the map:</strong></p>

              <ul>
                <li>are compiled from a range of sources of varying accuracy, reliability, completeness and correctness
                  which are outside of the control of Private Forests Tasmania and the Forest Practices Authority. No
                  representation or warranty is made by Private Forests Tasmania or the Forest Practices Authority for
                  the accuracy, completeness, or relevance of such mapping layers to the user&rsquo;s purpose.</li>
                <li>are produced from computer models. The models are based on the best data available to Private
                  Forests Tasmania and the Forest Practices Authority at the time the models were developed.</li>
                <li>are only indicative. They should not be relied upon for navigation purposes and may not reflect the
                  situation on-ground.</li>
              </ul>

              <p><strong>Links to External Web Sites</strong></p>

              <p>Private Forests Tasmania and the Forest Practices Authority have no direct control over the content of
                any linked sites, or the changes that may occur to the content on those sites. It is the responsibility
                of the user to make their own decisions about the accuracy, currency, reliability and correctness of
                information contained in linked external web sites.</p>

              <p>Links to external web sites do not constitute an endorsement or a recommendation of any material on
                those sites or of any third party products or services offered by, from or through those sites. Users of
                links provided by this web site are responsible for being aware of which organisation is hosting the web
                site they visit.</p>

              <h4>Disclaimer for FPA PDF Report Footer:</h4>

              <ul>
                <li><strong>Forestry Management Baseline Costs And Benefits Tool</strong></li>
                <li><strong>Landscape Value View (coming soon)</strong></li>
              </ul>

              <p>Some of the information within this report has been prepared by the Forest Practices Authority (FPA).
                Every reasonable endeavour has been used to ensure that the material was accurate at the time of
                publication. No representation or warranty is made by PFT for the accuracy, completeness, or relevance
                of such information to the user&rsquo;s purpose. You agree and accept that any use of the information is
                entirely at your own risk. PFT, the Crown, its officers, employees and agents do not accept any
                liability howsoever arising (including, without limitation, negligence) for any loss in connection with
                or arising out of any use of or reliance upon the information. Anyone considering any investment project
                should seek independent professional advice on the particular matter before making a decision.</p>

              <p>For further information please contact:<br />
                Forest Practices Authority<br />
                30 Patrick St, nipaluna/Hobart, Tasmania 7000<br />
                Telephone: (03) 6165 4090<br />
                <a href="https://www.fpa.tas.gov.au">www.fpa.tas.gov.au</a>
              </p>

              <h4>Disclaimer for PFT PDF Report Footer:</h4>

              <ul>
                <li>
                  <p><strong>Crop/Species DSS Tool</strong></p>
                </li>
                <li>
                  <p><strong>Farm Woodlot Tool</strong></p>
                </li>
                <li>
                  <p><strong>TOPEX Tool</strong></p>
                </li>
                <li>
                  <p><strong>Forest Description Tool</strong></p>
                </li>
              </ul>

              <p>Some of the information within this report has been prepared by Private Forests Tasmania (PFT). Every
                reasonable endeavour has been used to ensure that the material was accurate at the time of publication.
                No representation or warranty is made by PFT for the accuracy, completeness, or relevance of such
                information to the user&rsquo;s purpose. You agree and accept that any use of the information is
                entirely at your own risk. PFT, the Crown, its officers, employees and agents do not accept any
                liability howsoever arising (including, without limitation, negligence) for any loss in connection with
                or arising out of any use of or reliance upon the information. Anyone considering any investment project
                should seek independent professional advice on the particular matter before making a decision.</p>

              <p>For further information please contact:</p>

              <p>Private Forests Tasmania<br />
                PO Box 180, Kings Meadows Tas 7249<br />
                Telephone: (03) 6777 2720<br />
                Email: <a href="mailto:admin@pft.tas.gov.au">admin&#64;pft.tas.gov.au</a></p>

            </div>
          </div>

          <div class="d-none" id="pdf-footer-modal-container">
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Hic esse
              in nostrum neque earum corrupti fugiat facere ipsa nesciunt libero
              dolorum culpa cumque, illo sint voluptates rem explicabo possimus a.
            </p>
          </div>
        </div>

        <div class="modal-footer">
          <button (click)="generatePDFHandler()" class="btn btn-primary">
            Download PDF
          </button>
        </div>
      </div>
    </div>
  </div>

</div>
