import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { boolean } from 'mathjs';
import { PluggableMap, Feature } from 'ol';
import { Control } from 'ol/control';
import { Draw, Snap } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Subscription } from 'rxjs';
import { FenceDexie } from 'src/app/models/fence.model';
import { PropertyDexie } from 'src/app/models/property.model';
import { SpatialTool } from 'src/app/models/spatial-tools-mdoel';
import { mapService } from 'src/app/services/map.service';
import { PropertyService } from 'src/app/services/property.service';
import { SpatialToolsService } from 'src/app/services/spatial-tools.service';
import { DrawType } from 'src/app/_helpers/enums';
import { CalculateLength, ConvertFeatureToGeoJson, ConvertFeatureToWKT } from 'src/app/_helpers/transformations';

export interface FenceCreatedEvent{
  fence: FenceDexie,
  intersectedPaddockIds: Array<string>
}

@Component({
  selector: 'app-digitize-fence',
  templateUrl: './digitize-fence.component.html',
  styleUrls: ['./digitize-fence.component.css']
})
export class DigitizeFenceComponent implements OnInit, OnDestroy, AfterViewInit,SpatialTool {

  @Input() map: PluggableMap;
  @Input() snapLayers: Array<VectorLayer<any>>;

  @Output() fenceCreated = new EventEmitter<FenceCreatedEvent>();

  @Output() active = new EventEmitter<boolean>(false);


  disabled: boolean = false;
  activeProperty: PropertyDexie;
  isActive: boolean = false;
  interaction: Draw;
  snapInteractions: Array<Snap> = [];
  vectorSource: VectorSource<any>;
  control: Control;


  propertySub$: Subscription = new Subscription();
  spatialToolSub$: Subscription = new Subscription();

  constructor(private propertyService: PropertyService,private spatialToolService: SpatialToolsService, private elRef:ElementRef, private mapService: mapService) { }

  ngOnInit(): void {
    this.propertySub$ = this.propertyService.activeProperty.subscribe(property => {
      this.disabled = property == null ? true : false;
      this.activeProperty = property;

    });

    this.spatialToolSub$ = this.spatialToolService.activeTool.subscribe(activeTool => {
      if(activeTool == null)
      return;

      if(activeTool != this)
      {
        this.deSelect();
      }
    });



    this.vectorSource = new VectorSource();
  }

  ngAfterViewInit(): void {
    this.control = new Control({target: this.elRef.nativeElement.parentNode, element: this.elRef.nativeElement});
    this.map.addControl(this.control);
  }


  ngOnDestroy(): void {
    if(this.control == null)
    return;

    this.propertySub$.unsubscribe();
    this.spatialToolSub$.unsubscribe();

    this.map.removeControl(this.control);
    this.deSelect();
  }


  toggleDigitizeFence(){

    if (this.isActive)
    {
      this.deSelect();
    }
    else
    {
      this.draw();
      this.spatialToolService.activeTool.next(this);
      this.active.emit(this.isActive)
    }


  }


  private draw() {

    this.interaction = new Draw({
      source: this.vectorSource,
      type: DrawType.LineString,
      snapTolerance: 20,

    });

      this.snapLayers.forEach(lyr => {
        let snap = new Snap(
          {
            source: lyr.getSource()
          }
        )

        this.snapInteractions.push(
          snap
          )
      });




    // this.snapInteractions.push(new Snap(
    //   {
    //     source: this.vectorSource
    //   }
    // ));



    this.map.addInteraction(this.interaction);

    this.snapInteractions.forEach(snap => {
      this.map.addInteraction(snap);
    })

    this.isActive = true;

    this.interaction.on('drawend', (event) => {
      var feature = event.feature as Feature<any>;

      this.createFence(feature);

    });

  }

  private async createFence(feature)
  {
    let fence = new FenceDexie();

    let length = await CalculateLength(feature);

    length = parseFloat(length.toFixed(2));

    fence.wkt = ConvertFeatureToWKT(feature);
    fence.length = length;
    fence.propertyID =this.activeProperty.propertyID;
    fence.wallabyProof = false;
    fence.existing = false;

   let intersectedPaddocks = await this.mapService.getIntersectedPaddocks(feature)

   this.fenceCreated.emit({fence:fence,intersectedPaddockIds:intersectedPaddocks})
  }

   deSelect() {
    if (!this.isActive) return;

    this.map.removeInteraction(this.interaction);
    this.snapInteractions.forEach(snap => {
      this.map.removeInteraction(snap);
    });
    this.isActive = false;
    this.active.emit(this.isActive)

  }

}
