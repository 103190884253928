<div class="uk-column uk-padding-small@m uk-padding-remove@m" [formGroup]="form">

  <div class="uk-column-1-3@m uk-flex-row medium-flex">
      <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
          <label class="uk-flex-1">Is Fencing Required?
              <select formControlName="isFencingRequired" class="uk-select" (change)="fencingRequiredHandler($event)">
                  <option></option>
                  <option>Yes</option>
                  <option>No</option>
              </select>
          </label>
      </div>

      <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
          <label class="uk-flex-1">New Fence Construction Cost ($/m)
              <input class="uk-input" formControlName="fencing" type="number" placeholder=""
                  (keydown)="tempFencingStorageHandler($event)">
          </label>
      </div>

      <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
          <label class="uk-flex-1">Fence Maintenance Cost ($/m)
              <input class="uk-input" formControlName="fencingMaintenance" type="number" placeholder=""
                  (keydown)="tempFencingMaintenanceStorageHandler($event)">
          </label>
      </div>
  </div>

  <div class="uk-column-1-3@m uk-flex-row medium-flex i-am-build-different">
      <div class="uk-column uk-flex  uk-flex-column uk-flex-bottom uk-margin-bottom">
          <label class="uk-flex-1 uk-width-1-1">Ploughing Cost ($/ha)
              <input class="uk-input" formControlName="ploughing" type="number" placeholder="">
          </label>
      </div>

      <div class="uk-column uk-flex  uk-flex-column uk-flex-bottom uk-margin-bottom">
          <label class="uk-flex-1 uk-width-1-1">
              Harvesting and Haulage Costs for Clearfell ($/tonne)
              <input class="uk-input" formControlName="harvestingAndHaulageCostsForClearfell" type="number"
                  placeholder="">
          </label>
      </div>

      <div class="uk-column uk-flex  uk-flex-column uk-flex-bottom uk-margin-bottom">
          <label class="uk-flex-1 uk-width-1-1">Harvesting and Haulage Costs for Thinning ($/tonne)
              <input class="uk-input" formControlName="harvestingAndHaulageCostsForThinning"
                  type="number" placeholder="">
          </label>
      </div>
  </div>


  <div class="uk-column-1-3@m uk-flex-row medium-flex">

      <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
          <label class="uk-flex-1">
              Plantation and Planting Costs ($/tonne)
              <input class="uk-input" formControlName="plantationAndPlantingVosts" type="number" placeholder="">
          </label>
      </div>

      <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
          <label class="uk-flex-1">
              Weeding and Herbicide Costs ($/tonne)
              <input class="uk-input" formControlName="weedingHerbicideCosts" type="number" placeholder="">
          </label>
      </div>

      <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
          <label class="uk-flex-1">
              Distance from Buyer (km)
              <input class="uk-input" (keyup)="calculateTransportationCost($event)" formControlName="distanceFromBuyer" type="number"
                  placeholder="">
          </label>
      </div>
  </div>

  <div class="uk-column-1-1@m uk-flex-row medium-flex">

      <div class="uk-column uk-flex uk-flex-bottom uk-margin-bottom">
          <label class="uk-flex-1">
              Transport Cost ($/tonne)
              <input class="uk-input" formControlName="transportCode" type="number" placeholder="">
          </label>
      </div>

      <!-- This field hidden -->
      <div class="uk-column uk-hidden uk-flex uk-flex-bottom uk-margin-bottom">
          <label class="uk-flex-1">
              Contingency
              <input class="uk-input" formControlName="contingency" type="number" placeholder="">
          </label>
      </div>

  </div>

</div>

<a class="uk-button uk-button-default" href="#confirmation-modal" id="confirmation-modal-button" uk-toggle>Open</a>

<div id="confirmation-modal" uk-modal>
  <div class="uk-modal-dialog">
      <button class="uk-modal-close-default" type="button" uk-close (click)="modalRejectionHandler(false)"></button>
      <div class="uk-modal-header">
          <h2 class="uk-modal-title">Confirm reset</h2>
      </div>
      <div class="uk-modal-body">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
              ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim id est laborum.</p>
      </div>
      <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close" id="modal-rejection" type="button"
              (click)="modalRejectionHandler(false)">Cancel</button>
          <button class="uk-button uk-button-primary uk-modal-close" id="modal-confirmation" type="button"
              (click)="modalRejectionHandler(true)">OK</button>
      </div>
  </div>
</div>
