import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild, ViewRef } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CalculatorModalService } from 'src/app/services/calculator-modal-service.service';
import UIkit from "uikit";

@Component({
  selector: 'app-exit-confirmation',
  templateUrl: './exit-confirmation.component.html',
  styleUrls: ['./exit-confirmation.component.css']
})
export class ExitConfirmationComponent implements OnInit, OnDestroy {

  @Input() localStorageKeyProp: string;
  showHideProp: Subscription;
  value: boolean = false;

  @ViewChild('closeModal') closeMoal: ElementRef;

  constructor(public calculatorService: CalculatorModalService) {
    this.showHideProp = this.calculatorService.exitModalVisible.subscribe((value) => {
      this.value = value
      if (value)
        UIkit.modal(this.closeMoal.nativeElement).show();
    });
  }

  ngOnChanges(sc: SimpleChange, scs: SimpleChanges) {
  }

  ngOnInit(): void {
    this.calculatorService
  }

  ngOnDestroy(): void {
    this.showHideProp.unsubscribe();
    UIkit.modal(this.closeMoal?.nativeElement).hide();
  }

}
