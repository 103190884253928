
import { v4 as uuidv4 } from 'uuid';

export class PaddockDexie {

    paddockID: string = uuidv4();

    name: string;

    landUseType: string;

    enterpriseType: string;

    wkt: string;

    area: number;

    notes?:string;

    propertyID: string;

    uploaded:boolean = false;

    createdOn: string = new Date().toUTCString();

    modifiedOn: string = new Date().toUTCString();

}
