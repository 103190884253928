<h2 mat-dialog-title>Crop Suitability Report</h2>
<mat-dialog-content class="mat-typography">

  <mat-tab-group>
    <mat-tab *ngFor="let report of dssReports; first as isFirst" >
      <ng-template mat-tab-label>
        <span class="header-spacer" *ngIf="isFirst"><i class="fa fa-house-damage"></i>{{report.target.name}}</span>
        <span class="header-spacer" *ngIf="!isFirst"><i class="fa fa-vector-square"></i>{{report.target.name}}</span>
    </ng-template>
      <section class="reporting-section">
        <header class="reporting-header">
           <span>Reporting Area: <b>{{ report.target?.area | round: 1 }}</b>(ha)</span>
           <span *ngIf="report.totalAreaNotCovered >= 0.5"> Note that <b>{{report?.totalAreaNotCovered | round: 1}}</b>(ha) of this area was missing Enterprise Suitability coverage so cannot be reported on</span>
          <div class="reporting-header__actions">
            <button (click)="export(report)" class="button button--blue">Export to PDF</button>
          </div>
        </header>
        <main>
          <table mat-table [dataSource]="report.enterpriseReport.data" [paginator]="paginator" class="mat-elevation-z8">

            <ng-container matColumnDef="rank">
              <th mat-header-cell *matHeaderCellDef> Rank </th>
              <td mat-cell *matCellDef="let element"> {{element.rank | ordinal}} </td>
            </ng-container>


            <ng-container matColumnDef="image">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element"> <img width="50px" height="50px" src="/assets/images/report-icons/{{element.crop.name}}.svg"/></td>
            </ng-container>

              <ng-container matColumnDef="crop">
              <th mat-header-cell *matHeaderCellDef> Crop </th>
              <td mat-cell *matCellDef="let element"> <a target="_blank" [href]="fixUrl(element.crop.detailsLink)">{{ element.crop.label }}</a><sup> <a target="_blank" [href]="fixUrl(element.crop.rulesLink)"><i class="fa fa-info-circle"></i></a></sup></td>
            </ng-container>


            <ng-container matColumnDef="wellSuited">
              <th mat-header-cell *matHeaderCellDef>  Well Suited (ha) </th>
              <td mat-cell *matCellDef="let element"> {{element.wellSuited | round: 1}} </td>
            </ng-container>

            <ng-container matColumnDef="suitable">
              <th mat-header-cell *matHeaderCellDef> Suitable (ha) </th>
              <td mat-cell *matCellDef="let element"> {{element.suitable | round: 1}} </td>
            </ng-container>


            <ng-container matColumnDef="moderatelySuitable">
              <th mat-header-cell *matHeaderCellDef> Moderately Suitable (ha) </th>
              <td mat-cell *matCellDef="let element"> {{element.moderatelySuitable | round: 1}} </td>
            </ng-container>


            <ng-container matColumnDef="unsuitable">
              <th mat-header-cell *matHeaderCellDef> Unsuitable (ha) </th>
              <td mat-cell *matCellDef="let element"> {{element.unsuitable | round: 1}} </td>
            </ng-container>

            <ng-container matColumnDef="showOnMap">
              <th mat-header-cell *matHeaderCellDef> Actions </th>
              <td mat-cell *matCellDef="let element"> <button class="button button--brand" (click)="showCrop(element.crop,report.target)">Show on Map</button> </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </main>
      </section>
    </mat-tab>
  </mat-tab-group>




</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      close
    </button>
  </footer>
</mat-dialog-actions>
