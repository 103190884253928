<div id="exit-modal-confimation" bg-close="false" #closeModal uk-modal="" class="uk-modal" tabindex="-1">
    <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">Exit confirmation!</h2>
        <p>You are about to leave the page; however, you have some unsaved changes. Complete the form and hit submit to ensure that you data is saved to the database for later retrieval. However, if you wish to discard the changes and leave the page then press "Exit"</p>
        <!-- <p class="uk-text-right"> -->
        <p class="uk-flex uk-flex-between">
            <button type="button" class="uk-button uk-button-default uk-modal-close" (click)="calculatorService.setShowExitModal(false)">Complete form</button>
            <button type="button" class="uk-button uk-button-danger" (click)="calculatorService.confirmExitModalPopupHandler(localStorageKeyProp)">Exit</button>
        </p>
    </div>
</div>