import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Graticule, PluggableMap } from 'ol';
import { Control } from 'ol/control';
import VectorLayer from 'ol/layer/Vector';
import { Stroke } from 'ol/style';
import { SpatialTool } from 'src/app/models/spatial-tools-mdoel';

@Component({
  selector: 'app-graticle',
  templateUrl: './graticle.component.html',
  styleUrls: ['./graticle.component.css'],
})
export class GraticleComponent implements OnInit, AfterViewInit, OnDestroy,SpatialTool {
  @Input() map: PluggableMap;

  active = false;
  layer: VectorLayer<any>;
  control: Control;

  constructor(private elRef:ElementRef) {}

  ngOnInit(): void {
    this.layer = new Graticule({
      strokeStyle: new Stroke({
        color: '#ffa500',
        width: 2,
        lineDash: [0.5, 4],
      }),
      showLabels: true,
      wrapX: false,
    });
  }

  ngAfterViewInit(): void {
    this.control = new Control({target: this.elRef.nativeElement.parentNode, element: this.elRef.nativeElement});
    this.map.addControl(this.control);
  }

  ngOnDestroy(): void {
    if(this.control == null)
    return;

    this.map.removeControl(this.control);
    this.deSelect();
  }

  toggleGraticle = () => {
    if (this.active) {
      this.deSelect();
    } else {
      this.map.addLayer(this.layer);
      this.active = true;
    }
  };
  deSelect() {
    if (!this.active) return;

    this.map.removeLayer(this.layer);

    this.active = false;
  }
}
