import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {

      const date = new Date(value);

      if (isNaN(date.getTime()))
      {
        return ''
      }
      // Format the date to the user's local time
      const formattedDate = date.toLocaleString();

      return formattedDate;
     }


}
